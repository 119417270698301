import { useCallback, useMemo } from 'react';
import { Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { OpenFlyZoneMesh } from 'shared/map/features/open-fly-zone-mesh/open-fly-zone-mesh';
import { findOverlapForZones } from 'shared/map/utils/zone-overlap/find-overlap-for-zones';
import { OverlappingZone } from 'shared/map/utils/zone-overlap/overlapping-zone.model';
import { DroneZoneGroupCountBadge } from 'shared/map/features/drone-zone-overlap-mesh/drone-zone-group-count-badge';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { ControlledZoneMesh } from 'shared/map/features/controlled-zone-mesh/controlled-zone-mesh';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { OpenFlyZone, OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { meshHeightOrderOffset } from 'shared/map/defaults/mesh-render-order';
import { NoFlyZoneMesh } from './components/noFlyZoneMesh/noFlyZoneMesh';
import { DroneZoneType, DroneZoneTypes, DroneZones } from '../model/droneZones.model';
import { NoFlyZoneDrawer } from './NoFlyZoneDrawer';
import { DroneZoneMapOverlay } from './DroneZoneMapOverlay';

export const DroneZonesMapElements = ({
  noFlyZoneDrawPositionStart,
  noFlyZones,
  visibleZones,
  openFlyZones,
  controlledZones,
  worldBox,
  onMapOverlayPointerEnter,
  onMapOverlayPointerLeave,
  isMapOverlayVisible,
  onDroneZoneGroupClick,
  onDroneZoneDragEnd,
  onDroneZoneClick,
  onDroneZoneDragStart,
  options,
}: {
  noFlyZones: NoFlyZones;
  controlledZones: ControlledZones;
  openFlyZones: OpenFlyZones;
  visibleZones: Record<DroneZoneType, boolean>;
  worldBox: Vec6;
  onDroneZoneDragEnd: (zone: NoFlyZone) => void;
  onDroneZoneClick: (zone: NoFlyZone | ControlledZone | OpenFlyZone) => void;
  onDroneZoneGroupClick: (zones: DroneZones[]) => void;
  onDroneZoneDragStart: () => void;
  options: MapOptions;
  noFlyZoneDrawPositionStart?: Vector3;
  onMapOverlayPointerEnter?: (e: ThreeEvent<PointerEvent>) => void;
  onMapOverlayPointerLeave?: (e: ThreeEvent<PointerEvent>) => void;
  isMapOverlayVisible: boolean;
}) => {
  const { minZ, maxZ } = mapFacilityVectors(worldBox);
  const facilityHeight = maxZ - minZ;

  const noFlyZoneMeshes = useMemo(() => {
    const zonesToRender = Object.values(noFlyZones);

    return visibleZones[DroneZoneTypes.noFlyZone]
      ? zonesToRender.map((zone) => (
          <NoFlyZoneMesh
            key={zone.id}
            droneZone={zone}
            options={options}
            onClick={() => onDroneZoneClick(zone)}
            onDrag={onDroneZoneDragStart}
            onDragEnd={onDroneZoneDragEnd}
            onDragStart={onDroneZoneDragStart}
            isMapOverlayVisible={isMapOverlayVisible}
          />
        ))
      : [];
  }, [
    noFlyZones,
    onDroneZoneClick,
    onDroneZoneDragEnd,
    onDroneZoneDragStart,
    isMapOverlayVisible,
    options,
    visibleZones,
  ]);

  const openFlyZoneMeshes = useMemo(() => {
    const zonesToRender = Object.values(openFlyZones);

    return visibleZones[DroneZoneTypes.openFlyZone]
      ? zonesToRender.map((zone) => (
          <OpenFlyZoneMesh
            key={zone.id}
            droneZone={zone}
            options={options}
            onClick={() => onDroneZoneClick(zone)}
          />
        ))
      : [];
  }, [openFlyZones, onDroneZoneClick, options, visibleZones]);

  const overlappingControlledZones: [string, OverlappingZone][] = useMemo(
    () => Object.entries(findOverlapForZones(controlledZones)),
    [controlledZones],
  );

  const droneZoneGroupCountLabel = useMemo(
    () =>
      visibleZones[DroneZoneTypes.controlledZone]
        ? overlappingControlledZones.map(([id, overlap]) => (
            <DroneZoneGroupCountBadge
              key={`${id}-zone-count-label`}
              zones={overlap.zones}
              isMapOverlayVisible={isMapOverlayVisible}
              options={options}
              position={
                new Vector3(
                  overlap.centerPosition.x,
                  overlap.centerPosition.y,
                  facilityHeight + meshHeightOrderOffset.groups,
                )
              }
              onClick={() => onDroneZoneGroupClick(overlap.zones)}
            />
          ))
        : [],
    [
      onDroneZoneGroupClick,
      options,
      overlappingControlledZones,
      visibleZones,
      facilityHeight,
      isMapOverlayVisible,
    ],
  );

  const allOverlappingZones = overlappingControlledZones
    .map(([_id, overlap]) => (overlap.zones.length > 1 ? overlap.zones : []))
    .flat();

  const isZoneOverlapping = useCallback(
    (droneZone: DroneZones) =>
      allOverlappingZones.find(
        (overlappingZone: DroneZones) => overlappingZone.id === droneZone.id,
      ) !== undefined,
    [allOverlappingZones],
  );

  const controlledZoneMeshes = useMemo(
    () =>
      visibleZones[DroneZoneTypes.controlledZone]
        ? Object.values(controlledZones).map((zone: ControlledZone) => (
            <ControlledZoneMesh
              key={zone.id}
              isLabelVisible={isZoneOverlapping(zone) ? zone.isSelected : true}
              isMapOverlayVisible={isMapOverlayVisible}
              droneZone={zone}
              options={options}
              onClick={() => onDroneZoneClick(zone)}
              onDragEnd={onDroneZoneDragEnd}
            />
          ))
        : [],
    [
      visibleZones,
      controlledZones,
      isMapOverlayVisible,
      isZoneOverlapping,
      options,
      onDroneZoneDragEnd,
      onDroneZoneClick,
    ],
  );

  const mapElements = [
    ...noFlyZoneMeshes,
    ...openFlyZoneMeshes,
    ...controlledZoneMeshes,
    ...droneZoneGroupCountLabel,
  ];

  return (
    <>
      {isMapOverlayVisible && (
        <DroneZoneMapOverlay
          worldBox={worldBox}
          onPointerEnter={onMapOverlayPointerEnter}
          onPointerLeave={onMapOverlayPointerLeave}
        />
      )}

      {mapElements}

      {noFlyZoneDrawPositionStart && (
        <NoFlyZoneDrawer
          key="nfz-creator"
          drawPositionStart={noFlyZoneDrawPositionStart}
          worldBox={worldBox}
        />
      )}
    </>
  );
};
