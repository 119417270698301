import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment';
import { useState } from 'react';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ControlledZoneService } from 'services/ControlledZone';
import { ControlledZones } from 'shared/map/model/controlled-zone.model';
import { Box } from 'components/common/Box';
import { BackDropLoader } from '../../../../../Schedule/ScheduleLock/BackDropLoader';
import { ToggleAllAction, ControlledZonesToggleAction } from './controlledZoneToggleAll.model';
import { ControlledZoneToggleAllModal } from './ControlledZoneToggleAllModal/ControlledZoneToggleAllModal';

const LOG_PREFIX: string = getLogPrefixForType('COMPONENT', 'ControlledZoneToggleAll');

export const ControlledZoneToggleAll = ({
  zones,
  systemId,
  flightDomainId,
}: {
  zones: ControlledZones;
  systemId: string;
  flightDomainId: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failedIds, setFailedIds] = useState<string[]>([]);
  const [toggleAction, setToggleAction] = useState<ControlledZonesToggleAction | null>(null);

  const isActivateAllDisabled =
    Object.values(zones).every((z) => z.isActive) ||
    Object.values(zones).some((z) => z.waitingForDronesToComeHome) ||
    isLoading;
  const isDeactivateAllDisabled = Object.values(zones).every((z) => !z.isActive) || isLoading;

  const handleCloseDialog = () => {
    setFailedIds([]);
    setToggleAction(null);
  };

  const toggleControlledZones = async (action: ControlledZonesToggleAction) => {
    const interval =
      action === ToggleAllAction.deactivate
        ? []
        : [{ start_time: moment.utc().format(DATETIME_FORMAT), end_time: null }];

    setIsLoading(true);
    try {
      const res = await ControlledZoneService.toggleControlledZoneActiveState({
        systemId,
        flightDomainId,
        payload: { enabled_interval_list: interval },
      });

      if (res.data.failed_ids.length) {
        setFailedIds(res.data.failed_ids);
        setToggleAction(action);
      }
    } catch (error) {
      console.error(LOG_PREFIX, error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <BackDropLoader isVisible={isLoading} />
        <ButtonGroup variant="text" aria-label="text button group" size="small">
          <Button
            disabled={isDeactivateAllDisabled}
            onClick={() => toggleControlledZones('deactivate')}
            sx={{ borderRadius: 'unset' }}
          >
            Deactivate all
          </Button>
          <Button
            sx={{ borderRadius: 'unset' }}
            disabled={isActivateAllDisabled}
            onClick={() => toggleControlledZones('activate')}
          >
            Activate all
          </Button>
        </ButtonGroup>
      </Box>

      {!!toggleAction && (
        <ControlledZoneToggleAllModal
          action={toggleAction}
          numberOfFailedZones={failedIds.length}
          onClick={toggleControlledZones}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
};
