import { Dispatch } from 'react';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import upperFirst from 'lodash/upperFirst';
import { RRule, rrulestr, Weekday } from 'rrule';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

// variables, functions, configurations
import {
  IReportSpecificationST,
  IReportParameterST,
  IReportParameterUniqueNameST,
} from 'codegen/report_specification';
import {
  ScheduleFormAction,
  ScheduleFormState,
} from 'udb/features/reports/features/report-scheduler/reducers/ScheduleFormReducer';
import { INPUT_ERROR } from './errorMessages';
import {
  INPUT_MODES,
  WIDGET_TYPES,
  VALUE_TYPES,
  PARAM_TYPES,
  TRIGGERING_SOURCE,
} from './reportSpecifications';
import { DATETIME_FORMAT, WeekDayShort } from './datetimeFormats';
import { RRULE_WEEKDAYS, RRULE_INTERVALS } from './weekDays';
import { singleRequestHandler } from './requestHelpers';

// store
import RequestStore from '../store/RequestStore/RequestStore';
import { getSanitizedInputValues } from './functions/otherFunctions';

export const handleReportNameChange = ({
  reportName,
  setReportName,
  setErrors,
  validateReportName,
}: {
  reportName: string;
  setReportName: (name: string) => void;
  setErrors: any;
  validateReportName: (reportName: string) => any;
}) => {
  setReportName(reportName);
  setErrors((currentErrors: any) => ({
    ...currentErrors,
    validReportName: validateReportName(reportName).errorMsg,
  }));
};

// Validate ONE param, return an up-to-date list of errors for all params
export const validateParamValue = ({
  setErrors,
  reportSpec,
  reportParamValues,
  paramUniqueName, // to get errors displayed only on the changing form field we need
}: {
  setErrors: any;
  reportSpec: any;
  reportParamValues: any;
  paramUniqueName: string; // to get errors displayed only on the changing form field we need
}) => {
  setErrors((currentErrors: any) => {
    // Get all new errors for all params
    const allNewErrors = validateAllParamValues(reportSpec, reportParamValues).errorMessages;
    // Get new errors for the given paramUniqueName
    const paramErrors =
      allNewErrors.filter(
        (errorMsg: { unique_name: string }) => errorMsg.unique_name === paramUniqueName,
      ) || [];
    // Get all current errors, except the ones for the given paramUniqueName
    const currentErrorsAux =
      currentErrors?.validParams?.filter(
        (p: { unique_name: string }) => p.unique_name !== paramUniqueName,
      ) || [];
    // Return up to date error list
    return {
      ...currentErrors,
      validParams: [...paramErrors, ...currentErrorsAux],
    };
  });
};

// TODO::TR::2021-06-09::
//   - Merge handleDateFromChange and handleDateUntilChange into on function
//   - Modularize date validation a bit better
export const handleDateFromChange = ({
  reportSpec,
  isRecurring,
  dateFrom,
  dateUntil,
  setDateFrom,
  setDateUntil,
  setErrors,
  validateStartDate,
  validateEndDate,
  allowPastStartDate = false,
}: {
  reportSpec: any;
  isRecurring: boolean;
  dateFrom: string;
  dateUntil: string;
  setDateFrom: any;
  setDateUntil: any;
  setErrors: any;
  validateStartDate: any;
  validateEndDate: any;
  allowPastStartDate: boolean;
}) => {
  setDateFrom(dateFrom);

  // if newly selected dateFrom is after dateUntil,
  // set dateUntil to be one hour after dateFrom
  const newDateUntil = moment(dateFrom).isAfter(moment(dateUntil))
    ? moment(dateFrom).add(1, 'hours')
    : dateUntil;

  setDateUntil(newDateUntil);

  setErrors((currentErrors: any) => ({
    ...currentErrors,
    validDateFrom: validateStartDate(
      reportSpec,
      isRecurring,
      dateFrom,
      newDateUntil,
      allowPastStartDate,
    ).errorMsg,
    validDateUntil: validateEndDate(reportSpec, isRecurring, dateFrom, newDateUntil).errorMsg,
  }));
};

export const handleDateUntilChange = ({
  reportSpec,
  isRecurring,
  dateFrom,
  dateUntil,
  setDateUntil,
  setErrors,
  validateStartDate,
  validateEndDate,
  allowPastStartDate,
}: {
  reportSpec: any;
  isRecurring: boolean;
  dateFrom: string;
  dateUntil: string;
  setDateUntil: any;
  setErrors: any;
  validateStartDate: any;
  validateEndDate: any;
  allowPastStartDate: boolean;
}) => {
  setDateUntil(dateUntil);
  setErrors((currentErrors: any) => ({
    ...currentErrors,
    validDateFrom: validateStartDate(
      reportSpec,
      isRecurring,
      dateFrom,
      dateUntil,
      allowPastStartDate,
    ).errorMsg,
    validDateUntil: validateEndDate(reportSpec, isRecurring, dateFrom, dateUntil).errorMsg,
  }));
};

export const validateReportName = (reportName: string) => ({
  valid: Boolean(reportName.length),
  errorMsg: !reportName ? INPUT_ERROR.REPORT_NAME : '',
});

// Validate dynamic params for a given report pec
// These exclude reportName, dates, and recurrence (which are common to all reports)
export const validateAllParamValues = (reportSpec: { params: any[] }, reportParamValues: any[]) => {
  const errorMessages: any = [];

  // Get params that need validation from the report spec
  const paramsThatNeedValidation = reportSpec.params
    .filter((param: { enabled: boolean }) => param.enabled)
    .filter(
      (param: { input_mode: string }) =>
        param.input_mode !== INPUT_MODES.AUTOMATIC && param.input_mode !== INPUT_MODES.FIXED_VALUE,
    );

  // Ensure that all params have values set
  // if that is not the case, add an error message for invalid param values
  paramsThatNeedValidation.forEach(
    (param: {
      unique_name: string;
      value_type: string;
      input_settings: { lower_bound: any; upper_bound: any };
    }) => {
      const paramValue = reportParamValues?.find(
        (el: { unique_name: string }) => el.unique_name === param.unique_name,
      );
      if (isEmpty(paramValue)) {
        errorMessages.push({
          unique_name: param.unique_name,
          error: INPUT_ERROR.DEFAULT,
        });
      } else if (
        param.value_type === VALUE_TYPES.INTEGER ||
        param.value_type === VALUE_TYPES.DECIMAL
      ) {
        const { lower_bound: lowerBound, upper_bound: upperBound } = param.input_settings;
        if (
          (isNumber(lowerBound) && lowerBound > paramValue.values[0]) ||
          (isNumber(upperBound) && upperBound < paramValue.values[0])
        ) {
          if (param.unique_name === PARAM_TYPES.LOCATIONS_PERCENTAGE) {
            errorMessages.push({
              unique_name: param.unique_name,
              error: INPUT_ERROR.OUT_OF_RANGE(
                (lowerBound * 100).toString(),
                (upperBound * 100).toString(),
              ),
            });
          } else {
            errorMessages.push({
              unique_name: param.unique_name,
              error: INPUT_ERROR.OUT_OF_RANGE(lowerBound, upperBound),
            });
          }
        }
      }
    },
  );

  return { valid: isEmpty(errorMessages), errorMessages };
};

export const validateStartDate = (
  reportSpec: IReportSpecificationST,
  isRecurring: boolean,
  dateFrom: moment.MomentInput,
  dateUntil: moment.MomentInput,
  allowPastDates = false,
) => {
  const validate = (errorMsg: string) => ({
    valid: errorMsg === '',
    errorMsg,
  });

  // if invalide date
  if (!moment(dateFrom).isValid()) {
    return validate(INPUT_ERROR.INVALID_DATE);
  }

  // if dateFrom is before currnet time - 5 minutes
  if (
    moment(dateFrom).isBefore(moment().subtract(5, 'minutes').format(DATETIME_FORMAT)) &&
    !allowPastDates
  ) {
    return validate(INPUT_ERROR.PAST_DATE);
  }

  // if dates are the same
  if (
    moment(dateFrom).isSame(moment(dateUntil)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.SAME_DATE);
  }

  // if dateFrom is after dateUntil
  if (
    moment(dateFrom).isAfter(moment(dateUntil)) &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) ||
      !reportSpec?.scheduling?.allows_recurrence)
  ) {
    return validate(INPUT_ERROR.AFTER_UNTIL);
  }

  // validation passed
  return validate('');
};

export const validateEndDate = (
  reportSpec: IReportSpecificationST,
  isRecurring: boolean,
  dateFrom: moment.MomentInput,
  dateUntil: moment.MomentInput,
) => {
  const validate = (errorMsg: string) => ({
    valid: errorMsg === '',
    errorMsg,
  });

  // if invalid date
  if (
    !moment(dateUntil).isValid() &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) || dateUntil)
  ) {
    return validate(INPUT_ERROR.INVALID_DATE);
  }

  // if dateUntil is before current time
  if (
    moment(dateUntil).isBefore(moment().format(DATETIME_FORMAT)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.PAST_DATE);
  }

  // if dates are the same
  if (
    moment(dateFrom).isSame(moment(dateUntil)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.SAME_DATE);
  }

  // if dateUntil is before dateFrom
  if (
    moment(dateUntil).isBefore(dateFrom) &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) || dateUntil)
  ) {
    return validate(INPUT_ERROR.BEFORE_FROM);
  }

  // validation passed
  return validate('');
};

// Function for occurrence interval input validation
export const validateOccurrenceInterval = (
  isRecurring: boolean,
  value: number,
  maximumAllowed: number,
) => {
  let errorMsg = '';
  if (isRecurring) {
    if (value > maximumAllowed) {
      errorMsg = `Maximum allowed ${maximumAllowed > 1 ? 'occurrences' : 'occurrence'} ${
        maximumAllowed > 1 ? `are ${maximumAllowed}` : `is ${maximumAllowed}`
      }`;
    }
    if (value < 1) {
      errorMsg = "Occurrence can't be lower than 1";
    }
  }
  return {
    valid: errorMsg === '',
    errorMsg,
  };
};

// Function for interval input validation
export const validateRecurrenceInterval = (interval: any, isRecurring: boolean) => {
  let errorMsg = '';
  if (isRecurring) {
    if (!interval) {
      errorMsg = 'Please select recurring interval';
    }
  }
  return {
    valid: errorMsg === '',
    errorMsg,
  };
};

// Check if a given reportSpec param should render a single choice select UI component
// TODO::TR::2021-06-09:: create enumerator for all UI widgets, and functions to identify them
export const isParamSingleChoiceSelect = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.PREDEFINED_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.DROP_DOWN &&
  param?.cardinality_max === 1;

// Check if a given reportSpec param should render a multi choice select UI component
export const isParamMultiChoiceSelect = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.PREDEFINED_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.DROP_DOWN &&
  (param?.cardinality_max === -1 || param?.cardinality_max > 1);

// Check if a given reportSpec param should render a text input UI component
export const isParamTextInput = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.CUSTOM_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.TEXT_FIELD;

export const getRecurrenceText = (
  reportType: string | string[],
  isRecurring: boolean,
  text: string | undefined,
) => {
  if (reportType.includes('Auto')) {
    return 'Auto';
  }
  return isRecurring ? upperFirst(text) : '-';
};

export interface IGenerateRruleFromUIComponentsParam {
  days: WeekDayShort[];
  setDays: any;
  isRecurring: boolean;
  dateFrom: any;
  dateUntil: any;
  occurrenceInterval: any;
  timezone: any;
  interval: 'yearly' | 'monthly' | 'weekly' | 'daily' | 'hourly';
  reportSpecTriggering: any;
}
// Convert defined dates, intervals using rrule.js library
// module for parsing and serialization of recurrence rules
export const generateRruleFromUIComponents = ({
  days,
  setDays,
  isRecurring,
  dateFrom,
  dateUntil,
  occurrenceInterval,
  timezone,
  interval,
  reportSpecTriggering,
}: IGenerateRruleFromUIComponentsParam) => {
  let rule;
  let rRuleDays: WeekDayShort[] | Weekday[] = [];
  const weekDaysList = Object.keys(RRULE_WEEKDAYS) as WeekDayShort[];

  // Set current day as active weekDay in case there is no selected day
  if (isEmpty(days) && interval === 'weekly') {
    rRuleDays = weekDaysList.filter((day, index) => index === moment(dateFrom).weekday());
    setDays(rRuleDays);
  }

  rRuleDays = days.map((day) => RRULE_WEEKDAYS[day]);

  // We extract this constants because current version
  // of rrrule.js only work with 'Date'
  const yearFrom = moment(dateFrom).year();
  const monthFrom = moment(dateFrom).month();
  const dayFrom = Number(moment(dateFrom).format('DD'));
  const hoursFrom = moment(dateFrom).hours();
  const minutesFrom = moment(dateFrom).minutes();

  const yearUntilUTC = moment.utc(dateUntil).year();
  const monthUntilUTC = moment.utc(dateUntil).month();
  const dayUntilUTC = Number(moment.utc(dateUntil).format('DD'));
  const hoursUntilUTC = moment.utc(dateUntil).hours();
  const minutesUntilUTC = moment.utc(dateUntil).minutes();

  const dtstart = new Date(Date.UTC(yearFrom, monthFrom, dayFrom, hoursFrom, minutesFrom));
  const until = new Date(
    Date.UTC(yearUntilUTC, monthUntilUTC, dayUntilUTC, hoursUntilUTC, minutesUntilUTC),
  );

  // Create a rrule with defined dates and intervals
  if (isRecurring && interval) {
    // recurring request
    rule = new RRule({
      dtstart,
      until,
      freq: RRULE_INTERVALS[interval],
      interval: occurrenceInterval,
      byweekday: isEmpty(rRuleDays) || interval !== 'weekly' ? null : rRuleDays,
      tzid: timezone,
    });
  } else {
    // non-recurring and event based requests
    rule = new RRule({
      dtstart,
      until: reportSpecTriggering === TRIGGERING_SOURCE.EXTERNAL_EVENT ? until : null,
      count: reportSpecTriggering === TRIGGERING_SOURCE.EXTERNAL_EVENT ? 0 : 1,
      freq: RRule.SECONDLY,
      tzid: timezone,
    });
  }

  return {
    rString: rule.toString(),
  };
};

// Parse rRule string to object and return extracted values
export const convertRruleToUIComponents = (rrule: string) => {
  const days: WeekDayShort[] = [];
  const ruleDays = Object.keys(RRULE_WEEKDAYS) as WeekDayShort[];
  const ruleIntervals = Object.keys(RRULE_INTERVALS);
  const ruleObj = rrulestr(rrule); // convert rrule string to object

  // handle weekDays
  ruleObj.options.byweekday?.forEach((day) => {
    days.push(ruleDays[day]);
  });

  return {
    isRecurring: ruleObj.options.count === null,
    dtstart: ruleObj.options.dtstart,
    until: ruleObj.options.until,
    interval: ruleObj.options.interval,
    freq: ruleIntervals[ruleObj.options.freq],
    text: ruleObj.toText(),
    tzid: ruleObj.options.tzid,
    days,
  };
};

export const awaitGetNextOccurrence = (
  systemId: string,
  rrule: string,
  next_scheduling_time_utc: string,
  include: any,
) =>
  singleRequestHandler({
    request: RequestStore.getNextOccurrence,
    requestParams: [systemId, rrule, next_scheduling_time_utc, include],
    messageErrorFallback: 'Something went wrong while getting next occurrence',
  });

/**
 * Changes user given value on its way to the state
 * @param paramUniqueName name of the parameter
 * @param value value of the parameter
 * @returns prepared value for the state
 */
export const prepareParamValue = (
  paramUniqueName: IReportParameterUniqueNameST,
  values: any,
): string[] => {
  switch (paramUniqueName) {
    case PARAM_TYPES.LOCATIONS_PERCENTAGE:
      return isEmpty(values) ? [values] : [(parseFloat(values) / 100).toString()];
    default:
      return getSanitizedInputValues(values);
  }
};

/**
 * Get param spec
 * @param reportSpec report specification
 * @param paramUniqueName param unique name
 * @returns report spec params
 */
export const getParamSpec = (
  reportSpec: IReportSpecificationST,
  paramUniqueName: IReportParameterUniqueNameST,
) => reportSpec.params.find((param: IReportParameterST) => param.unique_name === paramUniqueName);

/**
 * Get recurrence data
 * @param systemId system id
 * @param rString r string
 * @param dispatch dispatch function
 * @param enqueueSnackbar snackback function
 * @returns promise for request call
 */
export const getRecurrenceData = (
  systemId: string,
  rString: string,
  dispatch: Dispatch<ScheduleFormAction>,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
): Promise<any> =>
  singleRequestHandler({
    request: RequestStore.countOccurrences,
    requestParams: [systemId, rString],
    callbackBeforeSend: () => dispatch({ type: 'SET_IS_LOADING', isLoading: true }),
    dispatcher: enqueueSnackbar,
    callbackSuccess: (r) =>
      dispatch({
        type: 'SET_RECURRENCE_DATA',
        payload: {
          occurrencesCount: r.data.count,
          countCapped: r.data.capped,
          rText: r.data.description,
          nextOccurrence: r.data.first_occurrence,
        },
      }),
    callbackFinally: () => dispatch({ type: 'SET_IS_LOADING', isLoading: false }),
  });

/**
 * Get recurrence data for deadlines
 * @param systemId system id
 * @param rString r string
 * @param dispatch dispatch function
 * @returns promise for request call
 */
export const getRecurrenceDataReportDeadline = (
  systemId: string,
  rString: string,
  dispatch: Dispatch<ScheduleFormAction>,
): Promise<any> =>
  singleRequestHandler({
    request: RequestStore.countOccurrences,
    requestParams: [systemId, rString],
    callbackBeforeSend: () => dispatch({ type: 'SET_IS_LOADING', isLoading: true }),
    callbackSuccess: (r) => {
      dispatch({
        type: 'SET_REPORT_DEADLINE_TEXT',
        payload: `The report will finish ${r.data.description}`.replace('at', 'by'),
      });
    },
    callbackFinally: () => dispatch({ type: 'SET_IS_LOADING', isLoading: false }),
  });

/**
 * Get a human-readable text from rString value
 * @param dispatch dispatch function
 * @param scheduleFormState ScheduleFormState params
 * @param reportSpecTriggering reports specification triggering
 * @param timezone users timezone
 * @returns r string
 */
export const getRString = (
  dispatch: Dispatch<ScheduleFormAction>,
  scheduleFormState: ScheduleFormState,
  reportSpecTriggering: string,
  timezone: string,
) =>
  generateRruleFromUIComponents({
    days: scheduleFormState.days,
    setDays: (newDays: WeekDayShort[]) => dispatch({ type: 'SET_DAYS', payload: newDays }),
    isRecurring: scheduleFormState.isRecurring,
    dateFrom: scheduleFormState.dateFrom,
    dateUntil: scheduleFormState.dateUntil,
    occurrenceInterval: scheduleFormState.occurrenceInterval,
    timezone,
    reportSpecTriggering,
    interval: scheduleFormState.interval,
  });
