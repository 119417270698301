import { Container, Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useParams, useSearchParams } from 'react-router-dom';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { getAmendingRowsData, getLocationListData, sortRows } from 'common/Tables/tableFunctions';
import { REPORT_STATES } from 'common/reportStates';
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem, {
  IBaseCardSimpleItemSectionItems,
} from 'components/BaseCard/BaseCardSimpleItem';
import { Box } from 'components/common/Box';
import EnhancedTable from 'components/EnhancedTable';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { useFacilityModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { ReportSummaryData } from '../../reducer/report-store/ReportStore';
import { IssueSummary, IssueSummaryCard } from './IssueSummaryCard';
import { CurrentTab } from './utils/GetTabData';

type ReportProps = {
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  summarySpinner: number;
  summaryData: ReportSummaryData;
  currentTabData: CurrentTab;
  reportState: string;
  tabs: { icon: JSX.Element; label: string; show: boolean }[];
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  changeTab: (currentTab: number) => void;
  activeTab: number;
  reviewLocationsTab: number;
  fullReportSpinner: number;
  currentActiveSpinner: {};
};

/**
 * Report
 * @param props ReportProps
 * @returns component
 */
export const Report = ({
  canReviewReport,
  reportNeedsReview,
  issuesSpinner,
  issuesSummary,
  summarySpinner,
  summaryData,
  currentTabData,
  reportState,
  tabs,
  getReportData,
  changeTab,
  activeTab,
  reviewLocationsTab,
  fullReportSpinner,
  currentActiveSpinner,
}: ReportProps) => {
  const { reportId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const searchTerm = searchParams.get('searchTerm') || '';
  const orderBy = searchParams.get('orderBy') || '';
  const order = (searchParams.get('order') as OrderValue) || '';

  const onRowClick = (e: KeyboardEvent, row: ILocationReportData) => {
    if (reportNeedsReview && activeTab === reviewLocationsTab) {
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
        payload: getAmendingRowsData(sortRows(currentTabData.rows, order, orderBy), row, reportId),
        refreshData: {
          refreshData: () =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
        },
        tableAmending: true,
      });
      searchParams.set('amendLocation', row.location);
      setSearchParams(searchParams);
    } else if (reportState !== REPORT_STATES.DELETED) {
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
        payload: getLocationListData(
          filterOrderTableRows(
            currentTabData.rows,
            searchTerm,
            orderBy,
            order,
            currentTabData.headCells,
          ),
          row,
        ),
        refreshDataOnClose: {
          refreshData: () =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
        },
        parentPage: 'Report',
        reportId,
        locationInfo: { tab: currentTabData.tableTitle, searchTerm },
      });
      searchParams.set('location', row.location);
      setSearchParams(searchParams);
    }
  };
  return (
    <Container maxWidth="xl">
      <Grid spacing={3} container className="c-page-content">
        {(!canReviewReport || !reportNeedsReview) && (
          <Grid md={6} sm={12} xs={12} item>
            <Grid md={12} sm={12} xs={12} item>
              <IssueSummaryCard isLoading={!!issuesSpinner} issuesSummary={issuesSummary} />
            </Grid>
          </Grid>
        )}

        <Grid md={6} sm={12} xs={12} item>
          <Grid md={12} sm={12} xs={12} item>
            <BaseCard
              cardFor="summary data"
              showHeader={true}
              showContent={true}
              showActionButtons={false}
              showHeaderDivider={true}
              isLoading={summarySpinner}
              title={summaryData.title}
              subtitle={summaryData.subtitle}
              counter={summaryData.counter}
              counterSubject={summaryData.counterSubject}
            >
              {summaryData.sections &&
                summaryData.sections.map((section) => (
                  <BaseCardSimpleItem
                    key={section.id}
                    sectionItems={section.sectionItems as IBaseCardSimpleItemSectionItems[]}
                  />
                ))}
            </BaseCard>
          </Grid>
        </Grid>
      </Grid>
      {!isEmpty(tabs) && (
        <Box>
          <EnhancedTable
            reportId={reportId}
            tableFor={currentTabData.tableFor}
            tableTitle={currentTabData.tableTitle}
            tableSubtitle={currentTabData.tableSubtitle}
            headCells={currentTabData.headCells}
            refreshData={{
              refreshData: () =>
                getReportData(
                  reportId,
                  NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB,
                ),
            }}
            rows={currentTabData.rows}
            tabs={tabs}
            tabStatus={true}
            changeTab={changeTab}
            startingTab={activeTab}
            isLoading={fullReportSpinner}
            currentActiveSpinner={currentActiveSpinner}
            headerButton={undefined}
            onRowClick={onRowClick}
          />
        </Box>
      )}
    </Container>
  );
};
