import { findLast, isArray, isEmpty } from 'lodash';
import { GridGroupingValueGetterParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { TABLE_DATETIME_FORMAT_SHORT } from 'common/datetimeFormats';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { DISPLAY_WMS_STATES, DISPLAY_VERITY_STATES } from 'common/slotStates';
import {
  ILocationDataST,
  IVeritySlotStatusST,
  IVeritySlotStatusStateST,
} from 'codegen/warehouse_status';
import { formatDateInTimezone } from 'common/functions/dateFunctions';
import { getIssueType } from './Cell';

const logPrefix = getLogPrefixForType('FUNCTION', 'WHS Cells');

type Intersection<A, B> = {
  [K in keyof A & keyof B]: A[K] | B[K];
};

type GetterParams = Intersection<
  GridValueGetterParams<ILocationDataST>,
  GridGroupingValueGetterParams<ILocationDataST>
>;

const dateTimeFrom = (dateTime: string) =>
  formatDateInTimezone(dateTime, TABLE_DATETIME_FORMAT_SHORT);

const getRowFromParams = (params: any): ILocationDataST =>
  params.row.slot_label != null ? params.row : params.api.getRow(params.rowNode.children[0]);

export const expectedContentValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);
  const wmsStatus = row.wms_status;

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return wmsStatus || '';
  }

  let content: string = ' ';

  const { barcodes } = wmsStatus;

  const nonEmptyBarcodes = barcodes.filter((b) => !!b);

  if (nonEmptyBarcodes.length === 1) {
    const barcode = nonEmptyBarcodes[0];
    content = barcode;
  } else if (nonEmptyBarcodes.length > 1) {
    const list = nonEmptyBarcodes.map((barcode) => barcode);
    content = list.join(', ');
  } else {
    content = DISPLAY_WMS_STATES[wmsStatus.state];
  }

  console.debug(logPrefix, 'expectedContent => ', content);

  return content;
};

export const wasFoundAtValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);
  let value = '-';
  const issue = row.issues && row.issues[0];
  const slotStatus: IVeritySlotStatusST | null =
    issue && issue.verity_slot_status_with_wms_bc !== 'string'
      ? (issue.verity_slot_status_with_wms_bc as IVeritySlotStatusST)
      : null;

  value = slotStatus && slotStatus.slot_label !== issue.location ? slotStatus.slot_label : value;

  return value;
};

export const wmsArticleNumberValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  return (typeof row.wms_status !== 'string' && row.wms_status?.article_nos[0]) || '-';
};

export const wmsQuantityValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  const values =
    typeof row.wms_status !== 'string' &&
    isArray(row.wms_status?.qtys) &&
    !isEmpty(row.wms_status?.qtys)
      ? replaceArrayItems(row.wms_status?.qtys, null, '-')
      : ['-'];

  return values?.join(', ');
};

export const customerValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  return (
    (typeof row.wms_status !== 'string' &&
      row.wms_status?.customers &&
      row.wms_status?.customers[0]) ||
    '-'
  );
};

export const wmsDateValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  const dateTime = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;
  if (!dateTime) return '-';
  return dateTimeFrom(dateTime);
};

export const contentFoundValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);

  const hasIssues = row.issues?.length;

  let verityStatus = row.verity_status as IVeritySlotStatusST;

  if (verityStatus?.state === IVeritySlotStatusStateST.Unreachable) {
    if (hasIssues) {
      verityStatus = row.issues[0].slot_status.verity_status as IVeritySlotStatusST;
    } else {
      verityStatus = null as unknown as IVeritySlotStatusST;
    }
  }

  if (!verityStatus || typeof verityStatus === 'string') {
    return verityStatus;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  let content: string = '-';

  const showOverrides = userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides![userOverrides!.length - 1].barcodes
    : verityBarcodes;

  if (barcodes.length === 1) {
    const barcode = barcodes[0];
    content = `${barcode} ${showOverrides && ' *'}`;
  } else if (barcodes.length > 1) {
    const list = barcodes.map((barcode) => `${barcode} ${showOverrides && ' *'}`);
    content = list.join(', ');
  } else {
    content = DISPLAY_VERITY_STATES[verityStatus.state];
  }

  return content;
};

export const shouldBeAtValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  const issue = row.issues && row.issues[0];
  const slotStatus: IVeritySlotStatusST | null =
    issue && issue.verity_slot_status_with_wms_bc !== 'string'
      ? (issue.verity_slot_status_with_wms_bc as IVeritySlotStatusST)
      : null;

  const clientOverride =
    issue && issue.slot_status.verity_status?.user_overrides
      ? findLast(issue.slot_status.verity_status?.user_overrides, (uo) => !uo.review)
      : null;

  const value = !clientOverride && slotStatus?.slot_label ? slotStatus?.slot_label : '-';

  return value;
};

export const issueValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);
  if (!row.issues || !row.issues[0]) return 'No Issue';
  const { label } = getIssueType(row.issues[0].type);
  return label;
};

export const issueStatusValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);
  return (row.issues && row.issues[0]?.state) || 'NONE';
};

export const foundDateValueGetter = (params: GetterParams): string => {
  const row = getRowFromParams(params);
  const dateTime = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  if (!dateTime) return '-';
  return dateTimeFrom(dateTime);
};

export const firstFoundOnValueGetter = (params: GetterParams) => {
  const row = getRowFromParams(params);
  const dateTime = row.issues && row.issues[0]?.first_found_on;
  if (!dateTime) return '-';
  return dateTimeFrom(dateTime);
};
