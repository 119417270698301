import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  tooltipedIcon: {
    margin: theme.spacing(0, 0.5),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  noMargin: {
    margin: 0,
  },
}));

const TooltipedIcon = (props: {
  tooltip: any;
  icon: any;
  text?: string;
  noMargin?: boolean;
  placement?: TooltipProps['placement'];
}) => {
  const { tooltip, icon, text, noMargin, placement } = props;

  const { classes } = useStyles();

  const tooltipClass = `${classes.icon} ${noMargin ? classes.noMargin : ''}`;

  return (
    <Tooltip title={tooltip} placement={placement}>
      <Box className={classes.tooltipedIcon} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" className={tooltipClass}>
          {icon}
        </Box>
        {text}
      </Box>
    </Tooltip>
  );
};

export default TooltipedIcon;
