import moment from 'moment-timezone';

import { Grid, InputBaseComponentProps, Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { weekDays } from 'common/weekDays';
import { HOURS_AND_MINUTES_FORMAT } from 'common/datetimeFormats';
import Spinner from 'components/common/Spinner';
import { IReportDeadlinesST } from 'codegen/facility_settings';
import { baseCardStyle, timePickerStyle } from './styles';

const useStyles = makeStyles()((theme: Theme) => ({
  ...baseCardStyle(theme),
  dayField: {
    border: '1px solid #e0e0e0',
    padding: '0 1rem',
  },
  timePicker: timePickerStyle('100%'),
}));

const DayField = (props: { dayLabel: string; time: string; onChange: (time: string) => void }) => {
  const { dayLabel, time, onChange } = props;

  const { classes: styles } = useStyles();

  const timeForPicker = time && moment(time, HOURS_AND_MINUTES_FORMAT);

  return (
    <Grid container className={styles.dayField}>
      <Grid item xs={6}>
        <p>{dayLabel}</p>
      </Grid>
      <Grid item xs={6}>
        <TimePicker
          data-testid={`c-report-deadline-${dayLabel}`}
          views={['hours', 'minutes']}
          ampm={false}
          value={timeForPicker}
          onChange={(newValue: moment.Moment | null) => {
            if (newValue) {
              onChange(newValue.format(HOURS_AND_MINUTES_FORMAT));
            } else {
              onChange('');
            }
          }}
          renderInput={({
            inputProps,
            ...restParams
          }: {
            inputProps?: InputBaseComponentProps;
          }) => (
            <TextField
              data-testid={`c-report-deadline-input-${dayLabel}`}
              classes={{ root: styles.timePicker }}
              variant="standard"
              inputProps={{
                ...inputProps,
                placeholder: '',
                'data-testid': 'mui-input',
              }}
              {...restParams}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

/**
 * Functional component that manages Report Deadlines Card for Facility Settings
 * @param props component props
 * @returns the functional component
 */
export const ReportDeadlinesCard = (props: {
  isLoading: boolean;
  timezone: string;
  currentDeadlines: IReportDeadlinesST;
  content?: JSX.Element;
  handleDeadlines: (deadlines: IReportDeadlinesST) => void;
}) => {
  const { isLoading, timezone, currentDeadlines, content, handleDeadlines } = props;

  const { classes: styles } = useStyles();

  moment.tz.setDefault(timezone);

  const updateDeadlines = (day: string) => (time: string) => {
    const dayKey = day.slice(0, 3).toLowerCase() as keyof IReportDeadlinesST;
    const newDeadlines = { ...currentDeadlines, [dayKey]: time || null };
    handleDeadlines(newDeadlines);
  };

  const deadlines = currentDeadlines
    ? Object.entries(currentDeadlines).map(([day, time]) => {
        const dayNames = weekDays.map((day) => day.label);
        const dayName = dayNames.find((d) =>
          d.toLocaleLowerCase().startsWith(day.toLocaleLowerCase()),
        );
        return { day: dayName! as keyof IReportDeadlinesST, time };
      })
    : [];

  return (
    <Card elevation={3} className={styles.card}>
      <CardHeader
        title="Report Deadlines"
        data-testid="c-report-deadlines-card-title"
        className={styles.cardSection}
        classes={{ title: styles.title }}
      />
      <Divider />
      <CardContent style={{ position: 'relative' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container spacing={3}>
            {isLoading ? (
              <Grid item xs={12} style={{ minHeight: 92 }}>
                <Spinner />
              </Grid>
            ) : (
              <>
                {deadlines.map((deadline) => (
                  <Grid item xs={12} sm={6} key={deadline.day}>
                    <DayField
                      dayLabel={deadline.day}
                      time={deadline.time}
                      onChange={updateDeadlines(deadline.day)}
                    />
                  </Grid>
                ))}
                {content && (
                  <Grid item xs={12} style={{ marginTop: '1em' }}>
                    {content}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </LocalizationProvider>
      </CardContent>
    </Card>
  );
};
