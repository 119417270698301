import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

export const drawerWidth = 300;
const openedMixin = (theme) => ({
  width: drawerWidth,
  height: 'calc(100vh - 35px)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  height: 'calc(100vh - 35px)',
  boxSizing: 'content-box',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(3)} + 40px)`,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    height: 'auto',
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    height: 'auto',
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      width: '65px',
    },
  }),
}));
