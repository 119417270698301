import { getLogPrefixForType } from 'common/functions/logFunctions';
import { INoFlyZonesResponseST } from 'codegen/no_fly_zone';
import GroundControlServices from '../../../../services/GroundControlServices';
import { GetDroneZonesParams } from './getDroneZone.model';

const logPrefix = getLogPrefixForType('FUNCTION', 'getNoFlyZones');

export const getNoFlyZones = ({
  requestController,
  flightDomainId,
  systemId,
  onSuccess,
  onBeforeSend,
  onFinally,
}: Omit<GetDroneZonesParams<INoFlyZonesResponseST>, 'isAdditionalDroneZonesEnabled'>) => {
  const reservation = requestController.reserveSlotForRequest();

  console.debug(logPrefix, `getNoFlyZones ${requestController.componentName}`);

  requestController.doRequest({
    request: GroundControlServices.getNoFlyZones,
    requestParams: [systemId, flightDomainId, reservation.signal],
    callbackBeforeSend: onBeforeSend,
    callbackFinally: onFinally,
    callbackSuccess: ({ data }: { data: INoFlyZonesResponseST }) => onSuccess(data),
  });
};
