// libraries
import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// material-ui
import { ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import customTheme from './customTheme';

// stores
import { FacilityLevelStoreProvider } from './store/FacilityLevelStore/facilityLevelStore';
import { UserLevelStoreProvider } from './store/UserLevelStore/userLevelStore';
import { ClientLevelStoreProvider } from './store/ClientLevelStore/clientLevelStore';
import { ClientModalsStoreProvider } from './store/Modals/clientModals/clientModalsStore';
import { FacilityMenuStoreProvider } from './store/FacilityMenuStore/facilityMenuStore';
import { FacilityModalsStoreProvider } from './store/Modals/facilityModals/facilityModalsStore';
import { GroundControlStoreProvider } from './store/GroundControl/groundControlLevelStore';
import { GroundControlModalsStoreProvider } from './store/Modals/groundControlModals/groundControlModalsStore';
import { SettingsContextProvider } from './store/Settings/settingsStore';
import { MapStoreProvider } from './shared/map-container/reducer/3DmapStore';

// we use ref here to perform closing snackbar
// eslint-disable-next-line
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: string) => () => {
  notistackRef.current!.closeSnackbar(key);
};

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const iconButton = (key: string) => (
  <IconButton size="small" onClick={onClickDismiss(key)}>
    <ClearIcon style={{ color: customTheme.palette.secondary.light }} />
  </IconButton>
);

const Providers = ({ children }: { children: ReactNode }) => (
  <SnackbarProvider
    preventDuplicate
    ref={notistackRef}
    maxSnack={3}
    action={(key) => iconButton(key.toString())}
  >
    <BrowserRouter>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={customTheme}>
          <UserLevelStoreProvider>
            <ClientLevelStoreProvider>
              <FacilityLevelStoreProvider>
                <SettingsContextProvider>
                  <ClientModalsStoreProvider>
                    <FacilityMenuStoreProvider>
                      <FacilityModalsStoreProvider>
                        <MapStoreProvider>
                          <GroundControlStoreProvider>
                            <GroundControlModalsStoreProvider>
                              {children}
                            </GroundControlModalsStoreProvider>
                          </GroundControlStoreProvider>
                        </MapStoreProvider>
                      </FacilityModalsStoreProvider>
                    </FacilityMenuStoreProvider>
                  </ClientModalsStoreProvider>
                </SettingsContextProvider>
              </FacilityLevelStoreProvider>
            </ClientLevelStoreProvider>
          </UserLevelStoreProvider>
        </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  </SnackbarProvider>
);

export default Providers;
