import { alpha, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

/**
 * Create connect drone styles
 * @param theme Theme param
 * @returns connect drone styles
 */
export const connectDroneStyles = makeStyles()((theme: Theme) => ({
  dot: {
    width: 13,
    height: 13,
    marginRight: theme.spacing(1.5),
  },
  dotActive: {
    backgroundColor: alpha(theme.palette.textSecondary.main, 0.7),
  },
  dots: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
  cardWrapper: {
    position: 'relative',
    height: '650px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    padding: theme.spacing(1),
    width: '100%',
  },
  cardContentSection: {
    height: '100%',
    overflowY: 'auto',
    padding: 0,
  },
  backdrop: {
    position: 'absolute',
  },
}));

/**
 * Create list styles
 * @param theme Theme param
 * @returns list styles
 */
export const useListStyles = (theme: Theme) => ({
  list: { padding: theme.spacing(0, 2), listStyle: 'auto' },
  listItem: {
    display: 'list-item',
    padding: theme.spacing(1.5, 0),

    '&::marker': {
      color: alpha(theme.palette.textSecondary.main, 0.6),
    },
  },
});

/**
 * Scan QR code styles
 */
export const useScanQrCodeStyles = makeStyles()((theme: Theme) => ({
  listItemIcon: {
    minWidth: 30,
  },
  text: {
    borderRadius: '4px',
    padding: theme.spacing(2),
    fontSize: 18,
  },
  subListItem: {
    padding: theme.spacing(1, 3),
  },
  subInnerListItem: {
    padding: theme.spacing(1, 6),
  },
  blue: {
    color: theme.palette.primary.main,
  },
  green: {
    color: theme.palette.success.main,
  },
  red: {
    color: theme.palette.error.main,
  },
  orange: {
    color: theme.palette.warning.main,
  },
}));

/**
 * Generate QR code styles
 */
export const useGenerateQrCodeStyles = makeStyles()((theme: Theme) => ({
  ssid: {
    marginTop: 0,
  },
  qrCodeImage: {
    position: 'absolute',
    left: 12,
    top: 10,
  },
  qrCode: {
    marginLeft: theme.spacing(1),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginLeft: 0,
      margin: theme.spacing(2, 0, 6, 0),
    },
  },
  wrapper: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
  },
  accordion: {
    backgroundColor: 'transparent',
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    padding: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  },
  accordionDetails: {
    padding: 0,
  },
}));

/**
 * Check for connection styles
 */
export const useCheckForConnectionStyles = makeStyles()((theme: Theme) => ({
  ...useListStyles(theme),
  innerList: { padding: theme.spacing(0, 2), listStyle: 'disc' },
  innerListItem: {
    display: 'list-item',
    '&::marker': {
      color: alpha(theme.palette.textSecondary.main, 0.6),
    },
  },
}));
