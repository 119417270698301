import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { COLOR_PALETTE } from 'common/colors';

export const useStyles = makeStyles()((theme: Theme) => ({
  accordion: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBlockEnd: '1px solid #e7e7e7',
    },
    '&:before': {
      display: 'none',
    },
  },
  cursor: {
    '& .MuiAccordionSummary-root:hover': {
      cursor: 'default',
    },
  },
  accordionSummaryTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    minWidth: 0,
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    flex: '0 0 auto',
  },
  createButton: {
    ':not([disabled])': {
      color: COLOR_PALETTE.BLUE,
    },
  },
}));
