import { Routes, Route, Navigate } from 'react-router-dom';
import { WARNING_PAGES_URLS } from 'common/pages';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { FacilitySettings } from 'pages/Admin/settings/FacilitySettings';
import FacilityDetails from 'pages/Admin/details/FacilityDetails';
import { SystemDetails } from 'pages/Admin/system_details/SystemDetails';
import Users from 'pages/Admin/users/Users';
import { NotFound } from 'pages/Error/NotFound';
import { UploadsPage } from 'pages/Admin/uploads/UploadPage';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

export const Admin = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const isUploadRouteAccessible =
    (stateFacilityLevel?.facilitySettings?.allow_manual_wms_file_upload ?? false) &&
    userHasPermission(PERMISSION.FACILITY_MANAGEMENT);

  return (
    <Routes>
      <Route path="" element={<Navigate to="system-details" />} />

      <Route
        path="system-details"
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.FACILITY_MANAGEMENT)}>
            <SystemDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="facility-settings"
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.FACILITY_MANAGEMENT)}>
            <FacilitySettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="facility-details"
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.FACILITY_MANAGEMENT)}>
            <FacilityDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="uploads"
        element={
          <ProtectedRoute isAccessible={isUploadRouteAccessible}>
            <UploadsPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="users"
        element={
          <ProtectedRoute isAccessible={userHasPermission(PERMISSION.USER_MANAGEMENT)}>
            <Users />
          </ProtectedRoute>
        }
      />

      {/* Error pages */}
      <Route path={WARNING_PAGES_URLS.NOT_FOUND} element={<NotFound />} />
      <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
    </Routes>
  );
};
