import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import { CLIENT_PAGES_URLS } from 'common/pages';
import { isProductionEnvironment } from 'common/functions/environments';
import { Box } from 'components/common/Box';
import { DeveloperDrawer } from 'components/DeveloperDrawer/DeveloperDrawer';
import { UserMenu } from './UserMenu';
import HideOnScroll from './HideOnScroll';

import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useSettingsStore } from '../../store/Settings/settingsStore';

import { headerStyles } from './styles';
import { getHomeRouteBasedOnPermissions } from '../../pages/SelectFacility/getHomeRouteBasedOnPermissions';

const Header = (props: any) => {
  const { classes } = headerStyles();

  const { HEADER_SETTINGS } = useSettingsStore();

  const { stateFacilityLevel, isDataReady: isFacilityDataReady } = useFacilityLevelStore();
  const { stateClientLevel } = useClientLevelStore() as any;
  const { stateUserLevel } = useUserLevelStore();
  const multipleFacilitiesAccess = stateClientLevel.isMultiFacility;

  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientLogoUrl, setClientLogoUrl] = useState('');
  const [homeRoute, setHomeRoute] = useState('');
  const [showDevDrawer, setShowDevDrawer] = useState(false);

  const { systemId } = useParams();

  const avatarInitials: string = stateUserLevel?.username?.[0]?.toUpperCase();
  const shouldHeaderRender = !stateFacilityLevel.ui?.notFound;

  const toggleSidebar = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchPermissionHomeRoute = async () => {
      if (systemId) {
        const route = await getHomeRouteBasedOnPermissions(systemId);

        setHomeRoute(route);
      }
    };

    if (multipleFacilitiesAccess) {
      setHomeRoute(CLIENT_PAGES_URLS.SELECT_FACILITY);

      return;
    }

    fetchPermissionHomeRoute();
  }, [multipleFacilitiesAccess, systemId]);

  useEffect(() => {
    if (systemId && !isFacilityDataReady(systemId)) {
      return;
    }
    setClientName(stateFacilityLevel.facilityData?.client);
    setClientLogoUrl(stateFacilityLevel.facilityData?.logo_url);
  }, [stateFacilityLevel.facilityData, isFacilityDataReady, systemId]);

  return shouldHeaderRender ? (
    <>
      <HideOnScroll threshold={50} {...props}>
        <AppBar
          className={classes.appBar}
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Container sx={{ padding: '0px 20px' }} disableGutters={true} maxWidth={false}>
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
              <Box display="flex">
                {HEADER_SETTINGS?.SHOW_CLIENT_LOGO && clientLogoUrl && (
                  <img
                    height="64px"
                    src={clientLogoUrl}
                    alt={clientName ? `${clientName}_logo` : 'logo'}
                  />
                )}

                <Typography variant="h6" className={classes.title}>
                  {HEADER_SETTINGS?.SHOW_CLIENT_NAME && clientName}
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ gap: 2 }}
              >
                <Link to={homeRoute} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <Typography
                    data-testid="c-header-menu-home"
                    color="inherit"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Home
                  </Typography>
                </Link>

                {!isProductionEnvironment() && (
                  <button
                    type="button"
                    style={{
                      cursor: 'pointer',
                      font: 'inherit',
                      background: 'none',
                      border: 0,
                      color: 'inherit',
                    }}
                  >
                    <Typography
                      data-testid="c-header-menu-dev-tools"
                      color="inherit"
                      sx={{ textTransform: 'uppercase' }}
                      onClick={() => setShowDevDrawer(!showDevDrawer)}
                    >
                      Dev-tools
                    </Typography>
                  </button>
                )}

                {HEADER_SETTINGS?.SHOW_SUPPORT_LINK && (
                  <Link
                    to={HEADER_SETTINGS?.SUPPORT_URL}
                    target="_blank"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <Typography
                      data-testid="c-header-menu-support"
                      sx={{ textTransform: 'uppercase' }}
                      color="inherit"
                    >
                      Support
                    </Typography>
                  </Link>
                )}

                <IconButton
                  color="inherit"
                  data-testid="c-header-menu-button"
                  aria-label="Open drawer"
                  onClick={toggleSidebar}
                  edge="start"
                >
                  <Avatar>{avatarInitials}</Avatar>
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      <UserMenu
        avatarInitials={avatarInitials}
        username={stateUserLevel.username}
        open={open}
        toggleSidebar={toggleSidebar}
        classes={classes}
      />
      {!isProductionEnvironment() && (
        <DeveloperDrawer visible={showDevDrawer} close={() => setShowDevDrawer(false)} />
      )}
    </>
  ) : null;
};

export default React.memo(Header);
