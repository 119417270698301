// #SG-20201104-FIXME: Reuse the same card components as on the Report page
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import * as tables from 'common/Tables';
import { changeTab } from 'common/functions/navigationFunctions';
import { SHOW_PAGE_HEADER_MENU_ICON } from 'common/settings';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { Box } from 'components/common/Box';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import EnhancedTable from 'components/EnhancedTable';
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem from 'components/BaseCard/BaseCardSimpleItem';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { handleLocationModalOpening } from 'udb/features/reports/features/report/utils/HandleLocationModalOpening';
import { useRequestController } from '../../hooks';

import WarehouseStore from '../../store/WarehouseStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useSettingsStore } from '../../store/Settings/settingsStore';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';

import { useClientModalsStore, useFacilityModalsStore } from '../../store/Modals';
import { ClientModalsActionTypes, FacilityModalsActionTypes } from '../../store/Modals/types';

import {
  defaultOccupancyData,
  defaultWarehouseStatus,
  getTabData,
  ILocationReportData,
  loadWarehouseData,
  Section,
  SectionItem,
} from './warehouseStatusFunctions';
import { LocationProps } from '../../udb/features/reports/reportsFunctions';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

const { warehouseTabs } = tables;

const logPrefix = getLogPrefixForType('PAGE', 'WarehouseStatus');

/**
 * Warehouse status
 * @returns component
 */
export const WarehouseStatus = () => {
  const {
    WAREHOUSE_TABLE_TABS,
    WHS_ISSUES_COLUMNS,
    WHS_SOLVED_COLUMNS,
    WHS_AMENDED_COLUMNS,
    WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
    WHS_INVALID_COLUMNS,
    WHS_FULL_REPORT_COLUMNS,
  } = useSettingsStore();

  const {
    issuesTab,
    issuesSolvedTab,
    amendedTab,
    issuesSnoozedTab,
    issuesPotentialTab,
    issuesInconclusiveTab,
    issuesInvalidTab,
    fullReportTab,
  } = useMemo(() => tables.warehouseTabsMappings(WAREHOUSE_TABLE_TABS), [WAREHOUSE_TABLE_TABS]);

  const location = useLocation() as LocationProps;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('searchTerm') || '';
  const orderBy = searchParams.get('orderBy') || '';
  const order = (searchParams.get('order') as OrderValue) || '';

  const [activeTab, setActiveTab] = useState<number>(Number(searchParams.get('activeTab')) || 1);
  const [warehouseStatusSpinner, setWarehouseStatusSpinner] = useState(0);
  const [occupancySpinner, setOccupancySpinner] = useState(0);
  const [issues, setIssues] = useState<ILocationReportData[]>([]);
  const [solved, setSolved] = useState<ILocationReportData[]>([]);
  const [amended, setAmended] = useState<ILocationReportData[]>([]);
  const [snoozed, setSnoozed] = useState<ILocationReportData[]>([]);
  const [potential, setPotential] = useState<ILocationReportData[]>([]);
  const [inconclusive, setInconclusive] = useState<ILocationReportData[]>([]);
  const [invalid, setInvalid] = useState<ILocationReportData[]>([]);
  const [fullReport, setFullReport] = useState<ILocationReportData[]>([]);
  const [occupancyData, setOccupancyData] = useState(defaultOccupancyData);
  const [warehouseStatus, setWarehouseStatus] = useState(defaultWarehouseStatus);
  const [latestUpdate] = useState(''); // setting of latestUpdate will be reintroduced soon
  const [isDownloading, setIsDownloading] = useState(false);

  const navigate = useNavigate();
  const {
    stateFacilityLevel,
    isDataReady: isFacilityDataReady,
    isFacilityConfigured,
  } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();

  const [tableLoadingRequestId, setTableLoadingRequestId] = useState<string[]>([]);
  const onGeneratedRequestID = (requestID: string): void => {
    setTableLoadingRequestId((ids: string[]) => [...ids, requestID]);
  };

  const { slots } = stateFacilityLevel;

  const { requestController } = useRequestController(logPrefix);

  const { systemId } = useParams();

  const isVisibleDownloadButton = userHasPermission(PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL);
  const isTableLoading: boolean = useMemo(
    () => tableLoadingRequestId.some((r) => requestController.isExecuting[r]),
    [requestController.isExecuting, tableLoadingRequestId],
  );

  const hasViewLocationDetailsPermissions = userHasPermission(PERMISSION.VIEW_LOCATION_DETAILS);

  /**
   * Current tab data
   */
  const currentTabData = useMemo(
    () =>
      getTabData({
        activeTab,
        issuesTab,
        issuesSolvedTab,
        amendedTab,
        issuesSnoozedTab,
        issuesPotentialTab,
        issuesInconclusiveTab,
        issuesInvalidTab,
        fullReportTab,
        issues,
        solved,
        fullReport,
        amended,
        snoozed,
        invalid,
        inconclusive,
        potential,
        WHS_ISSUES_COLUMNS,
        WHS_SOLVED_COLUMNS,
        WHS_FULL_REPORT_COLUMNS,
        WHS_AMENDED_COLUMNS,
        WHS_INVALID_COLUMNS,
        WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      }),
    [
      activeTab,
      issuesTab,
      issuesSolvedTab,
      amendedTab,
      issuesSnoozedTab,
      issuesPotentialTab,
      issuesInconclusiveTab,
      issuesInvalidTab,
      fullReportTab,
      issues,
      solved,
      fullReport,
      amended,
      snoozed,
      invalid,
      inconclusive,
      potential,
      WHS_ISSUES_COLUMNS,
      WHS_SOLVED_COLUMNS,
      WHS_FULL_REPORT_COLUMNS,
      WHS_AMENDED_COLUMNS,
      WHS_INVALID_COLUMNS,
      WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
    ],
  );

  /**
   * current active spinner
   */
  const currentActiveSpinner = {
    issues: isTableLoading,
    'solved issues': isTableLoading,
    amended: isTableLoading,
    'snoozed issues': isTableLoading,
    'potential issues': isTableLoading,
    'inconclusive issues': isTableLoading,
    'invalid issues': isTableLoading,
    report: isTableLoading,
  };

  /**
   * table header tabs
   */
  const tabs = warehouseTabs({
    WAREHOUSE_TABLE_TABS,
    numberOfIssues: issues.length,
    numberOfIssuesSolved: solved.length,
    numberOfAmended: amended.length,
    numberOfIssuesSnoozed: snoozed.length,
    numberOfIssuesPotential: potential.length,
    numberOfIssuesInconclusive: inconclusive.length,
    numberOfIssuesInvalid: invalid.length,
    numberOfFullReport: fullReport.length,
  });

  /**
   * Clear local state
   */
  const clearLocalState = () => {
    setIssues([]);
    setAmended([]);
    setSnoozed([]);
    setSolved([]);
    setPotential([]);
    setInconclusive([]);
    setInvalid([]);
    setFullReport([]);
  };

  /**
   * Get warehouse data
   */
  const getWarehouseData = useCallback(() => {
    loadWarehouseData({
      slots,
      logPrefix,
      requestController,
      systemId: systemId!,
      clearLocalState,
      setIssues,
      setSolved,
      setSnoozed,
      setPotential,
      setInconclusive,
      setInvalid,
      setFullReport,
      setAmended,
      onGeneratedRequestID,
    });
  }, [requestController, slots, systemId]);

  /**
   * Get active issues count for Warehouse status card
   */
  const getActiveIssueCount = useCallback(() => {
    console.debug(logPrefix, 'getActiveIssueCount');
    const { signal } = requestController.reserveSlotForRequest();
    requestController.doRequest({
      request: WarehouseStore.getActiveIssueCount,
      requestParams: [systemId, signal],
      callbackBeforeSend: () => setWarehouseStatusSpinner((repliesPending) => repliesPending + 1),
      callbackSuccess: (r) => setWarehouseStatus(() => r.warehouseStatus),
      messageErrorFallback: 'The Active Issue count could not be fetched.',
      callbackFinally: () => setWarehouseStatusSpinner(0),
    });
  }, [requestController, systemId]);

  /**
   *  Get occupancy data for summary card
   */
  const getOccupancyData = useCallback(() => {
    console.debug(logPrefix, 'getOccupancyData');
    const { signal } = requestController.reserveSlotForRequest();
    requestController.doRequest({
      request: WarehouseStore.getOccupancy,
      requestParams: [systemId, signal],
      callbackBeforeSend: () => setOccupancySpinner(1),
      callbackSuccess: setOccupancyData,
      messageErrorFallback: 'The Occupancy could not be fetched.',
      callbackFinally: () => setOccupancySpinner(0),
    });
  }, [requestController, systemId]);

  /**
   * Send the warehouse status excel file via email
   */
  const sendWarehouseExportEmail = useCallback(() => {
    const { signal } = requestController.reserveSlotForRequest();
    console.debug(logPrefix, 'sendWarehouseExportEmail');
    requestController.doRequest({
      request: WarehouseStore.sendWarehouseExportEmail,
      requestParams: [systemId, signal],
      callbackBeforeSend: () => setIsDownloading(true),
      messageSuccess: 'The warehouse status export shall be in your inbox in a few minutes.',
      messageErrorFallback: 'The Warehouse Status could not be sent by email.',
      callbackFinally: () => setIsDownloading(false),
    });
  }, [requestController, systemId]);

  /**
   * Tab switch handler
   * @param activeTab active tab index
   * @returns changeTab function execution
   */
  const tabSwitchHandler = (activeTab: number) =>
    changeTab({
      tabValue: activeTab,
      navigate,
      state: location.state,
      setActiveTab,
    });

  /**
   * Get data
   * Function used to re-fetch the data on the page any time the user modifies a slot status
   */
  const getData = useCallback(() => {
    getWarehouseData();
    getOccupancyData();
    getActiveIssueCount();
  }, [getWarehouseData, getOccupancyData, getActiveIssueCount]);

  /**
   * Handle opening of location modal based on url params provided
   */
  const handleModalOpening = useCallback(
    () =>
      handleLocationModalOpening({
        isModalOpened: facilityModalsState.locationModalOpened,
        searchParams,
        headCells: currentTabData.headCells,
        rows: currentTabData.rows,
        tableTitle: currentTabData.tableFor,
        dispatchFacilityModals,
        refreshData: getData,
      }),
    [
      facilityModalsState.locationModalOpened,
      searchParams,
      currentTabData.headCells,
      currentTabData.rows,
      currentTabData.tableFor,
      dispatchFacilityModals,
      getData,
    ],
  );

  /**
   * Get user confirmation (via dialog) to send the warehouse status excel file via email
   */
  const handleSendWarehouseExportEmail = useCallback(() => {
    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
      payload: {
        title: 'Send warehouse status export via e-mail?',
        message: `The warehouse status export will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`,
        onConfirm: sendWarehouseExportEmail,
      },
    });
  }, [dispatchClientModals, sendWarehouseExportEmail, stateUserLevel.username]);

  /**
   * These hooks are responsible for the loading of data to populate the page.
   * It gets triggered only once when the page is loaded
   */
  useEffect(() => {
    // Note: if the page is opened for a system ID which has no facility setup
    // then we don't want to try to get occupancy data and issue count, for they
    // would both fail and show an error notification.
    if (isFacilityDataReady(systemId!) && isFacilityConfigured(systemId!)) {
      getOccupancyData();
      getActiveIssueCount();
    }
  }, [getActiveIssueCount, getOccupancyData, isFacilityDataReady, isFacilityConfigured, systemId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWarehouseData();
  }, [getWarehouseData]);

  useEffect(() => {
    hasViewLocationDetailsPermissions && handleModalOpening();
  }, [handleModalOpening, hasViewLocationDetailsPermissions]);

  const onRowClick = (e: KeyboardEvent, row: ILocationReportData) => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
      payload: getLocationListData(
        filterOrderTableRows(
          currentTabData.rows,
          searchTerm,
          orderBy,
          order,
          currentTabData.headCells,
        ),
        row,
      ),
      refreshDataOnClose: {
        refreshData: getData,
      },
      parentPage: 'WarehouseStatus',
      reportId: undefined,
      locationInfo: { tab: currentTabData.tableTitle, searchTerm },
    });
    searchParams.set('location', row.location);
    setSearchParams(searchParams);
  };

  const handleRowClick = hasViewLocationDetailsPermissions ? onRowClick : undefined;

  return (
    <>
      <PageHeaderSection
        title="Warehouse status"
        subtitle={`The latest information about your warehouse ${
          latestUpdate && `: updated ${moment(latestUpdate).fromNow()}`
        }`}
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.WAREHOUSE_STATUS}
        onClickDownload={handleSendWarehouseExportEmail}
        downloadSpinning={isDownloading}
        showDownloadBtn={isVisibleDownloadButton}
      />
      <Container maxWidth="xl">
        <Grid alignItems="stretch" spacing={3} container className="c-page-content">
          {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
            <Grid md={6} sm={12} xs={12} item>
              <BaseCard
                cardFor="warehouse status"
                dataTestId="c-warehouse-status-card"
                showHeader={true}
                showContent={true}
                showActionButtons={false}
                showHeaderDivider={true}
                isLoading={warehouseStatusSpinner}
                title={warehouseStatus.title}
                subtitle={warehouseStatus.subtitle}
                counter={warehouseStatus.counter}
                counterSubject={warehouseStatus.counterSubject}
                actionButtons={warehouseStatus.actionButtons}
              >
                {warehouseStatus.sections &&
                  warehouseStatus.sections.map((section: Section) => (
                    <BaseCardSimpleItem
                      key={section.title}
                      title={section.title}
                      sectionItems={section.sectionItems}
                    />
                  ))}
              </BaseCard>
            </Grid>
          )}
          <Grid md={6} sm={12} xs={12} item>
            <BaseCard
              cardFor="warehouse occupancy"
              dataTestId="c-warehouse-occupancy-card"
              showHeader={true}
              showContent={true}
              showActionButtons={false}
              showHeaderDivider={true}
              isLoading={occupancySpinner}
              spinnerLabel="Loading occupancy..."
              title={occupancyData.title}
              subtitle={occupancyData.subtitle}
              counter={occupancyData.counter}
              counterSubject={occupancyData.counterSubject}
              actionButtons={occupancyData.actionButtons}
            >
              {occupancyData.sections &&
                occupancyData.sections.map((section: Section) => (
                  <BaseCardSimpleItem
                    key={section.sectionItems
                      .map((si: SectionItem) => si.label)
                      .concat('-')
                      .toString()}
                    title={section.title}
                    sectionItems={section.sectionItems}
                  />
                ))}
            </BaseCard>
          </Grid>
        </Grid>
        <Box>
          <EnhancedTable
            tableFor={currentTabData.tableFor}
            tableTitle={currentTabData.tableTitle}
            tableSubtitle={currentTabData.tableSubtitle}
            headCells={currentTabData.headCells}
            rows={currentTabData.rows}
            tabStatus={true}
            refreshData={{
              refreshData: getData,
            }}
            tabs={tabs}
            changeTab={tabSwitchHandler}
            startingTab={activeTab}
            currentActiveSpinner={currentActiveSpinner}
            headerButton={undefined}
            onRowClick={handleRowClick}
            isLoading={0}
          />
        </Box>
      </Container>
    </>
  );
};
