// libs
import { useCallback, useState } from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';
// mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import { CardActions, Typography } from '@mui/material';
// styles
import { baseCardStyle } from 'components/BaseCard/styles';
// components
import { Box } from 'components/common/Box';
import { FlightHoursItem } from './FlightHoursItem';
import { EditFlightHoursItem } from './EditFlightHoursItem';
// types and functions
import { OperatingHoursEventST } from '../API';
import {
  areFlightHoursAffectedByScheduleLock,
  EventST2View,
  EventView2ST,
  getHoursString,
  makeKey,
  validateHours,
} from './FlightHoursFunctions';
import { useGroundControlStore } from '../../../../../store/GroundControl/groundControlLevelStore';
import { RemoveFlightHoursModal } from './RemoveFlightHoursModal';

export type OperatingHoursEventBound = {
  weekDay: number;
  timePoint: moment.Moment | '';
};

export type OperatingHoursEvent = {
  start: OperatingHoursEventBound;
  end: OperatingHoursEventBound;
};

export const FlightHoursCard = (props: {
  schedule: OperatingHoursEventST[];
  setFlightHours: (schedule: OperatingHoursEventST[]) => void;
  /**
   * Flag that sets the component in read only mode (when true)
   */
  isReadOnlyMode?: boolean;
}) => {
  const { schedule, setFlightHours, isReadOnlyMode } = props;
  const { stateGroundControl } = useGroundControlStore();
  const { classes: cardStyles } = baseCardStyle();

  const scheduleLock = stateGroundControl.flightDomain.flight_domain_status?.next_scheduled_lock;

  const [addNewItem, setAddNewItem] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<false | number>(false);
  const [editIndex, setEditIndex] = useState<false | number>(false);

  const flightHours = isNil(schedule) ? [] : schedule.map(EventST2View);

  const addOperatingHours = useCallback(
    (newHours: OperatingHoursEvent) => {
      const newHoursST = EventView2ST(newHours);
      const newSchedule = [newHoursST, ...schedule];
      setFlightHours(newSchedule);
      setAddNewItem(false);
    },
    [schedule, setFlightHours],
  );

  const editOperatingHours = useCallback(
    (index: number, newHours: OperatingHoursEvent) => {
      const newHoursST = EventView2ST(newHours);
      const newSchedule = [...schedule];
      newSchedule[index] = newHoursST;
      setFlightHours(newSchedule);
      setEditIndex(false);
    },
    [schedule, setFlightHours],
  );

  const removeOperatingHours = useCallback(
    (index: number) => {
      const newSchedule = [...schedule].slice(0, index).concat(schedule.slice(index + 1));
      setFlightHours(newSchedule);
      setRemoveIndex(false);
    },
    [schedule, setFlightHours],
  );

  return (
    <Card data-testid="c-schedule-flight-hours-card" className={cardStyles.card} elevation={3}>
      <CardHeader
        title="Drone flight hours"
        subheader="Weekly schedule of drone flying hours"
        className={cardStyles.cardSection}
        classes={{
          title: cardStyles.title,
          subheader: cardStyles.subheader,
        }}
      />
      <Divider />
      <CardContent
        className={cardStyles.cardContentSection}
        data-testid="c-flight-hours-card-content"
      >
        {flightHours.length > 0 && (
          <Grid container mt={1} spacing={1}>
            {flightHours.map((flightHour, index) =>
              !isReadOnlyMode && index === editIndex ? (
                <Grid item xs={12} key={makeKey(flightHour)}>
                  <EditFlightHoursItem
                    cancel={() => setEditIndex(false)}
                    save={(newHours) => editOperatingHours(index, newHours)}
                    validate={validateHours(schedule)}
                    initialValue={flightHour}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} key={makeKey(flightHour)}>
                  <FlightHoursItem
                    hours={flightHour}
                    remove={() => setRemoveIndex(index)}
                    edit={() => setEditIndex(index)}
                    isReadOnlyMode={isReadOnlyMode}
                  />
                </Grid>
              ),
            )}
          </Grid>
        )}

        {flightHours.length === 0 && !addNewItem && (
          <Box
            width="100%"
            height="120px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle1" color="disabled">
              No Drone Flight Hours data available
            </Typography>
          </Box>
        )}
      </CardContent>
      {addNewItem && (
        <Grid mt={1} item xs={12}>
          <EditFlightHoursItem
            cancel={() => setAddNewItem(false)}
            save={addOperatingHours}
            validate={validateHours(schedule)}
          />
        </Grid>
      )}
      {!isReadOnlyMode && (
        <CardActions>
          <Box p={1} width="100%">
            <Button
              startIcon={<Add />}
              disabled={addNewItem}
              data-testid="c-add-flight-hours-button"
              variant="outlined"
              color="primary"
              size="medium"
              fullWidth
              onClick={() => setAddNewItem(true)}
            >
              ADD DRONE FLIGHT HOURS
            </Button>
          </Box>
        </CardActions>
      )}
      {removeIndex !== false && (
        <RemoveFlightHoursModal
          remove={() => removeOperatingHours(removeIndex)}
          cancel={() => setRemoveIndex(false)}
          hoursToRemove={getHoursString(flightHours[removeIndex])}
          isAffectedByScheduleLock={areFlightHoursAffectedByScheduleLock({
            flightHours: flightHours[removeIndex],
            scheduleLock,
          })}
        />
      )}
    </Card>
  );
};
