import React from 'react';

// material-ui core
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

// styles
import { Box } from 'components/common/Box';
import { tableStyles } from '../styles';
import { ToolbarAction } from '../types/toolbar';

// components

type EnhancedTableToolbarProps = {
  selectedRows: string[];
  noOfRowsFound?: number;
  searchTerm: string;
  searching: boolean;
  tabStatus: boolean;
  action?: ToolbarAction;
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  // props
  const { noOfRowsFound, searchTerm, searching, selectedRows, tabStatus, action } = props;

  const { classes, cx } = tableStyles();

  const numSelected = selectedRows.length;

  return (
    <Collapse in={true}>
      <Toolbar
        className={cx(classes.root, {
          [classes.highlight]: !!numSelected,
          [classes.hidden]: !numSelected && !searchTerm && !searching && !tabStatus,
        })}
      >
        <Box px={2} width="100%" display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            {!!numSelected && action && (
              <Tooltip title={action.title}>
                <IconButton
                  onClick={() => action.onClick(selectedRows)}
                  data-testid="c-table-toolbar-action"
                >
                  <action.icon />
                </IconButton>
              </Tooltip>
            )}
            <Box mt={1}>
              <Typography
                className={cx({
                  [classes.hidden]: numSelected === 0,
                  [classes.centered]: noOfRowsFound === null,
                })}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            {tabStatus ? (
              <Box display="flex" alignItems="center">
                <CircularProgress
                  // className={classes.spinner}
                  disableShrink={true}
                  color="secondary"
                  size={20}
                />
                <Typography
                  className={classes.toolbarText}
                  color="textSecondary"
                  ml={1}
                  variant="subtitle1"
                  component="div"
                >
                  This tab is still loading data...
                </Typography>
              </Box>
            ) : null}
          </Box>

          <Box flex={1} mt={1}>
            <Typography
              data-testid="c-table-search-results"
              className={cx(classes.toolbarText, {
                [classes.hidden]: noOfRowsFound === null,
              })}
              color="textSecondary"
              variant="subtitle1"
              component="div"
            >
              {searching && searchTerm ? (
                <>
                  <CircularProgress
                    className={classes.spinner}
                    disableShrink={true}
                    color="secondary"
                    size={20}
                  />
                  Searching...
                </>
              ) : (
                searchTerm &&
                `Found ${noOfRowsFound} ${
                  noOfRowsFound === 1 ? 'row' : 'rows'
                } containing '${searchTerm}'`
              )}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </Collapse>
  );
};

export default EnhancedTableToolbar;
