import { IUserPostRequestSTUserGroupsEnum } from 'codegen/user_admin';
import { IAddUserDataRequestParamST, IUpdateUserRestRequestParamST } from '../interfaces';

import { getSystemService, getUserAdminService } from './services';

const getAllUsers = (systemId: string, signal: AbortSignal) =>
  getUserAdminService().getAllUsers(systemId, 'true', { signal });

const getUser = (systemId: string, email: string, signal: AbortSignal) =>
  getUserAdminService().getUser(systemId, email, { signal });

const addUser = (systemId: string, data: IAddUserDataRequestParamST, signal: AbortSignal) =>
  getUserAdminService().createUser(systemId, data, { signal });

const updateUser = (
  systemId: string,
  { email, rest }: { email: string; rest: IUpdateUserRestRequestParamST },
  signal: AbortSignal,
) => getUserAdminService().updateUser(systemId, email, rest, { signal });

const updateUserGroups = (
  systemId: string,
  { email, user_groups }: { email: string; user_groups: IUserPostRequestSTUserGroupsEnum[] },
  signal: AbortSignal,
) =>
  getUserAdminService().updateUserGroups(
    systemId,
    email,
    { new_user_groups: user_groups },
    { signal },
  );

const deleteUser = (systemId: string, email: string, signal: AbortSignal) =>
  getUserAdminService().deleteUser(systemId, email, { signal });

/**
 * Get supported system IDs.
 * @returns {object} <{ [key: string]: ISystemIDsGetResponseValueST }>
 */
const getFacilities = (signal: AbortSignal) => getSystemService().systemSystemIdsGet({ signal });

/**
 * Get user system IDs.
 * @returns {object} <{ [key: string]: ISystemIDsGetResponseValueST }>
 */
const getUserFacilities = (signal: AbortSignal) =>
  getSystemService().systemUserSystemIdsGet({ signal });

const getUserGroups = (systemId: string, signal: AbortSignal) =>
  getUserAdminService().getUserGroups(systemId, { signal });

const UserServices = {
  getAllUsers,
  addUser,
  updateUser,
  updateUserGroups,
  getUser,
  deleteUser,
  getUserGroups,
  getFacilities,
  getUserFacilities,
};

export default UserServices;
