// libs
import { useCallback, useState } from 'react';
import moment from 'moment';
// mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Button, Divider, Typography } from '@mui/material';
// components
import { weekDays } from 'common/weekDays';
import { Box } from 'components/common/Box';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
// types
import { OperatingHoursEvent, OperatingHoursEventBound } from './FlightHoursCard';
// functions and variables
import { useFacilityLevelStore } from '../../../../../store/FacilityLevelStore/facilityLevelStore';
import { useStyles } from './FlightHoursStyles';
import { useGroundControlStore } from '../../../../../store/GroundControl/groundControlLevelStore';
import { areFlightHoursAffectedByScheduleLock } from './FlightHoursFunctions';

const getInitialState = (): OperatingHoursEvent => {
  const now = moment();
  const later = moment().add(1, 'day');
  return {
    start: {
      weekDay: now.weekday() + 1,
      timePoint: now,
    },
    end: {
      weekDay: later.weekday() + 1,
      timePoint: later,
    },
  };
};

const sortedWeekDays = weekDays.sort((a, b) => a.value - b.value);

export const EditFlightHoursItem = (props: {
  cancel: () => void;
  save: (time: OperatingHoursEvent) => void;
  validate: (
    time: OperatingHoursEvent,
    edit?: OperatingHoursEvent,
  ) => [isValid: boolean, message: string];
  initialValue?: OperatingHoursEvent;
}) => {
  const { cancel, save, validate, initialValue } = props;
  const { classes: styles } = useStyles();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateGroundControl } = useGroundControlStore();

  const timezone = stateFacilityLevel.facilityData?.timezone;
  moment.tz.setDefault(timezone);

  const scheduleLock = stateGroundControl.flightDomain.flight_domain_status?.next_scheduled_lock;

  const [{ start, end }, setTime] = useState(initialValue || getInitialState());

  const isAffectedInitial =
    initialValue &&
    areFlightHoursAffectedByScheduleLock({
      flightHours: { start: initialValue?.start, end: initialValue?.end },
      scheduleLock,
    });

  const isAffected = areFlightHoursAffectedByScheduleLock({
    flightHours: { start, end },
    scheduleLock,
  });

  const shouldDisplayWarning = isAffectedInitial && !isAffected;

  const changeHours = useCallback(
    (
      bound: keyof OperatingHoursEvent,
      prop: keyof OperatingHoursEventBound,
      val: string | moment.Moment,
    ) => {
      setTime((prevState) => ({
        ...prevState,
        [bound]: {
          ...prevState[bound],
          [prop]: val,
        },
      }));
    },
    [],
  );

  const handleSave = () => save({ start, end });

  const [isValid, helpfulMessage] = validate({ start, end }, initialValue);

  return (
    <Box className={styles.flightHoursItem}>
      <Grid container justifyContent="space-between" pt={2} spacing={2}>
        <Grid
          item
          sm={12}
          pl={2}
          md={5}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Grid item xs={12} md={6}>
            <CustomSelect
              label=""
              id="flight-hours-edit-start-week-day"
              testId="c-flight-hours-edit-start-week-day"
              variant="standard"
              name="Start day"
              value={start.weekDay.toString()}
              valueOptions={sortedWeekDays}
              onChange={(e) => changeHours('start', 'weekDay', e.target.value)}
              error={false}
              errorMessage=""
              defaultValue=""
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6} data-testid="c-flight-hours-edit-start-time">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label=""
                openTo="hours"
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                ampm={false}
                desktopModeMediaQuery="(min-width: 900px)"
                value={start.timePoint}
                onChange={(newValue: moment.Moment | null) =>
                  changeHours('start', 'timePoint', newValue || '')
                }
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    data-testid="c-flight-hours-edit-start-time-text-input"
                    variant="standard"
                    classes={{ root: styles.timePicker }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid
          item
          container
          sm={12}
          md={5}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Grid item xs={12} md={6}>
            <CustomSelect
              label=""
              id="flight-hours-edit-end-week-day"
              testId="c-flight-hours-edit-end-week-day"
              variant="standard"
              name="End day"
              value={end.weekDay.toString()}
              valueOptions={sortedWeekDays}
              onChange={(e) => changeHours('end', 'weekDay', e.target.value)}
              error={false}
              errorMessage=""
              defaultValue=""
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6} data-testid="c-flight-hours-edit-end-time">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label=""
                openTo="hours"
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                ampm={false}
                desktopModeMediaQuery="(min-width: 900px)"
                value={end.timePoint}
                onChange={(newValue: moment.Moment | null) =>
                  changeHours('end', 'timePoint', newValue || '')
                }
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    data-testid="c-flight-hours-edit-end-time-text-input"
                    variant="standard"
                    classes={{ root: styles.timePicker }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item lg={12} xl={8}>
            {helpfulMessage && <p className={styles.helpfulMessage}>{helpfulMessage}</p>}
            {shouldDisplayWarning && (
              <Typography sx={(theme) => ({ color: theme.palette.warning.main })}>
                By clicking save you will invalidate the existing schedule lock
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12} xl={4}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                data-testid="c-edit-flight-hours-cancel-button"
                variant="text"
                color="primary"
                size="medium"
                className={styles.cancelEditButton}
                onClick={cancel}
              >
                CANCEL
              </Button>
              <Button
                data-testid="c-edit-flight-hours-save-button"
                variant="contained"
                color="primary"
                size="medium"
                disabled={!isValid}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
