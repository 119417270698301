import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip, Typography } from '@mui/material';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';

const EXCLUSION_ICONS = {
  INCLUDE: <CheckIcon />,
  EXCLUDE: <ClearIcon />,
  NOT_SET: null,
};

type ContentCellProps = {
  row: ILocationDataST;
};

const logPrefix = getLogPrefixForType('COMPONENT', 'ExclusionStatusCell');

/**
 * Functional components rendering a Content (expected)
 * cell in a location data grid
 * @param props
 * @returns
 */
export const ExclusionStatusCell = (props: ContentCellProps) => {
  console.debug(logPrefix, props.row);

  const wmsStatus = props.row.wms_status as IWMSSlotStatusST;

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return <span className="c-data-grid-cell">{wmsStatus}</span>;
  }

  let tooltip: string | JSX.Element = '';
  let content: JSX.Element = <>-</>;

  const exclusionStatusWMS = wmsStatus.exclusion_status;
  const exclusionStatusVerity = props.row.slot_settings?.exclusion_status || 'NOT_SET';

  if (exclusionStatusVerity === 'NOT_SET') {
    tooltip =
      exclusionStatusWMS === 'EXCLUDE'
        ? 'Drones will not visit this location (WMS)'
        : 'Drones will visit this location (WMS)';
    content = EXCLUSION_ICONS[exclusionStatusWMS];
  } else {
    tooltip =
      exclusionStatusWMS === 'EXCLUDE'
        ? 'Drones will not visit this location (User)'
        : 'Drones will visit this location (User)';
    content = EXCLUSION_ICONS[exclusionStatusVerity];
  }

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-exclusion-status">
      <Typography variant="body2" sx={{ cursor: 'default' }}>
        {content}
      </Typography>
    </Tooltip>
  );
};
