// mui core
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';

// mui icons
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';

// variables, functions, configurations
import { AUTH_PAGES_URLS } from 'common/pages';
import { IClientModalsAction } from '../../store/Modals/clientModals/IClientModalsActions';
import { ClientModalsActionTypes } from '../../store/Modals/types';

interface IMenuLinksProps {
  toggleSidebar: () => void;
  dispatchClientModals: React.Dispatch<IClientModalsAction>;
  navigate: (url: string) => void;
}

const MenuLinks = (props: IMenuLinksProps) => {
  // props
  const { toggleSidebar, dispatchClientModals, navigate } = props;

  const menuLinks = [
    {
      action: () => {
        dispatchClientModals({ type: ClientModalsActionTypes.CHANGE_PASSWORD_MODAL });
        toggleSidebar && toggleSidebar();
      },
      label: 'Change password',
      visible: true,
      testId: 'change-password',
      icon: <LockIcon />,
    },
    {
      action: () => {
        dispatchClientModals({
          type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
          payload: {
            title: 'Logout',
            message: 'Are you sure?',
            onConfirm: () => navigate(AUTH_PAGES_URLS.SIGNOUT),
          },
        });

        toggleSidebar && toggleSidebar();
      },
      label: 'Logout',
      visible: true,
      testId: 'logout',
      icon: <LogoutIcon />,
    },
  ];
  return (
    <List>
      {menuLinks.map((link: any) => (
        <ListItem
          data-testid={`c-sidebar-link-${link.testId}`}
          onClick={() => {
            if (!link.action) {
              navigate(link.path);
            } else {
              link.action();
            }
          }}
          button
          key={link.label}
        >
          <ListItemIcon
            sx={{
              minWidth: 35,
            }}
          >
            {link.icon}
          </ListItemIcon>
          <ListItemText primary={link.label} />
        </ListItem>
      ))}
    </List>
  );
};

export default MenuLinks;
