// libraries
import React, { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { Routes, Route, useLocation } from 'react-router-dom';
import activeRequests from 'active-requests';

// components
import * as Sentry from '@sentry/react';
import { ModalsAndPopupsClientLevel } from './components/ModalsAndPopups/ModalsAndPopupsClientLevel';
import { ModalsAndPopupsFacilityLevel } from './components/ModalsAndPopups/ModalsAndPopupsFacilityLevel';
import { ModalsAndPopupsGroundControlLevel } from './components/ModalsAndPopups/ModalsAndPopupGroundControlLevel';

// variables, functions, configurations
import { AUTH_PAGES_URLS } from './common/pages';

// styles
import './App.css';

// routes
import ClientRoutes from './routes/ClientRoutes';
import AuthRoutes from './routes/AuthRoutes';

// pages
import AuthPage from './pages/Auth/AuthPage';
import { LocalStore } from './common/functions/storageFunctions';
import { useForceUpdate } from './hooks/useForceUpdate';
import { TokenManager } from './common/tokenManager';
import SignOut from './pages/Auth/SignOut';
import WebSocketComponent from './components/WebSocket/WebSocket';
import { WebSocketClient } from './shared/web-socket/web-socket.model';
import { webSocket } from './shared/web-socket/web-socket';

const tokenManager = TokenManager.getInstance();

LicenseInfo.setLicenseKey(
  'b03f915c37fc3caf802cb5e48626d055Tz02NzYxMCxFPTE3MTcxMzk5ODI0NjcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);
const websocket: WebSocketClient = webSocket();

const App = () => {
  // location
  const location = useLocation();

  const forceUpdate = useForceUpdate('App');
  LocalStore.setTriggerUpdate(forceUpdate);

  // tokens
  const accessToken = tokenManager.getAccessToken();

  // tracks the number of active network requests
  activeRequests.start();

  const deprecatedWebSocket = !tokenManager.userHaveAccess() ? null : <WebSocketComponent />;

  useEffect(() => {
    if (accessToken) {
      websocket.connect();
    }
    return () => {
      websocket.disconnect();
    };
  }, [accessToken]);
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div className="App">
      <SentryRoutes location={location} key={location.pathname}>
        {/* Authentication pages */}
        <Route path={`${AUTH_PAGES_URLS.ROOT}/*`} element={<AuthRoutes />} />
        {/* Client pages */}
        <Route
          path="/*"
          element={
            accessToken ? (
              <AuthPage>
                <ClientRoutes />
              </AuthPage>
            ) : (
              <SignOut onSignout={() => websocket?.disconnect()} />
            )
          }
        />
      </SentryRoutes>

      <ModalsAndPopupsGroundControlLevel />
      <ModalsAndPopupsFacilityLevel />
      <ModalsAndPopupsClientLevel />
      {deprecatedWebSocket}
    </div>
  );
};

export default App;
