import { Drawer, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useCallback, useRef } from 'react';
import { formatDateInTimezone } from 'common/functions/dateFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';

import { useStyles } from './styles';
import { slotUsageToExclusionStatus } from '../../utils/slotUsageToExclusionStatus';
import { ExclusionStatus } from '../../models/ExclusionStatus';
import { LocationsDrawerProps } from '../../models/LocationsDrawerProps';

const lp = getLogPrefixForType('COMPONENT', 'LocationsDrawer');

export const LocationsDrawer = ({
  onClose,
  exclusionHistory,
  location,
  isOpen,
}: LocationsDrawerProps): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();
  const justOpened = useRef(true);

  /**
   * Close the drawer by clicking anywhere on the screen
   * see https://verity-ag.atlassian.net/browse/UD-4355
   */
  const handleClickAway = useCallback(() => {
    const lpf = getLogPrefixForType('FUNCTION', 'handleClickAway', lp);
    if (!isOpen) return;

    console.debug(lpf, 'justOpened:', justOpened.current);
    if (justOpened.current) {
      // NOTE: you get here with the click which opened the drawer in the first place,
      // and you don't want to immediately close it
      // eno 2023-07-04
      justOpened.current = false;
    } else {
      onClose();
      justOpened.current = true;
    }
  }, [isOpen, onClose]);

  let udbExclusionStatus =
    typeof location?.verity_status === 'string'
      ? ExclusionStatus.NOT_SET
      : ExclusionStatus[location?.slot_settings?.exclusion_status as keyof typeof ExclusionStatus];

  const wmsExclusionStatus: ExclusionStatus =
    typeof location?.wms_status === 'string'
      ? ExclusionStatus.NOT_SET
      : ExclusionStatus[slotUsageToExclusionStatus(location?.wms_status?.slot_usage)];

  const hasWmsExclusionData = !!wmsExclusionStatus;

  udbExclusionStatus =
    !hasWmsExclusionData && udbExclusionStatus === ExclusionStatus.NOT_SET
      ? ExclusionStatus.INCLUDE
      : udbExclusionStatus;

  const udbExclusionStatusLabel = !hasWmsExclusionData ? 'Scan permission' : 'Permission override';
  const createdAt = exclusionHistory?.created_at
    ? formatDateInTimezone(exclusionHistory?.created_at, 'YYYY/MM/DD')
    : '-';
  const updatedBy = exclusionHistory?.email || '-';
  const reason = exclusionHistory?.reason || '-';
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer anchor="right" open={isOpen} variant="persistent" className={classes.drawer}>
        <Box
          p={3}
          mt={10}
          display="flex"
          flexDirection="column"
          height="100%"
          alignContent="space-between"
          position="relative"
        >
          <Box display="flex" flexDirection="column" height="calc(100% - 60px)">
            <Typography color="textSecondary" fontSize="14px">
              Location:
            </Typography>
            <Typography mb={4} fontWeight="bold" fontSize="24px">
              {location?.slot_label}
            </Typography>
            <Typography fontWeight="bold" mb={3}>
              Scan permission
            </Typography>
            {hasWmsExclusionData && (
              <TextField
                disabled
                variant="standard"
                sx={{ mb: 2 }}
                value={wmsExclusionStatus}
                label="WMS scan permission"
              />
            )}
            <TextField
              disabled
              variant="standard"
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
              value={udbExclusionStatus || ExclusionStatus.NOT_SET}
              label={udbExclusionStatusLabel}
            />
            <TextField
              disabled
              variant="standard"
              value={createdAt}
              sx={{ mb: 2 }}
              label="Last update"
            />
            <TextField
              disabled
              variant="standard"
              value={updatedBy}
              sx={{ mb: 2 }}
              label="Updated by"
            />
            <TextField
              disabled
              variant="standard"
              multiline
              maxRows={4}
              value={reason}
              sx={{ mb: 2 }}
              label="Reason"
            />
          </Box>

          <Box>
            <IconButton
              data-testid="c-locations-drawer-close-button"
              sx={{
                borderRadius: 1,
                color: theme.palette.grey[600],
                background: theme.palette.grey[300],
              }}
              onClick={() => {
                justOpened.current = true;
                onClose();
              }}
            >
              <ChevronRightOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};
