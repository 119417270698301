import { Navigate } from 'react-router-dom';
import { AUTH_PAGES_URLS } from 'common/pages';

// store and context
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';

interface IPublicRouteProps {
  children: JSX.Element;
  restricted: boolean;
}

const PublicRoute = ({ children, restricted }: IPublicRouteProps) => {
  const { stateUserLevel } = useUserLevelStore();
  return !stateUserLevel.isAuth && restricted ? <Navigate to={AUTH_PAGES_URLS.SIGNIN} /> : children;
};

export default PublicRoute;
