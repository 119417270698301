// This file will hold all the global settings for the app

import React from 'react';

// material-ui icons
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BugReportIcon from '@mui/icons-material/BugReport';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import {
  userHasPermission,
  userHasSomePermissions,
} from '../features/permissions/userHasPermission';
import { PERMISSION } from '../features/permissions/permissions.model';

// ###########################################################################
// Here are all the possible configurations for the home screen
// ###########################################################################
export const HOME_SCREEN_SETTINGS = () => ({
  // Cards
  SHOW_AUTOMATIC_UPLOADS_SUMMARY_CARD: true,

  // Page title
  SHOW_CLIENT_NAME: false,
  SHOW_FACILITY_NAME: false,
});

// ###########################################################################
// Here are all the possible configurations for the application header
// ###########################################################################
export const HEADER_SETTINGS = () => ({
  // Support link
  SHOW_SUPPORT_LINK: true,
  SUPPORT_URL: 'https://support.verity.net/support/home',

  // Texts
  SHOW_CLIENT_NAME: false,
  SHOW_FACILITY_NAME: true,
  // Logo
  SHOW_CLIENT_LOGO: true,
});

// ###########################################################################
// Here are all the possible configurations for the FacilityMenu
// ###########################################################################
export const FACILITY_MENU_SETTINGS = () => ({
  // Links
  SHOW_ADMIN_LINK: true,
  // The dev tools link is never rendered if the
  // code is running on a PROD environment. so
  // this setting only applies in DEV environments
  SHOW_DEV_TOOLS_LINK: true,
});

// ###########################################################################
// Facility settings
// ###########################################################################
export const FACILITY_SETTINGS_CARDS_VISIBILITY = () => ({
  AUTHENTICATION: true,
  BACKEND: true,
  BARCODES: true,
  BARCODE_TYPES: false,
  DUAL_CHECK_ON_UNLOCK: true,
  ISSUES: true,
  LOAD_SETTINGS_FROM_FILE: true,
  REPORT_AUTOMATIC_ACTIONS: true,
  REPORT_LOCATION: true,
  REPORT_REVIEWS: true,
  REPORT_DEADLINES: true,
  UI: true,
  WMS_INTEGRATION: true,
  SYSTEM_RESET: true,
});

// ###########################################################################
// WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB and
// REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB - settings for the pagination of
// requests on the warehouse status and report pages. This pagination refers
// to server requests, it's not related to the user facing pagination.
//
// MAX_CONCURRENT_REQUESTS and RETRIES - settings applied to the network
// requests. These are not global settings but should be instead used as:
//   1. argument to be passed to a function that handles multiple network
//      requests (requestQueue) - MAX_CONCURRENT_REQUESTS
//   2. argument to be passed to axios instance through service functions -
//      RETRIES
// ###########################################################################
export const NETWORK_REQUEST_SETTINGS = {
  WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB: 1000,
  REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB: 1000,
  MAX_CONCURRENT_REQUESTS: 12,
  RETRIES: 3,
};

// ###########################################################################
// This setting defines maximum number of locations that can be approved in a
// single approve slot status network request.
// ###########################################################################
export const CLIENT_SIDE_PAGINATION_SETTINGS = {
  SUBMIT_REPORT_REVIEW_PAGE_SIZE: 1000,
};

// ###########################################################################
// Here are all the possible tabs for the tables on Reports, Report (SOLVED
// tab not available for this page) and Warehouse status (uses REPORT tabs)
// pages.
// ###########################################################################
export const TAB_NAMES = {
  REPORTS: {
    ALL: 'ALL',
    FINISHED: 'FINISHED',
    ONGOING: 'ONGOING',
    SCHEDULED: 'SCHEDULED',
    'TO REVIEW': 'TO REVIEW',
    ARCHIVED: 'ARCHIVED',
  },
  REPORT: {
    ISSUES: 'ISSUES',
    SOLVED: 'SOLVED',
    AMENDED: 'AMENDED',
    SNOOZED: 'SNOOZED',
    'POTENTIAL I.': 'POTENTIAL I.',
    INCONCLUSIVE: 'INCONCLUSIVE',
    INVALID: 'INVALID',
    'FULL REPORT': 'FULL REPORT',
    'LOCATIONS TO REVIEW': 'LOCATIONS TO REVIEW',
    'OTHER LOCATIONS': 'OTHER LOCATIONS',
  },
  WAREHOUSE_UPLOADS: {
    'WMS SNAPSHOTS FILES': 'WMS SNAPSHOTS FILES',
    'STOCK CHECK FILES': 'STOCK CHECK FILES',
    'MOVED LOCATIONS FILES': 'MOVED LOCATIONS FILES',
  },
};

// ###########################################################################
// Here, we instantiate all the tabs we want shown in the tables on Reports,
// Report and Warehouse status pages. All the possible tabs are listed in the
// TAB_NAMES above.
// Order in which tabs are added in the below arrays matters. It corresponds
// to the position in which a tab will be rendered in a table
// ###########################################################################
export const TABS = (settingsData: any) => ({
  REPORTS_TABS: [
    {
      icon: '',
      label: TAB_NAMES.REPORTS.FINISHED,
      show: userHasPermission(PERMISSION.VIEW_FINISHED_REPORTS),
      orderBy: 'finishedAt',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.REPORTS.ONGOING,
      show: userHasPermission(PERMISSION.VIEW_ONGOING_REPORTS),
      orderBy: 'startedAt',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.REPORTS.SCHEDULED,
      show: userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT),
      orderBy: 'scheduledFor',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.REPORTS['TO REVIEW'],
      show: userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT),
      orderBy: 'startedAt',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.REPORTS.ARCHIVED,
      show: userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT),
      orderBy: 'finishedAt',
      order: 'desc',
    },
  ],
  REPORT_TABS: [
    {
      icon: <LibraryBooksIcon />,
      label: TAB_NAMES.REPORT['FULL REPORT'],
      show: true,
    },
    {
      icon: <ErrorIcon />,
      label: TAB_NAMES.REPORT.ISSUES,
      show: true,
    },
    {
      icon: <BugReportIcon />,
      label: TAB_NAMES.REPORT['POTENTIAL I.'],
      show: userHasPermission(PERMISSION.SHOW_POTENTIAL_REPORT_ISSUES)
        ? settingsData && settingsData.show_potential_issues
        : true,
    },
    {
      icon: <NotInterestedIcon />,
      label: TAB_NAMES.REPORT.SNOOZED,
      show: true,
    },
    {
      icon: <PersonIcon />,
      label: TAB_NAMES.REPORT.AMENDED,
      show: userHasPermission(PERMISSION.AMEND_REPORT)
        ? settingsData && settingsData.allow_user_amend
        : true,
    },
    {
      icon: <HelpIcon />,
      label: TAB_NAMES.REPORT.INCONCLUSIVE,
      show: true,
    },
    {
      icon: <CancelIcon />,
      label: TAB_NAMES.REPORT.INVALID,
      show: true,
    },
  ],
  REPORT_REVIEW_TABS: [
    {
      icon: <RateReviewIcon />,
      label: TAB_NAMES.REPORT['LOCATIONS TO REVIEW'],
      show: userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT),
    },
    {
      icon: <LibraryBooksIcon />,
      label: TAB_NAMES.REPORT['OTHER LOCATIONS'],
      show: userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT),
    },
  ],
  WAREHOUSE_STATUS_TABS: [
    {
      icon: <LibraryBooksIcon />,
      label: TAB_NAMES.REPORT['FULL REPORT'],
      show: true,
    },
    {
      icon: <ErrorIcon />,
      label: TAB_NAMES.REPORT.ISSUES,
      show: true,
    },
    {
      icon: <BugReportIcon />,
      label: TAB_NAMES.REPORT['POTENTIAL I.'],
      show: settingsData && settingsData.show_potential_issues,
    },
    {
      icon: <NotInterestedIcon />,
      label: TAB_NAMES.REPORT.SNOOZED,
      show: true,
    },
    {
      icon: <PersonIcon />,
      label: TAB_NAMES.REPORT.AMENDED,
      show: settingsData && settingsData.allow_user_amend,
    },
    {
      icon: <HelpIcon />,
      label: TAB_NAMES.REPORT.INCONCLUSIVE,
      show: true,
    },
    {
      icon: <CancelIcon />,
      label: TAB_NAMES.REPORT.INVALID,
      show: true,
    },
    {
      icon: <CheckCircleIcon />,
      label: TAB_NAMES.REPORT.SOLVED,
      show: false,
    },
  ],
  WAREHOUSE_UPLOADS_TABS: [
    {
      icon: '',
      label: TAB_NAMES.WAREHOUSE_UPLOADS['WMS SNAPSHOTS FILES'],
      show: true,
      orderBy: 'uploadDate',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.WAREHOUSE_UPLOADS['STOCK CHECK FILES'],
      show: true,
      orderBy: 'uploadDate',
      order: 'desc',
    },
    {
      icon: '',
      label: TAB_NAMES.WAREHOUSE_UPLOADS['MOVED LOCATIONS FILES'],
      show: true,
      orderBy: 'uploadDate',
      order: 'desc',
    },
  ],
});

export const REQUESTS = {
  SHOW_EXPIRED_INVENTORY_REQUESTS: false,
};

// possible values: ["last week", "two weeks", "last month", "all", "by date", "interval"]
export const TIMEFRAME_TO_FETCH_REPORTS_FROM = 'last week';

// possible values: ["last week", "two weeks", "last month", "all", "by date", "interval"]
export const TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM = 'last week';

// #########################################################
// Specifies visibility of header action menu on pages
// #########################################################
export const SHOW_PAGE_HEADER_MENU_ICON = {
  WAREHOUSE_DATA_UPLOADS: false,
  WAREHOUSE_STATUS: false,
  REPORTS: false,
  REPORT: false,
  ISSUE: true,
};

// #########################################################
// Specifies visibility of header action buttons on pages
// Examples: Save, Abort, Update
// #########################################################
export const SHOW_PAGE_HEADER_ACTIONS = {
  ADMIN: true,
};

// If locations to review that have correct verity data
// should be amended before submitting review
export const SAVE_ALL_REVIEWS_FOR_VERITY_USER = false;

// The (normed) percentage by which to reduce the lifespan
// of access and id tokens
export const ACCESS_AND_ID_TOKENS_LIFESPAN_REDUCER = 0.2;

export const TABLE_COLUMN_OPTIONS = (settingsData: any) => ({
  // WAREHOUSE STATUS PAGE TABLE SETTINGS
  WHS_ISSUES: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    issueType: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    barcodeExpectedFoundOn: { searchable: true, show: true },
    barcodeFoundShouldBeOn: { searchable: true, show: true },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },
  WHS_SOLVED: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    issueType: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    barcodeFoundShouldBeOn: { searchable: true, show: true },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },
  WHS_AMENDED: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    wmsValue: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    wmsDate: { searchable: true, show: true },
    verityValue: { searchable: true, show: true },
    verityDate: { searchable: true, show: true },
    userValue: { searchable: true, show: true },
    userName: { searchable: true, show: true },
    issue: { searchable: true, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },
  WHS_POTENTIAL_AND_INCONCLUSIVE: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },
  WHS_INVALID: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    contentFoundDate: { searchable: true, show: true },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },
  WHS_FULL_REPORT: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    slotUsage: { searchable: false, show: true },
    wmsValue: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    contentFoundDate: { searchable: true, show: true },
    wmsArticleNo: { searchable: true, show: true },
    wmsDescription: { searchable: false, show: false },
    wmsQty: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    issue: { searchable: true, show: true },
    issueStatus: { searchable: true, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: true },
  },

  // REPORT PAGE TABLE SETTINGS
  REPORT_ISSUES: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    issueType: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    barcodeExpectedFoundOn: { searchable: true, show: true },
    barcodeFoundShouldBeOn: { searchable: true, show: true },
    userValue: { searchable: false, show: false },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_SOLVED: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    issueType: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    barcodeFoundShouldBeOn: { searchable: true, show: true },
    userValue: { searchable: false, show: false },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_AMENDED: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    wmsValue: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    verityValue: { searchable: true, show: true },
    verityDate: { searchable: true, show: true },
    userValue: { searchable: true, show: true },
    userName: { searchable: true, show: true },
    issue: { searchable: true, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_POTENTIAL_AND_INCONCLUSIVE: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_INVALID: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    contentExpected: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    contentFoundDate: { searchable: true, show: true },
    issueFirstFoundOn: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_FULL_REPORT: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    slotUsage: { searchable: false, show: false },
    wmsValue: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    contentFoundDate: { searchable: true, show: true },
    wmsArticleNo: { searchable: true, show: true },
    wmsDescription: { searchable: false, show: false },
    wmsQty: { searchable: true, show: true },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    issue: { searchable: true, show: true },
    issueStatus: { searchable: false, show: true },
    image: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  REPORT_REVIEW: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    wmsValue: { searchable: false, show: false },
    wmsDate: { searchable: false, show: false },
    wmsCustomer: { searchable: true, show: settingsData && settingsData.show_customer },
    verityValue: { searchable: true, show: true },
    verityDate: { searchable: true, show: true },
    userValue: { searchable: false, show: false },
    userName: { searchable: false, show: false },
    wmsArticleNo: { searchable: false, show: false },
    wmsDescription: { searchable: false, show: false },
    wmsQty: { searchable: false, show: false },
    issue: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  WAREHOUSE_DATA_UPLOADS: {
    checkbox: { searchable: false, show: false },
    uploadDate: { searchable: true, show: true },
    clientFileName: { searchable: true, show: true },
    uploadUser: { searchable: true, show: true },
    mostRecentEntryUpdatedAt: { searchable: true, show: true },
    uploadStatus: { searchable: true, show: true },
    updatedLocations: { searchable: false, show: true },
    skippedLocations: { searchable: false, show: true },
    requestWarnings: { searchable: false, show: true },
    locationWarnings: { searchable: false, show: true },
    processedChunks: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  REPORTS_ALL: {
    checkbox: { searchable: false, show: false },
    reportType: { searchable: true, show: true },
    reportName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    startedAt: { searchable: true, show: true },
    finishedAt: { searchable: true, show: true },
    allLocations: { searchable: true, show: true },
    locationsToScan: { searchable: true, show: true },
    progress: { searchable: true, show: true },
    issues: { searchable: true, show: true },
    status: { searchable: true, show: true },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  REPORTS_FINISHED: {
    checkbox: {
      searchable: false,
      show: userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT),
    },
    reportType: { searchable: true, show: true },
    reportName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    startedAt: { searchable: true, show: true },
    finishedAt: { searchable: true, show: true },
    allLocations: { searchable: true, show: true },
    locationsToScan: { searchable: false, show: false },
    progress: { searchable: false, show: false },
    issues: { searchable: true, show: true },
    status: { searchable: false, show: false },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT) },
  },
  REPORTS_ONGOING: {
    checkbox: {
      searchable: false,
      show: userHasSomePermissions([
        PERMISSION.ARCHIVED_REPORTS_MANAGEMENT,
        PERMISSION.ABORT_ONGOING_REPORTS,
      ]),
    },
    reportType: { searchable: true, show: true },
    reportName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    startedAt: { searchable: true, show: true },
    allLocations: { searchable: true, show: true },
    locationsToScan: { searchable: false, show: false },
    progress: { searchable: true, show: true },
    status: { searchable: false, show: false },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  REPORTS_SCHEDULED: {
    checkbox: { searchable: false, show: false },
    reportType: { searchable: true, show: true },
    requestName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    createdAt: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    requestExpiresAt: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    status: { searchable: true, show: true },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  REPORTS_TO_REVIEW: {
    checkbox: {
      searchable: false,
      show: userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT),
    },
    reportType: { searchable: true, show: true },
    reportName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    startedAt: { searchable: true, show: true },
    allLocations: { searchable: true, show: true },
    locationsToScan: { searchable: false, show: false },
    progress: { searchable: true, show: true },
    status: { searchable: true, show: true },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  REPORTS_ARCHIVED: {
    checkbox: {
      searchable: false,
      show: userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT),
    },
    reportType: { searchable: true, show: true },
    reportName: { searchable: true, show: true },
    requestParams: { searchable: true, show: true },
    scheduledBy: { searchable: true, show: true },
    scheduledFor: { searchable: true, show: true },
    recurrence: { searchable: true, show: true },
    startedAt: { searchable: true, show: true },
    allLocations: { searchable: true, show: true },
    locationsToScan: { searchable: false, show: false },
    status: { searchable: true, show: true },
    priority: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  ISSUE_PAGE: {
    checkbox: { searchable: false, show: false },
    location: { searchable: true, show: true },
    wmsValue: { searchable: true, show: true },
    wmsDate: { searchable: true, show: true },
    contentFound: { searchable: true, show: true },
    contentFoundDate: { searchable: true, show: true },
    wmsArticleNo: { searchable: true, show: true },
    wmsDescription: { searchable: false, show: false },
    wmsQty: { searchable: true, show: true },
    issue: { searchable: true, show: true },
    image: { searchable: false, show: false },
    issueStatus: { searchable: false, show: false },
    actions: { searchable: false, show: false },
  },
  LOCATION_MODAL: {
    location: { searchable: false, show: false },
    wmsValue: { searchable: false, show: true },
    wmsDate: { searchable: false, show: true },
    contentFound: { searchable: false, show: true },
    contentFoundDate: { searchable: false, show: true },
    wmsArticleNo: { searchable: false, show: true },
    wmsDescription: { searchable: false, show: false },
    wmsQty: { searchable: false, show: true },
    issue: { searchable: false, show: true },
    actions: { searchable: false, show: true },
  },
  USER_MANAGEMENT: {
    email: { searchable: true, show: true },
    givenName: { searchable: true, show: true },
    familyName: { searchable: true, show: true },
    phoneNumber: { searchable: true, show: true },
    systemIds: { searchable: true, show: true },
    userGroups: { searchable: true, show: true },
    actions: { searchable: false, show: true },
  },
  NO_FLY_ZONES: {
    name: { searchable: true, show: true },
    description: { searchable: true, show: true },
    height: { searchable: true, show: true },
    status: { searchable: true, show: true },
    canFly: { searchable: true, show: true },
  },
  FLEET_OVERVIEW: {
    droneId: { searchable: true, show: true },
    chargerId: { searchable: true, show: true },
    chargerPosition: { searchable: true, show: true },
    batteryLevel: { searchable: true, show: true },
    batteryState: { searchable: true, show: true },
    wifiStatus: { searchable: true, show: true },
    droneState: { searchable: true, show: true },
  },
});
