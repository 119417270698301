import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import isNil from 'lodash/isNil';

// mui core
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

// components
import { getFlightDomainStatusColor } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';
import FleetStatus from './FleetStatus';
import StatusBarSwitch from '../StatusBarSwitch';

// helpers and functions
import { useViewportWidth } from '../../../hooks/useViewportWidth';
import { IFlightDomainData } from '../../../store/GroundControl/IGroundControlStore';
import { landedDronesMessage } from '../pages/FlightDomainOverview/FlightDomainOverviewFunctions';
import { isStatusBarSwitchEnabled } from '../pages/FlightDomainOverview/functions/isStatusBarSwitchEnabled';

interface IStatusBar {
  flightDomain: IFlightDomainData;
  isExecutingReturnHome: boolean;
  webSocketError: boolean;
  isExecutingEmergencyLanding: boolean;
  dronesFlying: boolean;
  lockSystem: () => void;
  unlockSystem: () => void;
  stickyStatusBar: boolean;
}

const StatusBar = (props: IStatusBar) => {
  const {
    flightDomain,
    isExecutingReturnHome,
    isExecutingEmergencyLanding,
    dronesFlying,
    webSocketError,
    lockSystem,
    unlockSystem,
    stickyStatusBar,
  } = props;

  // theme
  const theme = useTheme();

  // use viewport hook
  const width = useViewportWidth();

  const { flight_domain_status, fleet_status_summary } = flightDomain;

  const numberOfDronesFlying = isNil(fleet_status_summary?.num_drones_flying)
    ? -1
    : (fleet_status_summary?.num_drones_flying as number);
  const numberOfDronesOnCharger = isNil(fleet_status_summary?.num_drones_on_charger)
    ? -1
    : (fleet_status_summary?.num_drones_on_charger as number);
  const numberOfDronesLandedInPlace = isNil(fleet_status_summary?.num_drones_landed_in_error)
    ? -1
    : (fleet_status_summary?.num_drones_landed_in_error as number);

  const noFleetData = !fleet_status_summary;

  // Current status color of the flight domain
  const statusColor = getFlightDomainStatusColor({
    locked: flight_domain_status?.locked || false,
    isExecutingReturnHome,
    isExecutingEmergencyLanding,
  });

  const handleStatusText = (): string => {
    if (noFleetData) {
      return 'No data available.';
    }
    if (isExecutingEmergencyLanding) {
      return 'WARNING: safety landing in progress.';
    }

    if (flight_domain_status?.locked && fleet_status_summary?.num_drones_landed_in_error > 0) {
      return landedDronesMessage(fleet_status_summary?.num_drones_landed_in_error);
    }

    if (!flight_domain_status?.locked) {
      return 'ATTENTION: drones enabled.';
    }

    if (flight_domain_status?.locked && dronesFlying) {
      return 'ATTENTION: drones are still flying.';
    }

    return 'Drones are on their chargers.';
  };

  const statusText = handleStatusText();

  const isLockSwitchEnabled = isStatusBarSwitchEnabled(
    flightDomain.flight_domain_status?.can_unlock,
    flightDomain.flight_domain_status?.locked,
  );

  const renderSwitch = () => {
    if (flight_domain_status) {
      return (
        <StatusBarSwitch
          color={statusColor}
          locked={flight_domain_status.locked}
          checked={!flight_domain_status.locked}
          disabled={!isLockSwitchEnabled}
          onChange={() => {
            if (flight_domain_status.locked) {
              unlockSystem();
            } else {
              lockSystem();
            }
          }}
        />
      );
    }
    return (
      <StatusBarSwitch
        color={statusColor}
        locked={false}
        checked={true}
        disabled={false}
        noData={true}
        onChange={lockSystem}
      />
    );
  };

  return (
    <Box
      sx={{
        zIndex: 1200,
        top: stickyStatusBar ? 0 : 64,
        right: 0,
        mt: -2,
        overflowY: 'hidden',
        position: 'sticky',
        transition: theme.transitions.create('top', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
      width="100%"
    >
      <Accordion disableGutters square>
        <AccordionSummary
          data-testid="c-fd-status-bar"
          expandIcon={false}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{
            cursor: 'auto',
            background: statusColor,
            '& .MuiAccordionSummary-content': {
              margin: '0px 0px',
            },
          }}
        >
          <Grid justifyContent="space-between" sx={{ cursor: 'auto' }} width="100%" container>
            <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
              <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                {renderSwitch()}

                <Typography
                  data-testid="c-fd-status-bar-fd-name"
                  sx={{ fontWeight: 500 }}
                  mx={2}
                  color="white"
                >
                  {flightDomain.flight_domain_name.toUpperCase()}
                </Typography>
                <VerticalDivider theme={theme} />
              </Box>
              <Typography data-testid="c-fd-status-bar-safe_status" mx={2} color="white">
                {statusText}
              </Typography>
            </Box>

            <Box width="300px" p={2}>
              <FleetStatus
                width={width}
                theme={theme}
                webSocketError={webSocketError}
                noData={noFleetData}
                flying={numberOfDronesFlying}
                onCharger={numberOfDronesOnCharger}
                landedInPlace={numberOfDronesLandedInPlace}
              />
            </Box>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </Box>
  );
};

// Divider Component
const VerticalDivider = ({ theme }: { theme: Theme }) => (
  <Hidden mdDown>
    <Divider
      sx={{
        height: '35px',
        background: '#fff',
        position: 'absolute',
        right: 0,
        transition: theme.transitions.create(['height'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
      orientation="vertical"
      flexItem
    />
  </Hidden>
);

export default StatusBar;
