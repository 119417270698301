/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Container, Fab, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridEventListener,
  GridRowHeightParams,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { SHOW_PAGE_HEADER_MENU_ICON } from 'common/settings';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { useRequestController } from '../../hooks';
import { ILocationDataST } from '../../interfaces';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from '../../store/Modals';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import SnoozeMultipleIssuesModal from './SnoozeMultipleIssuesModal';
import { columns } from './WHSColumns';
import { WHSStore } from './WHStore';

const logPrefix = getLogPrefixForType('PAGE', 'WHS Page');
const noRows = [] as never[];

const useRerender = () => {
  const [, setState] = useState(Date.now());
  return () => setState(Date.now());
};

const styles = {
  width: '100%',
  display: 'flex',
  '& .MuiDataGrid-columnHeaders': { backgroundColor: 'lightgray' },
  '& .MuiDataGrid-columnHeader': { backgroundColor: 'lightgray' },
  '& .row-even': { backgroundColor: 'rgb(250, 250, 250)' },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
};

/**
 * Warehouse status experimental page
 * @returns component
 */
export const WarehouseStatusX = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { slots } = stateFacilityLevel;
  const rerender = useRerender();
  const { requestController } = useRequestController('WarehouseStatusX');
  const { systemId = '' } = useParams();
  const gridApiRef = useGridApiRef();

  const fetchIssuesOnly = useRef(true);
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);

  const store = useMemo(() => {
    console.debug(logPrefix, 'running WHS store memo -');
    if (gridApiRef.current.hasOwnProperty('updateRows')) {
      return WHSStore(systemId, gridApiRef.current, requestController, slots);
    }
    console.debug(logPrefix, '- Grid API is not available, store not created');
    return null;
  }, [gridApiRef.current]);

  const loadLocationsData = () => {
    if (!store) {
      rerender();
    } else if (fetchIssuesOnly.current) {
      store.loadLocationsWithIssues();
    } else {
      store.loadLocationData();
    }
  };

  useEffect(() => {
    console.debug(logPrefix, `useEffect run start ${store ? 'with' : 'without'} store`);
    loadLocationsData();
  }, [store]);

  const rowCount = gridApiRef.current?.getRowsCount && gridApiRef.current.getRowsCount();

  console.debug(
    logPrefix,
    `rendering ${rowCount} rows in columns`,
    columns,
    'on api',
    gridApiRef.current,
  );

  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    const filteredRows = gridFilteredSortedRowIdsSelector(gridApiRef)
      .map((rowId) => gridApiRef.current.getRow(rowId))
      .map((row) => getRowForFullReportTable(row.slot_label, row, row.issues));

    const selectedRow = getRowForFullReportTable(
      params.row.slot_label,
      params.row,
      params.row.issues,
    );

    const hasRows = Object.keys(params.row).length > 0;

    hasRows &&
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
        payload: getLocationListData(filteredRows, selectedRow),
        refreshDataOnClose: {
          refreshData: loadLocationsData,
        },
        parentPage: 'WarehouseStatus',
        reportId: undefined,
        locationInfo: { tab: 'currentTabData.tableTitle', searchTerm: 'searchTerm' },
      });
  };

  const loadingString =
    store && !store.isEverythingLoaded()
      ? `- Loading... (${rowCount} of ${slots.length} locations)`
      : '';

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([id, value]) => value) as ILocationDataST[];

  return (
    <>
      <PageHeaderSection
        title="BETA Warehouse status"
        subtitle={`The latest information about your warehouse ${loadingString}`}
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.WAREHOUSE_STATUS}
      />
      <Container maxWidth="xl">
        {fetchIssuesOnly.current && (
          <Fab
            sx={{ ml: 2 }}
            variant="extended"
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => {
              if (fetchIssuesOnly.current) {
                fetchIssuesOnly.current = false;
                loadLocationsData();
              }
            }}
          >
            All locations
          </Fab>
        )}
        <Fab
          sx={{ ml: 2 }}
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => setCheckboxSelection(!checkboxSelection)}
        >
          Selection
        </Fab>
        {checkboxSelection && (
          <>
            <Fab
              sx={{ ml: 2 }}
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              onClick={() => {
                setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
                setIsSnoozeModalOpen(true);
              }}
            >
              Snooze selected
            </Fab>
            <Fab
              sx={{ ml: 2 }}
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              onClick={() => {
                setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
                setIsSnoozeModalOpen(true);
              }}
            >
              Unsnooze selected
            </Fab>
          </>
        )}
        <Card
          sx={{
            height: 'calc(100vh - 22em)',
            display: 'flex',
            maxWidth: 'calc(100vw - 24em)',
            mt: 2,
          }}
        >
          <DataGridPremium
            sx={styles}
            columns={columns}
            apiRef={gridApiRef}
            rows={noRows}
            getRowId={(row) => row.slot_label}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
            }
            getRowHeight={({ model, densityFactor }: GridRowHeightParams) => {
              const verityStatusNrOfBarcodes = model.verity_status?.barcodes.length ?? 0;
              const wmsStatusNrOfBarcodes = model.wms_status?.barcodes.length ?? 0;
              const nrOfBarcodes =
                wmsStatusNrOfBarcodes > verityStatusNrOfBarcodes
                  ? wmsStatusNrOfBarcodes
                  : verityStatusNrOfBarcodes;

              if (nrOfBarcodes > 1) {
                return nrOfBarcodes * 20 + 30 * densityFactor;
              }

              return null;
            }}
            slotProps={{ toolbar: { excelOptions: { disableToolbarButton: true } } }}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
            }}
            loading={store?.isLoading()}
            onRowClick={handleEvent}
            checkboxSelection={checkboxSelection}
            rowGroupingColumnMode="multiple"
            disableRowSelectionOnClick
            disableColumnSelector // disables column show/hide
            disableColumnReorder // disables column reordering
            disableColumnPinning
            disableAggregation
          />
        </Card>
      </Container>
      {isSnoozeModalOpen && (
        <SnoozeMultipleIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationsData}
        />
      )}
    </>
  );
};
