import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const drawerWidth = 240;

export const headerStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiToolbar-root': {
      justifyContent: 'space-between',
    },
  },
  appBar: {
    zIndex: 1300,
    backgroundColor: theme.palette.accent.main,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  userOptionsDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1301,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  menuButton: {
    marginLeft: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    margin: theme.spacing(0, 2),
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },
  hoverable: {
    cursor: 'pointer',
  },
  logo: {
    width: 60,
    height: 65,
    display: 'flex',
    fontSize: '2.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#645ad1',
  },
  navLink: {
    color: '#fbfbfb',
    textDecoration: 'none',
    verticalAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    marginLeft: theme.spacing(4),
    '&:hover': {
      color: '#aaa',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  menuWrapper: {
    marginTop: theme.spacing(1),
  },
}));
