import { Dispatch } from 'react';
import { IExclusionRequestResponseST } from 'codegen/warehouse_status';
import { IRequestController } from 'hooks';
import {
  LocationsActionNames,
  LocationsActions,
} from 'udb/features/locations-management/reducer/LocationsActions';
import { WarehouseService } from 'services/Warehouse';
import { GetLocationExclusionStatusHistory } from 'services/Warehouse/GetLocationExclusionStatusHistory';

type GetLocationExclusionHistoryParams = {
  systemId: string;
  exclusionRequestIds: string[];
  requestController: IRequestController;
  dispatch: Dispatch<LocationsActions>;
};

/**
 * Triggers api call for fetching excluded location history
 * @param params UpdateExclusionStatusParams
 */
export const getLocationExclusionHistory = ({
  requestController,
  exclusionRequestIds,
  dispatch,
  systemId,
}: GetLocationExclusionHistoryParams) => {
  const requestIds: string[] = [];

  exclusionRequestIds.forEach((exclusionRequestId) => {
    const { signal, requestId } = requestController.reserveSlotForRequest();

    const requestParams: GetLocationExclusionStatusHistory = {
      signal,
      systemId,
      exclusionRequestId,
    };

    requestController.doRequest(
      {
        request: WarehouseService.getLocationExclusionStatusHistory,
        requestParams: [requestParams],
        messageErrorFallback: 'Location could not be fetched.',
        callbackSuccess: ({ data }: { data: IExclusionRequestResponseST }) =>
          dispatch({ type: LocationsActionNames.SET_LOCATION_EXCLUSION_HISTORY, payload: data }),
      },
      requestId,
    );

    requestIds.push(requestId);
  });

  return requestIds;
};
