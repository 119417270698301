import { useEffect } from 'react';

// mui core
import { Container, Grid } from '@mui/material';

// components

// context and store

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { FlightHoursCard } from './FlightHoursCard/FlightHoursCard';
import { OperatingHoursEventST } from './API';
import { ScheduleLock } from './ScheduleLock/ScheduleLock';
import { FlightHoursCalendar } from './FlightHoursCalendar/FlightHoursCalendar';
import { useFacilityLevelStore } from '../../../../store/FacilityLevelStore/facilityLevelStore';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';

const logPrefix = getLogPrefixForType('COMPONENT', 'FlightDomainSchedule');

export const FlightDomainSchedule = () => {
  const flightDomainId = LocalStore.getFlightDomainId();
  const { stateGroundControl, populateFlightDomainSchedule, saveFlightDomainSchedule } =
    useGroundControlStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { schedule } = stateGroundControl.flightDomainSchedule.drone_operating_hours;

  useEffect(() => {
    if (flightDomainId) {
      console.debug(logPrefix, 'populate flight domain schedule with f_ID', flightDomainId);
      populateFlightDomainSchedule(flightDomainId);
    }
  }, [flightDomainId, populateFlightDomainSchedule]);

  const updateSchedule = (schedule: OperatingHoursEventST[]): void => {
    console.debug(logPrefix, 'updateSchedule', schedule);
    saveFlightDomainSchedule({
      ...stateGroundControl.flightDomainSchedule,
      drone_operating_hours: { schedule },
    });
  };

  const showScheduleLock =
    stateFacilityLevel.facilitySettings?.show_scheduled_lock &&
    userHasPermission(PERMISSION.SCHEDULE_LOCK);

  return (
    <>
      <PageHeaderSection
        title="Schedule"
        subtitle={`Ground control schedule for ${stateGroundControl.flightDomain.flight_domain_name}.`}
      />
      <Container maxWidth="xl">
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item sm={12} lg={8} xl={6}>
            {userHasPermission(PERMISSION.VIEW_DRONE_FLIGHT_HOURS) && (
              <FlightHoursCard
                schedule={schedule}
                setFlightHours={updateSchedule}
                isReadOnlyMode={!userHasPermission(PERMISSION.EDIT_DRONE_FLIGHT_HOURS)}
              />
            )}
          </Grid>
          <Grid item sm={12} lg={8} xl={6}>
            {showScheduleLock && <ScheduleLock />}
          </Grid>
          <Grid item sm={12} width="100%">
            <FlightHoursCalendar schedule={schedule} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
