import Container from '@mui/material/Container';

import './style.css';

import { useParams } from 'react-router-dom';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import CardGrid from 'components/common/CardGrid/CardGrid';
import { HomeReportsGrid } from './HomeReportsGrid';
import { HomeWarehouseStatGrid } from './HomeWarehouseStatGrid';
import { userHasSomePermissions } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

export const Home = () => {
  const facilityName = localStorage.getItem('facilityName');
  const { systemId } = useParams();

  const isHomeReportsGridVisible: boolean = userHasSomePermissions([
    PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
    PERMISSION.VIEW_ONGOING_REPORTS,
    PERMISSION.VIEW_FINISHED_REPORTS,
  ]);

  const isHomeWarehouseStatGridVisible: boolean = userHasSomePermissions([
    PERMISSION.WMS_MANAGEMENT,
    PERMISSION.VIEW_WAREHOUSE_STATUS,
  ]);

  return (
    <>
      <PageHeaderSection
        title="Inventory overview"
        subtitle={`Here is your inventory overview for the ${facilityName} facility`}
      />

      <Container maxWidth="xl">
        <CardGrid columnCount={2} className="c-page-content">
          {isHomeWarehouseStatGridVisible && <HomeWarehouseStatGrid systemId={systemId!} />}

          {isHomeReportsGridVisible && <HomeReportsGrid />}
        </CardGrid>
      </Container>
    </>
  );
};
