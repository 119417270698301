import { Vec6 } from 'shared/map-container/MapContainer.model';
import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { toFixed2 } from 'shared/numberFormatting';
import { BottomLeftDimensions } from '../../model/NoFlyZoneGeometry.model';

export const validateSizeAndPositionFitsWithinWorldBox = ({
  zoneVectors,
  facilityVectors,
  fieldKey,
}: {
  zoneVectors: BottomLeftDimensions;
  facilityVectors: Vec6;
  fieldKey: keyof BottomLeftDimensions;
}): string | null => {
  const {
    w: zoneWidth,
    l: zoneLength,
    h: zoneHeight,
    minX: zoneMinX,
    minY: zoneMinY,
    minZ: zoneMinZ,
  } = zoneVectors;

  const {
    minX: facilityMinX,
    minY: facilityMinY,
    maxX: facilityMaxX,
    maxY: facilityMaxY,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(facilityVectors);

  switch (fieldKey) {
    case 'w': {
      const validationMaxX = toFixed2(zoneMinX + zoneWidth);
      const deltaX = toFixed2(validationMaxX - facilityMaxX);

      if (zoneWidth < 0) {
        return '"Width" must be a positive number';
      }

      if (validationMaxX > facilityMaxX) {
        return `"Width" of ${zoneWidth}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaX,
        )}m`;
      }

      return null;
    }
    case 'l': {
      const validationMaxY = toFixed2(zoneMinY + zoneLength);
      const deltaY = toFixed2(validationMaxY - facilityMaxY);

      if (zoneLength < 0) {
        return '"Length" must be a positive number';
      }

      if (validationMaxY > facilityMaxY) {
        return `"Length" of ${zoneLength}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaY,
        )}m`;
      }

      return null;
    }
    case 'h': {
      const validationMaxZ = toFixed2(zoneMinZ + zoneHeight);
      const deltaZ = toFixed2(validationMaxZ - facilityMaxZ);

      if (zoneHeight < 0) {
        return '"Height" must be a positive number';
      }

      if (validationMaxZ > facilityMaxZ) {
        return `"Height" of ${zoneHeight}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaZ,
        )}m`;
      }

      return null;
    }
    case 'minX': {
      const validationMaxX = toFixed2(zoneMinX + zoneWidth);
      const isBelowMin = zoneMinX < facilityMinX;
      const isAboveMax = validationMaxX > facilityMaxX;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinX = toFixed2(facilityMinX - zoneMinX);
      const deltaMaxX = toFixed2(validationMaxX - facilityMaxX);
      const validationValue = isBelowMin ? deltaMinX : deltaMaxX;

      return `"X-Position" of ${zoneMinX}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
        validationValue,
      )}m`;
    }
    case 'minY': {
      const validationMaxY = toFixed2(zoneMinY + zoneLength);
      const isBelowMin = zoneMinY < facilityMinY;
      const isAboveMax = validationMaxY > facilityMaxY;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinY = toFixed2(facilityMinY - zoneMinY);
      const deltaMaxY = toFixed2(validationMaxY - facilityMaxY);
      const validationValue = isBelowMin ? deltaMinY : deltaMaxY;

      return `"Y-Position" of ${zoneMinY}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
        validationValue,
      )}m`;
    }
    default:
      return null;
  }
};
