// libraries
import { useCallback, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// material-ui core
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

// material-ui icons
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// variables, functions, configurations
import { DATETIME_FORMAT, PICKER_SHORT_DATETIME_FORMAT } from 'common/datetimeFormats';
import { getTimeIntervalDates } from 'common/functions/otherFunctions';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';
import Alert from 'components/common/Alert';
import ActionsMenu from 'components/common/ActionsMenu';
import Spinner from 'components/common/Spinner';
import SkeletonPageHeader from 'components/Skeletons/SkeletonPageHeader';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';

// store
import { LocalStore } from 'common/functions/storageFunctions';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

// other
import { pageHeaderStyles } from './styles';

// hooks
import { useHideOnScroll } from '../../hooks/useHideOnScroll';

import PageHeaderBreadcrumbs from './PageHeaderBreadcrumbs';

// make week starts from "Monday"
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const useStyles = makeStyles()((theme) => ({ ...pageHeaderStyles(theme) }));

const PageHeaderSection = (props) => {
  // props
  const {
    title,
    subtitle,
    showMenuIcon,
    headerActions,
    showLoadedSince,
    showDownloadBtn,
    downloadSpinning,
    onClickDownload,
    initiallyGetDataInTimeInterval = 'all',
    showFacilityName = true,
    getDataFrom,
    enableIntervalSelectors = true,
    customBtn,
    isReportPage,
    isSchedulingPage,
    isGroundControlPage,
    alert,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // state variables
  const [loadSince, setLoadSince] = useState(
    searchParams.get('loadSince') || initiallyGetDataInTimeInterval,
  );
  const [chosenDate, setChosenDate] = useState(
    searchParams.get('from') || moment().startOf('day').format(DATETIME_FORMAT),
  );
  const [chosenIntervalFrom, setChosenIntervalFrom] = useState(
    searchParams.get('from') || moment().startOf('day').format(DATETIME_FORMAT),
  );
  const [chosenIntervalUntil, setChosenIntervalUntil] = useState(
    searchParams.get('until') || moment().endOf('day').format(DATETIME_FORMAT),
  );

  // hooks
  const stickyElement = useHideOnScroll({ threshold: 50 });
  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  // styles
  const { classes } = useStyles();

  const timezone = LocalStore.getTimezone();
  const { systemId } = useParams();

  // current facility
  const facility = stateClientLevel.facilityList.find((facility) => facility.id === systemId);

  // current facility name
  const facilityName = facility?.name || '';

  const haveMultipleFlightDomains = stateFacilityLevel.flightDomains.length > 1;

  // set default timezone
  moment.tz.setDefault(timezone);

  // Save chosen interval
  const storeChosenInterval = ({ loadSince, ...rest }) => {
    searchParams.set('from', String(rest.from));
    searchParams.set('until', String(rest.until));
    searchParams.set('loadSince', loadSince);
    setSearchParams(searchParams);
  };

  // Handling load since options for predefined dates,
  // last week, last two weeks, last month, all, by date, by interval
  const handleLoadSince = (loadSince) => {
    setLoadSince(loadSince);

    const dates = getTimeIntervalDates(loadSince);

    // Prevent request for 'interval' and 'date' options
    if (loadSince !== 'by interval' && loadSince !== 'by date') {
      getDataFrom(dates);
    }

    storeChosenInterval({
      loadSince,
      chosenDate,
      from: dates.from,
      until: dates.until,
    });
  };

  // Submit request for 'date' option
  const submitChosenDate = () => {
    const dates = {
      from: moment.utc(chosenDate).format(DATETIME_FORMAT),
      until: moment.utc(chosenDate).add(1, 'days').format(DATETIME_FORMAT),
    };
    getDataFrom(dates);
    storeChosenInterval({
      loadSince,
      ...dates,
    });
  };

  // Submit request for 'interval' option
  const submitInterval = () => {
    const dates = {
      from: moment.utc(chosenIntervalFrom).format(DATETIME_FORMAT),
      until: moment.utc(chosenIntervalUntil).format(DATETIME_FORMAT),
    };
    getDataFrom(dates);
    storeChosenInterval({
      loadSince,
      ...dates,
    });
  };

  // Dates validation
  const isValidDates = useCallback(() => {
    if (loadSince === 'by interval') {
      return (
        !moment(chosenIntervalFrom).isValid() ||
        !moment(chosenIntervalUntil).isValid() ||
        moment(chosenIntervalFrom).isAfter(chosenIntervalUntil) ||
        moment(chosenIntervalFrom).isAfter(moment(), 'day') ||
        moment(chosenIntervalUntil).isAfter(moment(), 'day')
      );
    }
    if (loadSince === 'by date') {
      return !moment(chosenDate).isValid() || moment(chosenDate).isAfter(moment());
    }
    return false;
  }, [loadSince, chosenDate, chosenIntervalFrom, chosenIntervalUntil]);

  // eslint-disable-next-line consistent-return
  const getTopValue = useCallback(() => {
    if (isGroundControlPage) {
      return stickyElement ? 60 : 120;
    }
    return stickyElement ? 0 : 60;
  }, [isGroundControlPage, stickyElement]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        zIndex: 1100,
        top: getTopValue(),
        pt: 2,
        position: 'sticky',
        background: '#fff',
        transition: theme.transitions.create(['top', 'margin', 'padding', 'background', 'border'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
      data-testid="c-page-header"
    >
      <Container disableGutters={true} maxWidth="xl">
        <Box
          width="100%"
          sx={{
            paddingBottom: theme.spacing(2),
            transition: theme.transitions.create(['top', 'margin', 'padding', 'background'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {title || subtitle ? (
            <Box className={classes.topHeader} display="flex">
              <Box alignItems="flex-end" display="flex">
                <Box pb={2}>
                  <Box>
                    <PageHeaderBreadcrumbs
                      navigate={navigate}
                      title={title}
                      facilityName={facilityName}
                      classes={classes}
                      systemId={systemId}
                      isReportPage={isReportPage}
                      multipleFacilitiesAccess={stateClientLevel.isMultiFacility}
                      multipleFlightDomains={haveMultipleFlightDomains}
                    />
                    {title && (
                      <Typography
                        data-testid="c-page-header-title"
                        className={classes.bold}
                        color="secondary"
                        variant="h4"
                      >
                        {showFacilityName &&
                          stateClientLevel.isMultiFacility &&
                          `${facilityName} - `}
                        {title}
                      </Typography>
                    )}
                  </Box>
                  <Box height="auto" display="flex" alignItems="flex-start">
                    <Typography
                      data-testid="c-overview-subtitle"
                      className={classes.subtitle}
                      color="textSecondary"
                      sx={{ fontWeight: 400 }}
                      variant="h6"
                    >
                      {subtitle}
                    </Typography>
                    {showLoadedSince && (
                      <FormControl className={classes.formControl}>
                        <CustomSelect
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          customClasses={classes.selectInput}
                          value={loadSince}
                          disabled={!enableIntervalSelectors}
                          onChange={(e) => handleLoadSince(e.target.value)}
                          valueOptions={[
                            { value: 'last week', label: 'last week' },
                            { value: 'last two weeks', label: 'last two weeks' },
                            { value: 'last month', label: 'last month' },
                            {
                              value: 'all',
                              label: 'all',
                            },
                            { value: 'by date', label: 'by date' },
                            { value: 'by interval', label: 'by interval' },
                          ]}
                        />
                      </FormControl>
                    )}
                    {showLoadedSince && loadSince === 'by interval' && (
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            className={classes.datepicker}
                            ampm={false}
                            inputFormat={PICKER_SHORT_DATETIME_FORMAT}
                            mask="____/__/__"
                            disableFuture={true}
                            value={chosenIntervalFrom}
                            onChange={(e) => setChosenIntervalFrom(e)}
                            maxDateMessage="Needs to be before end date"
                            renderInput={(params) => (
                              <TextField className={classes.datepicker} {...params} />
                            )}
                            disabled={!enableIntervalSelectors}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            className={classes.datepicker}
                            ampm={false}
                            inputFormat={PICKER_SHORT_DATETIME_FORMAT}
                            mask="____/__/__"
                            disableFuture={true}
                            value={chosenIntervalUntil}
                            onChange={(e) => setChosenIntervalUntil(e)}
                            minDateMessage="Needs to be after start date"
                            renderInput={(params) => (
                              <TextField className={classes.datepicker} {...params} />
                            )}
                            disabled={!enableIntervalSelectors}
                          />
                        </LocalizationProvider>
                        <Button
                          className={classes.intervalSubmitButton}
                          variant="outlined"
                          color="primary"
                          onClick={() => submitInterval()}
                          disabled={isValidDates() || !enableIntervalSelectors}
                        >
                          Go
                        </Button>
                      </Box>
                    )}
                    {showLoadedSince && loadSince === 'by date' && (
                      <Box className={classes.datepickers}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            className={classes.datepicker}
                            ampm={false}
                            disableFuture={true}
                            inputFormat={PICKER_SHORT_DATETIME_FORMAT}
                            mask="____/__/__"
                            value={chosenDate}
                            onChange={(e) => setChosenDate(e)}
                            renderInput={(params) => (
                              <TextField className={classes.datepicker} {...params} />
                            )}
                            disabled={!enableIntervalSelectors}
                          />
                        </LocalizationProvider>
                        <Button
                          className={classes.intervalSubmitButton}
                          variant="outlined"
                          color="primary"
                          onClick={() => submitChosenDate()}
                          disabled={isValidDates() || !enableIntervalSelectors}
                        >
                          Go
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.searchAndMenu}>
                {customBtn}
                {showDownloadBtn && (
                  <TooltipedIcon
                    tooltip="Download"
                    icon={
                      <IconButton
                        onClick={onClickDownload}
                        aria-label="page-header-download-button"
                        size="large"
                      >
                        <GetAppIcon color="secondary" />
                        {downloadSpinning && <Spinner />}
                      </IconButton>
                    }
                  />
                )}
                {showMenuIcon && (
                  <TooltipedIcon
                    tooltip="Menu"
                    icon={
                      <IconButton size="large">
                        <ActionsMenu isTable={false} options={headerActions} />
                      </IconButton>
                    }
                  />
                )}
              </Box>
            </Box>
          ) : (
            <SkeletonPageHeader />
          )}
          <Box style={{ position: 'relative' }} mb={2}>
            {alert && alert.state.show && (
              <Alert
                handleClose={() => alert.close(alert.label)}
                title={alert.state.title}
                message={alert.state.message}
                status={alert.state.status}
                isActive={true}
                preventClose={alert.state.preventClose}
              />
            )}
          </Box>

          {isSchedulingPage && (
            <Button
              sx={{ mt: 2 }}
              onClick={() => navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`)}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              color="primary"
            >
              Back to reports
            </Button>
          )}
        </Box>
      </Container>
    </Container>
  );
};

// PropTypes
PageHeaderSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showMenuIcon: PropTypes.bool,
  showLoadedSince: PropTypes.bool,
  showDownloadBtn: PropTypes.bool,
  download: PropTypes.func,
  onClickDownload: PropTypes.func,
  downloadSpinning: PropTypes.bool,
  initiallyGetDataInTimeInterval: PropTypes.string,
  getDataFrom: PropTypes.func,
  customBtn: PropTypes.element,
  isSchedulingPage: PropTypes.bool,
  isGroundControlPage: PropTypes.bool,
  isReportPage: PropTypes.bool,
  alert: PropTypes.object,
};

export default PageHeaderSection;
