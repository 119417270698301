import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// material-ui core
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// material-ui icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// components
import { validBarcodeInvalidLength } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';

// functions and variables

const BarcodeTypesAndLengths = (props) => {
  // props
  const {
    subtitle,
    inputType,
    label,
    inputProps,
    confirmChanges,
    currentList,
    min,
    max,
    errors,
    setErrors,
  } = props;

  // local state
  const [value, setValue] = useState('');
  const [types, setTypes] = useState([]);

  // Handle values
  const handleValue = (e) => {
    setErrors(validBarcodeInvalidLength(e, min, max));
    setValue(e);
  };

  // Add values
  const addTypes = () => {
    setValue('');
    confirmChanges([...types, inputType ? Number(value) : value]);
  };

  // Remove value
  const removeType = (type) => {
    const copyArr = currentList;
    confirmChanges(copyArr.filter((item) => item !== type));
  };

  useEffect(() => {
    if (currentList) {
      setTypes(currentList);
    }
  }, [currentList]);

  return (
    <>
      <Divider />

      <Box pt={1}>
        <Box p={2}>
          <Typography variant="h6" color="secondary">
            {subtitle}
          </Typography>
        </Box>

        <Box px={2} pt={1} pb={2}>
          {!isEmpty(types) ? (
            types.map((type, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box justifyContent="space-between" alignItems="center" display="flex" key={index}>
                <Typography>{type}</Typography>
                <IconButton
                  onClick={() => removeType(type)}
                  variant="contained"
                  color="error"
                  size="large"
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography align="center" variant="subtitle1" color="textSecondary" component="p">
              No barcode {label}
            </Typography>
          )}
        </Box>

        <Divider />

        <Box px={2} justifyContent="space-between">
          <Box pt={3} display="flex" justifyContent="space-between">
            <TextField
              label={`Enter barcode ${label}`}
              size="small"
              variant="outlined"
              fullWidth
              type={inputType}
              inputProps={inputProps}
              value={value}
              error={Boolean(value && errors)}
              helperText={value && errors}
              onChange={(e) => handleValue(e.target.value)}
              onBlur={(e) => handleValue(e.target.value)}
              onKeyUp={(e) => handleValue(e.target.value)}
            />

            <IconButton
              disabled={!value || Boolean(errors)}
              onClick={addTypes}
              variant="contained"
              color="primary"
              size="large"
            >
              <AddRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BarcodeTypesAndLengths;
