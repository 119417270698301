import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

// components
import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  badge: {
    padding: theme.spacing(0.6, 1.5),
  },
  bigBadge: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3),
  },
}));

/**
 * Badge component
 */
const Badge = (props: {
  /**
   * Badge background color
   */
  bgcolor: string;
  /**
   * Badge text color
   */
  color: string;
  /**
   * Big badge has margin and padding applied
   */
  bigBadge: boolean;
  /**
   * Content of the Badge
   */
  children: JSX.Element;
}) => {
  // props
  const { bgcolor, color, bigBadge, children } = props;

  // styles
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.badge, bigBadge && classes.bigBadge)}
      bgcolor={bgcolor}
      color={color}
      borderRadius="5px"
      display="inline"
      alignItems="center"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default Badge;
