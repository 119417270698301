import moment from 'moment';
import { HOURS_AND_MINUTES_FORMAT } from 'common/datetimeFormats';
import { OperatingHoursEventBoundaryST, OperatingHoursEventST } from '../API';
import { convertOperatingHoursToTimeRanges } from '../ScheduleLock/Functions/ConvertOperatingHoursToTimeRanges';
import { isHourAndMinutesWithinTimeRanges } from '../ScheduleLock/Functions/IsHourAndMinutesWithinTimeRanges';
import { OperatingHoursEvent, OperatingHoursEventBound } from './FlightHoursCard';

export type ValidationResult = {
  a: OperatingHoursEventST;
  b: OperatingHoursEventST;
};

const zeroBoundary = {
  start: { week_day: 1, timepoint: '00:00' },
  end: { week_day: 7, timepoint: '24:00' },
};

const BoundST2View = (bound: OperatingHoursEventBoundaryST): OperatingHoursEventBound => ({
  weekDay: bound.week_day,
  timePoint: moment(bound.timepoint, HOURS_AND_MINUTES_FORMAT),
});

const BoundView2ST = (bound: OperatingHoursEventBound): OperatingHoursEventBoundaryST => ({
  week_day: bound.weekDay === 0 ? 7 : bound.weekDay,
  timepoint: bound.timePoint ? bound.timePoint.format(HOURS_AND_MINUTES_FORMAT) : '',
});

export const EventST2View = (fh: OperatingHoursEventST): OperatingHoursEvent => ({
  start: BoundST2View(fh.start),
  end: BoundST2View(fh.end),
});

export const EventView2ST = (fh: OperatingHoursEvent): OperatingHoursEventST => ({
  start: BoundView2ST(fh.start),
  end: BoundView2ST(fh.end),
});

const isBeforeST = <T extends OperatingHoursEventBoundaryST>(a: T, b: T): boolean =>
  a.week_day < b.week_day || (a.week_day === b.week_day && a.timepoint < b.timepoint);

const isSameST = <T extends OperatingHoursEventBoundaryST>(a: T, b: T): boolean =>
  a.week_day === b.week_day && a.timepoint === b.timepoint;

export const makeKey = (e: OperatingHoursEvent) =>
  `${e.start.weekDay}${e.start.timePoint}${e.end.weekDay}${e.end.timePoint}`;

export const isBefore = <T extends OperatingHoursEventBound>(a: T, b: T): boolean =>
  isBeforeST(BoundView2ST(a), BoundView2ST(b));

/**
 * This function removes excess zeros (hh:mm:00.000000) from timepoint strings returned by BE
 * @param h OperatingHoursEventST to sanitize
 * @returns sanitized OperatingHoursEventST
 */
const sanitizeTimepointStrings = (h: OperatingHoursEventST): OperatingHoursEventST => {
  const startParts = h.start.timepoint.split(':');
  h.start.timepoint = `${startParts[0]}:${startParts[1]}`;
  const endParts = h.end.timepoint.split(':');
  h.end.timepoint = `${endParts[0]}:${endParts[1]}`;
  return h;
};

export const doOverlap = (hs: OperatingHoursEventST[]) => {
  const normalizedAndSanitized = hs.reduce((acc, cur) => {
    cur = sanitizeTimepointStrings(cur);
    if (isBeforeST(cur.end, cur.start) || isSameST(cur.end, cur.start)) {
      acc.push({ start: zeroBoundary.start, end: cur.end, orig: cur });
      acc.push({ start: cur.start, end: zeroBoundary.end, orig: cur });
    } else {
      acc.push(cur);
    }
    return acc;
  }, [] as (OperatingHoursEventST & { orig?: OperatingHoursEventST })[]);

  const sorted = normalizedAndSanitized.sort((a, b) => (isBeforeST(a.start, b.start) ? -1 : 1));

  const { overlap } = sorted.reduce(
    (acc, curr) => {
      if (acc.overlap) {
        return acc;
      }
      if (!acc.previous) {
        acc.previous = curr;
        return acc;
      }
      if (isBeforeST(curr.start, acc.previous.end)) {
        acc.overlap = { a: acc.previous.orig || acc.previous, b: curr.orig || curr };
        return acc;
      }
      acc.previous = curr;
      return acc;
    },
    {
      previous: null as (OperatingHoursEventST & { orig?: OperatingHoursEventST }) | null,
      overlap: null as ValidationResult | null,
    },
  );

  return overlap;
};

interface IFlightHoursAffected {
  flightHours: OperatingHoursEvent;
  scheduleLock?: string;
}

export const areFlightHoursAffectedByScheduleLock = ({
  flightHours,
  scheduleLock,
}: IFlightHoursAffected) => {
  if (!scheduleLock) return false;

  const scheduleLockMoment = moment(scheduleLock);

  const transformedFlightHours = [EventView2ST(flightHours)];
  const timeRanges = convertOperatingHoursToTimeRanges(
    transformedFlightHours,
    scheduleLockMoment.day(),
  );

  return isHourAndMinutesWithinTimeRanges(
    scheduleLockMoment.hour(),
    scheduleLockMoment.minutes(),
    timeRanges,
  );
};

/**
 * Check if new shift overlaps with existing shifts.
 * @param schedule OperatingHoursEventST[]
 * @returns old shift new one overlaps with.
 */
export const validateHours =
  (schedule: OperatingHoursEventST[]) =>
  (
    newHours: OperatingHoursEvent,
    edit?: OperatingHoursEvent,
  ): [isValid: boolean, message: string] => {
    if (newHours.start.timePoint === '' || newHours.end.timePoint === '') {
      return [false, 'Please specify start and end time.'];
    }

    if (!moment(newHours.start.timePoint).isValid() || !moment(newHours.end.timePoint).isValid()) {
      return [false, 'Please enter the right hours format.'];
    }

    const newHoursST = EventView2ST(newHours);
    const editST = edit ? EventView2ST(edit) : null;

    /**
     * If we are editing a shift, we need to remove it from the schedule
     * to avoid self-overlap.
     */
    const deDupedSchedule = editST
      ? schedule.filter(
          (h) =>
            h.start.week_day !== editST.start.week_day ||
            h.end.week_day !== editST.end.week_day ||
            !h.start.timepoint.startsWith(editST.start.timepoint) ||
            !h.end.timepoint.startsWith(editST.end.timepoint),
        )
      : schedule;

    const overlap = doOverlap([...deDupedSchedule, newHoursST]);
    const otherEvent = overlap && (overlap.a === newHoursST ? overlap.b : overlap.a);
    if (otherEvent) {
      return [false, getValidationMessage(otherEvent)];
    }

    return [true, ''];
  };

/**
 * Turns OperatingHoursEvent into human readable string.
 * @param hours OperatingHoursEvent
 * @returns Day HH:mm - Day HH:mm formatted string.
 */
export const getHoursString = (hours: OperatingHoursEvent) => {
  const { start, end } = hours;
  const startDay = moment.weekdays(start.weekDay);
  const startTime = moment(start.timePoint).format(HOURS_AND_MINUTES_FORMAT);
  const endDay = moment.weekdays(end.weekDay);
  const endTime = moment(end.timePoint).format(HOURS_AND_MINUTES_FORMAT);

  return `${startDay} ${startTime} - ${endDay} ${endTime}`;
};

/**
 * Make a helpful message for user about overlapping shifts.
 * @param event shift that is overlapping.
 * @returns human readable string.
 */
export const getValidationMessage = (event: OperatingHoursEventST) => {
  const eventString = getHoursString(EventST2View(event));

  return `Overlap with ${eventString}`;
};
