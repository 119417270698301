// ActionsMenu component
//
// Props:
//   - options [array of objects]: menu items, with labels and click handlers

import React, { useState } from 'react';

// material-ui core
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// material-ui icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

import isEmpty from 'lodash/isEmpty';

// other
import PropTypes from 'prop-types';

const ActionsMenu = (props: {
  size?: 'small' | 'medium' | 'large';
  isTable?: boolean;
  options: any;
}) => {
  // props
  const { size = 'large', isTable = true, options } = props;

  // state variables
  const [anchorEl, setAnchorEl] = useState(null);

  // component variables
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (externalEvent: () => void) => {
    setAnchorEl(null);
    externalEvent();
  };

  return (
    <>
      <IconButton
        aria-label="More options"
        data-testid="c-actions-button"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon
          color={!isTable ? 'secondary' : 'inherit'}
          fontSize={!isTable ? 'large' : 'medium'}
        />
      </IconButton>
      <Menu
        data-testid="c-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!isEmpty(options) ? (
          options.map((option: any, i: string) => (
            <MenuItem
              data-testid={`c-action-${option.label?.toLowerCase()}`}
              key={`option.label-${option.label}`}
              disabled={option.disabled}
              onClick={() => handleMenuItemClick(option.clickHandler)}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            data-testid="c-action-no-items"
            key="option.label-no-items"
            disabled={true}
            onClick={() => {}}
          >
            no items to display
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

// PropTypes
ActionsMenu.propTypes = {
  options: PropTypes.array,
};

export default ActionsMenu;
