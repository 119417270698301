// ###########################################################################
// This file contains the functions needed to process data received from
// the backend and displayed on pages:
// - Warehouse status
// - Reports
// - Report
// - Issue
// ###########################################################################

import isEmpty from 'lodash/isEmpty';

import ImageIcon from '@mui/icons-material/Image';

import { sort } from '../otherFunctions';

import { DISPLAY_ISSUE_TYPES } from '../../issueTypesAndStates';

import { IIssueST, ISlotStatusST, IVerityStatusST } from '../../../interfaces';

import {
  getSlotUsageStatus,
  getWMSDateFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getVerityValueFromSlotStatus,
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getVeritySlotStatusVersionFromSlotStatus,
  getWMSStateFromSlotStatus,
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getCustomersFromSlotStatus,
  getVerityStateFromSlotStatus,
  getImageIdsFromSlotStatus,
  getVerityUserOverwriteFromSlotStatus,
  getClientUserOverwriteFromSlotStatus,
  getWMSValueFromSlotStatus,
  getUserValueFromSlotStatus,
  getOriginalVerityState,
  getOriginalVerityValue,
  getSlotActions,
  getPaddedAndMatchArrays,
} from '../slot/slotFunctions';
import {
  getIssueTypeForFullReportTable,
  getWmsBarcodeLocationAccordingToVerityFromSingleIssue,
  getVerityBarcodeLocationAccordingToWMSFromSingleIssue,
  getLocationIssueType,
  getIssueTypeFromSingleIssueForReviewTable,
  getSnoozeActionLabel,
  isSnoozedActionDisabled,
} from '../issueFunctions';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';

/**
 * Handle row actions permissions
 * @param issue
 * @param permissions
 * @returns list of available actions
 */
export const getRowActions = (issue: IIssueST) => {
  if (userHasPermission(PERMISSION.SNOOZE_LOCATION)) {
    return [
      {
        label: getSnoozeActionLabel(issue),
        disabled: isEmpty(issue) || isSnoozedActionDisabled(issue),
      },
    ];
  }
  return [];
};

/**
 * Get row for full report table
 * (this type of row is also used on the amended table)
 * @param location ID of the location
 * @param slotStatus slot status
 * @param issuesForLocation issues related to the location
 * @param permissions WH Status permissions
 * @returns dictionary containing the description of a table row representing a location
 */
export const getRowForFullReportTable = (
  location: string,
  slotStatus: ISlotStatusST,
  issuesForLocation: IIssueST[],
  isReport?: boolean,
) => {
  // Get the most recent issue for this location, when one exists
  // TODO :: check if the issues associated with a location are already ordered, if so, remove the sort

  // Note: the getTableRowForLocation requires an IIssue instance (even though it might be empty)
  // therefore we need to defined locationIssue as an empty IIssue.
  const isUnreachableLocation = slotStatus.verity_status?.state === 'UNREACHABLE';
  const whsLocationWithIssue = !isReport && !isEmpty(issuesForLocation);
  const reachableReportLcationWithIssue =
    isReport && !isEmpty(issuesForLocation) && !isUnreachableLocation;

  let locationIssue: IIssueST = {} as IIssueST;
  if (whsLocationWithIssue || reachableReportLcationWithIssue) {
    const sortedIssuesData = sort({
      array: issuesForLocation,
      sortingOrder: 'desc',
      sortBy: 'updated_at',
    }) as IIssueST[];
    [locationIssue] = sortedIssuesData;
  }

  const isUnreachableLocationInWHS = isUnreachableLocation && !isReport;

  if (isUnreachableLocationInWHS && !slotStatus.verity_status.user_overrides.length) {
    if (!isEmpty(locationIssue)) {
      const slotStatusVersionsUnreachable = slotStatus.verity_status.version;
      slotStatus = locationIssue.slot_status;
      slotStatus.verity_status.version = slotStatusVersionsUnreachable;
    } else {
      slotStatus.verity_status = null as unknown as IVerityStatusST;
    }
  }

  // Get data needed to populate a table row
  const rowData = {
    ...getTableRowForLocation(location, slotStatus, locationIssue),
  };

  return rowData;
};

/**
 * Get row and issue data to populate issue table rows
 * this type of row is also used on tabs: Snoozed, Inconclusive, Invalid and Potential
 * @param location ID of the location
 * @param locationIssueData Issue
 * @param permissions WH permissions
 */
export const getRowAndSingleIssueForIssueTable = (
  location: string,
  locationIssueData: IIssueST[],
) => {
  // The back-end sorts the issue data appropriately.
  const singleIssueData = locationIssueData[0];

  // Get the slot data related to the latest issue
  const slotStatus = singleIssueData.slot_status;

  // Get data needed to populate a table row
  const rowData = { ...getTableRowForLocation(location, slotStatus, singleIssueData) };

  return { rowData, singleIssueData };
};

/**
 * Get the data needed to render a location row
 */
const getTableRowForLocation = (location: string, slotStatus: ISlotStatusST, issue: IIssueST) => {
  const rowData: { [field: string]: any } = {};
  const slotContent = getPaddedAndMatchArrays(slotStatus);

  rowData.location = location;
  rowData.slotUsage = getSlotUsageStatus(slotStatus);

  rowData.id = !isEmpty(issue) // TO REMOVE:: once we remove the issue page completely
    ? `${issue.issue_id}?version=${issue.version}`
    : location;
  rowData.issueId = !isEmpty(issue) ? `${issue.issue_id}` : null;

  rowData.issue = !isEmpty(issue) ? getIssueTypeForFullReportTable(issue) : 'None';
  rowData.issueType = !isEmpty(issue) ? DISPLAY_ISSUE_TYPES[issue.type] : 'None';
  rowData.issueFirstFoundOn = !isEmpty(issue) ? issue.first_found_on : '-';
  rowData.issueStatus = issue.state;

  rowData.contentExpected = slotContent.paddedContentExpected;
  rowData.wmsValue = slotContent.paddedContentExpected;
  rowData.colorizeContentExpected = slotContent.colorizeContentExpected;

  rowData.wmsDate = getWMSDateFromSlotStatus(slotStatus);

  rowData.wmsCustomer = slotContent.paddedCustomer;
  rowData.wmsArticleNo = slotContent.paddedArticleNumber;
  rowData.wmsQty = slotContent.paddedQuantity;
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(slotStatus);

  rowData.barcodeExpectedFoundOn =
    !isEmpty(issue) && issue.state !== 'SOLVED'
      ? getWmsBarcodeLocationAccordingToVerityFromSingleIssue(issue)
      : '-';
  rowData.barcodeFoundShouldBeOn = !isEmpty(issue)
    ? getVerityBarcodeLocationAccordingToWMSFromSingleIssue(issue)
    : '-';

  rowData.contentFound = slotContent.paddedContentFound;
  rowData.contentFoundDate = slotContent.contentFoundDate;
  rowData.contentFoundChangedByTheClient = slotContent.contentFoundChangedByTheClient;
  rowData.colorizeContentFound = slotContent.colorizeContentFound;

  rowData.matchArray = slotContent.matchArray;

  rowData.verityValue = getVerityValueFromSlotStatus(slotStatus);
  rowData.verityDate = getVerityDateFromSlotStatus(slotStatus);

  rowData.userValue = slotContent.contentFoundChangedByTheClient
    ? slotContent.paddedContentFound
    : getUserValueFromSlotStatus(slotStatus);
  rowData.userName = getUserNameFromSlotStatus(slotStatus);

  rowData.locationIssueType = getLocationIssueType(issue, rowData.verityDate, rowData.wmsDate);

  rowData.issueStatus = !isEmpty(issue) ? issue.state : 'NONE';
  rowData.image = <ImageIcon />;

  // TR::2020-01-06:: FIX ME, allow all entries to redirect to a location page
  rowData.allowRedirection = !isEmpty(issue);

  rowData.actions = getTableRowActionsForSlotStatus(slotStatus, issue, rowData);

  return rowData;
};

/**
 * Get the data needed to execute actions on a location / issue
 * used on actions and on the location modal
 * @param slotStatus
 * @param locationIssue
 * @param rowData
 * @param permissions
 * @returns
 */
const getTableRowActionsForSlotStatus = (
  slotStatus: ISlotStatusST,
  locationIssue: IIssueST,
  rowData: any,
) => {
  const rowActions = getRowActions(locationIssue);
  return {
    data: {
      id: locationIssue && locationIssue.issue_id,
      version: locationIssue && locationIssue.version,
      state: locationIssue && locationIssue.state,
      issueType: locationIssue && locationIssue.type,

      location: slotStatus.slot_label,

      slotStatusVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus),
      latestSlotVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus), // TR:: I left this here for retro-compatibility reasons

      wmsValue: getWMSValueFromSlotStatus(slotStatus).contentExpectedValues.join(', '), // sent as a string to allow amend
      wmsState: getWMSStateFromSlotStatus(slotStatus),
      wmsDate: getWMSDateFromSlotStatus(slotStatus),
      wmsArticleNo: getWMSArticleNosFromSlotStatus(slotStatus),
      wmsQty: getWMSQtyFromSlotStatus(slotStatus),
      wmsCustomer: getCustomersFromSlotStatus(slotStatus),
      wmsSlotStatusVersion: getWMSSlotStatusVersionFromSlotStatus(slotStatus),

      originalVerityValue: getOriginalVerityValue(slotStatus).join(', '), // sent as a string to allow amend
      originalVerityState: getOriginalVerityState(slotStatus),
      verityValue: getVerityValueFromSlotStatus(slotStatus).join(', '), // sent as a string to allow amend
      verityState: getVerityStateFromSlotStatus(slotStatus),
      verityDate: getVerityDateFromSlotStatus(slotStatus),

      userOverride: getClientUserOverwriteFromSlotStatus(slotStatus),
      userOverrideValue: getUserValueFromSlotStatus(slotStatus),
      userOverrideUserName: getUserNameFromSlotStatus(slotStatus),

      imageIds: getImageIdsFromSlotStatus(slotStatus),

      snoozedByUserId: locationIssue && locationIssue.snoozed_by_user_id,
      snoozedByUserName: locationIssue && locationIssue.snoozed_by_user_name,
      snoozedOn: locationIssue && locationIssue.snoozed_on,
      snoozedUntil: locationIssue && locationIssue.snoozed_until,

      rowData,
    },
    actions: rowActions,
  };
};

// Get row for locations to review table
export const getRowForLocationsToReviewTable = (
  location: string,
  slotStatus: ISlotStatusST,
  issuesForLocation: IIssueST[],
) => {
  const rowData: any = {};
  let locationIssue: IIssueST | null = null;

  if (!isEmpty(issuesForLocation)) {
    const sortedIssuesData = sort({
      array: issuesForLocation,
      sortingOrder: 'desc',
      sortBy: 'updated_at',
    }) as IIssueST[];
    [locationIssue] = sortedIssuesData;
  }

  const actions = getSlotActions(location, slotStatus);

  rowData.id =
    locationIssue && isEmpty(locationIssue)
      ? `${locationIssue.issue_id}?version=${locationIssue.version}`
      : location;

  rowData.location = slotStatus.slot_label;
  rowData.wmsValue = getWMSValueFromSlotStatus(slotStatus);
  rowData.wmsDate = getWMSDateFromSlotStatus(slotStatus);

  // During the review stage, on locations to review
  // mark the verity value with a "*", in case it already has been reviewed by a verity user
  rowData.verityValue = getVerityUserOverwriteFromSlotStatus(slotStatus)
    ? `${getVerityValueFromSlotStatus(slotStatus)} *`
    : getVerityValueFromSlotStatus(slotStatus);

  rowData.verityDate = getVerityDateFromSlotStatus(slotStatus);
  rowData.version = getVeritySlotStatusVersionFromSlotStatus(slotStatus);
  rowData.userValue = getUserValueFromSlotStatus(slotStatus);
  rowData.userName = getUserNameFromSlotStatus(slotStatus);
  rowData.wmsArticleNo = getWMSArticleNosFromSlotStatus(slotStatus);

  rowData.wmsQty = getWMSQtyFromSlotStatus(slotStatus);
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(slotStatus);
  rowData.issue = locationIssue ? getIssueTypeFromSingleIssueForReviewTable(locationIssue) : null;
  rowData.actions = actions;

  // TR::2020-01-08:: FIX-ME: Allow redirection to open the location amendment modal
  rowData.allowRedirection = false;

  return rowData;
};
