import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';

import GroundControlServices from '../../../../../services/GroundControlServices';
import { useRequestController } from '../../../../../hooks';

export const DeleteNoFlyZoneModal = ({
  noFlyZone,
  onClose,
  onConfirm,
}: {
  noFlyZone: NoFlyZone;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { requestController } = useRequestController('DeleteNoFlyZoneModal');
  const { systemId = '', flightDomainId = '' } = useParams();

  const message = (
    <>
      <Typography component="span">
        Are you sure you would like to delete the following no-fly zone?
      </Typography>
      <br />
      <Typography component="span" display="flex" justifyContent="center" mt={2} fontWeight="bold">
        {noFlyZone.name}
      </Typography>
    </>
  );

  const requestNoFlyZoneDeletion = () => {
    const { signal } = requestController.reserveSlotForRequest();
    requestController.doRequest({
      request: GroundControlServices.deleteNoFlyZone,
      requestParams: [{ systemId, flightDomainId, nfzId: noFlyZone.id, signal }],
      messageErrorFallback: 'Something went wrong while trying to delete no fly zone!',
      callbackFinally: () => {
        onConfirm();
      },
    });
  };

  return (
    <ModalConfirm
      opened
      title="Delete No-fly zone"
      message={message}
      handleClose={onClose}
      onConfirm={requestNoFlyZoneDeletion}
      confirmLabel="Delete"
      cancelLabel="Cancel"
    />
  );
};
