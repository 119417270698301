import React from 'react';
import { CANNOT_UNLOCK_REASON, LOCK_REASON } from 'common/groundControlHelpers';
import { IFleetVersionResponseST } from 'codegen/flight_domain';
import { GroundControlLevelAction } from './groundControlLevelActions';
import { FleetOverviewST } from '../../pages/GroundControl/pages/FleetOverview/API';
import { IRequestController } from '../../hooks';
import { FlightDomainScheduleST } from '../../pages/GroundControl/pages/Schedule/API';

export interface IGroundControlState {
  flightDomainLoaded: boolean;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
  dronesFlying: boolean;
  flightDomain: IFlightDomainData;
  flightDomainSchedule: FlightDomainScheduleST;
  fleetOverview: FleetOverviewST;
  fleetVersions: IFleetVersionResponseST;
  webSocketError: boolean; // <= TBD
  alertInfo: IAlertInfo;
  activeAlertVariant: string;
}

/**
 * Server type for a FlightDomainStatus.
 */
export type FlightDomainStatusST = {
  locked: boolean;
  last_locked_unlocked_time: string;
  lock_reason: LOCK_REASON | null;
  can_unlock: boolean;
  cannot_unlock_reason: CANNOT_UNLOCK_REASON | null;
  triggered_by: string;
  current_drone_operating_hours: {
    start: string;
    end: string;
  };
  next_drone_operating_hours: {
    start: string;
    end: string;
  };
  next_scheduled_lock: string;
  updated_at: string;
};

/**
 * Server type for a FleetStatusSummary.
 */
export type FleetStatusSummaryST = {
  num_drones_flying: number;
  num_drones_on_charger: number;
  num_drones_landed_in_error: number;
  updated_at: string;
};

/**
 * Server type for a FlightDomain.
 */
export interface IFlightDomainData {
  flight_domain_id: string;
  flight_domain_name: string;
  flight_domain_status?: FlightDomainStatusST | null;
  fleet_status_summary?: FleetStatusSummaryST | null;
  isLoadingFleetData: boolean;
}

export interface IAlertInfo {
  main: IAlertInfoData;
  fleetCard: IAlertInfoData;
  statusCard: IAlertInfoData;
  flightHoursCard: IAlertInfoData;
}

export interface IAlertInfoData {
  isOpened: boolean;
  variant?: string;
  message?: string;
}

/**
 * Fleet icon tooltips values
 */
export enum TooltipTypes {
  FLYING = 'FLYING',
  ON_CHARGERS = 'ON CHARGERS',
  ERRORS = 'ERRORS',
}

export interface IGroundControlLevelContext {
  stateGroundControl: IGroundControlState;
  dispatchGroundControlLevel: React.Dispatch<GroundControlLevelAction>;
  getFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  refreshFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  isDataReady: () => boolean;
  asyncGetFleetOverview: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<FleetOverviewST>;
  asyncGetFleetVersion: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<IFleetVersionResponseST>;
  populateFlightDomainSchedule: (flightDomainId: string) => Promise<any>;
  saveFlightDomainSchedule: (schedule: FlightDomainScheduleST) => Promise<any>;
  asyncGetFlightDomainInfo: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
}
