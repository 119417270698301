import isEmpty from 'lodash/isEmpty';

// material-ui core
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

// functions

// components
import { bytesToSize } from 'common/functions/fileFunctions';
import { Box } from 'components/common/Box';
import UploadInfo, { IUploadInfoStateParam } from 'components/common/UploadInfo';
import Spinner from 'components/common/Spinner';
import CustomDropZone from 'components/common/CustomDropZone';
import BaseCardSimpleItem from '../BaseCard/BaseCardSimpleItem';
import BaseCard, { IBaseCardActionButton } from '../BaseCard/BaseCard';

const useStyles = makeStyles()((theme: any) => ({
  messageWrapper: {
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
}));

/**
 * Interface for the data prop of the Upload Card component
 */
export interface IUploadCardData {
  /**
   * title of the Card
   */
  title: string;
  /**
   * Subtitle of the Card
   */
  subtitle: string;
  /**
   * Sections of the Card
   */
  sections: {
    /**
     * Items in a given section
     */
    sectionItems: {
      /**
       * Label of the item
       */
      label: string;
      /**
       * Value of the item
       */
      value: any;
    }[];
  }[];
  /**
   * Action Button
   */
  actionButtons: IBaseCardActionButton[];
}

/**
 * Props for the UploadCard Component
 */
interface IUploadCardProps {
  cardFor: string;
  uploadInfoLabel: any;
  data: IUploadCardData;
  showAlert: any;
  alertState: IUploadInfoStateParam;
  progress: number;
  onDrop: (param: any) => void;
  fileDetails: {
    file: File;
  };
  isLoading: boolean;
  testId: string;
}

export const UploadCard = (props: IUploadCardProps) => {
  // props
  const {
    cardFor,
    uploadInfoLabel,
    data,
    showAlert,
    alertState,
    progress,
    onDrop,
    fileDetails,
    isLoading,
    testId,
  } = props;

  // styles
  const { classes } = useStyles();

  return (
    <BaseCard
      uploadCard
      cardFor={cardFor}
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={false}
      title={data.title}
      subtitle={data.subtitle}
      actionButtons={data.actionButtons}
    >
      <UploadInfo
        testId={`c-${testId}-alert`}
        state={alertState}
        label={uploadInfoLabel}
        showAlert={showAlert.close}
        progress={progress}
      />
      {!isEmpty(data.sections) ? (
        data.sections.map((section) => (
          <BaseCardSimpleItem
            key={section.sectionItems
              .map((si) => si.label)
              .concat('-')
              .toString()}
            sectionItems={section.sectionItems}
          />
        ))
      ) : (
        <Box className={classes.messageWrapper}>
          <Typography align="center" color="textSecondary" variant="body1">
            No Files uploaded
          </Typography>
        </Box>
      )}
      <Box data-testid={`c-${testId}-droparea`} px={2}>
        <CustomDropZone
          id={`c-${testId}-dropzone`}
          maxFiles={1}
          text={`Drop ${cardFor} file here...`}
          onDrop={onDrop}
        />

        <Typography variant="caption">
          {fileDetails?.file?.name
            ? `File: ${fileDetails.file.name} - ${bytesToSize(fileDetails.file?.size)}`
            : 'No file selected'}
        </Typography>
      </Box>
      {isLoading ? <Spinner /> : null}
    </BaseCard>
  );
};
