import { TimeRange } from './TimeRange';

/**
 * The calculation includes start hour and end hour
 * @param hour number
 * @param timeRanges array of TimeRange
 * @returns boolean
 */
export const isHourWithinTimeRanges = (hour: number, timeRanges: TimeRange[]): boolean =>
  timeRanges.some(
    (timeRange: TimeRange) => hour >= timeRange.fromHours && hour <= timeRange.toHours,
  );
