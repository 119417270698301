import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

// material-ui
import { SelectChangeEvent, TextField } from '@mui/material';
import Link from '@mui/material/Link';

// variables, functions, configurations
import { RELATIVE_DATETIME_FORMAT } from 'common/datetimeFormats';
import CustomSearchableSelect from 'components/common/CustomFormComponents/CustomSearchableSelect';

// components
import { Box } from 'components/common/Box';
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem from 'components/BaseCard/BaseCardSimpleItem';
import {
  FacilityDetailsFields,
  DirtyFacilityDetailsFields,
  FacilityMapDataFields,
} from './FacilityDetails';

/**
 * Props for FacilityDetailsCard
 */
interface IFacilityDetailsCardProps {
  /**
   * Flag to indicate if the facility is active
   */
  facilityActive: boolean;
  /**
   * Facility details fields and map data
   */
  facilityData: FacilityDetailsFields & FacilityMapDataFields;
  /**
   * Plain facility details fields
   */
  values: FacilityDetailsFields;
  /**
   * Facility details fields marked dirty
   */
  dirtyFields: DirtyFacilityDetailsFields;
  /**
   * List of countries
   */
  countries: { value: string; label: string; timezones: string }[];
  /**
   * List of timezones
   */
  timezones: string[];
  /**
   * Flag indicating if upload is in progress
   */
  isLoading: boolean;
  /**
   * Flag indicating if upload is disabled
   */
  isUploadDisabled: boolean;
  /**
   * Sets all facility details
   */
  setValues: (f: (values: FacilityDetailsFields) => FacilityDetailsFields) => void;
  /**
   * Sets a single facility detail field
   */
  handleChange: (
    s: keyof FacilityDetailsFields,
  ) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => void;
}

/**
 * Gets timezone helper text
 * @param dirtyFieldsTimeZone is timezone field dirty
 * @param valuesTimeZone timezone value string
 * @param country county value string
 * @returns timezone helper text
 */
const timezoneSelectHelperText = (
  dirtyFieldsTimeZone: boolean,
  valuesTimeZone: string,
  country: string,
) => {
  if (dirtyFieldsTimeZone && isEmpty(valuesTimeZone)) {
    return 'You need to set a timezone';
  }
  if (!country) {
    return '* You need to select a country, before you can select a timezone';
  }
  return '';
};

/**
 * FacilityDetailsCard
 * @param props: IFacilityDetailsCardProps
 * @returns Facility details card component
 */
const FacilityDetailsCard = ({
  facilityActive,
  facilityData,
  values,
  dirtyFields,
  countries,
  timezones,
  isLoading,
  isUploadDisabled,
  setValues,
  handleChange,
}: IFacilityDetailsCardProps) => {
  const facilityInfoCardData = {
    title: 'Facility details',
    subtitle: facilityActive
      ? 'Read-only details for facility'
      : 'Facility details can be set only once',
    sections: facilityActive
      ? [
          {
            sectionTitle: 'Facility Info',
            sectionItems: [
              {
                label: 'Client',
                value: facilityData?.client || 'not available',
                testId: 'c-facility-client',
              },
              {
                label: 'Facility',
                value: facilityData?.name || 'not available',
                testId: 'c-facility-name',
              },
              {
                label: 'Store ID',
                value: facilityData?.store_id || 'not available',
                testId: 'c-facility-store-id',
              },
              {
                label: 'Country',
                value: facilityData?.country || 'not available',
                testId: 'c-facility-country',
              },
              {
                label: 'Time zone',
                value: facilityData?.timezone || 'not available',
                testId: 'c-facility-timezone',
              },
              {
                label: 'Updated',
                value: facilityData?.created_at
                  ? facilityData?.timezone &&
                    moment(facilityData?.created_at)
                      .tz(facilityData?.timezone)
                      .calendar(null, RELATIVE_DATETIME_FORMAT)
                  : 'not available',
                testId: 'c-facility-created',
              },
              {
                label: 'Logo',
                value: facilityData?.logo_url ? (
                  <Link href={facilityData.logo_url} target="_blank">
                    image
                  </Link>
                ) : (
                  'not available'
                ),
                testId: 'c-facility-logo',
              },
            ],
          },
        ]
      : null,
  };

  return (
    <BaseCard
      cardFor="facility details"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      title={facilityInfoCardData.title}
      subtitle={facilityInfoCardData.subtitle}
      isLoading={isLoading}
      spinnerLabel="Loading facility info data..."
    >
      {facilityInfoCardData &&
        facilityInfoCardData.sections &&
        facilityInfoCardData.sections.map((section) => {
          const key = `${section.sectionTitle}-${section.sectionItems
            .map((s) => s.label)
            .concat('-')}`;
          const items = section.sectionItems.filter((si) => si.value !== 'not available');
          return <BaseCardSimpleItem key={key} sectionItems={items} />;
        })}
      <Box>
        {!facilityActive && (
          <Box px={2}>
            <TextField
              data-testid="c-client-name-input"
              error={dirtyFields.client && isEmpty(values.client)}
              helperText={
                dirtyFields.client && isEmpty(values.client) && 'You need to set a client name'
              }
              variant="outlined"
              margin="normal"
              value={values.client}
              fullWidth
              id="client"
              label="Client name"
              name="client"
              onChange={handleChange('client')}
              disabled={isUploadDisabled}
            />
            <TextField
              data-testid="c-facility-name-input"
              error={dirtyFields.name && isEmpty(values.name)}
              helperText={
                dirtyFields.name && isEmpty(values.name) && 'You need to set a facility name'
              }
              variant="outlined"
              value={values.name}
              margin="normal"
              fullWidth
              id="facility_name"
              label="Facility name"
              name="facility_name"
              onChange={handleChange('name')}
              disabled={isUploadDisabled}
            />
            <CustomSearchableSelect
              testId="c-country-select"
              value={values.country}
              label="Country"
              options={['', ...countries.map((c: any) => c.value)]}
              onChange={(event: any, newValue: any) => {
                setValues((prevState) => ({ ...prevState, country: newValue }));
              }}
              error={dirtyFields.country && isEmpty(values.country)}
              helperText={
                dirtyFields.country && isEmpty(values.country) ? 'You need to set a country' : ''
              }
              disabled={isUploadDisabled}
            />
            <CustomSearchableSelect
              testId="c-timezone-select"
              value={values.timezone}
              label="Timezone"
              options={['', ...timezones.map((c: any) => c.value)]}
              disabled={!values.country || isUploadDisabled}
              onChange={(event, newValue) => {
                setValues((prevState) => ({ ...prevState, timezone: newValue }));
              }}
              error={dirtyFields.timezone && isEmpty(values.timezone)}
              helperText={timezoneSelectHelperText(
                dirtyFields.timezone,
                values.timezone,
                values.country,
              )}
            />
            <TextField
              data-testid="c-latitude-input"
              error={dirtyFields.latitude && isEmpty(values.latitude)}
              helperText={
                dirtyFields.latitude &&
                isEmpty(values.latitude) &&
                'You need to set the latitude of the facility'
              }
              type="number"
              variant="outlined"
              value={values.latitude}
              margin="normal"
              fullWidth
              id="latitude"
              label="Latitude"
              name="latitude"
              onChange={handleChange('latitude')}
              disabled={isUploadDisabled}
            />
            <TextField
              data-testid="c-longitude-input"
              error={dirtyFields.longitude && isEmpty(values.longitude)}
              helperText={
                dirtyFields.longitude &&
                isEmpty(values.longitude) &&
                'You need to set the longitude of the facility'
              }
              type="number"
              variant="outlined"
              value={values.longitude}
              margin="normal"
              fullWidth
              id="longitude"
              label="Longitude"
              name="longitude"
              onChange={handleChange('longitude')}
              disabled={isUploadDisabled}
            />
          </Box>
        )}
      </Box>
    </BaseCard>
  );
};

export default FacilityDetailsCard;
