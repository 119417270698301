import { createSvgIcon } from '@mui/material/utils';
import { Box } from '../../Box';

interface IIconProps {
  theme: any;
  active: any;
}

const FacilitySettingsIcon = ({ theme, active }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.228 15.3333C13.228 14.2857 14.0851 13.4286 15.1328 13.4286C16.1804 13.4286 17.0375 14.2857 17.0375 15.3333C17.0375 16.3809 16.1804 17.2381 15.1328 17.2381C14.0851 17.2381 13.228 16.3809 13.228 15.3333ZM20.0851 15.9809C20.1137 15.7714 20.1328 15.5524 20.1328 15.3333C20.1328 15.1143 20.1137 14.8952 20.0851 14.6762L21.4947 13.5714C21.6185 13.4667 21.6566 13.2857 21.5709 13.1428L20.2375 10.8381C20.1518 10.6952 19.9804 10.6381 19.828 10.6952L18.1709 11.3619C17.828 11.0952 17.4566 10.8762 17.047 10.7048L16.7994 8.94285C16.7709 8.79047 16.628 8.66666 16.4661 8.66666H13.7994C13.6375 8.66666 13.4947 8.79047 13.4661 8.95237L13.2185 10.7143C12.8185 10.8857 12.4375 11.1048 12.0947 11.3714L10.4375 10.7048C10.2851 10.6476 10.1137 10.7048 10.028 10.8476L8.69466 13.1524C8.60895 13.2952 8.64705 13.4762 8.77086 13.5809L10.1804 14.6857C10.1518 14.8952 10.1328 15.1143 10.1328 15.3333C10.1328 15.5524 10.1518 15.7714 10.1804 15.9905L8.77086 17.0952C8.64705 17.2 8.60895 17.3809 8.69466 17.5238L10.028 19.8286C10.1137 19.9714 10.2851 20.0286 10.4375 19.9714L12.0947 19.3048C12.4375 19.5714 12.8089 19.7905 13.2185 19.9619L13.4661 21.7238C13.4947 21.8762 13.6375 22 13.7994 22H16.4661C16.628 22 16.7709 21.8762 16.7994 21.7143L17.047 19.9524C17.447 19.7809 17.828 19.5619 18.1709 19.2952L19.828 19.9619C19.9804 20.019 20.1518 19.9619 20.2375 19.819L21.5709 17.5143C21.6566 17.3714 21.6185 17.1905 21.4947 17.0857L20.0851 15.9809Z"
        fill={active ? theme.palette.primary.light : theme.palette.grey[600]}
      />
      <path
        d="M9.57624 2L1.0293 5.64928V18.1905H5.24154V10.0952H8.26424L8.38838 9.88065L8.3962 9.86763C8.96207 8.9245 10.1079 8.54631 11.1078 8.92128L11.129 8.92921L11.525 9.08852L11.586 8.65423L11.5918 8.62136C11.7739 7.5894 12.6746 6.7619 13.8009 6.7619H16.4676C17.1498 6.7619 17.7607 7.06911 18.1722 7.54973V5.64928L9.57624 2Z"
        fill={active ? theme.palette.primary.light : theme.palette.grey[600]}
      />
    </svg>,
    'SettingIcon',
  );
  return (
    <Box>
      <Icon />
    </Box>
  );
};

export default FacilitySettingsIcon;
