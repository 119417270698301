import { Theme } from '@mui/material';
import { Moment } from 'moment';
import type { TZDate } from '@toast-ui/calendar';
import { formatDatesToHoursMinutesRange } from '../../pages/GroundControl/pages/Schedule/FlightHoursCalendar/Functions/FormatDatesToHoursMinutesRange';
import { calendarDroneIcon } from '../../pages/GroundControl/pages/Schedule/FlightHoursCalendar/Icons';

interface ITimeEvent {
  start: Moment;
  end: Moment;
}

export const calendarTemplate = {
  timegridDisplayPrimaryTime: ({ time }: { time: TZDate }) => {
    const { fromHours, fromMinutes } = formatDatesToHoursMinutesRange(time, time);
    return `${fromHours}:${fromMinutes}`;
  },
  timegridNowIndicatorLabel: () => '',
  time: (event: ITimeEvent) => {
    const { start, end } = event;
    const { fromHours, fromMinutes, toHours, toMinutes } = formatDatesToHoursMinutesRange(
      start,
      end,
    );

    const flyingHours = `<span style="color:white;display:flex;flex-direction:column;align-items:center;margin-left:-8px">${calendarDroneIcon} ${fromHours}:${fromMinutes} - ${toHours}:${toMinutes} </span>`;

    return flyingHours;
  },
};

export const createCalendars = (theme: Theme) => [
  {
    id: 'fhc',
    name: 'Drones flight hours',
    backgroundColor: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
];

export const calendarTheme = {
  common: {
    holiday: {
      color: 'unset',
    },
  },
};
