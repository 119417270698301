/* tslint:disable */
/* eslint-disable */
/**
 * QR generator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IImageResponseST
 */
export interface IImageResponseST {
  /**
   * The URL to fetch the QR code image
   * @type {string}
   * @memberof IImageResponseST
   */
  image_url: string;
}

/**
 * QRGeneratorApi - axios parameter creator
 * @export
 */
export const QRGeneratorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} ssid The SSID of the network
     * @param {'AUTO' | 'DHCP' | 'STATIC'} assignment How addresses are assigned in the network. Choices are \&quot;AUTO\&quot;, \&quot;DHCP\&quot;, and \&quot;STATIC\&quot;. For \&quot;static\&quot;, an ip, gateway and netmask have to be defined.
     * @param {string} [passphrase] The passphrase of the network
     * @param {string} [ip] The IP to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [netmask] The netmask to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [gateway] The gateway to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrCode: async (
      systemIdStr: string,
      flightDomainId: string,
      ssid: string,
      assignment: 'AUTO' | 'DHCP' | 'STATIC',
      passphrase?: string,
      ip?: string,
      netmask?: string,
      gateway?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getQrCode', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getQrCode', 'flightDomainId', flightDomainId);
      // verify required parameter 'ssid' is not null or undefined
      assertParamExists('getQrCode', 'ssid', ssid);
      // verify required parameter 'assignment' is not null or undefined
      assertParamExists('getQrCode', 'assignment', assignment);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/qr-generator`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (ssid !== undefined && ssid !== null) {
        localVarHeaderParameter['ssid'] = String(ssid);
      }

      if (passphrase !== undefined && passphrase !== null) {
        localVarHeaderParameter['passphrase'] = String(passphrase);
      }

      if (assignment !== undefined && assignment !== null) {
        localVarHeaderParameter['assignment'] = String(assignment);
      }

      if (ip !== undefined && ip !== null) {
        localVarHeaderParameter['ip'] = String(ip);
      }

      if (netmask !== undefined && netmask !== null) {
        localVarHeaderParameter['netmask'] = String(netmask);
      }

      if (gateway !== undefined && gateway !== null) {
        localVarHeaderParameter['gateway'] = String(gateway);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QRGeneratorApi - functional programming interface
 * @export
 */
export const QRGeneratorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QRGeneratorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} ssid The SSID of the network
     * @param {'AUTO' | 'DHCP' | 'STATIC'} assignment How addresses are assigned in the network. Choices are \&quot;AUTO\&quot;, \&quot;DHCP\&quot;, and \&quot;STATIC\&quot;. For \&quot;static\&quot;, an ip, gateway and netmask have to be defined.
     * @param {string} [passphrase] The passphrase of the network
     * @param {string} [ip] The IP to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [netmask] The netmask to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [gateway] The gateway to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQrCode(
      systemIdStr: string,
      flightDomainId: string,
      ssid: string,
      assignment: 'AUTO' | 'DHCP' | 'STATIC',
      passphrase?: string,
      ip?: string,
      netmask?: string,
      gateway?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IImageResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQrCode(
        systemIdStr,
        flightDomainId,
        ssid,
        assignment,
        passphrase,
        ip,
        netmask,
        gateway,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * QRGeneratorApi - factory interface
 * @export
 */
export const QRGeneratorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QRGeneratorApiFp(configuration);
  return {
    /**
     *
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} ssid The SSID of the network
     * @param {'AUTO' | 'DHCP' | 'STATIC'} assignment How addresses are assigned in the network. Choices are \&quot;AUTO\&quot;, \&quot;DHCP\&quot;, and \&quot;STATIC\&quot;. For \&quot;static\&quot;, an ip, gateway and netmask have to be defined.
     * @param {string} [passphrase] The passphrase of the network
     * @param {string} [ip] The IP to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [netmask] The netmask to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {string} [gateway] The gateway to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrCode(
      systemIdStr: string,
      flightDomainId: string,
      ssid: string,
      assignment: 'AUTO' | 'DHCP' | 'STATIC',
      passphrase?: string,
      ip?: string,
      netmask?: string,
      gateway?: string,
      options?: any,
    ): AxiosPromise<IImageResponseST> {
      return localVarFp
        .getQrCode(
          systemIdStr,
          flightDomainId,
          ssid,
          assignment,
          passphrase,
          ip,
          netmask,
          gateway,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QRGeneratorApi - object-oriented interface
 * @export
 * @class QRGeneratorApi
 * @extends {BaseAPI}
 */
export class QRGeneratorApi extends BaseAPI {
  /**
   *
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} ssid The SSID of the network
   * @param {'AUTO' | 'DHCP' | 'STATIC'} assignment How addresses are assigned in the network. Choices are \&quot;AUTO\&quot;, \&quot;DHCP\&quot;, and \&quot;STATIC\&quot;. For \&quot;static\&quot;, an ip, gateway and netmask have to be defined.
   * @param {string} [passphrase] The passphrase of the network
   * @param {string} [ip] The IP to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
   * @param {string} [netmask] The netmask to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
   * @param {string} [gateway] The gateway to use if \&quot;assignment\&quot; is static. Format: x.x.x.x
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRGeneratorApi
   */
  public getQrCode(
    systemIdStr: string,
    flightDomainId: string,
    ssid: string,
    assignment: 'AUTO' | 'DHCP' | 'STATIC',
    passphrase?: string,
    ip?: string,
    netmask?: string,
    gateway?: string,
    options?: AxiosRequestConfig,
  ) {
    return QRGeneratorApiFp(this.configuration)
      .getQrCode(
        systemIdStr,
        flightDomainId,
        ssid,
        assignment,
        passphrase,
        ip,
        netmask,
        gateway,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
