import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    background: 'white',
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 8px -2px rgba(0,0,0,0.1), 0 2px 12px -4px rgba(0,0,0,0.2)',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content minmax(0, 1fr) min-content',
    zIndex: 2,
    overflow: 'hidden',
  },
  wrapperHeader: {
    boxShadow: '0 2px 8px -2px rgba(0,0,0,0.1)',
    padding: theme.spacing(0.5, 1.5),
  },
  wrapperHeaderControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'end',
    gap: theme.spacing(1),
    fontSize: 12,
    lineHeight: 1,
    color: alpha('#000000', 0.4),
  },
  wrapperHeaderControlsInfo: {
    flex: '1 1 auto',
    minWidth: 0,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  wrapperHeaderControlsActions: {
    flex: '0 0 auto',
  },
  closeButton: {
    '> svg': {
      fontSize: 'inherit',
    },
  },
  wrapperHeaderSelection: {
    padding: theme.spacing(1, 0.5),
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content',
    rowGap: theme.spacing(2),
  },
  wrapperHeaderOptions: {
    maxHeight: 150,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content',
    rowGap: theme.spacing(0.5),
  },
  wrapperBody: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  wrapperBodyScrollableArea: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content minmax(0, 1fr)',
    padding: theme.spacing(2),
    rowGap: theme.spacing(4),
    minHeight: '100%',
  },
  detailsPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));
