import { Grid } from '@mui/material';
import { Metric } from './Metric';

type BatteryMetricsProps = {
  voltage?: number | null;
  current?: number | null;
  tempCelsius?: number | null;
};

export const BatteryMetrics = ({ voltage, current, tempCelsius }: BatteryMetricsProps) => {
  const v = voltage ? <Metric value={voltage} unit="V" unitLong="Volts" /> : null;
  const c = current ? <Metric value={current} unit="A" unitLong="Amperes" /> : null;
  const t = tempCelsius ? (
    <Metric
      value={tempCelsius}
      unit="°C"
      tooltip={`${tempCelsius} °C (${1.8 * tempCelsius + 32} °F)`}
    />
  ) : null;

  return (
    <Grid alignItems="stretch" container spacing={2}>
      <Grid item md={6}>
        Voltage:
      </Grid>
      <Grid item md={6}>
        {v}
      </Grid>
      <Grid item md={6}>
        Current:
      </Grid>
      <Grid item md={6}>
        {c}
      </Grid>
      <Grid item md={6}>
        Temperature:
      </Grid>
      <Grid item md={6}>
        {t}
      </Grid>
    </Grid>
  );
};
