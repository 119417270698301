import { ReactElement } from 'react';
import { Link } from '@mui/material';

import { KeyboardArrowRight } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

import { IGenerateQRCode } from '../../../../../services/DroneServices';

import ReadyModeImg from '../../../../../assets/wifiConnectionInstructions/ready_mode.gif';
import DroneOnModeImg from '../../../../../assets/wifiConnectionInstructions/drone_on_mode.gif';
import ScanModeImg from '../../../../../assets/wifiConnectionInstructions/scan_mode.gif';
import ScanQrImg from '../../../../../assets/wifiConnectionInstructions/scan_qr_code.gif';
import DroneRebootImg from '../../../../../assets/wifiConnectionInstructions/wait_for_reboot.gif';
import CheckIssuesImg from '../../../../../assets/wifiConnectionInstructions/check_for_issues.png';
import CheckConnection from '../../../../../assets/wifiConnectionInstructions/check-for-connection.gif';

/**
 * Instructions data interface
 */
export interface IInstructionsData {
  id: string;
  title: string;
  indicator?: true;
  text: string;
  instructions?: {
    primaryText: string;
    secondaryText: string;
    image: ReactElement<any, any>;
  };
  helperText?: {
    [key: number]: string;
  };
  buttons?: IActionButton[];
  support?: React.ReactElement;
}

export interface IActionButton {
  id: number;
  label: string;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  helperText?: boolean;
  endIcon?: React.ReactElement;
  startIcon?: React.ReactElement;
}

/**
 * Validation errors type
 */
export type ValidationErrors = {
  ssid?: string;
  passphrase?: string;
  assignment?: string;
  ip?: string;
  netmask?: string;
  gateway?: string;
};

/**
 * Assignment type
 */
type Assignment = {
  value: 'DHCP' | 'STATIC';
  label: string;
};

/**
 * Assignment dropdown values
 */
export const ASSIGNMENTS: Assignment[] = [
  { value: 'DHCP', label: 'DHCP' },
  { value: 'STATIC', label: 'STATIC' },
];

/**
 * Advanced inputs for generate QR code component
 */
export const advancedInputs = ['ip', 'netmask', 'gateway'];

/**
 * IP address regex
 */
export const regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

/**
 * Validate function
 * @param param0 IValidate params
 * @returns errors for input fields
 */
export const validate = ({ ssid, ip, netmask, gateway, assignment }: Partial<IGenerateQRCode>) => {
  const errors: ValidationErrors = {};

  if (!ssid) errors.ssid = 'Wifi name is required';
  // NOTE: the passphrase check disabled as part of https://verity-ag.atlassian.net/browse/UD-4439
  // TODO / FIXME: remove this comment is the feature is made final
  // eno 13.06.2023
  // if (!passphrase) errors.passphrase = 'Password is required';
  if (assignment === ASSIGNMENTS[1].value) {
    if (!ip) errors.ip = 'IP address is required';

    if (ip && !ip?.match(regex)) {
      errors.ip = "IP address doesn't match format";
    }

    if (!netmask) errors.netmask = 'Netmask is required';

    if (netmask && !netmask?.match(regex)) {
      errors.netmask = "Netmask doesn't match format";
    }

    if (!gateway) errors.gateway = 'Gateway is required';

    if (gateway && !gateway?.match(regex)) {
      errors.gateway = "Gateway doesn't match format";
    }
  }

  return errors;
};

const Support = () => (
  <Link underline="none" href="mailto:support@verity.net">
    Verity Support
  </Link>
);

/**
 * Wifi connection instructions data
 */
export const connectionInstructions: IInstructionsData[] = [
  {
    id: 'cli-1',
    indicator: true,
    title: 'Generate QR code',
    text: 'Use the QR code generator to translate WiFi credentials into the format readable by drones.',
  },
  {
    id: 'cli-2',
    title: 'Confirm drone is ON',
    text: 'Check if the drone is turned on.',
    instructions: {
      image: <img alt="ci_dimg1" src={DroneOnModeImg} width="50%" />,
      primaryText: 'ACTIVATE READY MODE',
      secondaryText:
        'If the drone is on, the LED light should be solid white. If this is not the case, initiate a reboot. For directions on how to initiate a reboot, go to help documentation. If there is still an issue, please reach out to Verity Support.',
    },
    helperText: {
      0: 'Try again. Make sure that the drone is turned on and the battery is charged.',
    },
    buttons: [
      { id: 1, label: 'No, nothing happened', variant: 'outlined', helperText: true },
      { id: 2, label: 'Yes, next', endIcon: <KeyboardArrowRight /> },
    ],
  },
  {
    id: 'cli-3',
    title: 'Activate READY mode',
    text: 'Put the drone into the READY mode in order to give it further commands.',
    instructions: {
      image: <img alt="ci_dimg1" src={ReadyModeImg} width="50%" />,
      primaryText: 'ACTIVATE READY MODE',
      secondaryText:
        'Using a pen or paperclip, press and hold the button on the back of the drone until the light turns solid blue. Did the LED light turn solid blue?',
    },
    helperText: {
      0: 'Try again. Make sure that the drone is turned on and the battery is charged.',
    },
    buttons: [
      { id: 1, label: 'No, nothing happened', variant: 'outlined', helperText: true },
      { id: 2, label: 'Yes, next', endIcon: <KeyboardArrowRight /> },
    ],
  },
  {
    id: 'cli-4',
    title: 'Activate SCAN mode',
    text: 'By activating SCAN mode, enable the drone to perform scanning.',
    instructions: {
      image: <img alt="ci_dimg2" src={ScanModeImg} width="50%" />,
      primaryText: 'ACTIVATE SCAN MODE',
      secondaryText: 'Press the button quickly two times. Did the LED light turn flashing green?',
    },
    helperText: {
      0: 'Try again to press the button quickly 2 times using your pen or paperclip.',
      1: 'Drone could not read your input. Try again to press the button quickly 2 times using your pen or paperclip.',
    },
    buttons: [
      { id: 1, label: 'No, it is still solid blue', variant: 'outlined', helperText: true },
      { id: 2, label: 'No, it is flashing red', variant: 'outlined', helperText: true },
      { id: 3, label: 'Yes, next', endIcon: <KeyboardArrowRight /> },
    ],
  },
  {
    id: 'cli-5',
    title: 'Scan QR code',
    text: 'Scan the QR code using the drone camera.',
    instructions: {
      image: <img alt="ci_dimg3" src={ScanQrImg} width="50%" />,
      primaryText: 'Scan QR code',
      secondaryText:
        'Hold the drone up about 30-50 cm from the screen with the camera pointed at the QR code until the LED light turns solid green. Did the LED light turn solid green?',
    },
    helperText: {
      0: 'Keep trying to scan the QR Code.',
      1: 'The session has expired. Start again by activating READY mode.',
    },
    buttons: [
      { id: 1, label: 'It is still flashing green', variant: 'outlined', helperText: true },
      { id: 2, label: 'No, it is solid red', variant: 'outlined', helperText: true },
      { id: 3, label: 'Yes, next', endIcon: <KeyboardArrowRight /> },
    ],
  },
  {
    id: 'cli-6',
    title: 'Wait for reboot',
    text: 'After the successful scan, the drone needs to auto-reboot.',
    instructions: {
      image: <img alt="ci_dimg4" src={DroneRebootImg} width="50%" />,
      primaryText: 'Wait for reboot',
      secondaryText: 'The drone will initiate a reboot process. This will take a few minutes.',
    },
    buttons: [{ id: 0, label: 'Next', endIcon: <KeyboardArrowRight /> }],
    support: <Support />,
  },
  {
    id: 'cli-7',
    title: 'Check for connection',
    text: 'When all done - see the drone connection status in the Fleet table.',
    instructions: {
      image: <img alt="ci_dimg5" src={CheckConnection} width="50%" />,
      primaryText: 'Check for issues',
      secondaryText:
        'When the LED light is solid white, check the fleet table to confirm drone has connected to WiFi. If the LED light is solid yellow or red, go to the next slide.',
    },

    buttons: [{ id: 2, label: 'next', endIcon: <KeyboardArrowRight /> }],
  },
  {
    id: 'cli-8',
    title: 'Check for issues',
    text: 'When all done - see the drone connection status in the Fleet table.',
    instructions: {
      image: <img alt="ci_dimg5" src={CheckIssuesImg} width="50%" />,
      primaryText: 'Check for issues',
      secondaryText:
        'If the LED light is solid yellow, there is a problem connecting to the WiFi network or the internet. Generate a new QR code with correct credentials and check that the drone is in the vicinity of the network. Put the drone in READY mode to start again. If the LED light is solid red, please reach out to Verity Support.',
    },

    buttons: [{ id: 2, label: 'finish', endIcon: <CheckIcon /> }],
  },
];
