import React from 'react';

// material-ui core
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// components
import { Box } from 'components/common/Box';
import NoConnection from 'components/common/NoConnection';

// styles
import { tableStyles } from '../styles';

type EnhancedTableHeaderProps = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  children: any;
  noData?: boolean;
  headerButton: {
    label: string;
    action: () => void;
  };
};

const EnhancedTableHeader = (props: EnhancedTableHeaderProps) => {
  // props
  const { icon, title, subtitle, children, headerButton, noData } = props;
  const { classes } = tableStyles();

  return (
    <Box className={classes.root} display="flex">
      {icon ? <Box className={classes.icon}>{icon}</Box> : null}

      <Box mb={2}>
        <Typography className={classes.title} color="secondary" variant="h4">
          {title || null}
        </Typography>

        <Typography className={classes.subheader} color="textSecondary">
          {noData ? <NoConnection text="No data available." /> : subtitle || null}
        </Typography>
      </Box>

      <Box p={1} display="flex" alignItems="center" className={classes.search}>
        {children}
        {headerButton && (
          <Box ml={2}>
            <Button
              data-testid="AddUserButton"
              variant="contained"
              color="primary"
              onClick={headerButton.action}
            >
              {headerButton.label}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EnhancedTableHeader;
