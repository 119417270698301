import { isEmpty } from 'lodash';
import moment from 'moment';
import { IIssueST } from '../../interfaces';
import {
  ISSUE_STATES,
  DISPLAY_ISSUE_TYPES,
  LOCATION_ISSUE_TYPES,
  ISSUE_TYPES,
} from '../issueTypesAndStates';
import { getClientUserOverwriteFromSlotStatus } from './slot/slotFunctions';
import { ISSUE_ACTIONS } from '../Actions/actionTypes';
import { INCONCLUSIVE_TAB_ALLOWED_DATA, INVALID_TAB_ALLOWED_DATA } from '../tabDataDefinitions';

/**
 * Returns the IssueType from location issue data, for the Review table
 * @param issue
 * @returns IssueType or "-"
 */
export const getIssueTypeFromSingleIssueForReviewTable = (issue: IIssueST) =>
  !isEmpty(issue) && issue.state !== ISSUE_STATES.SOLVED ? DISPLAY_ISSUE_TYPES[issue.type] : 'None';

/**
 * Returns the issue type from issue data, for the Full report table
 * @param issue
 * @returns Issue type
 */
export const getIssueTypeForFullReportTable = (issue: IIssueST) => {
  if (
    !isEmpty(issue) &&
    (issue.state === ISSUE_STATES.NEW ||
      issue.state === ISSUE_STATES.KNOWN ||
      issue.state === ISSUE_STATES.SNOOZED)
  ) {
    return DISPLAY_ISSUE_TYPES[issue.type];
  }
  return 'None';
};

/**
 * Returns the location issue type from issue data
 * @param issue
 * @param verityDate
 * @param wmsDate
 * @returns issue type
 */
export const getLocationIssueType = (issue: IIssueST, verityDate: string, wmsDate: string) => {
  const vD = verityDate && verityDate !== '-' ? verityDate : null;
  const wD = wmsDate && wmsDate !== '-' ? wmsDate : null;
  const compareDates = vD && wD;

  if (
    !isEmpty(issue) &&
    (issue.state === ISSUE_STATES.NEW ||
      issue.state === ISSUE_STATES.KNOWN ||
      issue.state === ISSUE_STATES.SNOOZED)
  ) {
    return LOCATION_ISSUE_TYPES.HAS_ISSUE;
  }
  if (
    (compareDates && moment(vD).isBefore(wD)) ||
    (!isEmpty(issue) && issue.state === ISSUE_STATES.NA)
  ) {
    return LOCATION_ISSUE_TYPES.SYSTEM_CANT_INFER_IF_HAS_ISSUE;
  }
  return LOCATION_ISSUE_TYPES.HAS_NO_ISSUE;
};

/**
 * Get the verity barcode location according to wms data (from issue data)
 * @param issue
 * @returns location name or "-"
 */
export const getVerityBarcodeLocationAccordingToWMSFromSingleIssue = (issue: IIssueST) =>
  // if there is an user overwrite, this location shall not be displayed
  // because it refers to the verity result or verity user overwrite only
  !getClientUserOverwriteFromSlotStatus(issue.slot_status) &&
  issue.wms_slot_status_with_verity_bc &&
  issue.wms_slot_status_with_verity_bc.slot_label
    ? issue.wms_slot_status_with_verity_bc.slot_label
    : '-';

/**
 * Get the wms barcode location according to verity data (from issue data)
 * @param issue
 * @returns location name or "-"
 */
export const getWmsBarcodeLocationAccordingToVerityFromSingleIssue = (issue: IIssueST) => {
  let processedValue = '-';
  // We only want to return the location if it is different from the issue location
  // otherwise it brings no added value to the user
  if (
    issue.verity_slot_status_with_wms_bc &&
    issue.verity_slot_status_with_wms_bc.slot_label !== issue.location
  ) {
    processedValue = issue.verity_slot_status_with_wms_bc.slot_label;
  }
  return processedValue;
};

/**
 * Get the snooze / unsnooze label
 */
export const getSnoozeActionLabel = (issue: IIssueST) =>
  !isEmpty(issue) && issue.state === ISSUE_STATES.SNOOZED
    ? ISSUE_ACTIONS.UNSNOOZE
    : ISSUE_ACTIONS.SNOOZE;

/**
 * Check if snoozing / unsnoozing shall be enabled
 */
export const isSnoozedActionDisabled = (issue: IIssueST) => {
  const snoozeDisabled =
    issue.state === ISSUE_STATES.SOLVED ||
    issue.state === ISSUE_STATES.NA ||
    INCONCLUSIVE_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(issue.type as unknown as ISSUE_TYPES) ||
    INVALID_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(issue.type as unknown as ISSUE_TYPES);

  return snoozeDisabled;
};
