export enum LOCK_REASON {
  MANUAL = 'MANUAL',
  EMERGENCY_LAND = 'EMERGENCY_LAND',
  DRONE_OPERATING_HOURS_END = 'DRONE_OPERATING_HOURS_END',
  FLEET_DECIMATED = 'FLEET_DECIMATED',
  SYSTEM_INITIALIZATION = 'SYSTEM_INITIALIZATION',
  SCHEDULED_LOCK = 'SCHEDULED_LOCK',
}

export enum CANNOT_UNLOCK_REASON {
  ALREADY_UNLOCKED = 'ALREADY_UNLOCKED',
  OUTSIDE_DRONE_OPERATING_HOURS = 'OUTSIDE_DRONE_OPERATING_HOURS',
}

export const renderLockReason = {
  [LOCK_REASON.MANUAL]: 'Manual Lock',
  [LOCK_REASON.EMERGENCY_LAND]: 'Safety Landing',
  [LOCK_REASON.DRONE_OPERATING_HOURS_END]: 'Drone Flight Hours Ended',
  [LOCK_REASON.FLEET_DECIMATED]: 'Fleet Decimated',
  [LOCK_REASON.SYSTEM_INITIALIZATION]: 'System Initialization',
  [LOCK_REASON.SCHEDULED_LOCK]: 'Schedule lock',
};
