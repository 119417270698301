import { IReportST } from 'codegen/report';
import { formatReportProgress } from '../../udb/features/reports/reportsFunctions';
import { REPORT_STATES } from '../reportStates';

export const isReportFinishedIncomplete = (report: IReportST) => {
  const { completion } = calculateCompletion(report);
  return (
    [REPORT_STATES.FINISHED, REPORT_STATES.DELETED].includes(report.state as REPORT_STATES) &&
    completion < 100
  );
};

export const isReportFinished = (status: string) =>
  status === REPORT_STATES.FINISHED || status === REPORT_STATES.DELETED;

/**
 * FIXME https://verity-ag.atlassian.net/browse/UD-5292
 * the completion as calculated by the BE is no longer correct
 * calculation on the FE is done until the BE is updated (or the
 * calculation is removed from the BE)
 * eno 2023-11-10
 *
 * Calculate the completion of a report
 * @param report Report to be analyzed
 * @returns completed and total location, and completion rate
 */
export const calculateCompletion = (report: IReportST) => {
  const completed = report.location_data_count.SCANNED - (report.num_unreachable_locations ?? 0);
  const total = report.num_locations - (report.location_data_count.EXCLUDED || 0);
  const completion = +((completed / total) * 100).toFixed(1);
  return { completed, total, completion };
};

/**
 * Render the report completion message
 * @param report report whose progress needs to be reported
 * @returns a string with the progress status
 */
export const renderReportProgressCompletion = (report: IReportST) => {
  const { completed, total, completion } = calculateCompletion(report);
  if (isReportFinishedIncomplete(report)) {
    return `${completed} of ${total} - ${completion}%`;
  }
  // FIXME https://verity-ag.atlassian.net/browse/UD-5292
  // to be updated when the calculation of progress will be fixed
  // in the BE
  // eno 2023-11-10
  return `${formatReportProgress(completed / total)}%`;
};

/**
 * Create a report alert for the incomplete locations
 * @param report report the alert is generated for
 * @returns JSX snippet with the alert
 */
export const renderIncompleteReportAlert = (report: IReportST) => {
  const incompleteLocations =
    (report.num_unreachable_locations || 0) + report.location_data_count.ABORTED;

  const hasSystemBeenUnlocked = report.location_data_count.SCANNED > incompleteLocations;

  return (
    <>
      This report does not include data for {incompleteLocations}{' '}
      {incompleteLocations > 1 ? 'locations' : 'location'}. Common reasons include: <br />
      <ul style={{ padding: 0 }}>
        {hasSystemBeenUnlocked && <li>The system was not unlocked and the drones did not fly</li>}
        {report.location_data_count.ABORTED && (
          <li>
            The drones did not have enough time to scan all locations before the system was locked
            or the report deadline was reached
          </li>
        )}

        <li>There was an issue that prevented the report to complete</li>
      </ul>
    </>
  );
};
