// types and interfaces
import { IStartSimulationPostRequestST } from 'codegen/nav_simulation';
import { INoFlyZonePostRequestST, INoFlyZoneUpdatePropertiesRequestST } from 'codegen/no_fly_zone';
import { FlightDomainScheduleST } from '../pages/GroundControl/pages/Schedule/API';

// services
import {
  getEmergencyProcedureApiService,
  getFlightDomainApiService,
  getFlightDomainSettingsApiService,
  getFleetSimulationApiService,
  getNoFlyZonesApiService,
} from './services';

/**
 * Start flight simulator
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param {IStartSimulationPostRequestST} data
 */
const requestFlightSimulation = (
  systemId: string,
  flightDomainId: string,
  data: IStartSimulationPostRequestST,
) => getFleetSimulationApiService().postFleetSimulator(systemId, flightDomainId, data);

/**
 * Get the current flight domain info
 * @param systemId ID of the current facility
 * @param flightDomainIdFromURL ID of the flight domain from URL param
 * @param signal Abort Signal
 */
const getFlightDomainInfo = (
  systemId: string,
  flightDomainIdFromURL: string,
  signal: AbortSignal,
) => getFlightDomainApiService().getFlightDomainInfo(systemId, flightDomainIdFromURL, { signal });

/**
 * Get flight domain fleet overview
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param signal Abort Signal
 */
const getFleetOverview = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getFleetOverview(systemId, flightDomainId, { signal });

/**
 * Get Estimated Obstacles
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param signal Abort Signal
 */
const getEstimatedObstacles = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getEstimatedObstacles(systemId, flightDomainId, { signal });

/**
 * Get Space Reservations
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param signal Abort Signal
 */
const getSpaceReservations = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getSpaceReservations(systemId, flightDomainId, { signal });

/**
 * Get no fly zones
 * @param systemId ID of the current facility
 * @param flightDomainId  ID of the flight domain
 * @param signal Abort Signal
 * @returns no fly zones
 */
const getNoFlyZones = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getNoFlyZonesApiService().getNoFlyZones(systemId, flightDomainId, undefined, { signal });

/**
 * Create no fly zone interface
 */
interface ICreateNoFlyZone {
  systemId: string;
  flightDomainId: string;
  nfz: INoFlyZonePostRequestST;
  signal: AbortSignal;
}
/**
 * Create no fly zone
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param nfz no fly zone
 * @param signal Abort Signal
 * @returns no fly zone
 */
const createNoFlyZone = ({ systemId, flightDomainId, nfz, signal }: ICreateNoFlyZone) =>
  getNoFlyZonesApiService().createNoFlyZone(systemId, flightDomainId, nfz, { signal });

/**
 * Update no fly zone properties interface
 */
interface IUpdateNoFlyZoneProperties {
  systemId: string;
  flightDomainId: string;
  nfzId: string;
  nfz: INoFlyZoneUpdatePropertiesRequestST;
  signal: AbortSignal;
}
/**
 * Update no fly zone properties
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param nfzId ID of no fly zone
 * @param nfz no fly zone
 * @param signal Abort Signal
 */
const updateNoFlyZoneProperties = ({
  systemId,
  flightDomainId,
  nfzId,
  nfz,
  signal,
}: IUpdateNoFlyZoneProperties) =>
  getNoFlyZonesApiService().updateNoFlyZoneProperties(systemId, flightDomainId, nfzId, nfz, {
    signal,
  });

/**
 * Delete no fly zone interface
 */
interface IDeleteNoFlyZone {
  systemId: string;
  flightDomainId: string;
  nfzId: string;
  signal: AbortSignal;
}
/**
 * Delete no fly zone
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param nfzId ID of no fly zone
 * @param signal Abort Signal
 */
const deleteNoFlyZone = ({ systemId, flightDomainId, nfzId, signal }: IDeleteNoFlyZone) =>
  getNoFlyZonesApiService().deleteNoFlyZone(systemId, flightDomainId, nfzId, { signal });

/**
 * Set active no-fly zone
 * @param systemId ID of the current facility
 * @param flightDomainId ID of the flight domain
 * @param nfzId ID of the no flight zone
 * @param active status
 * @returns no fly zone
 */
const setNoFlyZoneActive = (
  systemId: string,
  flightDomainId: string,
  nfzId: string,
  drone_allowed_in_zone: boolean,
) =>
  getNoFlyZonesApiService().setNoFlyZoneActive(systemId, flightDomainId, nfzId, {
    drone_allowed_in_zone,
  });

/**
 * Get the current fleet status summary of a specific flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of the flight domain
 * @param signal Abort Signal
 */
const getFleetStatusSummary = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getFleetStatusSummary(systemId, flightDomainId, { signal });

/**
 * Get the version and serial numbers of drones in specified flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of the flight domain
 * @param signal Abort Signal
 */
const getFleetVersion = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getFleetVersion(systemId, flightDomainId, { signal });

/**
 * Get settings of a specific flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of flight domain to get settings for
 * @param signal Abort Signal
 */
const getFlightDomainSettings = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getFlightDomainSettingsApiService().getFlightDomainSettings(systemId, flightDomainId, { signal });

/**
 * Set settings of a specific flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of flight domain to set settings for
 * @param data new settings
 * @param signal Abort Signal
 */
const setFlightDomainSettings = (
  systemId: string,
  flightDomainId: string,
  data: FlightDomainScheduleST,
  signal: AbortSignal,
) =>
  getFlightDomainSettingsApiService().setFlightDomainSettings(systemId, flightDomainId, data, {
    signal,
  });

/**
 * Lock Flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of the flight domain
 * @param signal Abort Signal
 */
const lockFlightDomain = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getEmergencyProcedureApiService().lockFlightDomain(systemId, flightDomainId, { signal });

/**
 * Unlock Flight domain
 * @param systemId id of the current facility
 * @param flightDomainId id of the flight domain
 * @param signal Abort Signal
 */
const unlockFlightDomain = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getEmergencyProcedureApiService().unlockFlightDomain(systemId, flightDomainId, { signal });

/**
 * Emergency landing
 * @param systemId id of the current facility
 * @param flightDomainId id of the flight domain
 * @param signal Abort Signal
 */
const emergencyLanding = (systemId: string, flightDomainId: string, signal: AbortSignal) =>
  getEmergencyProcedureApiService().emergencyLanding(systemId, flightDomainId, { signal });

const GroundControlServices = {
  updateNoFlyZoneProperties,
  deleteNoFlyZone,
  createNoFlyZone,
  requestFlightSimulation,
  getFlightDomainInfo,
  getFleetStatusSummary,
  getFleetOverview,
  getFleetVersion,
  getEstimatedObstacles,
  getSpaceReservations,
  getNoFlyZones,
  setNoFlyZoneActive,
  getFlightDomainSettings,
  setFlightDomainSettings,
  lockFlightDomain,
  unlockFlightDomain,
  emergencyLanding,
};

export default GroundControlServices;
