import { Metric } from './Metric';

type ChargingStatsProps = {
  currentLimit?: number | null;
  timeToChargeInSeconds?: number | null;
};

export const ChargingStats = ({ currentLimit, timeToChargeInSeconds }: ChargingStatsProps) => {
  const max = currentLimit ? (
    <Metric value={`Max ${currentLimit}`} unit="A" unitLong="Ampere" />
  ) : null;
  const ttc = timeToChargeInSeconds ? (
    <Metric value={timeToChargeInSeconds} unit="s until charged" />
  ) : null;

  return (
    <>
      {max}
      {ttc}
    </>
  );
};
