import { OperatingHoursEventST } from '../../API';
import { convertOperatingHoursToTimeRanges } from '../../ScheduleLock/Functions/ConvertOperatingHoursToTimeRanges';
import { TimeRange } from '../../ScheduleLock/Functions/TimeRange';
import { circularRangeForWeekDays } from './CircularRangeForWeekDays';

const createDefaultTimeRange = (): TimeRange => ({
  fromHours: 0,
  fromMinutes: 0,
  toHours: 23,
  toMinutes: 59,
});

const createDefaultOperatingHours = (weekDay: number) => ({
  start: {
    week_day: weekDay,
    timepoint: '00:00:00.000000',
  },
  end: {
    week_day: weekDay,
    timepoint: '23:59:00.000000',
  },
});

interface IWeekDayOperatingHours extends TimeRange {
  weekDay: number;
}
/**
 * Converts schedule to operating hours based on day of week
 * @param schedule OperatingHoursEventST[]
 * @returns operating hours
 */
export const createOperatingHoursByWeekDayFromSchedule = (
  schedule: OperatingHoursEventST[],
): IWeekDayOperatingHours[] => {
  const operatingHours: IWeekDayOperatingHours[] = [];

  schedule.forEach((i: OperatingHoursEventST) => {
    if (i.start.week_day === i.end.week_day) {
      const [fromHour, fromMinutes] = i.start.timepoint.split(':');
      const [toHour, toMinutes] = i.end.timepoint.split(':');
      const range = {
        weekDay: i.start.week_day,
        fromHours: Number(fromHour),
        fromMinutes: Number(fromMinutes),
        toHours: Number(toHour),
        toMinutes: Number(toMinutes),
      };

      operatingHours.push(range);
    } else {
      const days = circularRangeForWeekDays(i.start.week_day, i.end.week_day);
      const operatingHoursByDay = days.map((day, index) => {
        const operatingHours = createDefaultOperatingHours(day);
        if (day === i.start.week_day && index === 0) {
          operatingHours.start.timepoint = i.start.timepoint;
        } else if (day === i.end.week_day && index === days.length - 1) {
          operatingHours.end.timepoint = i.end.timepoint;
        }

        return operatingHours;
      });

      operatingHoursByDay.forEach((oh) => {
        const weekDay = oh.start.week_day;
        const [range] = convertOperatingHoursToTimeRanges([oh], weekDay, createDefaultTimeRange());
        const weekDayOperatingHours = {
          weekDay,
          fromHours: range.fromHours,
          fromMinutes: range.fromMinutes,
          toHours: range.toHours,
          toMinutes: range.toMinutes,
        };

        operatingHours.push(weekDayOperatingHours);
      });
    }
  });

  return operatingHours;
};
