import { useNavigate, useParams } from 'react-router-dom';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import BaseCard, { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import BaseCardReportItem from 'components/BaseCard/BaseCardReportItem';
import { ReportInCard } from 'udb/features/reports/reducer/report-store/reportInCard.model';

interface IToReviewReportsCardProps {
  activeTab: number;
  reports: ReportInCard[];
  reportsNoInfo: { numberOfReports: number; lastUpdatedAt: string };
  isLoading: boolean;
}

export const ToReviewReportsCard = (props: IToReviewReportsCardProps) => {
  const { activeTab, reports, reportsNoInfo, isLoading } = props;

  const navigate = useNavigate();
  const { systemId = '' } = useParams();

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      label: 'See all reports to review',
      clickHandler: () => {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`, {
          state: {
            activeTab,
          },
        });
      },
    },
  ];

  const toReviewReportsData = {
    title: 'Reports for review',
    subtitle: reportsNoInfo.lastUpdatedAt ? `Last update: ${reportsNoInfo.lastUpdatedAt}` : '',
    counter: reportsNoInfo.numberOfReports.toString(),
    actionButtons,
    sections: reports,
  };

  return (
    <BaseCard
      cardFor="reports to review"
      dataTestId="c-reports-to-review-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      isLoading={isLoading}
      title={toReviewReportsData.title}
      subtitle={toReviewReportsData.subtitle}
      counter={toReviewReportsData.counter}
      actionButtons={toReviewReportsData.actionButtons}
      sx={{ order: 5 }}
    >
      {toReviewReportsData.sections &&
        toReviewReportsData.sections.map((section) => (
          <BaseCardReportItem
            key={section.reportId}
            reportId={section.reportId}
            reportType={section.reportType}
            date={section.date}
            recurrence={section.recurrence}
            recurrenceDescription={section.recurrenceDescription}
            reportName={section.reportName}
            progress={section.progress}
            reportStatus={section.reportStatus}
            totalLocations={section.totalLocations}
            showBottomDivider={true}
            actionButtons={section.actionButtons}
          />
        ))}
    </BaseCard>
  );
};
