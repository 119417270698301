/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { Floor } from 'shared/map-container/features/mesh-floor/MeshFloor';
import { MapControls } from 'shared/map-container/features/map-controls/MapControls';
import { OrthoCamera } from 'shared/map-container/features/map-orthographic-camera/OrthoCamera';
import {
  CameraState,
  initialCameraState,
  MutableState,
} from 'shared/map-container/reducer/MutableState';
import { isCursorInFacility } from 'shared/map-container/utils/3DmapFunctions';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { OpenFlyZone, OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { ORTHO_CAMERA_Z } from 'shared/map-container/defaults/orthographicCameraZ.default';
import { MapCanvas } from 'shared/map-container/features/map-canvas/MapCanvas';
import { Zoomer } from 'shared/map-container/utils/Zoomer';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { DroneZoneType } from '../model/droneZones.model';
import { makeNoFlyZone } from './makeNoFlyZone';
import { DroneZonesMapElements } from './DroneZonesMapElements';
import { useDroneZoneContext } from '../reducer/DroneZonePageContext';
import { ClientDroneZones } from '../model/clientDroneZones.model';
import { FlowState } from '../reducer/zoneStatusInitialState';

export const wrapperId = 'DroneZonesMapWrapper';

export const DroneZonesMap = ({
  noFlyZones,
  controlledZones,
  openFlyZones,
  isDrawingNewNoFlyZone,
  visibleZones,

  onDroneZoneClick,
}: {
  noFlyZones: NoFlyZones;
  controlledZones: ControlledZones;
  openFlyZones: OpenFlyZones;
  visibleZones: Record<DroneZoneType, boolean>;
  isDrawingNewNoFlyZone: boolean;
  onDroneZoneClick: (zone: NoFlyZone | ControlledZone | OpenFlyZone | null) => void;
}) => {
  const {
    droneZonePageState: { flowState },
    dispatchDroneZone,
  } = useDroneZoneContext();
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { options } = mapState;

  const worldBox: Vec6 = (mapState?.map?.box as Vec6) || [0, 0, 0, 0, 0, 0];
  const { minX, minY, minZ, maxX, maxY, maxZ } = mapFacilityVectors(worldBox);
  const facilityHeight = maxZ - minZ;

  const mapPositionRef = useRef<CameraState['position']>(new Vector3(0, 0, 0));

  const [noFlyZoneDrawPositionStart, setNoFlyZoneDrawPositionStart] = useState<Vector3 | undefined>(
    undefined,
  );

  const mutableState = useMemo(() => {
    MutableState.destroyInstance();

    return MutableState.constructState(
      'DroneZonesMap',
      {
        ...initialCameraState,
        position: new Vector3((maxX + minX) / 2, (maxY + minY) / 2, ORTHO_CAMERA_Z),
        orthographicFOV: options.camera.orthographic.maxFOV,
        currentOrthographicFOV: options.camera.orthographic.maxFOV,
      },
      { worldBox, guidePosition: new Vector3(0, 0, 0), isFloorHeld: false },
      {},
    );
  }, [mapState.map]);

  const maxNFZHeight = (mapState.map?.box[5] || 99) - (mapState.map?.box[2] || 0);

  const isMapOverlayVisible = [FlowState.CREATE, FlowState.DRAW].includes(flowState);
  const isDrawing = flowState === FlowState.DRAW;

  const zoomer = useMemo(
    () => new Zoomer(options.camera, mapState.canvasSize),
    [options.camera, mapState.map, mapState.canvasSize],
  );

  const camera = useMemo(
    () => (
      <OrthoCamera
        worldBounds={[minX, maxX, minY, maxY]}
        options={options.camera}
        fovAxisRatio={mapState.canvasProportion}
        zoomer={zoomer}
      />
    ),
    [options.camera, mapState.canvasProportion, mapState.map],
  );

  const hasCameraPositionChanged = () => {
    const { x: prevX, y: prevY } = mapPositionRef.current;
    const { x: currX, y: currY } = new Vector3().copy(mutableState.interaction.guidePosition);

    const camerFOV = mutableState.cameraState.currentOrthographicFOV;
    const movementThreshold = camerFOV * 0.0008;
    const hasXMove = Math.abs(currX - prevX) > movementThreshold;
    const hasYMove = Math.abs(currY - prevY) > movementThreshold;

    return hasXMove || hasYMove;
  };

  const handleCreateNoFlyZone = (newNoFlyZone: NoFlyZone) => {
    dispatchDroneZone({
      type: 'SHOW_NO_FLY_ZONE_CREATION_FORM',
      payload: newNoFlyZone,
    });
  };

  const handleDroneZoneDragEnd = (droneZone: NoFlyZone) => {
    dispatchDroneZone({
      type: 'END_DRAGGING_DRONE_ZONE',
      payload: droneZone,
    });
  };

  const handleDroneZoneDragStart = () => {
    const mutState = MutableState.getState();

    mutState.interaction.isFloorHeld = false;
  };

  const handleFloorDown = (): void => {
    const mutState = MutableState.getState();

    mapPositionRef.current = new Vector3().copy(mutableState.interaction.guidePosition);

    if (isDrawingNewNoFlyZone) {
      if (
        !noFlyZoneDrawPositionStart &&
        isCursorInFacility(worldBox, mutState.interaction.guidePosition)
      ) {
        setNoFlyZoneDrawPositionStart(new Vector3().copy(mutableState.interaction.guidePosition));
      }
      return;
    }

    mutState.interaction.isFloorHeld = true;
  };

  const handleFloorUp = (): void => {
    const mutState = MutableState.getState();

    if (
      noFlyZoneDrawPositionStart &&
      isCursorInFacility(worldBox, mutState.interaction.guidePosition)
    ) {
      const newNFZ = makeNoFlyZone(
        'New NFZ',
        {
          start: noFlyZoneDrawPositionStart,
          end: new Vector3(
            mutState.interaction.guidePosition.x,
            mutState.interaction.guidePosition.y,
            facilityHeight,
          ),
        },
        maxNFZHeight,
      );

      setNoFlyZoneDrawPositionStart(undefined);
      handleCreateNoFlyZone(newNFZ);
    }

    mutState.interaction.isFloorHeld = false;
  };

  const handleMouseLeave = () => {
    setNoFlyZoneDrawPositionStart(undefined);
  };

  const handleDroneZoneClick = (zone: ClientDroneZones | null) => {
    const cameraIsStatic = !hasCameraPositionChanged();

    if (cameraIsStatic) {
      onDroneZoneClick(zone);
    }
  };

  const handleSelectDroneGroup = (droneZones: ClientDroneZones[]) => {
    const cameraIsStatic = !hasCameraPositionChanged();

    if (cameraIsStatic) {
      dispatchDroneZone({
        type: 'SELECT_MULTIPLE_DRONE_ZONE',
        payload: droneZones,
      });
    }
  };

  const handleDroneZoneDeselect = () => {
    handleDroneZoneClick(null);
  };

  const handleMapOverlayPointer = !isDrawing
    ? (e: ThreeEvent<PointerEvent>) => e.stopPropagation()
    : undefined;

  useEffect(() => {
    if (flowState !== FlowState.DRAW && !!noFlyZoneDrawPositionStart) {
      setNoFlyZoneDrawPositionStart(undefined);
    }
  }, [flowState, noFlyZoneDrawPositionStart]);

  return (
    <MapCanvas
      mapState={mapState}
      mapDispatch={mapDispatch}
      mutableState={mutableState}
      canvasSize={{ width: 0, height: 630 }}
      floor={
        <Floor
          mapState={mapState}
          zoomer={zoomer}
          onClick={handleDroneZoneDeselect}
          onFloorDown={handleFloorDown}
          onFloorUp={handleFloorUp}
        />
      }
      zoomControls={<MapControls zoomer={zoomer} zoomSteps={options.camera.numberOfZoomSteps} />}
      mapElements={
        <DroneZonesMapElements
          isMapOverlayVisible={isMapOverlayVisible}
          onMapOverlayPointerLeave={handleMapOverlayPointer}
          onMapOverlayPointerEnter={handleMapOverlayPointer}
          noFlyZoneDrawPositionStart={noFlyZoneDrawPositionStart}
          noFlyZones={noFlyZones}
          visibleZones={visibleZones}
          openFlyZones={openFlyZones}
          controlledZones={controlledZones}
          worldBox={worldBox}
          onDroneZoneClick={handleDroneZoneClick}
          onDroneZoneDragEnd={handleDroneZoneDragEnd}
          onDroneZoneGroupClick={handleSelectDroneGroup}
          onDroneZoneDragStart={handleDroneZoneDragStart}
          options={options}
        />
      }
      camera={camera}
      wrapperId={wrapperId}
      onMouseLeave={handleMouseLeave}
      onPointerMissed={handleDroneZoneDeselect}
    />
  );
};
