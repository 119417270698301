import { useParams } from 'react-router-dom';
import { useRequestController } from '../../../../../../hooks';
import { ScheduleLockService } from '../../../../../../services/ScheduleLock';

const ERROR_MESSAGES = {
  403: 'You don`t have permission to perform this operation!',
  409: 'Schedule lock doesn`t exist!',
};
interface IDeleteScheduleLock {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const useDeleteScheduleLock = () => {
  const { requestController } = useRequestController('DeleteScheduleLock');
  const { systemId = '', flightDomainId = '' } = useParams();
  return {
    deleteScheduleLock: ({ setLoading, setError }: IDeleteScheduleLock) =>
      requestController.doRequest({
        request: ScheduleLockService.deleteScheduleLock,
        requestParams: [{ systemId, flightDomainId, signal: requestController.signal }],
        callbackBeforeSend: () => {
          setError('');
          setLoading(true);
        },
        callbackError: ({ response }: { response: { status: number } }) => {
          const errorMessage =
            ERROR_MESSAGES[response.status as keyof typeof ERROR_MESSAGES] ||
            'Something went wrong while performing this action!';
          setError(errorMessage);
        },
        callbackFinally: () => setLoading(false),
      }),
  };
};
