import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: '800px',
    padding: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
