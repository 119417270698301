import { getEmergencyProcedureApiService } from '../services';

interface IDeleteScheduleLock {
  systemId: string;
  flightDomainId: string;
  signal: AbortSignal;
}
/**
 * Delete schedule lock
 * @param param0 IDeleteScheduleLock params
 */
export const deleteScheduleLock = ({ systemId, flightDomainId, signal }: IDeleteScheduleLock) =>
  getEmergencyProcedureApiService().deleteScheduleLock(systemId, flightDomainId, { signal });
