import * as THREE from 'three';
import { Vector3 } from 'three';
import customTheme from '../../../customTheme';
import { CANVAS_HEIGHT } from '../../map/defaults/map-canvas.defaults';

import { Vec6 } from '../MapContainer.model';

/**
 * Makes an integer range.
 * @param n - The number of elements in the range.
 * @returns [0, 1, 2, ..., n-1]
 */
export const zeroTo = (n: number): number[] => Array.from({ length: n }, (_, i) => i);

/**
 * Clamp a number between a minimum and maximum value.
 * @param value - The value to be clamped.
 * @param min - The minimum value.
 * @param max - The maximum value.
 * @returns The value clamped between min and max.
 */
export const between = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

// Currently hardcoded to en-US - should be based on user preference in future.
export const valueToLocaleDecimalPlaces = (value: number, decimalPlaceCount: number) =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaceCount,
    minimumFractionDigits: decimalPlaceCount,
  });

export const zoneValueToLocaleFixedDecimalPlaces = (value: number) =>
  valueToLocaleDecimalPlaces(value, 2);

export const zoneCoordinatesToLocaleFixedDecimalPlaces = <G extends { [key: string]: number }>(
  obj: G,
): G =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: zoneValueToLocaleFixedDecimalPlaces(obj[key]),
    }),
    {} as G,
  );

/**
 * @param wb maps world box
 * @param gp V3 of where cursor meets the floor
 * @returns true if cursor is inside the facility
 */
export const isCursorInFacility = (wb: Vec6, gp: Vector3) =>
  wb[0] < gp.x && gp.x < wb[3] && wb[1] < gp.y && gp.y < wb[4];

type Dimensions = {
  width: number;
  length: number;
};

type FOVSpan = {
  horizontalFOV: number;
  verticalFOV: number;
};

type PrintTextParams = {
  textLines: string[];
  fontWeight?: string;
  textColor: string;
  context: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  dimensions?: Dimensions;
  fovSpan: FOVSpan;
  maxFontSizeInPx?: number;
  minFontSizeInPx?: number;
};

export type MakeTextureParams = {
  textLines: string[];
  fontWeight?: string;
  backgroundColor?: string;
  proportion: number;
  lineWidth?: number;
  borderColor?: string;
  dashed?: boolean;
  hiRes?: boolean;
  includeBorder: boolean;
  defaultHiResSize?: number;
  textColor?: string;
  opacity?: number;
  dimensions?: Dimensions;
  fovSpan: FOVSpan;
  maxFontSizeInPx?: number;
  minFontSizeInPx?: number;
};

/**
 * Turns a string into THREE.Texture
 * @param param0 MakeTextureParams params
 * @returns THREE.Texture
 */
export const makeTexture = ({
  hiRes,
  proportion,
  backgroundColor = '',
  defaultHiResSize = 512,
  borderColor = 'black',
  dashed,
  fontWeight,
  textLines,
  opacity,
  textColor = 'black',
  lineWidth = 1,
  includeBorder,
  dimensions,
  fovSpan,
  maxFontSizeInPx = 30,
  minFontSizeInPx = 16,
}: MakeTextureParams): THREE.Texture => {
  const baseSize = hiRes ? defaultHiResSize : 196;
  const canvas = document.createElement('canvas');

  canvas.width = proportion < 1 ? baseSize * proportion : baseSize;
  canvas.height = proportion < 1 ? baseSize : baseSize / proportion;

  const context = canvas.getContext('2d');

  if (!context) {
    console.debug(
      'makeTexture',
      `Failed to create canvas context for baseSize ${baseSize} and proportion ${proportion}`,
    );
    return new THREE.Texture();
  }

  if (opacity) {
    // Make texture transparent
    const opacityLevel = Math.round(opacity * 256);
    context.fillStyle = `rgb(${opacityLevel}, ${opacityLevel}, ${opacityLevel})`;
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  if (backgroundColor) {
    // Fill the box
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'black';
  }

  if (includeBorder) {
    // Draw the contour of the box
    context.strokeStyle = borderColor;
    context.lineWidth = lineWidth;

    if (dashed === true) {
      context.setLineDash([16]);
    }
    context.strokeRect(0, 0, canvas.width, canvas.height);
  }

  if (textLines) {
    printText({
      textLines,
      textColor,
      context,
      fontWeight,
      canvas,
      dimensions,
      fovSpan,
      maxFontSizeInPx,
      minFontSizeInPx,
    });
  }

  const texture = new THREE.CanvasTexture(canvas);
  return texture;
};

/**
 * Print text on canvas
 * @param param0 PrintTextParams
 * @returns size of font used
 */
export const printText = ({
  textLines,
  textColor,
  context,
  canvas,
  fontWeight = 'normal',
  dimensions,
  fovSpan,
  maxFontSizeInPx = 30,
  minFontSizeInPx = 16,
}: PrintTextParams): number => {
  const minCanvasSize = 20;
  const unitSizeInPixel = dimensions ? CANVAS_HEIGHT / (2 * fovSpan.verticalFOV) : 1;
  const elementWidthInPixel = dimensions ? unitSizeInPixel * dimensions.width : canvas.width;
  const elementHeightInPixel = dimensions ? unitSizeInPixel * dimensions.length : canvas.height;

  // Setup the font so that the text is aligned in the middle of the box
  context.fillStyle = textColor;
  context.textAlign = 'center';
  context.textBaseline = 'middle';

  const longestTextLine = textLines.reduce((a, l) => (a.length > l.length ? a : l), '' as string);

  let adjustedFontSize = 0;

  /**
   * Will mutate context.font and adjustedFontSize to fit text width
   * @param x screen horizontal side of canvas
   * @param y screen vertical side of canvas
   * @param isVertical flag for vertical orientation
   */
  const constrainTextWidthBy = (x: number, y: number, isVertical: boolean = false): void => {
    const fontSize = y < minCanvasSize ? y - 6 : y - minFontSizeInPx;
    context.font = `${fontWeight} ${fontSize}px ${customTheme.typography.fontFamily}`;
    let adjustedMaxFontSizeInPx =
      (maxFontSizeInPx * y) / (isVertical ? elementWidthInPixel : elementHeightInPixel);
    adjustedMaxFontSizeInPx = Math.max(adjustedMaxFontSizeInPx, minFontSizeInPx);
    const ratio = (x - minFontSizeInPx) / context.measureText(longestTextLine).width;
    adjustedFontSize = Math.min(fontSize, Math.round(fontSize * ratio), adjustedMaxFontSizeInPx);
    context.font = `${fontWeight} ${adjustedFontSize}px ${customTheme.typography.fontFamily}`;
  };

  /**
   * Will mutate context.font and adjustedFontSize to fit text height
   * @param constraint constraint text lines need to fit in
   * @returns [lineHeight, totalHeight]
   */
  const constrainTextHeightBy = (constraint: number): [number, number] => {
    let lineHeight = adjustedFontSize * 1.2;
    let totalHeight = lineHeight * textLines.length;

    if (totalHeight > constraint) {
      adjustedFontSize /= textLines.length;
      lineHeight = adjustedFontSize * 1.2;
      totalHeight = lineHeight * textLines.length;
      context.font = `${fontWeight} ${adjustedFontSize}px ${customTheme.typography.fontFamily}`;
    }
    return [lineHeight, totalHeight];
  };

  if (canvas.width < canvas.height) {
    // The box is vertically elongated
    // in this case it makes more sense to put the text vertically, to make better use
    // of the available space.
    //
    // Given that canvas doesn't support vertical text natively we need to to first rotate
    // the canvas by 90 degrees and then apply the text.
    //
    // It should be noted how the offsets change in this case, in particular then Y axis one
    // which in case of a rotation of 90 degrees (text bottom -> up) needs to be negative
    context.rotate((90 * Math.PI) / 180);
    // Calculate the font size so, that it will fit the box vertically
    constrainTextWidthBy(canvas.height, canvas.width, true);

    const [lineHeight, totalHeight] = constrainTextHeightBy(canvas.width);

    textLines.forEach((line, i) => {
      const yPos = -canvas.width / 2 + i * lineHeight - totalHeight / 2 + lineHeight / 2;
      context.fillText(line, canvas.height / 2, yPos);
    });

    console.debug(
      `3D label alphaMap (${adjustedFontSize}px on ${canvas.width}x${canvas.height}px): ${textLines}`,
    );
  } else {
    // Calculate the font size so, that it will fit the box horizontally
    constrainTextWidthBy(canvas.width, canvas.height);

    const [lineHeight, totalHeight] = constrainTextHeightBy(canvas.height);

    textLines.forEach((line, i) => {
      const yPos = canvas.height / 2 + i * lineHeight - totalHeight / 2 + lineHeight / 2;
      context.fillText(line, canvas.width / 2, yPos);
    });

    console.debug(
      `3D label alphaMap (${adjustedFontSize}px on ${canvas.width}x${canvas.height}px): ${textLines}`,
    );
  }

  return adjustedFontSize;
};

type IMakeThreeMaterial = {
  color: string;
  texture: THREE.Texture;
  alphaMap?: THREE.Texture;
  depthTest?: boolean;
  depthFunc?: THREE.DepthModes;
};

/**
 * Create array of materials for box sides given color and texture.
 * @param color color of box
 * @param texture texture for top side of box
 * @returns array of THREE.Material
 */
export const makeTHREEMaterial = ({
  color,
  texture,
  alphaMap,
  depthTest = true,
  depthFunc = THREE.LessEqualDepth,
}: IMakeThreeMaterial): THREE.MeshBasicMaterial[] => {
  /**
   * WARNING: never set transparency to true without setting alphaMap or opacity.
   */
  const right = new THREE.MeshBasicMaterial({ color, visible: false });
  const left = new THREE.MeshBasicMaterial({ color, visible: false });
  const up = new THREE.MeshBasicMaterial({ color, visible: false });
  const down = new THREE.MeshBasicMaterial({ color, visible: false });
  const top = alphaMap
    ? new THREE.MeshBasicMaterial({
        map: texture,
        alphaMap,
        transparent: true,
        depthTest,
        depthFunc,
      })
    : new THREE.MeshBasicMaterial({ map: texture, depthTest, depthFunc });
  const bottom = new THREE.MeshBasicMaterial({ color, visible: false });

  top.needsUpdate = true;

  return [right, left, up, down, top, bottom];
};
