import { Dispatch } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { WARNING_PAGES_URLS } from 'common/pages';
import { REPORT_SPEC_NAME } from 'common/reportSpecifications';
import { IRequestController, IAlertContent } from 'hooks';
import ReportStore, { ReportSummary } from 'udb/features/reports/reducer/report-store/ReportStore';
import {
  ReportContainerActionNames,
  ReportContainerActions,
} from '../reducer/ReportContainerActions';

const logPrefix = getLogPrefixForType('FUNCTION', 'getReportSummary');

type GetReportSummary = {
  reportId: string;
  dispatch: Dispatch<ReportContainerActions>;
  requestController: IRequestController;
  systemId: string;
  showAlert: { warning: (params: IAlertContent) => void };
  navigate: (param: string) => void;
};

/**
 * This function handles get report summary call
 * @returns report summary
 */
export const getReportSummary = ({
  reportId,
  dispatch,
  requestController,
  systemId,
  showAlert,
  navigate,
}: GetReportSummary) => {
  const { signal } = requestController.reserveSlotForRequest();

  return requestController.doRequest({
    request: ReportStore.getReportSummary,
    requestParams: [systemId, reportId, signal],
    callbackBeforeSend: () =>
      dispatch({ type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND }),
    callbackSuccess: (r: ReportSummary) => {
      const hasNoEmptyLocations = r.reportSummaryData.counter === 0;
      const isReportSpecEmptyLocation =
        r.fullResponse.request.report_spec.report_spec_name === REPORT_SPEC_NAME.EMPTY_LOCATION;

      // Special case where a report's setup resulted in  0 locations
      // For example the regular expression returns no value
      if (hasNoEmptyLocations) {
        showAlert.warning({
          title: 'This report’s setup resulted in 0 locations, therefore the report is empty.',
          label: 'Report',
        });
      }

      // Special case where an Empty Slot report results in 0 locations
      // because there where no empty locations on the area selected by the user
      if (hasNoEmptyLocations && isReportSpecEmptyLocation) {
        showAlert.warning({
          label: '',
          title: 'This report is empty.',
          message:
            'This means that the areas selected have no empty locations and therefore there was nothing to scan.',
        });
      }

      dispatch({
        type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_SUCCESS,
        payload: r.reportSummaryData,
      });
    },
    messageErrorFallback: 'The Report Summary could not be fetched.',
    callbackError: (e: any) => {
      if (e?.response?.status === 404) {
        console.debug(logPrefix, `error when fetching report summary: ${e}`);
        // Note: the request returned a 404 not found => the report doesn't exist.
        const url = WARNING_PAGES_URLS.NOT_FOUND;
        console.debug(logPrefix, `report summary not found: redirect to: ${url}`);
        navigate(url);
      }
    },
    callbackFinally: () =>
      dispatch({
        type: ReportContainerActionNames.SET_ANY,
        payload: { fieldName: 'summarySpinner', value: 0 },
      }),
  });
};
