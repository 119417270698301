import React from 'react';

// mui core and styles
import { Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// mui icons
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

// custom styles
import './switch.css';

// components
import { Box } from 'components/common/Box';

const SwitchThumb = ({ disabled, cardSwitch, color, isChecked, theme }) => (
  <Box
    p={1}
    sx={{
      background: (cardSwitch && (!disabled ? color : theme.palette.disabled)) || color,
    }}
    className="switch"
  >
    {isChecked ? (
      <LockOpenIcon sx={{ color: '#fff', fontSize: '15px' }} />
    ) : (
      <LockIcon sx={{ color: '#fff', fontSize: '15px' }} />
    )}
  </Box>
);

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '33px',
    width: '118px',
    padding: 0,
  },
  track: {
    border: '1px solid #fff',
    opacity: 1,
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color']),
  },
  thumb: {},
  switchBase: {
    '&:hover': {
      border: 'none',
      backgroundColor: 'none',
      transition: 'all .3s',
    },
    '&:hover .switch': {
      boxShadow: '-0.2rem 0rem 0rem #c7c7c7',
    },
    paddingTop: 3,
    paddingLeft: 3,
  },
  checked: {},
  focusVisible: {},
}));

/**
 * Functional component representing a Status Bar Switch (on/off)
 * @param {*} param0 props
 * @returns the functional component
 */
function StatusBarSwitch({ color, cardSwitch, noData, locked, ...restProps }) {
  const switchContent = (checked) => {
    if (noData) {
      return "'UNKNOWN'";
    }
    if (checked) {
      return "'UNLOCKED'";
    }
    return "'LOCKED'";
  };

  const { classes, theme } = useStyles();

  return (
    <Switch
      data-testid="c-fd-lock-unlock-switch"
      inputProps={{ 'aria-label': 'fd-lock-unlock-switch' }}
      disableRipple={true}
      disabled={restProps.disabled}
      icon={
        <SwitchThumb
          cardSwitch={cardSwitch}
          disabled={restProps.disabled}
          theme={theme}
          color={color}
          isChecked={restProps.checked}
        />
      }
      checkedIcon={
        <SwitchThumb
          cardSwitch={cardSwitch}
          disabled={restProps.disabled}
          theme={theme}
          color={color}
          isChecked={restProps.checked}
        />
      }
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      {...restProps}
      sx={{
        '& .MuiSwitch-switchBase .MuiSwitch-input': {
          width: '130px',
          left: locked ? 0 : '-140%',
        },
        '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
          opacity: !cardSwitch ? '0.6 !important' : 1,
          background: cardSwitch && restProps.disabled ? '#EEEEEE' : 'auto',
          cursor: 'default',
        },
        '& .MuiSwitch-switchBase': {
          zIndex: 1200,
          '&:hover': {
            left: '4px',
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(84px)',
          '&:hover': {
            left: '-4px',
          },

          '&:hover .switch': {
            boxShadow: '0.2rem 0rem 0rem #c7c7c7 !important',
          },
        },
        '& .MuiButtonBase-root:hover': {
          backgroundColor: 'transparent',
        },

        // Track and label style
        '& .MuiSwitch-track': {
          backgroundColor: '#fff !important',
          opacity: '1 !important',
          border: `1px solid ${(cardSwitch && (!restProps.disabled ? color : '#CECECE')) || color}`,
          borderRadius: 100 / 2,

          '&:before, &:after': {
            position: 'absolute',
            top: 8,
            fontSize: '12px',
            fontWeight: 'bold',
          },
          '&:before': {
            content: restProps.checked ? switchContent(restProps.checked) : "''",
            left: 15,
            color: (cardSwitch && (!restProps.disabled ? color : theme.palette.disabled)) || color,
          },
          '&:after': {
            content: !restProps.checked ? switchContent(restProps.checked) : "''",
            left: 46,
            color: (cardSwitch && (!restProps.disabled ? color : theme.palette.disabled)) || color,
          },
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        },
      }}
    />
  );
}

export default StatusBarSwitch;
