import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { LONG_DATETIME_FORMAT } from 'common/datetimeFormats';
import { LocalStore } from 'common/functions/storageFunctions';
import { useGroundControlStore } from '../../../../../../store/GroundControl/groundControlLevelStore';
import { OperatingHoursEventST } from '../../API';
import { circularRangeForWeekDays } from '../../FlightHoursCalendar/Functions/CircularRangeForWeekDays';
import { areMinutesWithinRanges } from '../Functions/AreMinutesWithinRanges';
import { convertOperatingHoursToTimeRanges } from '../Functions/ConvertOperatingHoursToTimeRanges';
import {
  getMinutesFromTimeRangesAccordingToHour,
  IMinutesRange,
} from '../Functions/GetMinutesFromTimeRangesAccordingToHour';
import { isHourWithinTimeRanges } from '../Functions/IsHourWithinTimeRanges';
import { TimeRange } from '../Functions/TimeRange';

interface IScheduleLockDateTimeProps {
  onChange: Dispatch<SetStateAction<Moment | null>>;
  onError: Dispatch<SetStateAction<string | null>>;
  error: string | null;
  lockDateTime: Moment | null;
}

const shouldDisableHours = ({
  isToday,
  facilityHours,
  time,
  timeRanges,
}: {
  isToday?: boolean;
  facilityHours: number;
  time: number;
  timeRanges: TimeRange[];
}) => {
  if (isToday && facilityHours > time) {
    return true;
  }
  return !isHourWithinTimeRanges(time, timeRanges);
};

const shouldDisableMinutes = ({
  isLockHourSameAsFacilityHour,
  facilityMinutes,
  time,
  minutesRanges,
}: {
  isLockHourSameAsFacilityHour?: boolean;
  facilityMinutes: number;
  time: number;
  minutesRanges?: IMinutesRange[];
}) => {
  if (isLockHourSameAsFacilityHour && facilityMinutes > time) {
    return true;
  }
  if (minutesRanges && minutesRanges.length) {
    return !areMinutesWithinRanges(minutesRanges, time);
  }
  return false;
};

/**
 * Schedule lock date time picker
 * @param param0 IScheduleLockDateTimeProps params
 * @returns component
 */
export const ScheduleLockDateTimePicker = ({
  onChange,
  onError,
  error,
  lockDateTime,
}: IScheduleLockDateTimeProps) => {
  const { stateGroundControl } = useGroundControlStore();
  const { schedule } = stateGroundControl.flightDomainSchedule.drone_operating_hours;

  const timezone = LocalStore.getTimezone();

  const helperText = error && 'The chosen date and time must fit into the drone flight hours';

  const days = Array.from(
    new Set(
      schedule.reduce(
        (acc: number[], val: OperatingHoursEventST) => [
          ...acc,
          ...circularRangeForWeekDays(val.start.week_day, val.end.week_day),
        ],
        [],
      ),
    ),
  );

  const timeRanges: TimeRange[] = lockDateTime?.day()
    ? convertOperatingHoursToTimeRanges(schedule, lockDateTime.day())
    : [];

  const hour = lockDateTime?.hour();

  const facilityTime = moment().tz(timezone);
  const facilityHours = facilityTime.tz(timezone).hours();
  const facilityMinutes = facilityTime.tz(timezone).minutes();
  const isToday = lockDateTime?.isSame(facilityTime, 'date');
  const isLockHourSameAsFacilityHour = isToday && facilityHours === hour;

  const minutesRanges = hour ? getMinutesFromTimeRangesAccordingToHour(timeRanges, hour) : [];

  const shouldDisableTime = (time: number, type: string) => {
    if (!timeRanges.length) return false;

    let shouldDisable;

    switch (type) {
      case 'hours':
        shouldDisable = shouldDisableHours({ isToday, facilityHours, time, timeRanges });
        break;
      case 'minutes':
        shouldDisable = shouldDisableMinutes({
          isLockHourSameAsFacilityHour,
          facilityMinutes,
          time,
          minutesRanges,
        });
        break;
      default:
        shouldDisable = false;
    }

    return shouldDisable;
  };

  const shouldDisableDate = (date: Moment) => !days.includes(date.isoWeekday());
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDateTimePicker
        disablePast
        label="Schedule at"
        ampm={false}
        onError={onError}
        shouldDisableDate={shouldDisableDate}
        shouldDisableTime={shouldDisableTime}
        disableMaskedInput
        inputFormat={LONG_DATETIME_FORMAT}
        value={lockDateTime}
        onChange={onChange}
        PopperProps={{
          placement: 'top-end',
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            data-testid="c-datetime-schedule-at-input"
            error={!!error}
            helperText={helperText}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};
