import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// mui core
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

// components
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import EmergencyLandingTrigger from './EmergencyLandingTrigger';
import StatusBar from './StatusBar/StatusBar';

// store & services
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { useHideOnScroll } from '../../hooks/useHideOnScroll';

// variables, functions, configurations
import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';
import { GroundControlModalsActionNames } from '../../store/Modals/groundControlModals/groundControlModalsActions';
import { useLoadGroundControlLevelData } from '../../hooks/useLoadGroundControlLevelData';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

const GroundControlPage = () => {
  // styles and param hooks
  const stickyElement = useHideOnScroll({ threshold: 50 });

  // context and stores
  const { flightDomainId } = useLoadGroundControlLevelData();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();
  const { isDataReady: isFlightDomainDataReady, stateGroundControl } = useGroundControlStore();

  // Current active flight domain
  const { flightDomain, isExecutingEmergencyLanding, isExecutingReturnHome, dronesFlying } =
    stateGroundControl;

  // user permissions

  useEffect(
    /**
     * close all ground control level modals on mount and on unmount
     */
    () => {
      dispatchGroundControlModals({ type: GroundControlModalsActionNames.RESET });
      return () => dispatchGroundControlModals({ type: GroundControlModalsActionNames.RESET });
    },
    [dispatchGroundControlModals],
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const shouldRenderPage = isFlightDomainDataReady() && flightDomainId;

  if (shouldRenderPage) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* Page content */}
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          {flightDomain && (
            <StatusBar
              flightDomain={flightDomain}
              webSocketError={stateGroundControl.webSocketError}
              isExecutingEmergencyLanding={isExecutingEmergencyLanding}
              isExecutingReturnHome={isExecutingReturnHome}
              dronesFlying={dronesFlying}
              stickyStatusBar={stickyElement!}
              lockSystem={() =>
                dispatchGroundControlModals({
                  type: GroundControlModalsActionNames.LOCK_MODAL,
                  payload: flightDomainId,
                })
              }
              unlockSystem={() =>
                dispatchGroundControlModals({
                  type: GroundControlModalsActionNames.UNLOCK_MODAL,
                  payload: flightDomainId,
                })
              }
            />
          )}
          <Box>
            <Box>
              <Container disableGutters maxWidth={false}>
                <Outlet />
              </Container>
            </Box>
          </Box>
        </Box>
        {/* Emergency Landing modal trigger */}
        {userHasPermission(PERMISSION.INITIATE_SAFETY_LANDING) && (
          <EmergencyLandingTrigger
            sticky={stickyElement}
            disabled={stateGroundControl.webSocketError}
            onClick={() =>
              dispatchGroundControlModals({
                type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL,
                payload: flightDomainId || '',
              })
            }
          />
        )}
      </Box>
    );
  }
  return <Spinner label="loading flight domain data ..." />;
};

export default GroundControlPage;
