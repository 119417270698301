import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { NEW_DRONE_ZONE_ID } from './defaults/newDroneZoneId';
import { NoFlyZoneDrawPosition } from '../model/NoFlyZone.model';
import { allNumbersToFixed2 } from '../../../../../shared/numberFormatting';

/**
 * Turns NFZ creation state into NFZ state.
 * @param name of the NFZ
 * @param drawPosition NFZ creation state
 * @param height of the NFZ
 * @returns NFZ state
 */
export const makeNoFlyZone = (
  name: string,
  drawPosition: NoFlyZoneDrawPosition,
  height: number,
): NoFlyZone => {
  const { start, end } = drawPosition;

  if (!start || !end) {
    return {
      id: NEW_DRONE_ZONE_ID,
      type: DroneZoneTypes.noFlyZone,
      name: 'failed creation',
      description: '',
      sizeAndPosition: { w: 0, l: 0, h: 0, minX: 0, minY: 0, minZ: 0, maxZ: 0 },
      isActive: false,
      isSelected: false,
    };
  }

  const [dx, dy] = [end.x - start.x, end.y - start.y];
  const [x, y] = [start.x + dx / 2, start.y + dy / 2];
  const [width, length] = [Math.abs(dx), Math.abs(dy)];
  const [minX, minY] = [x - width / 2, y - length / 2];

  return {
    name,
    id: NEW_DRONE_ZONE_ID,
    type: DroneZoneTypes.noFlyZone,
    description: '',
    sizeAndPosition: allNumbersToFixed2({
      w: width,
      l: length,
      h: height,
      minX,
      minY,
      minZ: start.z,
      maxZ: end.z,
    }),
    isActive: false,
    isSelected: false,
  };
};
