import { Card, CardHeader, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseCardStyle } from 'components/BaseCard/styles';
import { useGroundControlStore } from '../../../../../store/GroundControl/groundControlLevelStore';
import { ScheduleLockCreation } from './ScheduleLockCreation/ScheduleLockCreation';
import { ScheduleLockDeletion } from './ScheduleLockDeletion';
import { ScheduleLockEmptyState } from './ScheduleLockEmptyState';

export const SCHEDULE_LOCK_STATES = {
  empty: 'empty',
  creation: 'creation',
  deletion: 'deletion',
};

const COMPONENTS = {
  [SCHEDULE_LOCK_STATES.empty]: ScheduleLockEmptyState,
  [SCHEDULE_LOCK_STATES.creation]: ScheduleLockCreation,
  [SCHEDULE_LOCK_STATES.deletion]: ScheduleLockDeletion,
};

/**
 * Schedule lock
 * @returns component
 */
export const ScheduleLock = () => {
  const { classes: cardStyles } = baseCardStyle();

  const { stateGroundControl } = useGroundControlStore();

  const scheduleLock =
    stateGroundControl.flightDomain?.flight_domain_status?.next_scheduled_lock || '';

  const [currentComponent, setCurrentComponent] = useState('');

  const Component = currentComponent && COMPONENTS[currentComponent];

  useEffect(() => {
    const defaultComponent = scheduleLock
      ? SCHEDULE_LOCK_STATES.deletion
      : SCHEDULE_LOCK_STATES.empty;

    setCurrentComponent(defaultComponent);
  }, [scheduleLock]);

  return (
    <Card
      data-testid="c-schedule-lock-card"
      sx={{ position: 'relative' }}
      className={cardStyles.card}
      elevation={3}
    >
      <CardHeader
        title="Drone schedule lock"
        subheader="Plan your next early flight ending"
        className={cardStyles.cardSection}
        classes={{
          title: cardStyles.title,
          subheader: cardStyles.subheader,
        }}
      />
      <Divider />
      {Component && <Component onClick={setCurrentComponent} scheduleLock={scheduleLock} />}
    </Card>
  );
};
