import { Box, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

type MetricProps = {
  tooltip?: string;
  value: number | string;
  unit?: string;
  unitLong?: string;
};

export const Metric = ({ tooltip, value, unit, unitLong }: MetricProps) => {
  const t = tooltip || `${value} ${unitLong || unit || ''}`;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Tooltip title={t}>
      <Box
        component="div"
        onClick={() => {
          navigator.clipboard.writeText(t);
          enqueueSnackbar('Copied to clipboard', { variant: 'success' });
        }}
        data-testid="metric-container"
      >
        {value}
        {unit ? ` ${unit}` : null}
      </Box>
    </Tooltip>
  );
};
