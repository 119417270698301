import { valueToLocaleDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { DroneZones } from '../../../../../../../pages/GroundControl/pages/NoFlyZones/model/droneZones.model';

export const getHeightRangeValues = ({
  min,
  max,
  zones,
}: {
  min: number;
  max: number;
  zones: DroneZones[];
}): { id: string; position: string; value: string }[] => {
  const zoneZAxisValues = [
    ...zones
      .map((zone) => [zone.sizeAndPosition.minZ, zone.sizeAndPosition.maxZ])
      .flat(1)
      .filter((value) => max - value >= 1 && value - min >= 1),
  ];

  const deduplicateRangeValues = [...new Set([min, ...zoneZAxisValues, max])];

  const sortedLegendRange = deduplicateRangeValues
    .sort((legendA, legendB) => legendB - legendA)
    .map((legend) => ({
      id: crypto.randomUUID(),
      position: `${(legend / max) * 100}%`,
      value: valueToLocaleDecimalPlaces(legend, 1),
    }));

  return sortedLegendRange;
};
