import { IScheduleLockPutRequestST } from 'codegen/nav_simulation';
import { getEmergencyProcedureApiService } from '../services';

interface ISetScheduleLock {
  systemId: string;
  flightDomainId: string;
  scheduleLock: IScheduleLockPutRequestST;
  signal: AbortSignal;
}
/**
 * Update schedule lock
 * @param param0 IUpdateScheduleLock params
 */
export const setScheduleLock = ({
  systemId,
  flightDomainId,
  scheduleLock,
  signal,
}: ISetScheduleLock) =>
  getEmergencyProcedureApiService().scheduleLock(systemId, flightDomainId, scheduleLock, {
    signal,
  });
