import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { createDroneZoneObject } from 'delivery/features/drone-zones/features/drone-zone-controls/features/drone-zone-form/utils/createDroneZoneObject.util';
import { NoFlyZonesList } from '../NoFlyZoneList/NoFlyZonesList';
import { NoFlyZoneCreator } from '../NoFlyZoneList/NoFlyZoneCreator';
import { useRequestController } from '../../../../../hooks';
import { NoFlyZoneForm } from './NoFlyZoneForm/NoFlyZoneForm';
import { FlowState } from '../reducer/zoneStatusInitialState';
import { DroneZonesList } from '../DroneZonesList/DroneZonesList';
import { useFacilityLevelStore } from '../../../../../store/FacilityLevelStore/facilityLevelStore';
import { DroneZoneTypes } from '../model/droneZones.model';
import { DroneZoneControlsProps } from './DroneZoneControlsProps.model';
import { useDroneZoneContext } from '../reducer/DroneZonePageContext';
import { createOrUpdateNoFlyZone } from './api/createOrUpdateNoFlyZone';

export const DroneZoneControls = ({
  systemId,
  flightDomainId,
  flowState,
  noFlyZones,
  controlledZones,
  worldBox,
  isSystemLocked,
  areActionsEnabled,
  currentlySelectedZone,
  currentlyEditingZone,
  onSelectDroneZone,
}: DroneZoneControlsProps) => {
  const { requestController } = useRequestController('NoFlyZones');
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchDroneZone } = useDroneZoneContext();

  const isLoading = flowState === FlowState.LOADING;
  const isAdditionalDroneZonesEnabled = stateFacilityLevel.facilitySettings.show_coexistence_map;

  const editedNewFlyZone =
    currentlyEditingZone ?? createDroneZoneObject({ type: DroneZoneTypes.noFlyZone });

  const handleUpdateNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchDroneZone({
      type: 'UPDATE_NO_FLY_ZONE',
      payload: noFlyZone,
    });

  const handleCancelNoFlyZone = (noFlyZone: NoFlyZone) => {
    dispatchDroneZone({ type: 'CANCEL_EDITING_NO_FLY_ZONE', payload: noFlyZone });
  };

  const handleSubmitNoFlyZone = (noFlyZone: NoFlyZone) =>
    createOrUpdateNoFlyZone({
      flightDomainId,
      noFlyZone,
      onSuccess: () => dispatchDroneZone({ type: 'SAVE_NO_FLY_ZONE_CHANGES', payload: null }),
      requestController,
      systemId,
    });

  const handleNoFlyZoneCreationCancel = () =>
    dispatchDroneZone({ type: 'SET_FLOW_STATE', payload: FlowState.LIST });

  switch (flowState) {
    case FlowState.LOADING:
    case FlowState.LIST:
    case FlowState.DELETE:
      return isAdditionalDroneZonesEnabled ? (
        <DroneZonesList
          flightDomainId={flightDomainId}
          systemId={systemId}
          noFlyZones={noFlyZones}
          controlledZones={controlledZones}
          isLoading={isLoading}
          isSystemLocked={isSystemLocked}
          areActionsEnabled={areActionsEnabled}
          currentlySelectedZone={currentlySelectedZone}
          onSelect={onSelectDroneZone}
        />
      ) : (
        <NoFlyZonesList
          noFlyZones={noFlyZones}
          isLoading={isLoading}
          onSelectNoFlyZone={onSelectDroneZone}
          systemId={systemId}
          flightDomainId={flightDomainId}
        />
      );
    case FlowState.DRAW:
      return <NoFlyZoneCreator onCancelCreateNFZ={handleNoFlyZoneCreationCancel} />;
    case FlowState.CREATE:
      return (
        <NoFlyZoneForm
          noFlyZone={editedNewFlyZone}
          worldBox={worldBox}
          onUpdate={handleUpdateNoFlyZone}
          onCancel={handleCancelNoFlyZone}
          onSubmit={handleSubmitNoFlyZone}
        />
      );
    default:
      return null;
  }
};
