import { REPORT_STATES } from 'common/reportStates';
import { Reports } from 'udb/features/reports/reportsFunctions';
import { GetFinishedReportsParams } from './getFinishedReportsParams.model';
import reportStore from '../../reducer/report-store/ReportStore';

export const getFinishedReports = ({
  setData,
  setSpinner,
  systemId,
  requestController,
  dates,
}: GetFinishedReportsParams) => {
  const finishedReportsRequestParams = { ...dates, states: [REPORT_STATES.FINISHED] };
  const finishedRequestReservation = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: reportStore.asyncGetReportSummariesRecursively,
    requestParams: [
      systemId,
      finishedReportsRequestParams,
      requestController,
      finishedRequestReservation.requestId,
      finishedRequestReservation.signal,
      false,
      (r: Reports) => {
        setData(r.reportsFinishedAll);
      },
    ],
    callbackBeforeSend: () => setSpinner(() => 1),
    messageErrorFallback: 'Something went wrong while getting the  summaries of finished reports',
    callbackFinally: () => setSpinner(() => 0),
  });
};
