import React from 'react';

// context and store
import Spinner from 'components/common/Spinner';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useLoadUserLevelData } from '../../hooks';

// components

const AuthPage = ({ children }: { children: JSX.Element }) => {
  const { isDataReady: isUserDataReady } = useUserLevelStore();
  useLoadUserLevelData('AuthPage');
  if (isUserDataReady()) {
    return children;
  }
  return <Spinner label="loading user data..." />;
};

export default AuthPage;
