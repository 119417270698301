export const INPUT_ERROR = {
  // DATE INPUTS
  INVALID_DATE: 'The date inserted is invalid.',
  PAST_DATE: 'The date inserted is in the past.',
  SAME_DATE: 'The dates inserted are the same.',
  AFTER_UNTIL: 'The date inserted is after the "end" date.',
  BEFORE_FROM: 'The date inserted is before the "start" date.',

  // TEXT AND SELECT INPUTS
  REPORT_NAME: 'You need to define a report name.',
  DEFAULT: 'This value cannot be empty.',
  NUMBER_OF_OCCURRENCES: 'Please set correct dates and/or recurrence interval.',
  OUT_OF_RANGE: (min: any, max: any) => `Value out of range. Allowed range ${min}-${max}`,
};
