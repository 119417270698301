import { Button, CardActions, CardContent, Grid, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { Box } from 'components/common/Box';
import { baseCardStyle } from 'components/BaseCard/styles';
import { SCHEDULE_LOCK_STATES } from './ScheduleLock';

interface IScheduleLockEmptyStateProps {
  onClick: (param: string) => void;
}

/**
 * Schedule lock empty state
 * @param param0 IScheduleLockEmptyStateProps params
 * @returns component
 */
export const ScheduleLockEmptyState = ({ onClick }: IScheduleLockEmptyStateProps) => {
  const { classes: cardStyles } = baseCardStyle();

  return (
    <>
      <CardContent
        className={cardStyles.cardContentSection}
        data-testid="c-schedule-lock-card-content"
      >
        <Grid
          container
          mt={1}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography color="textSecondary">
              There is no schedule lock set. To set one click on the button below.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box p={1} width="100%">
          <Button
            startIcon={<Add />}
            data-testid="c-add-schedule-lock-button"
            variant="outlined"
            color="primary"
            size="medium"
            fullWidth
            onClick={() => onClick(SCHEDULE_LOCK_STATES.creation)}
          >
            SET SCHEDULE LOCK
          </Button>
        </Box>
      </CardActions>
    </>
  );
};
