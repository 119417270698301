import { LocalStore } from 'common/functions/storageFunctions';
import { Configuration, FlightAreasApi } from 'codegen/flight_areas';

export const initFlightAreasAPI = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const api = new FlightAreasApi(config);

  return api;
};
