import {
  IAuthChallengeMFAAndSMSST,
  IAuthChallengeNewPasswordST,
  IVerifySoftwareTokenPostRequestST,
  ISetUserMFAPreferencePostRequestST,
  IChangePasswordPostRequestST,
} from 'codegen/authentication';
import { getAuthenticationService } from './services';

/**
 * Authenticate user.
 * @param {string} username Username for user
 * @param {string} password Password for user
 * @returns {object} <IAuthenticationResponseST>
 */
const signIn = (username: string, password: string) =>
  getAuthenticationService().signIn({ username, password });

/**
 * Responds to the authentication challenge.
 * @param {string} username Username for user
 * @param {object} data <IAuthChallengeMFAAndSMSST> or <IAuthChallengeNewPasswordST>
 * @returns {object} <IAuthenticationResponseST>
 */
const authChallenge = ({ ...data }: IAuthChallengeMFAAndSMSST | IAuthChallengeNewPasswordST) =>
  getAuthenticationService().authChallenge({ ...data });

/**
 * Refresh user tokens.
 * @param {string} username Username for user
 * @param {string} refresh_token Refresh token value for user
 * @returns {object} <IRefreshTokenPostResponseST>
 */
const refreshToken = (username: string, refresh_token: string) =>
  getAuthenticationService().refreshToken({ username, refresh_token });

/**
 * Revoke the given Refresh Token
 * @parm refresh_token Refresh Token to be revoked
 */
const revokeToken = (refresh_token: string) =>
  getAuthenticationService().revokeRefreshToken({ refresh_token });

/**
 * Resets the user's password.
 * @param {string} username Username for user
 * @returns {object} <IForgotPasswordPostResponseST>
 */
const forgotPassword = (username: string) =>
  getAuthenticationService().forgotPassword({ username });

/**
 * Reset a forgotten password.
 * @param {string} username Username for user
 * @param {string} password Password for user
 * @param {string} code The confirmation code from your user's request
 * @returns {object} any
 */
const confirmForgotPassword = (username: string, password: string, code: string) =>
  getAuthenticationService().confirmForgotPassword({ username, password, code });

/**
 * Resends the confirmation code.
 * @param {string} username Username for user
 * @returns {object} <IResendConfirmationCodePostResponseST>
 */
const resendCode = (username: string) => getAuthenticationService().resendCode({ username });

/**
 * Get attributes for a user.
 * @param {string} access_token Access token value for user
 * @returns {object} <IGetUserPostResponseST>
 */
const getUser = (access_token: string) => getAuthenticationService().getUser({ access_token });

/**
 * Associate software token to the user account.
 * @param {string} access_token Access token value for user
 * @returns {object} <IAssociateSoftwareTokenPostResponseST>
 */
const getSecretCode = (access_token: string) =>
  getAuthenticationService().getSecretCode({ access_token });

/**
 * Mark MFA status as "verified".
 * @param {object} data <IVerifySoftwareTokenPostRequestST>
 * @returns {object} <IVerifySoftwareTokenPostResponseST>
 */
const verifySoftwareToken = ({ ...data }: IVerifySoftwareTokenPostRequestST) =>
  getAuthenticationService().verifySoftwareToken({ ...data });

/**
 * Set the user's MFA.
 * @param {object} data <ISetUserMFAPreferencePostRequestST>
 * @returns {object} any
 */
const setUserMfaPreference = ({ ...data }: ISetUserMFAPreferencePostRequestST) =>
  getAuthenticationService().setUserMfaPreference({ ...data });

/**
 * Changes the password for a user.
 * @param {object} data <IChangePasswordPostRequestST>
 * @returns {object} any
 */
const changePassword = ({ ...data }: IChangePasswordPostRequestST) =>
  getAuthenticationService().changePassword({ ...data });

const authServices = {
  signIn,
  authChallenge,
  refreshToken,
  revokeToken,
  forgotPassword,
  confirmForgotPassword,
  resendCode,
  getUser,
  getSecretCode,
  verifySoftwareToken,
  changePassword,
  setUserMfaPreference,
};

export default authServices;
