import React from 'react';
import { Box } from '../Box';

interface CardGridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  columnCount?: number;
}

/**
 * CardGrid - creates a responsive grid of cards.
 * @summary Developers can specify a number of columns, all cards will stack vertically below MD breakpoint.
 */
const CardGrid: React.FC<CardGridProps> = ({ children, columnCount = 1, ...props }) => (
  <div {...props}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          md: 'minmax(0, 1fr)',
          lg: `repeat(${columnCount}, minmax(0, 1fr))`,
        },
        gap: 4,
      }}
    >
      {children}
    </Box>
  </div>
);

export default CardGrid;
