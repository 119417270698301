import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';

import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';
import { IFacilitySettingsST, IReportDeadlinesST } from 'codegen/facility_settings';
import {
  validRangeGtZero,
  validIfGtZero,
  validIfInRangeInclusive,
} from 'common/functions/otherFunctions';
import { downloadBase64FromJSON, loadFileFromDisk } from 'common/functions/fileFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { FACILITY_SETTINGS_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import BaseCard, { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import ExpansibleDragAndDropFile from 'components/common/ExpansibleDragAndDropFile/ExpansibleDragAndDropFile';
import Spinner from 'components/common/Spinner';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import BarcodeTypesAndLengths from './BarcodeTypesAndLengths';
import { EmailList } from './EmailList';
import SettingsCard from './SettingsCard';
import { SettingsCardContent } from './SettingsCardContent';
import '../admin.css';

import WarehouseServices from '../../../services/WarehouseServices';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { FacilityActionNames } from '../../../store/FacilityLevelStore/facilityLevelActions';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useSettingsStore } from '../../../store/Settings/settingsStore';

import { useRequestController } from '../../../hooks';

import '../facilityCardStyle.css';
import { ReportDeadlinesCard } from './ReportDeadlinesCard';
import { SettingsItem } from './types';

export const FacilitySettings = forwardRef((props: any, ref: any) => {
  const { facilityTimezone } = props;

  const { FACILITY_SETTINGS_CARDS_VISIBILITY } = useSettingsStore();

  const [, setActiveFacility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const [autoAbortDisabled, setAutoAbortDisabled] = useState(false);
  const [autoApprovalDisabled, setAutoApprovalDisabled] = useState(false);
  const [autoUpdateDisabled, setAutoUpdateDisabled] = useState(false);

  const [fileDetails, setFileDetails] = useState<File>({} as File);

  const [wmsApiHandlers, setWmsApiHandlers] = useState({} as any);
  const [wmsParsers, setWmsParsers] = useState({} as any);
  const [bcMatchLogics, setBcMatchLogics] = useState({} as any);
  const [issueLogicFilters, setIssueLogicFilters] = useState({} as any);

  const [facilitySettings, setFacilitySettings] = useState({} as IFacilitySettingsST);
  const [initialSettings, setInitialSettings] = useState({} as IFacilitySettingsST);

  const autoAbortRef = useRef<any>();
  const autoApprovalRef = useRef<any>();
  const autoUpdateRef = useRef<any>();

  const { dispatchFacilityLevel, asyncGetFlightDomains, stateFacilityLevel } =
    useFacilityLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { systemId } = useParams();
  const facilityName = localStorage.getItem('facilityName');

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const logPrefix = getLogPrefixForType('COMPONENT', 'FacilitySetting');
  const { requestController } = useRequestController(logPrefix);

  const [isFacilitySettingsLoaded, setIsFacilitySettingsLoaded] = useState(
    !isEmpty(stateFacilityLevel.facilitySettings),
  );

  // This use effect ensures that if the user refreshes the page while on the
  // sub page facility settings, the save button and download buttons are displayed after
  // the settings are available on the FE and stored in state.facilitySettings
  useEffect(() => {
    console.debug(logPrefix, 'useEffect', 'set facility settings loaded');
    setIsFacilitySettingsLoaded(() => !isEmpty(stateFacilityLevel.facilitySettings));
  }, [stateFacilityLevel.facilitySettings, logPrefix]);

  useEffect(
    /**
     * This useEffect loads the required settings from the back-end
     */
    () => {
      // Fetch names of API handlers used to import Client WMS data
      const wmsClientApiPromise = requestController.doRequest({
        request: WarehouseServices.getWmsClientApi,
        requestParams: [systemId],
        messageErrorFallback: 'The WMS Client API could not be fetched.',
        callbackSuccess: (r: { data: { wms_client_api_handler_names: string[] } }) => {
          const handlers = r.data.wms_client_api_handler_names.map((item: string) => ({
            label: item,
            value: item,
          }));

          handlers.push({
            label: 'Disabled',
            value: 'Disabled',
          });
          setWmsApiHandlers(handlers);
        },
      });

      // Fetch names of parsers used to import WMS data
      const wmsParsersPromise = requestController.doRequest({
        request: WarehouseServices.getWmsParsers,
        requestParams: [systemId],
        messageErrorFallback: 'The WMS Parsers could not be fetched.',
        callbackSuccess: (r: { data: { wms_parser_names: string[] } }) => {
          const parser = r.data.wms_parser_names.map((item: string) => ({
            label: item,
            value: item,
          }));
          parser.push({
            label: 'Disabled',
            value: 'Disabled',
          });
          setWmsParsers(parser);
        },
      });

      // Fetch names of barcode match logics
      const bcMatchLogicsPromise = requestController.doRequest({
        request: WarehouseServices.getBcMatchLogics,
        requestParams: [systemId],
        messageErrorFallback: 'The Bc Match Logics could not be fetched.',
        callbackSuccess: (r) => {
          const matchLogic = r.data.map((item: { name: string }) => ({
            label: item.name,
            value: item.name,
          }));
          matchLogic.push({ label: 'Default', value: 'Default' });
          setBcMatchLogics(matchLogic);
        },
      });

      // Fetch names of issue logic filters
      const issueLogicFiltersPromise = requestController.doRequest({
        request: WarehouseServices.getIssueLogicFilters,
        requestParams: [systemId],
        messageErrorFallback: 'The Issue Logic Filters could not be fetched.',
        callbackSuccess: (r) => {
          const filter = r.data.map((item: { name: string; description: string }) => ({
            label: item.name,
            value: item.name,
            description: item.description,
          }));
          setIssueLogicFilters(filter);
        },
      });

      Promise.all([
        wmsClientApiPromise,
        wmsParsersPromise,
        bcMatchLogicsPromise,
        issueLogicFiltersPromise,
      ]).then(() => {
        setIsReady(true);
      });
    },
    [
      requestController,
      setBcMatchLogics,
      setIssueLogicFilters,
      setWmsApiHandlers,
      setWmsParsers,
      systemId,
    ],
  );

  const validateInputs = useCallback(() => {
    const { min, max } = validRangeGtZero(
      facilitySettings.barcode_min_length,
      facilitySettings.barcode_max_length,
    );
    let validAbort = autoAbortDisabled
      ? ''
      : validIfGtZero(facilitySettings.auto_abort_period_multiplier, true);
    let validUpdate = autoUpdateDisabled
      ? ''
      : validIfGtZero(facilitySettings.auto_update_period_multiplier, true);
    const areDeadlinesDefined = facilitySettings.report_deadlines
      ? Object.values(facilitySettings.report_deadlines).filter((d) => !!d).length
      : false;
    if (areDeadlinesDefined) {
      if (autoAbortDisabled) {
        validAbort = 'this value is mandatory when deadline is set';
      }
      if (autoUpdateDisabled) {
        validUpdate = 'this value is mandatory when deadline is set';
      }
    }
    const validApproval = validIfGtZero(facilitySettings.auto_approval_period_multiplier);
    const validCorrectResults = validIfInRangeInclusive(
      facilitySettings.fraction_of_correct_results_to_be_reviewed,
      0,
      1,
    );
    const validExternalReviewNumAssignments = validIfInRangeInclusive(
      facilitySettings.external_review_num_assignments_per_review,
      0,
      9,
    );
    const validExternalReviewBatchSize = validIfGtZero(
      facilitySettings.external_review_batch_size_desired,
    );
    const validExternalReviewBatchWaitTime = validIfGtZero(
      facilitySettings.external_review_batch_max_wait_time_s,
    );
    const validMturkLifetime = validIfGtZero(facilitySettings.mturk_hit_lifetime_in_seconds);

    setErrors((prevState: any) => ({
      ...prevState,
      barcodeMax: max,
      barcodeMin: min,
      validAbort,
      validUpdate,
      validApproval,
      validCorrectResults,
      validExternalReviewNumAssignments,
      validExternalReviewBatchSize,
      validExternalReviewBatchWaitTime,
      validMturkLifetime,
    }));
  }, [
    autoAbortDisabled,
    autoUpdateDisabled,
    facilitySettings.auto_abort_period_multiplier,
    facilitySettings.auto_approval_period_multiplier,
    facilitySettings.auto_update_period_multiplier,
    facilitySettings.barcode_max_length,
    facilitySettings.barcode_min_length,
    facilitySettings.external_review_batch_max_wait_time_s,
    facilitySettings.external_review_batch_size_desired,
    facilitySettings.external_review_num_assignments_per_review,
    facilitySettings.fraction_of_correct_results_to_be_reviewed,
    facilitySettings.mturk_hit_lifetime_in_seconds,
    facilitySettings.report_deadlines,
  ]);

  const handleValuesChange = useCallback(
    (prop: string, value: any) => {
      setFacilitySettings({ ...facilitySettings, [prop]: value });
    },
    [facilitySettings],
  );

  const handleSettings = () => {
    const validInputs = Object.values(errors).every((err) => err === '');

    if (!validInputs) {
      enqueueSnackbar('Please validate all inputs', { variant: 'error' });
      return null;
    }

    const requestPayload = {
      ...facilitySettings,
      auto_abort_period_multiplier: !autoAbortDisabled ? Number(autoAbortRef.current.value) : null,
      auto_approval_period_multiplier: !autoApprovalDisabled
        ? Number(autoApprovalRef.current.value)
        : null,
      auto_update_period_multiplier: !autoUpdateDisabled
        ? Number(autoUpdateRef.current.value)
        : null,
      wms_parser_name:
        facilitySettings.wms_parser_name !== 'Disabled' ? facilitySettings.wms_parser_name : null,
      wms_client_api_handler_name:
        facilitySettings.wms_client_api_handler_name !== 'Disabled'
          ? facilitySettings.wms_client_api_handler_name
          : null,
      barcode_match_logic_name:
        facilitySettings.barcode_match_logic_name !== 'Default'
          ? facilitySettings.barcode_match_logic_name
          : null,
    };

    return requestController.doRequest({
      request: WarehouseServices.setFacilitySettings,
      requestParams: [systemId, requestPayload],
      callbackBeforeSend: () => {
        setIsLoading(true);
      },
      callbackSuccess: (r) => {
        LocalStore.dataToLocalStorage({ settingsData: r.data.settings });
        dispatchFacilityLevel({
          type: FacilityActionNames.SET_FACILITY_SETTINGS,
          payload: r.data.settings,
        });

        !stateFacilityLevel.flightDomainsLoaded &&
          asyncGetFlightDomains(requestController, systemId!);
      },
      messageSuccess: 'Settings updated',
      messageErrorFallback: 'Failed to update Settings',
      callbackFinally: () => {
        setIsLoading(false);
        loadData();
      },
    });
  };

  const populateUI = useCallback(
    (settings: IFacilitySettingsST) => {
      const {
        wms_parser_name,
        wms_client_api_handler_name,
        barcode_match_logic_name,
        auto_update_period_multiplier,
        auto_abort_period_multiplier,
        auto_approval_period_multiplier,
      } = settings;

      setInitialSettings({
        ...settings,
        wms_parser_name: wms_parser_name || 'Disabled',
        wms_client_api_handler_name: wms_client_api_handler_name || 'Disabled',
        barcode_match_logic_name: barcode_match_logic_name || 'Default',
      });
      setFacilitySettings({
        ...settings,
        wms_parser_name: wms_parser_name || 'Disabled',
        wms_client_api_handler_name: wms_client_api_handler_name || 'Disabled',
        barcode_match_logic_name: barcode_match_logic_name || 'Default',
      });
      LocalStore.dataToLocalStorage({ settingsData: settings });

      if (auto_update_period_multiplier === null) {
        setAutoUpdateDisabled(true);
      } else {
        setAutoUpdateDisabled(false);
      }
      if (auto_abort_period_multiplier === null) {
        setAutoAbortDisabled(true);
      } else {
        setAutoAbortDisabled(false);
      }
      if (auto_approval_period_multiplier === null) {
        setAutoApprovalDisabled(true);
      } else {
        setAutoApprovalDisabled(false);
      }

      dispatchFacilityLevel({
        type: FacilityActionNames.SET_FACILITY_SETTINGS,
        payload: settings,
      });
    },
    [dispatchFacilityLevel],
  );

  const { FACILITY_SETTINGS_BASE64_FILE_EXTENSION } = useSettingsStore();
  const downloadSettingsFile = useCallback(() => {
    console.debug(logPrefix, 'downloadSettingsFile invoked');
    // Set file name
    const fileName = !isEmpty(stateFacilityLevel.facilityData.name)
      ? stateFacilityLevel.facilityData.name
      : stateFacilityLevel.facilityData.store_id;
    const facilitySettingsTemp = stateFacilityLevel.facilitySettings;
    downloadBase64FromJSON(facilitySettingsTemp, fileName, FACILITY_SETTINGS_BASE64_FILE_EXTENSION);
  }, [
    FACILITY_SETTINGS_BASE64_FILE_EXTENSION,
    stateFacilityLevel.facilityData.name,
    stateFacilityLevel.facilityData.store_id,
    stateFacilityLevel.facilitySettings,
    logPrefix,
  ]);

  const loadData = useCallback(() => {
    // check if an active facility exists
    // and set facility settings
    requestController.doRequest({
      request: WarehouseServices.getFacilitySettings,
      requestParams: [systemId],
      callbackBeforeSend: () => setIsLoading(true),
      callbackSuccess: (r) => {
        if (isEmpty(r.data.settings)) {
          setActiveFacility(false);
        } else {
          setActiveFacility(true);
          populateUI(r.data.settings);
        }
      },
      messageErrorFallback: `Failed to fetch the Settings for the facility with id ${systemId}`,
      callbackFinally: () => setIsLoading(false),
    });
  }, [populateUI, requestController, systemId]);

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      disabled: isEmpty(fileDetails),
      label: 'Load settings',
      clickHandler: () => {
        loadFileFromDisk(fileDetails, populateUI);
        const msg = `Settings from ${fileDetails.name} are now loaded, don't forget to save them.`;
        enqueueSnackbar(msg, { variant: 'success' });
        setFileDetails({} as File);
      },
    },
  ];

  const loadSettingsFromFileData = {
    title: 'Load settings from file',
    subtitle: '',
    actionButtons,
  };

  const settingsOptions: { [key: string]: SettingsItem[] } = useMemo(
    () => ({
      ui: [
        {
          type: 'switch',
          label: 'Enable Beta Warehouse status page',
          testId: 'c-enable-experimental-features-input',
          description:
            'If enabled, Beta Warehouse status page is enabled for all the users of the facility.',
          value: facilitySettings.enable_experimental_features,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_experimental_features', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Show customer to clients',
          testId: 'c-show-customer-input',
          description:
            'If enabled, the customer information provided for each item is displayed on the location modal.',
          value: facilitySettings.show_customer,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_customer', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Show potential issues to clients',
          testId: 'c-show-potential-issue-to-clients',
          description: 'If enabled, potential issues are shown to the user.',
          value: facilitySettings.show_potential_issues,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_potential_issues', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Show Ground Control application',
          testId: 'c-show-ground-control-app',
          description: 'If enabled, Ground Control related pages are shown to the user.',
          value: facilitySettings.show_ground_control_app,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_ground_control_app', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Enable coexistence',
          testId: 'c-show-coexistence',
          description:
            'When enabled will show drone open-fly and controlled zones on client map and will also add a map to delivery allowing  the creation, editing and deleting of respective zones',
          value: facilitySettings.show_coexistence_map,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_coexistence_map', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Show scheduled lock',
          testId: 'c-show-scheduled-lock',
          description: 'If enabled, scheduled lock is shown.',
          value: facilitySettings.show_scheduled_lock,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_scheduled_lock', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Show analytics page',
          testId: 'c-show-superset-analytics-page',
          description: 'If enabled, analytics page is shown.',
          value: facilitySettings.enable_superset_integration,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_superset_integration', e.target.checked),
        },
      ],
      dualCheckOnUnlock: [
        {
          type: 'switch',
          label: 'Show Dual Check on System Unlock',
          testId: 'c-show-dual-check-on-unlock',
          description: 'If enabled, the dual check on system unlock will be shown.',
          value: facilitySettings.enable_dual_check_on_unlock,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_dual_check_on_unlock', e.target.checked),
        },
        {
          type: 'text',
          multiline: true,
          rows: 3,
          label: 'Disclaimer for the Dual Check on System unlock',
          width: 'larger',
          testId: 'c-dual-check-disclaimer',
          description: 'Disclaimer being shown when displaying the dual check on System Unlock.',
          value: facilitySettings.dual_check_on_unlock_disclaimer,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('dual_check_on_unlock_disclaimer', e.target.value),
        },
      ],
      backend: [
        {
          testId: 'c-task-request-results',
          type: 'switch',
          label: 'Task requests/results in CSFA mode',
          description:
            'If set to true, User Dashboard will stop communicating task result and request with control station and will switch to cloud services.',
          value: facilitySettings.enable_csfa_task_request_result,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_csfa_task_request_result', e.target.checked),
        },
        {
          testId: 'c-connect-navigation-backend',
          type: 'switch',
          label: 'Connect to Navigation Backend',
          description:
            'If ebabled, the User Dashboard connects with the navigation backend. If disabled, the User Dashboard connects to the simulator.',
          value: facilitySettings.connect_to_navigation_backend,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('connect_to_navigation_backend', e.target.checked),
        },
        {
          testId: 'c-connect-data-backend',
          type: 'switch',
          label: 'Connect to Data Backend',
          description:
            'If ebabled, the User Dashboard connects with the data backend. If disabled, the User Dashboard connects to the simulator.',
          value: facilitySettings.connect_to_data_backend,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('connect_to_data_backend', e.target.checked),
        },
        {
          testId: 'c-diagnostic-view-request',
          type: 'switch',
          label: 'Enable Diagnostic View',
          description:
            'If set to true, User Dashboard will request and display the diagnostic view images for verity users.',
          value: facilitySettings.enable_diagnostic_view_logic,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_diagnostic_view_logic', e.target.checked),
        },
        {
          testId: 'c-enable-coexistence-wms-integration',
          type: 'switch',
          label: 'Enable Coexistence WMS integration',
          description:
            'If enabled, the drone zones can be managed using the WMS integration in addition to manually',
          value: facilitySettings.enable_coexistence_wms_integration,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_coexistence_wms_integration', e.target.checked),
        },
      ],
      issues: [
        {
          type: 'switch',
          label: 'Allow client amend',
          testId: 'c-allow-client-amend',
          description: 'If enabled, the user can amend results collected by Verity.',
          value: facilitySettings.allow_user_amend,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('allow_user_amend', e.target.checked),
        },
        {
          type: 'select',
          label: 'Barcode match logic',
          selectOptions: bcMatchLogics,
          testId: 'c-barcode-match-logic',
          description: 'The name of the handler that decides if Verity and WMS barcodes match.',
          value: facilitySettings.barcode_match_logic_name,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('barcode_match_logic_name', e.target.value),
        },
        {
          type: 'multi-select',
          label: 'Issue logic filters',
          selectOptions: issueLogicFilters,
          testId: 'c-issue-logic-filter',
          description:
            'Names of handlers that modify the issue logics. Handlers will be applied in list order.',
          values: facilitySettings.issue_logic_filter_names,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('issue_logic_filter_names', e.target.value),
          clearAction: () => handleValuesChange('issue_logic_filter_names', []),
        },
      ],
      wmsIntegration: [
        {
          type: 'select',
          label: 'WMS Client API Handler name',
          selectOptions: wmsApiHandlers,
          testId: 'c-client-api-handler-name',
          description: 'The name of the API handler used to import client WMS data.',
          value: facilitySettings.wms_client_api_handler_name,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_client_api_handler_name', e.target.value),
        },
        {
          type: 'select',
          testId: 'c-select-wms-parser',
          label: 'WMS parser name',
          selectOptions: wmsParsers,
          description: 'The name of the parser used to import WMS data.',
          value: facilitySettings.wms_parser_name,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_parser_name', e.target.value),
        },
        {
          type: 'text',
          label: 'Slot Label Regex',
          width: 'medium',
          testId: 'c-slot-label-regex',
          description:
            'This regex is used to validate slot labels when importing WMS data. Note that it is case-sensitive.',
          value: facilitySettings.wms_slot_label_regex,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_slot_label_regex', e.target.value),
        },
        {
          type: 'text',
          label: 'Snapshot File Regex',
          width: 'medium',
          testId: 'c-snapshot-file-regex',
          description: `This regex is used to map the WMS File Name to the Snapshot WMS Upload Type.
                    The regex should be a pattern that matches the Snapshot file name used by the client.`,
          value: facilitySettings.wms_file_name_regex_snapshot,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_file_name_regex_snapshot', e.target.value),
        },
        {
          type: 'text',
          label: 'Stock Check File Regex',
          width: 'medium',
          testId: 'c-stock-check-file-regex',
          description: `This regex is used to map the WMS File Name to the Stock Check WMS Upload Type.
                    The regex should be a pattern that matches the Stock Check file name used by the client.`,
          value: facilitySettings.wms_file_name_regex_stock_check,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_file_name_regex_stock_check', e.target.value),
        },
        {
          type: 'text',
          label: 'Moved Locations File Regex',
          width: 'medium',
          testId: 'c-moved-locations-file-regex',
          description: `This regex is used to map the WMS File Name to the Moved Locations WMS Upload Type.
                    The regex should be a pattern that matches the Moved Locations file name used by the client.`,
          value: facilitySettings.wms_file_name_regex_moved_locations,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_file_name_regex_moved_locations', e.target.value),
        },
        {
          type: 'switch',
          testId: 'c-allow-wms-upload',
          label: 'Allow manual upload of WMS files',
          description:
            'If enabled, the user can manually upload WMS files (snapshots, moved locations and stock check).',
          value: facilitySettings.allow_manual_wms_file_upload,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('allow_manual_wms_file_upload', e.target.checked),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 1, step: '1' },
          label: 'Maximum number of WMS entries per location',
          testId: 'c-maximum-wms-entries-per-location',
          description: `The maximum number of rows in a WMS excerpt that may reference the same location.
                    If the excerpt contains more rows for a location, the location will be considered invalid.`,
          value: facilitySettings.wms_max_num_entries_per_location,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('wms_max_num_entries_per_location', Number(e.target.value)),
        },
      ],
      reportReviews: [
        {
          type: 'switch',
          label: 'Use reviewer barcodes only for internal reports',
          testId: 'c-use-reviewer-barcode-only-for-internal-reports',
          description: `If enabled, we do not unconditionally trust the reviewers.
                    The barcodes they enter might not be reliable. The system moves the barcode to the comment
                    field and set the state to 'NOT_EMPTY' instead.
                    We consider reviewer-entered barcodes reliable if they are equal to the barcodes expected
                    by the wms or the ones found by verity.`,
          value: facilitySettings.carefully_trust_reviewers_barcodes,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('carefully_trust_reviewers_barcodes', e.target.checked),
        },
        {
          type: 'switch',
          label: 'Auto approve verity status',
          testId: 'c-auto-approve-verity-status',
          description: `If enabled, verity status for locations with issues will be automatically approved
                    and will not require review. If 'External review enabled' is set to true, reviews will
                    still be generated, but will not affect the results.`,
          value: facilitySettings.auto_approve_verity_status,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('auto_approve_verity_status', e.target.checked),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 0.0, step: '0.01' },
          label: 'Fraction of correct results to be reviewed',
          testId: 'c-fraction-of-correct-results-to-be-review',
          description: `The fraction of verity status for locations without issues that will
                    require review`,
          errors: errors.validCorrectResults,
          value: facilitySettings.fraction_of_correct_results_to_be_reviewed,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange(
              'fraction_of_correct_results_to_be_reviewed',
              Number.parseFloat(e.target.value),
            ),
        },
      ],
      systemReset: [
        {
          type: 'switch',
          testId: 'c-enable-whs-reset-input',
          label: 'Enable Warehouse Status Reset',
          description:
            'If enabled, the functionality to reset the warehouse status is exposed to the user.',
          value: facilitySettings.enable_warehouse_status_reset,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_warehouse_status_reset', e.target.checked),
        },
        {
          type: 'button',
          testId: 'c-reset-system-button',
          text: 'Reset',
          disabled: !initialSettings.enable_warehouse_status_reset,
          label: 'Reset Warehouse Status Data',
          action: () =>
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.SYSTEM_RESET,
              payload: { facilityName },
              refreshData: { refreshData: () => loadData() },
            }),
        },
      ],
      externalReviews: [
        {
          type: 'switch',
          label: 'External review enabled',
          testId: 'c-external-review-enabled',
          description: `Specifies whether verity slot statuses with issues should be reviewed externally before
                    being available to the system. If 'Auto approve verity status' is also set to true, external
                    review will still be generated, but will not affect the results. The review data will be
                    available in the internal report/warehouse status exports.`,
          value: facilitySettings.external_review_enabled,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('external_review_enabled', e.target.checked),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 1, step: '1' },
          label: 'Number of assignments per review',
          testId: 'c-number-of-assignments-per-review',
          description: `Specifies the number of assignments to create for each review. For each assignment that
                    is rejected, a new assignment will be created (up to the maximum of 9).`,
          value: facilitySettings.external_review_num_assignments_per_review,
          errors: errors.validExternalReviewNumAssignments,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange(
              'external_review_num_assignments_per_review',
              Number(e.target.value),
            ),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 1, step: '1' },
          label: 'Desired batch size',
          testId: 'c-desired-batch-size',
          description: `Specifies the number of locations that should be part of a single review. If not enough
                    results are available to review within the specified wait time, the review will be submitted
                    with the available locations.`,
          value: facilitySettings.external_review_batch_size_desired,
          errors: errors.validExternalReviewBatchSize,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('external_review_batch_size_desired', Number(e.target.value)),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 1, step: '1' },
          label: 'Batch wait time (in seconds)',
          testId: 'c-batch-wait-time',
          description: `Specifies the time to wait in seconds to collect the desired batch size of locations to review as
                    part of a single review. Once the time expires, the review will be submitted with the available locations.`,
          value: facilitySettings.external_review_batch_max_wait_time_s,
          errors: errors.validExternalReviewBatchWaitTime,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('external_review_batch_max_wait_time_s', Number(e.target.value)),
        },
        {
          type: 'switch',
          label: 'Override Verity data',
          testId: 'c-override-verity-data',
          description: `Specifies whether to incorporate the reviewer amendments in the Verity data.
                    if not enabled, review data will still be available in internal report/warehouse status exports.`,
          value: facilitySettings.external_review_overrides_verity_data,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('external_review_overrides_verity_data', e.target.checked),
        },
        {
          type: 'select',
          label: 'Show Verity prior',
          selectOptions: [
            { value: 'NEVER', label: 'Never' },
            { value: 'NOT_FOR_CONTROL', label: 'Not for control' },
            { value: 'ALWAYS', label: 'Always' },
          ],
          testId: 'c-show-verity-prior',
          description: `Specifies whether Verity's interpretation of the images are shown to the external reviewers
                    as a prior they can simply accept. If set to 'Not for control', control questions
                    (where we know Verity data are correct) will not contain the prior, but other questions will.`,
          value: facilitySettings.external_review_show_prior,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('external_review_show_prior', e.target.value),
        },
        {
          type: 'number',
          width: 'small',
          inputProps: { min: 1, step: '1' },
          label: 'MTurk HIT lifetime (in seconds)',
          testId: 'c-mturk-hit-lifetime',
          description: `An amount of time, in seconds, after which the Amazon MTurk HIT is no longer available for reviewers
                    to accept. After the lifetime of the HIT elapses, the HIT no longer appears in HIT searches,
                    even if not all of the assignments for the HIT have been accepted.`,
          value: facilitySettings.mturk_hit_lifetime_in_seconds,
          errors: errors.validMturkLifetime,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('mturk_hit_lifetime_in_seconds', Number(e.target.value)),
        },
        {
          type: 'text',
          multiline: true,
          rows: 3,
          label: 'MTurk HIT type Id',
          width: 'larger',
          testId: 'c-mturk-hit-type-id',
          description: `Creating an Amazon MTurk HIT with a HIT Type allows one to be explicit about which HITs
                    ought to be the same type and is a best practice for customers creating large numbers of HITs.

                    The value can be obtained by a logged-in user by accessing
                    https://requestersandbox.mturk.com/create/projects
                    for the sandbox environment, and
                    https://requester.mturk.com/create/projects
                    for the production environment.

                    Clicking on any project name will reveal the corresponding
                    HITType ID, Layout ID and Layout Parameters.

                    The value changes whenever the HIT parameters change.`,
          value: facilitySettings.mturk_hit_type_id,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('mturk_hit_type_id', e.target.value),
        },
        {
          type: 'text',
          multiline: true,
          rows: 3,
          label: 'MTurk HIT layout Id',
          width: 'larger',
          testId: 'c-mturk-hit-layout-id',
          description: `The HIT Layout Id allows one to use a pre-existing HIT design with placeholder values for HIT creation.
                    https://docs.aws.amazon.com/AWSMechTurk/latest/AWSMturkAPI/ApiReference_HITLayoutArticle.html.


                    The value can be obtained by a logged-in user by accessing
                    https://requestersandbox.mturk.com/create/projects
                    for the sandbox environment, and
                    https://requester.mturk.com/create/projects
                    for the production environment.

                    Clicking on any project name will reveal the corresponding
                    HITType ID, Layout ID and Layout Parameters.

                    The value changes whenever the HIT parameters change.`,
          value: facilitySettings.mturk_hit_layout_id,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('mturk_hit_layout_id', e.target.value),
        },
      ],
      barcodes: [
        {
          type: 'number',
          testId: 'c-barcode-min-length-input',
          width: 'small',
          inputProps: { min: 1, max: facilitySettings.barcode_max_length, step: '1' },
          label: 'Barcode min length',
          description: 'The minimum length of a valid barcode.',
          value: facilitySettings.barcode_min_length,
          errors: errors.barcodeMin,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('barcode_min_length', Number(e.target.value)),
        },
        {
          type: 'number',
          testId: 'c-barcode-max-length-input',
          width: 'small',
          inputProps: { min: facilitySettings?.barcode_min_length ?? 1, step: '1' },
          label: 'Barcode max length',
          description: 'The maximum length of a valid barcode.',
          value: facilitySettings.barcode_max_length,
          errors: errors.barcodeMax,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('barcode_max_length', Number(e.target.value)),
        },
        {
          type: 'switch',
          label: 'Trim leading characters',
          testId: 'c-trim-leading-characters',
          description: `If enabled, leading characters are removed from the barcode value provided by Verity.
                    The system will remove as many as needed to have the barcode no longer than the closest smaller
                    or equal accepted length. For example, if you have 18 characters barcodes, set both min and max
                    to 18. If you have 11 and 18 characters barcodes, set min to 11, max to 18 and add
                    [12,13,14,15,16,17] to invalid lengths. This will result in the system accepting 11 and 18
                    characters for a barcode. With 11 and 18 characters accepted the system will trim as
                    follows: 19->18, 18->18, 17->11, ..., 12->11, 11->11`,
          value: facilitySettings.trim_leading_digits,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('trim_leading_digits', e.target.checked),
        },
        {
          type: 'switch',
          label: 'WMS Barcode sub-string match',
          testId: 'c-barcode_wms_substring_match',
          description: `If enabled, a WMS barcode is matched if it is contained in the barcode value provided
        by Verity (if disabled a full match is required)`,
          value: facilitySettings.barcode_wms_substring_match,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('barcode_wms_substring_match', e.target.checked),
        },
      ],
      reportDeadlines: [
        {
          type: 'switch',
          label: 'Show report specific deadlines',
          testId: 'c-show-report-specific-deadlines',
          description: `If enabled, we do show the option of setting report specific deadlines
          at the time of scheduling a report.`,
          value: facilitySettings.show_report_deadlines,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('show_report_deadlines', e.target.checked),
        },
      ],
      reportsActions: [
        {
          type: 'number with activation',
          width: 'medium',
          inputProps: { min: 1, step: '1' },
          label: 'Auto update (in minutes)',
          testId: 'c-auto-update',
          description: `If enabled, specifies when the automatic update should start.
                    The automatic update window starts N minutes before the start of the automatic abort and lasts
                    1 minute. If not set, the report is not automatically updated.`,
          disabled: autoUpdateDisabled,
          value: facilitySettings.auto_update_period_multiplier,
          errors: errors.validUpdate,
          ref: autoUpdateRef,
          onChange: () => {
            !autoUpdateDisabled
              ? setErrors((prevState: any) => ({ ...prevState, validUpdate: '' }))
              : validateInputs();
            setAutoUpdateDisabled(!autoUpdateDisabled);
          },
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('auto_update_period_multiplier', Number(e.target.value)),
        },
        {
          type: 'number with activation',
          width: 'medium',
          inputProps: { min: 1, step: '1' },
          label: 'Auto abort (in minutes)',
          testId: 'c-auto-abort',
          description: `If enabled, specifies when the automatic abort should start.
                    The automatic abort window starts N minutes before the start of the automatic approval and lasts
                    1 minute. If not set, the report is not automatically aborted.`,
          disabled: autoAbortDisabled,
          value: facilitySettings.auto_abort_period_multiplier,
          errors: errors.validAbort,
          ref: autoAbortRef,
          onChange: () => {
            !autoAbortDisabled
              ? setErrors((prevState: any) => ({ ...prevState, validAbort: '' }))
              : validateInputs();
            setAutoAbortDisabled(!autoAbortDisabled);
          },
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('auto_abort_period_multiplier', Number(e.target.value)),
        },
        {
          type: 'number with activation',
          width: 'medium',
          inputProps: { min: 1, step: '1' },
          label: 'Auto approval (in minutes)',
          testId: 'c-auto-approval',
          description: `If enabled, specifies when the automatic approval of the locations in review should start.
                    The automatic approval window starts N+1 minutes before the end of the Drone Flight Hours and lasts
                    1 minute. If not set, the report is not automatically approved.`,
          disabled: autoApprovalDisabled,
          value: facilitySettings.auto_approval_period_multiplier,
          errors: !autoApprovalDisabled && errors.validApproval,
          ref: autoApprovalRef,
          onChange: () => {
            !autoApprovalDisabled
              ? setErrors((prevState: any) => ({ ...prevState, validApproval: '' }))
              : validateInputs();
            setAutoApprovalDisabled(!autoApprovalDisabled);
          },
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('auto_approval_period_multiplier', Number(e.target.value)),
        },
      ],
      authentication: [
        {
          type: 'switch',
          label: 'Enable Multi-factor Authentication',
          testId: 'c-enable-multi-factor-authenticate',
          description: 'If not enabled, MFA is disabled for all the users of the facility.',
          value: facilitySettings.enable_mfa,
          action: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleValuesChange('enable_mfa', e.target.checked),
        },
      ],
    }),
    [
      autoAbortDisabled,
      autoApprovalDisabled,
      autoUpdateDisabled,
      bcMatchLogics,
      dispatchFacilityModals,
      errors.barcodeMax,
      errors.barcodeMin,
      errors.validAbort,
      errors.validApproval,
      errors.validCorrectResults,
      errors.validExternalReviewBatchSize,
      errors.validExternalReviewBatchWaitTime,
      errors.validExternalReviewNumAssignments,
      errors.validMturkLifetime,
      errors.validUpdate,
      facilityName,
      facilitySettings.allow_manual_wms_file_upload,
      facilitySettings.allow_user_amend,
      facilitySettings.auto_abort_period_multiplier,
      facilitySettings.auto_approval_period_multiplier,
      facilitySettings.auto_approve_verity_status,
      facilitySettings.auto_update_period_multiplier,
      facilitySettings.barcode_match_logic_name,
      facilitySettings.barcode_max_length,
      facilitySettings.barcode_min_length,
      facilitySettings.barcode_wms_substring_match,
      facilitySettings.carefully_trust_reviewers_barcodes,
      facilitySettings.connect_to_data_backend,
      facilitySettings.connect_to_navigation_backend,
      facilitySettings.dual_check_on_unlock_disclaimer,
      facilitySettings.enable_coexistence_wms_integration,
      facilitySettings.enable_csfa_task_request_result,
      facilitySettings.enable_diagnostic_view_logic,
      facilitySettings.enable_dual_check_on_unlock,
      facilitySettings.enable_experimental_features,
      facilitySettings.enable_mfa,
      facilitySettings.enable_superset_integration,
      facilitySettings.enable_warehouse_status_reset,
      facilitySettings.external_review_batch_max_wait_time_s,
      facilitySettings.external_review_batch_size_desired,
      facilitySettings.external_review_enabled,
      facilitySettings.external_review_num_assignments_per_review,
      facilitySettings.external_review_overrides_verity_data,
      facilitySettings.external_review_show_prior,
      facilitySettings.fraction_of_correct_results_to_be_reviewed,
      facilitySettings.issue_logic_filter_names,
      facilitySettings.mturk_hit_layout_id,
      facilitySettings.mturk_hit_lifetime_in_seconds,
      facilitySettings.mturk_hit_type_id,
      facilitySettings.show_customer,
      facilitySettings.show_ground_control_app,
      facilitySettings.show_coexistence_map,
      facilitySettings.show_potential_issues,
      facilitySettings.show_report_deadlines,
      facilitySettings.show_scheduled_lock,
      facilitySettings.trim_leading_digits,
      facilitySettings.wms_client_api_handler_name,
      facilitySettings.wms_file_name_regex_moved_locations,
      facilitySettings.wms_file_name_regex_snapshot,
      facilitySettings.wms_file_name_regex_stock_check,
      facilitySettings.wms_max_num_entries_per_location,
      facilitySettings.wms_parser_name,
      facilitySettings.wms_slot_label_regex,
      handleValuesChange,
      initialSettings.enable_warehouse_status_reset,
      issueLogicFilters,
      loadData,
      validateInputs,
      wmsApiHandlers,
      wmsParsers,
    ],
  );

  // Here we handle uncontrolled number inputs with activation
  // for "Report automatic actions", to remove entered value when
  // checkbox is unchecked
  useEffect(() => {
    if (autoUpdateRef.current && autoUpdateDisabled) {
      autoUpdateRef.current.value = '';
    }
    if (autoAbortRef.current && autoAbortDisabled) {
      autoAbortRef.current.value = '';
    }
    if (autoApprovalRef.current && autoApprovalDisabled) {
      autoApprovalRef.current.value = '';
    }
  }, [
    autoAbortDisabled,
    autoAbortRef,
    autoApprovalDisabled,
    autoApprovalRef,
    autoUpdateDisabled,
    autoUpdateRef,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    validateInputs();
  }, [facilitySettings, validateInputs]);

  // @ts-ignore
  useImperativeHandle(ref, () => ({
    handleSettings,
  }));

  if (!isReady) {
    return <Spinner label="Loading Settings ..." />;
  }
  return (
    <>
      <PageHeaderSection
        title="Administration"
        showMenuIcon={false}
        showLoadedSince={false}
        showDownloadBtn={
          isFacilitySettingsLoaded &&
          location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}`
        }
        onClickDownload={downloadSettingsFile}
        customBtn={
          isFacilitySettingsLoaded &&
          location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}` ? (
            <Button
              data-testid="c-save-settings-changes-btn"
              endIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              onClick={handleSettings}
            >
              Save changes
            </Button>
          ) : null
        }
      />
      <Container maxWidth="xl">
        <Grid direction="column" container className="c-page-content">
          <div id="facility-settings-page">
            <Grid spacing={3} alignItems="flex-start" container>
              <Grid md={6} sm={12} xs={12} item>
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.SYSTEM_RESET && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="System Reset"
                      isLoading={!!isLoading}
                      items={settingsOptions.systemReset}
                      testId="c-system-reset-settings-card"
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_DEADLINES && (
                  <Grid item>
                    <ReportDeadlinesCard
                      isLoading={isLoading}
                      timezone={facilityTimezone}
                      currentDeadlines={facilitySettings.report_deadlines!}
                      handleDeadlines={(reportDeadlines: IReportDeadlinesST) => {
                        setFacilitySettings({
                          ...facilitySettings,
                          report_deadlines: reportDeadlines,
                        });
                        validateInputs();
                      }}
                      content={
                        <>
                          <Divider />
                          <div
                            data-testid="c-report-automatic-actions-settings-card"
                            style={{ marginTop: '2em' }}
                            className="settings-card"
                          >
                            <SettingsCardContent
                              isLoading={isLoading}
                              items={settingsOptions.reportsActions || []}
                            />
                          </div>
                          <Divider />
                          <div
                            data-testid="c-report-report-specific-deadlines-settings-card"
                            style={{ marginTop: '2em' }}
                            className="settings-card"
                          >
                            <SettingsCardContent
                              isLoading={isLoading}
                              items={settingsOptions.reportDeadlines || []}
                            />
                          </div>
                        </>
                      }
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.WMS_INTEGRATION && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="WMS integration"
                      isLoading={isLoading}
                      items={settingsOptions.wmsIntegration}
                      testId="c-wms-integration-settings-card"
                      content={
                        <EmailList
                          title="WMS email sender whitelist"
                          tooltip="List of email addresses from which the WMS files are accepted.
                                   If the file is sent from an address that is not on this list, the email is rejected.
                                   Leave the list empty to disable the automatic import."
                          currentList={
                            facilitySettings.wms_email_senders
                              ? facilitySettings.wms_email_senders.split(',')
                              : []
                          }
                          confirmChanges={(e: string[]) => {
                            handleValuesChange('wms_email_senders', e.join(','));
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_REVIEWS && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="Report reviews"
                      isLoading={isLoading}
                      testId="c-report-reviews-settings-card"
                      items={settingsOptions.reportReviews}
                    />
                    <SettingsCard
                      title="External reviews"
                      isLoading={isLoading}
                      testId="c-external-reviews-settings-card"
                      items={settingsOptions.externalReviews}
                    />
                  </Grid>
                )}

                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_LOCATION && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="Configure Share feedback on a location"
                      testId="c-support-settings-card"
                      isLoading={isLoading}
                      content={
                        <EmailList
                          title="Email recipients"
                          tooltip="List of email addresses that receive feedback submissions."
                          currentList={
                            facilitySettings.support_email_recipients
                              ? facilitySettings.support_email_recipients.split(',')
                              : []
                          }
                          confirmChanges={(e: string[]) => {
                            handleValuesChange('support_email_recipients', e.join(','));
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.DUAL_CHECK_ON_UNLOCK && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      testId="c-backend-settings-card"
                      title="Dual Check on Unlock"
                      isLoading={isLoading}
                      items={settingsOptions.dualCheckOnUnlock}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid md={6} sm={12} xs={12} item>
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.LOAD_SETTINGS_FROM_FILE && (
                  <Grid md={12} sm={12} xs={12} item>
                    <BaseCard
                      cardFor="Settings"
                      showHeader={true}
                      showContent={true}
                      showActionButtons={true}
                      showHeaderDivider={true}
                      title="Load settings from file"
                      subtitle=""
                      actionButtons={loadSettingsFromFileData.actionButtons}
                    >
                      <Box key="1" px={2} display="flex" flexDirection="column">
                        <Box my={2}>
                          <ExpansibleDragAndDropFile
                            title="Load settings from file"
                            testId="c-load-settings-from-file"
                            dropzoneText="Drop settings file here..."
                            fileDetails={fileDetails}
                            isUploadDisabled={false}
                            onDrop={(e) => setFileDetails(e[0])}
                          />
                        </Box>
                      </Box>
                    </BaseCard>
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.UI && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="UI"
                      testId="c-settings-ui-card"
                      isLoading={isLoading}
                      items={settingsOptions.ui}
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.ISSUES && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="Issues"
                      isLoading={isLoading}
                      testId="c-issues-settings-card"
                      items={settingsOptions.issues}
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BARCODES && (
                  <Grid>
                    <SettingsCard
                      title="Barcodes"
                      isLoading={isLoading}
                      items={settingsOptions.barcodes}
                      testId="c-barcodes-settings-card"
                      content={
                        <BarcodeTypesAndLengths
                          subtitle="Barcode invalid lengths"
                          label="invalid lengths"
                          inputType="number"
                          inputProps={{ min: 0 }}
                          errors={errors.validBarcodeLength}
                          setErrors={(error: any) =>
                            setErrors({ ...errors, validBarcodeLength: error })
                          }
                          min={facilitySettings.barcode_min_length}
                          max={facilitySettings.barcode_max_length}
                          currentList={
                            facilitySettings.barcode_invalid_lengths
                              ? facilitySettings.barcode_invalid_lengths
                              : []
                          }
                          confirmChanges={(e: any) => {
                            handleValuesChange('barcode_invalid_lengths', e);
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BARCODE_TYPES && (
                  <Grid>
                    <SettingsCard
                      title="Barcodes types"
                      isLoading={isLoading}
                      testId="c-barcodes-types-settings-card"
                      content={
                        <BarcodeTypesAndLengths
                          label="type"
                          currentList={
                            facilitySettings.barcode_types ? facilitySettings.barcode_types : []
                          }
                          confirmChanges={(e: any) => {
                            setFacilitySettings({
                              ...facilitySettings,
                              barcode_types: e,
                            });
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.AUTHENTICATION && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="Authentication"
                      testId="c-authentication-settings-card"
                      isLoading={isLoading}
                      items={settingsOptions.authentication}
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BACKEND && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      testId="c-backend-settings-card"
                      title="Backend"
                      isLoading={isLoading}
                      items={settingsOptions.backend}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    </>
  );
});
