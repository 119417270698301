import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';

import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import isEmpty from 'lodash/isEmpty';
import { singleRequestHandler } from 'common/requestHelpers';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { Box } from 'components/common/Box';
import DeveloperServices from '../../services/DeveloperServices';
import GroundControlServices from '../../services/GroundControlServices';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { IFleeSimulationData } from '../../interfaces/groundControlInterfaces';
import { IFlightDomainData } from '../../store/GroundControl/IGroundControlStore';

import { DeveloperDrawerButton } from './DeveloperDrawerButton';
import { GroundControlActionNames } from '../../store/GroundControl/groundControlLevelActions';
import {
  initialFleetOverview,
  initialFlightDomain,
} from '../../store/GroundControl/groundControlInitialState';
import { WebSocketNotifications } from './WebSocketNotifications';
import { ScriptedFrontendSimulator } from './ScriptedFrontendSimulator';
import { OperationsSimulator } from './OperationsSimulator';
import { useClientModalsStore } from '../../store/Modals';
import { ClientModalsActionTypes } from '../../store/Modals/types';

const useStyles = makeStyles()(() => ({
  consoleOutput: {
    height: 300,
    overflow: 'auto',
    padding: 16,
    fontSize: 12,
  },
  drawer: {
    padding: 16,
  },
  clearBtn: {
    float: 'right',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  /**
   * Index is used as key for ids and labels
   */
  index: number;
  /**
   * Current active tab
   */
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      data-testid={`c-vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box width="100%" pl={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

const TabLink = (props: any) => (
  <Tab sx={{ width: '160px', paddingLeft: 0 }} {...props} {...a11yProps(0)} />
);

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

interface IIssueRateInputParams {
  stepRef: any;
  issueRate: any;
  setIssueRate: any;
}
const IssueRateInput = ({ stepRef, issueRate, setIssueRate }: IIssueRateInputParams) => {
  useEffect(() => {
    stepRef.current.step = '0.1';
    stepRef.current.focus();
  }, [stepRef]);

  return (
    <TextField
      data-testid="c-dev-tools-issue-rate"
      id="issueRate"
      style={{ width: '120px' }}
      type="number"
      size="small"
      inputRef={stepRef}
      InputProps={{
        inputProps: {
          max: 1,
          min: 0,
        },
      }}
      label="Issue rate"
      value={issueRate}
      onChange={(e) => setIssueRate(e.target.value)}
    />
  );
};

const FleetSimulationForm = ({
  systemId,
  flightDomain,
  flightDomainsList,
  errors,
  setErrors,
  addToOutput,
}: {
  systemId: string;
  errors: any;
  setErrors: any;
  flightDomain: IFlightDomainData;
  flightDomainsList: IFlightDomainData[];
  addToOutput: (value: string[]) => void;
}) => {
  const initialData = {
    duration: 30,
    period: 1,
    num_drones_flying: 10,
    optimal: false,
    sub_optimal_num_drones_no_wifi: 0,
  };
  const [flightDomainId, setFlightDomainId] = useState('');
  const [flightDomainData, setFlightDomainData] = useState<IFlightDomainData>();
  const [data, setData] = useState<IFleeSimulationData>(initialData);

  const optionsValue = flightDomainsList.map((flightDomain) => ({
    value: flightDomain.flight_domain_id,
    label: flightDomain.flight_domain_name,
  }));

  const handleDataChange = (name: string, value: number | boolean) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (flightDomainId) {
      const selectedFlightDomainData = flightDomainsList.find(
        (fd) => fd.flight_domain_id === flightDomainId,
      );
      setFlightDomainData(selectedFlightDomainData);
    }
  }, [flightDomainId, flightDomainsList]);

  const maxDrones = (flightDomainData: any) => {
    if (flightDomainData) {
      return (
        flightDomainData.fleet_status_summary.num_drones_flying +
        flightDomainData.fleet_status_summary.num_drones_on_charger +
        flightDomainData.fleet_status_summary.num_drones_landed_in_error
      );
    }
    return 0;
  };

  const handleErrors = useCallback(() => {
    if (flightDomainData) {
      if (data.num_drones_flying > maxDrones(flightDomainData!)) {
        setErrors((err: any) => ({
          ...err,
          nFlying: `Max drones flying are ${maxDrones(flightDomainData!)}`,
        }));
        return;
      }
      if (data.sub_optimal_num_drones_no_wifi > data.num_drones_flying) {
        setErrors((err: any) => ({
          ...err,
          nNoWifi: "Can't be higher then number of flying drones",
        }));
        return;
      }
      setErrors({});
    }
  }, [data.num_drones_flying, data.sub_optimal_num_drones_no_wifi, flightDomainData, setErrors]);

  useEffect(() => {
    handleErrors();
  }, [handleErrors]);

  return (
    <Box>
      <DeveloperDrawerButton
        testId="c-dev-tools-flight-domain-unlock"
        buttonLabel="Start flight simulation"
        disabled={!flightDomainId || !isEmpty(errors)}
        clickHandler={() => {
          addToOutput(['::: Flight simulation request has been sent!']);
          GroundControlServices.requestFlightSimulation(systemId, flightDomainId, data).then(() => {
            addToOutput(['::: Flight simulation has ben started!']);
          });
        }}
        content={
          <TooltipedIcon
            tooltip="Start flight simulation"
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        }
      />
      <Box width="100%" display="flex" flexDirection="column" my={2}>
        <CustomSelect
          id="flightDomainId"
          variant="outlined"
          name="Flight Domain"
          value={flightDomainId}
          valueOptions={optionsValue}
          size="small"
          style={{ marginBottom: '16px' }}
          onChange={(e) => {
            setFlightDomainId(e.target.value);
            setData(initialData);
          }}
          label="Select Flight Domain"
          error={false}
          errorMessage=""
          defaultValue=""
          disabled={isEmpty(flightDomainsList)}
          testId="flightDomainId"
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-duration"
          type="number"
          size="small"
          name="duration"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Duration"
          value={data.duration}
          disabled={!flightDomainId}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-period"
          type="number"
          size="small"
          name="period"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Period"
          value={data.period}
          disabled={!flightDomainId}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />
        <TextField
          data-testid="c-dev-tools-fleet-simulation-drones-flying"
          type="number"
          size="small"
          name="num_drones_flying"
          error={Boolean(errors.nFlying)}
          helperText={errors.nFlying}
          disabled={!flightDomainId}
          InputProps={{
            inputProps: {
              min: 0,
              max: maxDrones(flightDomainData!),
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Drones Flying"
          value={data.num_drones_flying}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />

        <TextField
          data-testid="c-dev-tools-fleet-simulation-drones-no-wifi"
          type="number"
          size="small"
          name="sub_optimal_num_drones_no_wifi"
          disabled={!flightDomainId}
          error={Boolean(errors.nNoWifi)}
          helperText={errors.nNoWifi}
          InputProps={{
            inputProps: {
              min: 0,
              max: maxDrones(flightDomainData!),
            },
          }}
          sx={{ marginBottom: 2 }}
          label="No Wifi Drones"
          value={data.sub_optimal_num_drones_no_wifi}
          onChange={(e) => handleDataChange(e.target.name, Number(e.target.value))}
        />

        <FormControlLabel
          control={
            <Switch
              data-testid="c-dev-tools-fleet-simulation-drones-optimal"
              name="optimal"
              value={data.optimal}
              onChange={(e) => handleDataChange(e.target.name, e.target.checked)}
            />
          }
          label="Optimal"
          labelPlacement="end"
        />
      </Box>
    </Box>
  );
};

const logPrefix = getLogPrefixForType('COMPONENT', 'DeveloperDrawer');

interface IDeveloperDrawerProps {
  visible: boolean;
  close: () => void;
}
export const DeveloperDrawer = (props: IDeveloperDrawerProps) => {
  const stepRef = useRef();

  const [output, setOutput] = useState([':: Well, hello you...']);
  const [issueRate, setIssueRate] = useState(0.2);
  const [nrLocationsToSimulate, setNrLocationsToSimulate] = useState('');
  const [value, setValue] = useState(0);
  const [errors, setErrors] = useState({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { flightDomain } = stateGroundControl;

  const navigate = useNavigate();

  const { classes } = useStyles();

  const systemId = useLocation().pathname.split('/')[1];

  const { enqueueSnackbar } = useSnackbar();

  const closeDrawer = useCallback(() => {
    props.close();
  }, [props]);

  const clearOutput = () => {
    setOutput(() => []);
  };

  const addToOutput = (messages: string[]) => {
    setOutput((prevState) => [...prevState, ...messages]);
  };

  const genericRequestSentCallback = (requestName: string) => {
    const messages = [`:: Request sent to ${requestName}`];
    addToOutput(messages);
  };

  const genericErrorCallback = (errorMsg: string) => {
    const messages = [errorMsg];
    addToOutput(messages);
  };

  const isFacilityDataAvailable = useCallback(() => {
    console.debug(
      logPrefix,
      `check state.facilityData, empty: ${isEmpty(stateFacilityLevel.facilityData)}`,
    );
    return !isEmpty(stateFacilityLevel.facilityData);
  }, [stateFacilityLevel.facilityData]);

  const handleRunTaskExecutorClick = () => {
    console.debug(logPrefix, 'handleRunTaskExecutorClick');
    singleRequestHandler({
      request: DeveloperServices.postTaskExecutor,
      requestParams: [systemId, issueRate, nrLocationsToSimulate],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => genericRequestSentCallback('Task executor'),
      callbackSuccess: postTaskExecutorSuccessCallback,
      messageSuccess: 'Task executor invoked successfully',
      callbackError: (e) => {
        genericErrorCallback(':: :: Error invoking task executor');
      },
    });
  };

  const postTaskExecutorSuccessCallback = (reply: { data: any }) => {
    const messages = [
      ':: :: Note: you have to wait at most 1 min. for the task executor to be able to poll pending requests.',
      `:: :: ${JSON.stringify(reply.data, null, ' ')}`,
    ];
    addToOutput(messages);
  };

  const handleCSUploadImagesClick = () => {
    console.debug(logPrefix, 'handleCSUploadImagesClick');
    singleRequestHandler({
      request: DeveloperServices.postCSUploadImages,
      requestParams: [systemId],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => genericRequestSentCallback('CS upload images'),
      callbackSuccess: postCSUploadImagesSuccessCallback,
      messageSuccess: 'Simulated image uploader invoked successfully',
      callbackError: (e) => {
        genericErrorCallback(':: :: Error invoking simulated image uploader');
      },
    });
  };

  const postCSUploadImagesSuccessCallback = (reply: { data: any }) => {
    const messages = [`:: :: ${JSON.stringify(reply.data, null, ' ')}`];
    addToOutput(messages);
  };

  /**
   * Get duration of the tokens
   * @returns accessTokenDuration and refreshTokenDuration in seconds
   */
  const getTokensDuration = () => {
    const authData = LocalStore.getAuthData();
    const accessTokenDurationInMinutes = authData?.user_pool_description.access_token_validity;
    const accessTokenDurationInSeconds = accessTokenDurationInMinutes! * 60;
    const refreshTokenDurationInMinutes = authData?.user_pool_description.refresh_token_validity;
    const refreshTokenDurationInSeconds = refreshTokenDurationInMinutes! * 60;

    // For sake of safety we reduce original tokens duration by 60 seconds
    const accessTokenDuration = accessTokenDurationInSeconds - 60;

    return { accessTokenDuration, refreshTokenDuration: refreshTokenDurationInSeconds };
  };

  const expireAccessAndIdTokens = (secondsUntilTokenIsInvalid = 0) => {
    console.debug(logPrefix, `expireAccessAndIdTokens, seconds: ${secondsUntilTokenIsInvalid}`);

    const { accessTokenDuration } = getTokensDuration();
    const currentDatetime = moment.utc().format(DATETIME_FORMAT);

    // Set token expiration date reduced by {secondsUntilTokenIsInvalid}
    // amount of seconds until expiration
    const updatedAccessAndIdTokensReceivedDate = moment(currentDatetime)
      .subtract(accessTokenDuration - secondsUntilTokenIsInvalid, 's')
      .format(DATETIME_FORMAT);

    LocalStore.setAccessAndIdTokenReceivedTime(updatedAccessAndIdTokensReceivedDate);
  };

  const expireRefreshToken = (secondsUntilTokenIsInvalid = 0) => {
    console.debug(logPrefix, `expireRefreshToken, seconds: ${secondsUntilTokenIsInvalid}`);

    const { refreshTokenDuration } = getTokensDuration();
    const currentDatetime = moment.utc().format(DATETIME_FORMAT);

    // Set token expiration date reduced by {secondsUntilTokenIsInvalid}
    // amount of seconds until expiration
    const updatedRefreshTokenReceivedDate = moment(currentDatetime)
      .subtract(refreshTokenDuration - secondsUntilTokenIsInvalid, 's')
      .format(DATETIME_FORMAT);

    LocalStore.setRefreshTokenReceivedTime(updatedRefreshTokenReceivedDate);
  };

  const testExpireRefreshToken = () => {
    const lp = getLogPrefixForType('FUNCTION', 'testExpireRefreshToken', logPrefix);
    console.debug(
      lp,
      'Changed local storage refresh token expiration date, refresh the page. The sign in modal will appear within a five seconds.',
    );
    expireRefreshToken(5);
    navigate(0);
    closeDrawer();
  };

  const testExpireAccessAndIdToken = () => {
    const lp = getLogPrefixForType('FUNCTION', 'testExpireAccessAndIdToken', logPrefix);
    console.debug(
      lp,
      'Changed local storage access and id tokens expiration date. The refresh of the token will be intercepted in the next api call.',
    );
    expireAccessAndIdTokens(2);
    closeDrawer();
  };

  const testTokenExpirationWithDataLoaded = () => {
    console.debug(logPrefix, 'testTokenExpirationWithDataLoaded');
    closeDrawer();
    expireAccessAndIdTokens(5);
    navigate(0);
  };

  const testTokenExpirationWithoutDataLoaded = () => {
    console.debug(logPrefix, 'testTokenExpirationWithoutDataLoaded');
    expireAccessAndIdTokens(0);
    expireRefreshToken(0);
    closeDrawer();
    navigate(0);
  };

  const testOpenSignInModal = () => {
    closeDrawer();

    setTimeout(() => {
      dispatchClientModals({
        type: ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL,
        payload: { open: true },
      });
    }, 3000);
  };

  const initiateNoDataAvailable = () => {
    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
      payload: initialFlightDomain,
    });
    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLEET_OVERVIEW,
      payload: initialFleetOverview,
    });
  };

  return (
    <SwipeableDrawer
      data-testid="c-dev-tools"
      anchor="top"
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Escape') {
          props.close();
        }
      }}
      open={Boolean(props.visible)}
      onClose={() => closeDrawer()}
      onOpen={() => closeDrawer()}
    >
      <Box mt={5} className={classes.drawer}>
        <h3 style={{ marginBottom: '0px' }}>Developer box</h3>
        <Grid alignItems="stretch" spacing={3} container>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Box mt={2} sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                sx={{
                  borderRight: 1,
                  borderColor: 'divider',
                  overflow: 'visible',
                }}
              >
                <TabLink
                  data-testid="c-tab-link-task-executor"
                  label="Task Executor"
                  {...a11yProps(0)}
                />
                {/*
                  TODO::: https://verity-ag.atlassian.net/browse/UD-3403
                */}
                <TabLink
                  data-testid="c-tab-link-tokens"
                  label="Tokens Expiration"
                  {...a11yProps(1)}
                />
                <TabLink
                  data-testid="c-tab-link-ground-control"
                  label="Ground Control"
                  {...a11yProps(2)}
                />
                <TabLink data-testid="c-tab-link-other" label="Other" {...a11yProps(3)} />
                <TabLink
                  data-testid="c-tab-link-web-socket-notifications"
                  label="Web socket notifications"
                  {...a11yProps(4)}
                />
                <TabLink
                  data-testid="c-tab-link-scripted-frontend-simulator"
                  label="Scripted frontend simulator"
                  {...a11yProps(5)}
                />
                <TabLink
                  data-testid="c-tab-link-operations-simulator"
                  label="Operations simulator"
                  {...a11yProps(6)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                {isFacilityDataAvailable() && (
                  <>
                    <DeveloperDrawerButton
                      testId="c-dev-tools-task-executor"
                      buttonLabel="Run task executor"
                      clickHandler={handleRunTaskExecutorClick}
                      content={
                        <>
                          <TextField
                            data-testid="c-dev-tools-nr-locations-to-simulate"
                            type="number"
                            size="small"
                            name="nrLocationsToSimulate"
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: 10,
                              },
                            }}
                            sx={{ marginBottom: 2, width: '335px' }}
                            label="# LocationsToSimulate"
                            value={nrLocationsToSimulate}
                            onChange={(e) =>
                              setNrLocationsToSimulate(e.target.value === '0' ? '' : e.target.value)
                            }
                          />
                          <IssueRateInput
                            stepRef={stepRef}
                            issueRate={issueRate}
                            setIssueRate={setIssueRate}
                          />
                        </>
                      }
                    />
                    <DeveloperDrawerButton
                      testId="c-dev-tools-upload-images"
                      buttonLabel="Run CS upload images"
                      clickHandler={handleCSUploadImagesClick}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <DeveloperDrawerButton
                  testId="c-dev-tools-token-expiration-access"
                  buttonLabel="Expire Access Token"
                  clickHandler={testExpireAccessAndIdToken}
                  content={
                    <TooltipedIcon
                      tooltip="Changed local storage access and id tokens receiving date.The refresh of the token will be triggered at the next api call."
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />
                <DeveloperDrawerButton
                  testId="c-dev-tools-token-expiration-refresh"
                  buttonLabel="Expire Refresh Token"
                  clickHandler={testExpireRefreshToken}
                  content={
                    <TooltipedIcon
                      tooltip="Changes local storage refresh token receiving date. The sign in modal will appear in 3 seconds"
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />

                <DeveloperDrawerButton
                  testId="c-dev-tools-token-expiration-auto-refresh"
                  buttonLabel="Test access token auto refresh while data is loaded"
                  clickHandler={testTokenExpirationWithDataLoaded}
                  content={
                    <TooltipedIcon
                      tooltip="Changes access tokens received date and after 5 seconds, refresh token request should be triggered."
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />
                <DeveloperDrawerButton
                  testId="c-dev-tools-token-expiration-without-data"
                  buttonLabel="Test tokens expiration while data is NOT loaded"
                  clickHandler={testTokenExpirationWithoutDataLoaded}
                  content={
                    <TooltipedIcon
                      tooltip="Changes local storage tokens expiration dates and reloads the page (with no data loaded). Soon after the page is reloaded (~10s) the sign in modal will appear."
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />
                <DeveloperDrawerButton
                  testId="c-dev-tools-open-sign-in-modal"
                  buttonLabel="Open the sign in modal"
                  clickHandler={testOpenSignInModal}
                  content={
                    <TooltipedIcon
                      tooltip="Makes the Sign in modal appear after a few seconds."
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <FleetSimulationForm
                  systemId={systemId!}
                  flightDomain={flightDomain}
                  errors={errors}
                  setErrors={setErrors}
                  flightDomainsList={stateFacilityLevel.flightDomains}
                  addToOutput={addToOutput}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <DeveloperDrawerButton
                  testId="c-dev-tools-initiate-no-data"
                  buttonLabel="Initiate No Data Available"
                  clickHandler={initiateNoDataAvailable}
                  content={
                    <TooltipedIcon
                      tooltip="Initiate state with no data"
                      icon={<InfoOutlinedIcon fontSize="small" />}
                    />
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <WebSocketNotifications />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ScriptedFrontendSimulator addToOutput={addToOutput} systemId={systemId!} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <OperationsSimulator addToOutput={addToOutput} systemId={systemId!} />
              </TabPanel>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h5> Output: </h5>
              <Button
                size="small"
                variant="outlined"
                className={classes.clearBtn}
                onClick={() => clearOutput()}
              >
                Clear
              </Button>
            </Box>
            <Paper className={classes.consoleOutput}>
              {output.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={index}> {item} </p>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};
