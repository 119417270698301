import { TABLE_COLUMN_OPTIONS } from 'common/settings';

export type FleetOverviewTableRow = {
  batteryLevel: string;
  batteryState: string;
  chargerId: string;
  chargerPosition: string;
  droneId: string;
  droneState: string;
  wifiStatus: string;
};

export type FleetOverviewColumn = {
  id: string;
  searchable: boolean;
  show: boolean;
  label: string;
  type: string;
  align: string;
  style?: Function;
};

const greyOutOfflineDrones = (row: FleetOverviewTableRow) =>
  row.wifiStatus === 'Offline' ? { color: 'lightGray' } : {};

export const FLEET_OVERVIEW_COLUMNS = (settingsData: any): FleetOverviewColumn[] => [
  {
    id: 'droneId',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.droneId.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.droneId.show,
    label: 'Drone ID',
    type: 'string',
    align: 'left',
  },
  {
    id: 'chargerId',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.chargerId.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.chargerId.show,
    label: 'Charger ID',
    type: 'string',
    align: 'left',
  },
  {
    id: 'chargerPosition',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.chargerPosition.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.chargerPosition.show,
    label: 'Charger Position',
    type: 'string',
    align: 'left',
  },
  {
    id: 'batteryLevel',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.batteryLevel.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.batteryLevel.show,
    label: 'Battery Level',
    type: 'string',
    align: 'center',
    style: greyOutOfflineDrones,
  },
  {
    id: 'batteryState',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.batteryState.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.batteryState.show,
    label: 'Battery State',
    type: 'string',
    align: 'left',
    style: greyOutOfflineDrones,
  },
  {
    id: 'wifiStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.wifiStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.wifiStatus.show,
    label: 'WiFi Status',
    type: 'string',
    align: 'center',
    style: (row: FleetOverviewTableRow) => ({
      color: row.wifiStatus === 'Online' ? 'green' : 'red',
    }),
  },
  {
    id: 'droneState',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.droneState.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).FLEET_OVERVIEW.droneState.show,
    label: 'Drone State',
    type: 'string',
    align: 'center',
    style: (row: FleetOverviewTableRow) => {
      // highlight the field in red if there is an error
      // but greys it out (regardless of the state) if the
      // drone is not online
      let style = { color: 'green' };
      if (row.wifiStatus === 'Offline') {
        style = { color: 'lightGray' };
      } else if (row.droneState === 'Error') {
        style = { color: 'red' };
      }
      return style;
    },
  },
];
