import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { CANVAS_HEIGHT } from 'shared/map/defaults/map-canvas.defaults';

export const useStyles = makeStyles()((theme: Theme) => ({
  mapWrapper: {
    display: 'grid',
    gridTemplateAreas: `
      'controlBarMap'
      'map'
      'mapSideMenu'
    `,
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content',
    gap: theme.spacing(2, 3),

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'minmax(0, 1fr) 360px',
      gridTemplateAreas: `
        'map mapSideMenu'
      `,
    },
  },
  mapWrapperCoexistence: {
    [theme.breakpoints.up('lg')]: {
      gridTemplateAreas: `
        'controlBarMap controlBarSideMenu'
        'map mapSideMenu'
      `,
    },
  },
  controlBarMap: {
    gridArea: 'controlBarMap',
  },
  map: {
    gridArea: 'map',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
  },
  legend: {
    position: 'absolute',
    insetInlineStart: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
  },
  details: {
    position: 'absolute',
    insetInlineEnd: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
    insetBlockEnd: theme.spacing(1),
    width: 270,
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
  },
  sidemenu: {
    gridArea: 'mapSideMenu',
    height: CANVAS_HEIGHT,
  },
  sideMenuCoexistence: {
    border: '1px solid #e7e7e7',
    borderRadius: theme.spacing(0.5),
  },
}));
