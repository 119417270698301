import { useNavigate } from 'react-router-dom';
import { IProcessedFile } from 'common/functions/otherFunctions';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import BaseCard, { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem from 'components/BaseCard/BaseCardSimpleItem';

interface IAutomaticUploadsCardProps {
  systemId: string;
  latestWMSSnapshotFile?: IProcessedFile;
  latestStockCheckFile?: IProcessedFile;
  latestMovedLocationsFile?: IProcessedFile;
  wmsSnapshotFilesTab: number;
}

export const AutomaticUploadsCard = (props: IAutomaticUploadsCardProps) => {
  const {
    systemId,
    latestWMSSnapshotFile,
    latestStockCheckFile,
    wmsSnapshotFilesTab,
    latestMovedLocationsFile,
  } = props;

  const navigate = useNavigate();

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      label: 'See all uploaded files',
      clickHandler: () => {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS}`, {
          state: { activeTab: wmsSnapshotFilesTab },
        });
      },
    },
  ];

  const automaticUploads = {
    title: 'Automatic uploads',
    subtitle: 'Latest automatic uploads.',
    actionButtons,
    sections: [
      {
        title: 'WMS snapshot:',
        visible: true,
        file: {
          name: latestWMSSnapshotFile?.clientFileName,
          url: latestWMSSnapshotFile?.fileURL,
        },
        sectionItems: [
          {
            label: 'Uploaded at:',
            value: latestWMSSnapshotFile?.uploadedAt,
          },
          {
            label: 'Most recent location WMS time:',
            value: latestWMSSnapshotFile?.lastEntryUpdate,
          },
          {
            label: 'Total number of locations:',
            value: latestWMSSnapshotFile?.totalLocations,
          },
          {
            label: 'Updated locations:',
            value: latestWMSSnapshotFile?.updatedLocations,
          },
        ],
      },
      {
        title: 'Stock-check:',
        visible: true,
        file: {
          name: latestStockCheckFile?.clientFileName,
          url: latestStockCheckFile?.fileURL,
        },
        sectionItems: [
          {
            label: 'Uploaded at:',
            value: latestStockCheckFile?.uploadedAt,
          },
        ],
      },
      {
        title: 'Moved locations:',
        visible: true,
        file: {
          name: latestMovedLocationsFile?.clientFileName,
          url: latestMovedLocationsFile?.fileURL,
        },
        sectionItems: [
          {
            label: 'Uploaded at:',
            value: latestMovedLocationsFile?.uploadedAt,
          },
        ],
      },
    ],
  };

  return (
    <BaseCard
      cardFor="automatic uploads"
      dataTestId="c-automatic-uploads-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      title={automaticUploads.title}
      subtitle={automaticUploads.subtitle}
      actionButtons={automaticUploads.actionButtons}
      sx={{ order: 6 }}
    >
      {automaticUploads.sections &&
        automaticUploads.sections
          .filter((section) => section.visible)
          .map((section, i) => (
            <BaseCardSimpleItem
              key={section.title}
              title={section.title}
              file={section.file}
              sectionItems={section.sectionItems}
            />
          ))}
    </BaseCard>
  );
};
