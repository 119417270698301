import React from 'react';

// material-ui core
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

// material-ui icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// components
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { SettingsItem } from './types';

interface ISettingsListItemProps {
  classes: { cardContentList?: string };
  item: SettingsItem;
  children?: any;
  testId: string;
}

const SettingsListItem = (props: ISettingsListItemProps) => {
  const { classes, item, children, testId } = props;

  return (
    <List
      className={classes.cardContentList}
      component="ul"
      data-testid={`settings-item-container-${testId}`}
    >
      <Box className="v-flex-center" px={2}>
        <Box className="v-flex-center">
          <Typography variant="subtitle1" color="textSecondary" component="p">
            {item.label}
          </Typography>
          {item.description && (
            <TooltipedIcon
              tooltip={item.description}
              icon={<InfoOutlinedIcon fontSize="small" />}
            />
          )}
        </Box>
        <Box className="v-flex-center" data-testid={`settings-item-content-${testId}`}>
          {children}
        </Box>
      </Box>
    </List>
  );
};

export default SettingsListItem;
