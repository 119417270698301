import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { DroneZones, DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { ControlledZoneAPI } from '../../api/controlled-zone';

export const DeleteDroneZoneModal = ({
  droneZone,
  onClose,
  onDeleted,
}: {
  droneZone: DroneZones;
  onClose: () => void;
  onDeleted: () => void;
}) => {
  const { systemId = '', flightDomainId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const message = (
    <>
      <Typography component="span">
        Are you sure you would like to delete the following drone zone?
      </Typography>
      <br />
      <Typography component="span" display="flex" justifyContent="center" mt={2} fontWeight="bold">
        {droneZone.name}
      </Typography>
    </>
  );

  const requestDroneZoneDeletion = async () => {
    let response;

    if (droneZone.type === DroneZoneTypes.controlledZone) {
      try {
        response = await ControlledZoneAPI.deleteControlledZone(
          systemId,
          flightDomainId,
          droneZone.id,
        );
      } catch (error) {
        const err = error as AxiosError<Error, Error>;
        const message =
          err?.response?.data?.message ||
          'Something went wrong when deleting drone "controlled zone"';

        enqueueSnackbar(message, { variant: 'error' });
      }
    }

    if (response) {
      onDeleted();
    }

    onClose();
  };

  return (
    <ModalConfirm
      opened
      title="Delete No-fly zone"
      message={message}
      handleClose={onClose}
      onConfirm={requestDroneZoneDeletion}
      confirmLabel="Delete"
      cancelLabel="Cancel"
    />
  );
};
