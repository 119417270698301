import { ChangeEvent, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import { Box } from 'components/common/Box';
import { ISlotSettingsSTExclusionStatusEnum } from 'codegen/warehouse_status';

type LocationsModalProps = {
  onClose: () => void;
  onSubmit: (reason: string, exclusionStatus: ISlotSettingsSTExclusionStatusEnum) => void;
  numOfLocations: number;
  exclusionState: {
    action: string;
    placeholder: string;
    exclusionStatus: ISlotSettingsSTExclusionStatusEnum;
  };
};

/**
 * Locations modal props
 * @param props LocationsModalProps
 * @returns JSX.Element
 */
export const LocationsModal = ({
  onClose,
  onSubmit,
  numOfLocations,
  exclusionState,
}: LocationsModalProps) => {
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    onSubmit(reason, exclusionState.exclusionStatus as ISlotSettingsSTExclusionStatusEnum);
    onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setReason(e.target.value);

  const actionButtons = (
    <Box width="100%" display="flex">
      <Button variant="outlined" onClick={onClose} fullWidth sx={{ mr: 1 }}>
        Cancel
      </Button>
      <Button onClick={handleSubmit} variant="contained" fullWidth>
        {exclusionState.action} locations
      </Button>
    </Box>
  );

  const titleData = <Box p={2}>{exclusionState.action}</Box>;
  const locationsText =
    numOfLocations === 1 ? 'location has been selected' : 'locations have been selected';
  return (
    <ModalBase
      testId="c-location-management-modal"
      opened
      handleClose={onClose}
      title={titleData}
      closeButton
      actionButtons={actionButtons}
    >
      <Typography mb={2}>
        <strong>{numOfLocations}</strong> {locationsText}. Specify the reason for
        {` ${exclusionState.placeholder}`} and click on the button below.
      </Typography>
      <TextField
        label={`Reason for ${exclusionState.placeholder} (optional)`}
        variant="standard"
        fullWidth
        onChange={handleChange}
      />
    </ModalBase>
  );
};
