import { Button, CardActions, CardContent, Grid, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { baseCardStyle } from 'components/BaseCard/styles';
import { Box } from 'components/common/Box';
import { ActionsAlert } from 'components/common/ActionsAlert';
import { LocalStore } from 'common/functions/storageFunctions';
import { SCHEDULE_LOCK_STATES } from '../ScheduleLock';
import { useSetScheduleLock } from '../Functions/SetScheduleLock';
import { ScheduleLockDateTimePicker } from './ScheduleLockDateTimePicker';
import { BackDropLoader } from '../BackDropLoader';

interface IScheduleLockCreationProps {
  onClick: (param: string) => void;
}

/**
 * Schedule lock creation
 * @param param0 IScheduleLockCreationProps params
 * @returns component
 */
export const ScheduleLockCreation = ({ onClick }: IScheduleLockCreationProps) => {
  const { classes: cardStyles } = baseCardStyle();
  const timezone = LocalStore.getTimezone();

  const { setScheduleLock } = useSetScheduleLock();

  const [lockDateTime, setLockDateTime] = useState<Moment | null>(null);
  const [error, setError] = useState<string | null>('');
  const [errorMessage, setErrorResponse] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () =>
    setScheduleLock({
      scheduleLock: { lock_at: moment(lockDateTime).tz(timezone).format(DATETIME_FORMAT) },
      setLoading: setIsLoading,
      setError: setErrorResponse,
    });

  return (
    <>
      <BackDropLoader isVisible={isLoading} />
      <CardContent
        className={cardStyles.cardContentSection}
        data-testid="c-schedule-lock-card-content"
      >
        <Grid
          container
          mt={1}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Grid item xs={12} display="flex" mx={2} flexDirection="column" justifyContent="center">
            <Box mb={2}>
              <ActionsAlert
                isActive={!!errorMessage}
                message={errorMessage}
                handleClose={() => setErrorResponse('')}
                status="warning"
                handleClick={handleSubmit}
                handleClickLabel="Retry"
              />
            </Box>

            <ScheduleLockDateTimePicker
              onChange={setLockDateTime}
              onError={setError}
              error={error}
              lockDateTime={lockDateTime}
            />
            <Typography mt={2} color="textSecondary">
              Drones will stop flying at the time of the scheduled lock.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box p={1} width="100%" display="flex" justifyContent="center" alignItems="center">
          <Button
            sx={{ mr: 1 }}
            data-testid="c-add-schedule-lock-button"
            variant="outlined"
            color="primary"
            size="medium"
            fullWidth
            onClick={() => onClick(SCHEDULE_LOCK_STATES.empty)}
          >
            CANCEL
          </Button>
          <Button
            data-testid="c-add-schedule-lock-button"
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            disabled={!lockDateTime || !!error}
            onClick={handleSubmit}
          >
            SET
          </Button>
        </Box>
      </CardActions>
    </>
  );
};
