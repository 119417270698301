// libraries
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

// material-ui core
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// variables, functions, configurations
import { signIn } from 'common/functions/userFunctions';
import { CLIENT_PAGES_URLS } from 'common/pages';
import { TokenManager } from 'common/tokenManager';

// hooks

// styles

// components
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import Copyright from 'components/common/Copyright';
import sharedStyles from './styles';
import { useRequestController } from '../../hooks';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { SignInForm } from './SignInForm';

const tokenManager = TokenManager.getInstance();

const SignIn = () => {
  const { classes } = sharedStyles();

  // state variables
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => signInClicked(values.username, values.password),
  });

  const [spinner, setSpinner] = useState(0);

  // Context and hooks
  const { dispatchUserLevelStore } = useUserLevelStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { requestController } = useRequestController('SignIn');

  /**
   * Sign the user in with the given parameters.
   * @param username User Name
   * @param password password
   */
  const signInClicked = (username: string, password: string) => {
    signIn({
      username,
      password,
      setSpinner,
      dispatchUserLevelStore,
      navigate,
      enqueueSnackbar,
      requestController,
    });
  };

  useEffect(() => {
    if (tokenManager.userHaveAccess()) {
      // - If authenticated users tries to access the Signin page
      // they will be redirected to the Select facility page.
      // - If they have access only to one facility,
      // Select Facility page will take care of further redirection
      navigate(CLIENT_PAGES_URLS.SELECT_FACILITY);
    }
  }, [navigate]);

  return (
    <Container className={classes.container} component="main">
      <Typography className={classes.headerText} component="h1" variant="h3">
        Sign in
      </Typography>

      <Paper elevation={3} className={classes.paper}>
        <SignInForm
          username={formik.values.username}
          password={formik.values.password}
          handleChange={formik.handleChange}
          handleSubmit={formik.handleSubmit}
          showForgotPasswordLink={true}
        />
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>

      {spinner ? <Spinner /> : null}
    </Container>
  );
};

export default SignIn;
