import { lighten, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const tableStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  checkAllCheckbox: {
    color: theme.palette.accent.contrastText,
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  tableRow: {
    MozUserSelect: 'none',
    cursor: 'pointer',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$hover:hover': {
      backgroundColor: theme.palette.accent.hover,
    },
  },
  hoverDisabled: {
    MozUserSelect: 'none',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$hover:hover': {
      backgroundColor: 'inherit',
    },
    '&$hover:hover:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    position: 'relative',
  },
  hover: {},
  tableHeaderCell: {
    backgroundColor: theme.palette.shades.darker,
    color: theme.palette.primary.light,
    lineHeight: '1.1rem',
  },
  spinner: {
    marginRight: '0.5rem',
  },
  tableBody: {
    position: 'relative',
  },
  emptyTable: {
    height: '300px',
  },
  icon: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '100px',
    '& svg': {
      height: '100%',
      width: '100%',
      maxWidth: '80px',
    },
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
    paddingTop: theme.spacing(2),
  },
  subheader: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    marginRight: theme.spacing(4),
  },
  toolbarText: {
    fontSize: '.9rem',
    wordBreak: 'break-all',
    textAlign: 'right',
  },
  search: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  hidden: {
    display: 'none',
  },
  centered: {
    textAlign: 'center',
  },
  faded: {
    color: theme.palette.shades.lighter,
  },
  searchIcon: {
    cursor: 'pointer',
  },
  truncate: {
    // SS:27-04-21 Find a better way of ensuring max width
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noWordBreakNoBullets: {
    whiteSpace: 'pre',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
}));
