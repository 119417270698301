import PageHeaderSection from 'components/Page/PageHeaderSection';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { noFlyZoneAlertState } from './NoFlyZoneHeader/NoFlyZoneAlertState';

export const DroneZoneHeader = () => {
  const { stateGroundControl } = useGroundControlStore();

  const isStatusChangeEnabled: boolean =
    userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) ||
    stateGroundControl.flightDomain.flight_domain_status?.locked ||
    (stateGroundControl.flightDomain.fleet_status_summary?.num_drones_flying || -1) < 0;

  return (
    <PageHeaderSection
      title="Drone zones"
      subtitle="Create, control and preview drone zones"
      isGroundControlPage={true}
      alert={noFlyZoneAlertState(stateGroundControl, isStatusChangeEnabled)}
    />
  );
};
