// libraries
import { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// functions, stores
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { AUTH_PAGES_URLS, CLIENT_PAGES_URLS } from 'common/pages';
import { useClientLevelStore } from '../store/ClientLevelStore/clientLevelStore';
import { useRequestController } from '.';

/**
 * List of pages which forces a reload of the client data (Facility List):
 * this is used to avoid double loading the client data.
 *
 * NOTE: this is used e.g. for the Select Facility page / component
 */
const PAGES_FORCING_A_RELOAD = [CLIENT_PAGES_URLS.SELECT_FACILITY];

/**
 * Hook that ensures that the data for the current client is loaded.
 * @param componentName Name of the component making use of the hook.
 * @returns a flag which is true if the client data has been loaded and is ready to use.
 */
export const useLoadClientLevelData = (componentName: string) => {
  // Client level state and navigate
  const { stateClientLevel, asyncPopulateFacilities, isDataReady, isDataLoading, hasDataErrors } =
    useClientLevelStore() as any;

  /**
   * Navigation function.
   */
  const navigate = useNavigate();
  /**
   * Location hook.
   */
  const location = useLocation();
  /**
   * String to prefix console logs with.
   */
  const logPrefix = getLogPrefixForType('HOOK', 'useLoadClientLevelData', componentName);
  /**
   * get request controller object to be used e.g. for canceling pending network requests.
   */
  const { requestController } = useRequestController(logPrefix);
  /**
   * flag indicating whether the current page forces a reload on mounting,
   * i.e.: whether the pages needs an up-to-date set of data or whether the
   * client data is stable over a session.
   *
   * E.g.: SelectFacility does force that, and the reason is the following:
   * in the admin page a user could give himself access to additional facility
   * and he'll probably want to be able to access that facility right away,
   * without having to sign-out and sign-in again.
   */
  const isReloadForced = useMemo(
    () => PAGES_FORCING_A_RELOAD.includes(location.pathname),
    [location.pathname],
  );
  /**
   * Flag indicating whether a reload is required: this is used in order to
   * avoid continuously reloading the client-data at each render.
   */
  const isReloadRequired = useRef(isReloadForced);

  useEffect(
    /**
     * Pre-populate client level store in case it is empty
     * This allows users to para-shoot into a url, ensuring
     * that client level data is correctly populated
     *
     * Note: this deals with the cases in which the client data (e.g. facility list)
     * does not need to be re-loaded, if it is already available when the component is mounted
     */
    () => {
      if (isReloadForced) {
        // The hook is running with the isReloadForced flag set
        // => do not use this useEffect
        return;
      }
      if (hasDataErrors()) {
        console.debug(
          logPrefix,
          'previously failed to load the facility list',
          '=> populate SKIPPED',
        );
        return;
      }
      if (isDataReady()) {
        console.debug(logPrefix, 'facility list already populated => populate SKIPPED');
        return;
      }
      if (isDataLoading()) {
        console.debug(logPrefix, 'facility list is already loading => populate SKIPPED');
        return;
      }

      console.debug(logPrefix, 'populate the facility list');
      asyncPopulateFacilities(requestController, false).then(() => {
        console.debug(logPrefix, 'facility list successfully populated');
      });
    },
    [
      asyncPopulateFacilities,
      hasDataErrors,
      isDataLoading,
      isDataReady,
      isReloadForced,
      logPrefix,
      requestController,
    ],
  );

  useEffect(
    /**
     * Refresh the list of facilities when mounting a component which forces a reload
     *
     * Note: this useEffect could be theoretically be merged into the previous one,
     * however the resulting useEffect would be much more complicated than having
     * the two of them separated.
     */
    () => {
      if (!isReloadForced) {
        // The hook is running without the isReloadForced flag set
        // => do not use this useEffect
        return;
      }
      if (!isReloadRequired.current) {
        console.debug(
          logPrefix,
          'the page forces a reload but right now it is not needed => SKIPPED',
        );
        return;
      }
      console.debug(logPrefix, 'forced re-populate of the facility list');
      asyncPopulateFacilities(requestController, true).finally(() => {
        isReloadRequired.current = false;
        console.debug(logPrefix, 'facility list successfully re-populated');
      });
    },
    [asyncPopulateFacilities, isReloadForced, logPrefix, requestController],
  );

  useEffect(
    /**
     * Handle automatic logout if the user does not have access to any
     * configured facility (these facilities are already filtered out in
     * "stateClientLevel" when the user is a client user)
     */
    () => {
      if (isDataReady() && stateClientLevel.facilityList.length === 0) {
        console.debug(logPrefix, 'the user has no facility available => SIGNOUT');

        navigate(AUTH_PAGES_URLS.SIGNOUT, {
          state: {
            logoutMessage: 'None of the facilities you have access to is configured yet.',
            variant: 'info',
          },
        });
      }
    },
    [isDataReady, logPrefix, navigate, stateClientLevel.facilityList.length],
  );
};
