import { PERMISSION } from './permissions.model';

export const inventoryManagerPermissions: PERMISSION[] = [
  PERMISSION.VIEW_WAREHOUSE_STATUS,
  PERMISSION.VIEW_LOCATION_DETAILS,
  PERMISSION.SHARE_FEEDBACK_ON_LOCATION,
  PERMISSION.EXCLUDE_INCLUDE_LOCATIONS,
  PERMISSION.VIEW_LOCATION_STATUS,
  PERMISSION.VIEW_ANALYTICS,
  PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL,
  PERMISSION.WMS_MANAGEMENT,
  PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
  PERMISSION.VIEW_ONGOING_REPORTS,
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.EXPORT_FINISHED_REPORTS,
  PERMISSION.ABORT_ONGOING_REPORTS,
  PERMISSION.UPDATE_REPORTS,
  PERMISSION.SNOOZE_LOCATION,
  PERMISSION.AMEND_LOCATION,
  PERMISSION.AMEND_REPORT,
  PERMISSION.SHOW_POTENTIAL_REPORT_ISSUES,
];

export const verityUserPermissions: PERMISSION[] = [
  PERMISSION.VIEW_WAREHOUSE_STATUS,
  PERMISSION.VIEW_LOCATION_DETAILS,
  PERMISSION.SHARE_FEEDBACK_ON_LOCATION,
  PERMISSION.ADVANCED_SHARE_FEEDBACK_ON_LOCATION,
  PERMISSION.EXCLUDE_INCLUDE_LOCATIONS,
  PERMISSION.VIEW_LOCATION_STATUS,
  PERMISSION.VIEW_ANALYTICS,
  PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL,
  PERMISSION.WMS_MANAGEMENT,
  PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
  PERMISSION.FACILITY_MANAGEMENT,
  PERMISSION.VIEW_ONGOING_REPORTS,
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.EXPORT_FINISHED_REPORTS,
  PERMISSION.ABORT_ONGOING_REPORTS,
  PERMISSION.ARCHIVED_REPORTS_MANAGEMENT,
  PERMISSION.UPDATE_REPORTS,
  PERMISSION.SNOOZE_LOCATION,
  PERMISSION.AMEND_LOCATION,
  PERMISSION.VIEW_DRONE_FLIGHT_HOURS,
  PERMISSION.EDIT_DRONE_FLIGHT_HOURS,
  PERMISSION.DELIVERY_MANAGEMENT,
  PERMISSION.PRIORITY_PICKER_INTERNAL,
];

export const superAdminPermissions: PERMISSION[] = [
  PERMISSION.VIEW_WAREHOUSE_STATUS,
  PERMISSION.VIEW_LOCATION_DETAILS,
  PERMISSION.SHARE_FEEDBACK_ON_LOCATION,
  PERMISSION.ADVANCED_SHARE_FEEDBACK_ON_LOCATION,
  PERMISSION.EXCLUDE_INCLUDE_LOCATIONS,
  PERMISSION.VIEW_LOCATION_STATUS,
  PERMISSION.VIEW_ANALYTICS,
  PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL,
  PERMISSION.WMS_MANAGEMENT,
  PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
  PERMISSION.VIEW_ONGOING_REPORTS,
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.EXPORT_FINISHED_REPORTS,
  PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
  PERMISSION.ABORT_ONGOING_REPORTS,
  PERMISSION.FACILITY_MANAGEMENT,
  PERMISSION.ARCHIVED_REPORTS_MANAGEMENT,
  PERMISSION.UPDATE_REPORTS,
  PERMISSION.SNOOZE_LOCATION,
  PERMISSION.AMEND_LOCATION,
  PERMISSION.VIEW_LOCK_STATUS,
  PERMISSION.LOCK_SYSTEM,
  PERMISSION.UNLOCK_SYSTEM,
  PERMISSION.SCHEDULE_LOCK,
  PERMISSION.GENERATE_QR_CODE,
  PERMISSION.NO_FLY_ZONE_MANAGEMENT,
  PERMISSION.DRONE_ZONES_MANAGEMENT,
  PERMISSION.VIEW_FLEET_INFO,
  PERMISSION.VIEW_DRONE_FLIGHT_HOURS,
  PERMISSION.EDIT_DRONE_FLIGHT_HOURS,
  PERMISSION.INITIATE_SAFETY_LANDING,
  PERMISSION.USER_MANAGEMENT,
  PERMISSION.DELIVERY_MANAGEMENT,
  PERMISSION.PRIORITY_PICKER_INTERNAL,
];

export const reviewerPermissions: PERMISSION[] = [
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
];

export const groundControlOfficerPermissions: PERMISSION[] = [
  PERMISSION.VIEW_LOCK_STATUS,
  PERMISSION.LOCK_SYSTEM,
  PERMISSION.UNLOCK_SYSTEM,
  PERMISSION.SCHEDULE_LOCK,
  PERMISSION.GENERATE_QR_CODE,
  PERMISSION.NO_FLY_ZONE_MANAGEMENT,
  PERMISSION.DRONE_ZONES_MANAGEMENT,
  PERMISSION.VIEW_FLEET_INFO,
  PERMISSION.VIEW_DRONE_FLIGHT_HOURS,
  PERMISSION.INITIATE_SAFETY_LANDING,
];

export const analyticsPermissions: PERMISSION[] = [PERMISSION.VIEW_ANALYTICS];

export const safetyControlsPermissions: PERMISSION[] = [
  PERMISSION.VIEW_LOCK_STATUS,
  PERMISSION.LOCK_SYSTEM,
  PERMISSION.VIEW_FLEET_INFO,
  PERMISSION.VIEW_DRONE_FLIGHT_HOURS,
  PERMISSION.INITIATE_SAFETY_LANDING,
];
