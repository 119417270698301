import { LocalStore } from 'common/functions/storageFunctions';
import {
  Configuration as EmergencyProceduresConfig,
  EmergencyProceduresApi,
} from 'codegen/emergency_procedure';
import { Configuration as FlightDomainConfig, FlightDomainApi } from 'codegen/flight_domain';
import {
  Configuration as FlightDomainSettingsConfig,
  FlightDomainSettingsApi,
} from 'codegen/flight_domain_settings';
import { Configuration as NoFlyZonesConfig, NoFlyZonesApi } from 'codegen/no_fly_zone';
import { Configuration as FacilityConfig, FacilityApi } from 'codegen/facility';
import { Configuration as QrCodeConfig, QRGeneratorApi } from 'codegen/qr_generator';
import {
  Configuration as ReportSpecification,
  ReportSpecificationApi,
} from 'codegen/report_specification';
import {
  Configuration as InventoryRequestConfig,
  InventoryRequestApi,
} from 'codegen/inventory_request';
import {
  Configuration as FacilitySettingsConfig,
  FacilitySettingsApi,
} from 'codegen/facility_settings';
import {
  Configuration as NavSimulationConfig,
  FleetSimulationApi,
  EmergencyProceduresApi as NavSimulatorEmergencyProceduresApi,
  OperationsSimulatorApi,
} from 'codegen/nav_simulation';
import { Configuration as AnalyticsConfig, AnalyticsApi } from 'codegen/analytics';
import {
  Configuration as ScriptedFrontendConfig,
  ScriptedFrontendApi,
} from 'codegen/sfe_simulator';
import { Configuration as SystemConfig, SystemApi } from 'codegen/system';
import { Configuration as WMSDataConfig, WMSDataApi } from 'codegen/wms_data';
import { Configuration as AuthenticationConfig, AuthenticationApi } from 'codegen/authentication';
import { Configuration as ReportConfig, ReportApi } from 'codegen/report';
import { Configuration as Map3DConfig, FacilityApi as Map3DApi } from 'codegen/map_3d';
import { Configuration as DevToolsConfig, DevToolsApi } from 'codegen/dev_tools';
import { Configuration as VersionConfig, VersionApi } from 'codegen/version';
import { Configuration as ImageConfig, ImageApi } from 'codegen/image';
import { Configuration as UserOverrideConfig, UserOverrideApi } from 'codegen/user_override';
import { Configuration as IssueSnoozeConfig, IssueSnoozeApi } from 'codegen/issue_snooze';
import { Configuration as ReviewConfig, ReviewApi } from 'codegen/review';
import { Configuration as UserAdminConfig, UserAdminApi } from 'codegen/user_admin';
import {
  Configuration as WarehouseStatusConfig,
  WarehouseStatusApi,
} from 'codegen/warehouse_status';
import { Configuration as ClientAPIConfig, ClientAPIApi } from 'codegen/client_api';
import {
  Configuration as CustomIssueLogicConfig,
  CustomIssueLogicApi,
} from 'codegen/custom_issue_logic';

/**
 * Get flight domain api service
 * @returns api service
 */
export const getFlightDomainApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new FlightDomainSettingsConfig({ apiKey: idToken });
  const apiService = new FlightDomainApi(config);
  return apiService;
};

/**
 * Get no fly zones api service
 * @returns api service
 */
export const getNoFlyZonesApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new NoFlyZonesConfig({ apiKey: idToken });
  const apiService = new NoFlyZonesApi(config);
  return apiService;
};
/**
 * Get flight domain settings api
 * @returns api service
 */
export const getFlightDomainSettingsApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new FlightDomainConfig({ apiKey: idToken });
  const apiService = new FlightDomainSettingsApi(config);
  return apiService;
};
/**
 * Get emergency procedure service
 * @returns api service
 */
export const getEmergencyProcedureApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new EmergencyProceduresConfig({ apiKey: idToken });
  const apiService = new EmergencyProceduresApi(config);
  return apiService;
};

/**
 * Get facility service
 * @returns api service
 */
export const getFacilityApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new FacilityConfig({ apiKey: idToken });
  const apiService = new FacilityApi(config);
  return apiService;
};

/**
 * Get QR code service
 * @returns api service
 */
export const getQrCodeApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new QrCodeConfig({ apiKey: idToken });
  const apiService = new QRGeneratorApi(config);
  return apiService;
};

/**
 * Get report specification service
 * @returns api service
 */
export const getReportSpecificationApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new ReportSpecification({ apiKey: idToken });
  const apiService = new ReportSpecificationApi(config);
  return apiService;
};

/**
 * Get inventory request service
 * @returns api service
 */
export const getInventoryRequestApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new InventoryRequestConfig({ apiKey: idToken });
  const apiService = new InventoryRequestApi(config);
  return apiService;
};

/**
 * Get facility settings api service
 * @returns api service
 */
export const getFacilitySettingsApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new FacilitySettingsConfig({ apiKey: idToken });
  const apiService = new FacilitySettingsApi(config);
  return apiService;
};

/**
 * Get fleet simulation api service
 * @returns api service
 */
export const getFleetSimulationApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new NavSimulationConfig({ apiKey: idToken });
  const apiService = new FleetSimulationApi(config);
  return apiService;
};

/**
 * Get nav simulation emergency procedures api service
 * @returns api service
 */
export const getNavSimulationEmergencyProceduresApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new NavSimulationConfig({ apiKey: idToken });
  const apiService = new NavSimulatorEmergencyProceduresApi(config);
  return apiService;
};

/**
 * Get analytics api service
 * @returns api service
 */
export const getAnalyticsService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new AnalyticsConfig({ apiKey: idToken });
  const apiService = new AnalyticsApi(config);
  return apiService;
};

/**
 * Get scripted frontend simulator api service
 * @returns api service
 */
export const getSfeSimulatorService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new ScriptedFrontendConfig({ apiKey: idToken });
  const apiService = new ScriptedFrontendApi(config);
  return apiService;
};

/**
 * Get system api service
 * @returns api service
 */
export const getSystemService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new SystemConfig({ apiKey: idToken });
  const apiService = new SystemApi(config);
  return apiService;
};

/**
 * Get WMS data api service
 * @returns api service
 */
export const getWMSDataService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new WMSDataConfig({ apiKey: idToken });
  const apiService = new WMSDataApi(config);
  return apiService;
};

/**
 * Get authentication api service
 * @returns api service
 */
export const getAuthenticationService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new AuthenticationConfig({ apiKey: idToken });
  const apiService = new AuthenticationApi(config);
  return apiService;
};

/**
 * Get report api service
 * @returns api service
 */
export const getReportService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new ReportConfig({ apiKey: idToken });
  const apiService = new ReportApi(config);
  return apiService;
};

/**
 * Get map 3D service
 * @returns api service
 */
export const getMap3DService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Map3DConfig({ apiKey: idToken });
  const apiService = new Map3DApi(config);
  return apiService;
};

/**
 * Get dev tools service
 * @returns api service
 */
export const getDevToolsService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new DevToolsConfig({ apiKey: idToken });
  const apiService = new DevToolsApi(config);
  return apiService;
};

/**
 * Get dev tools service
 * @returns api service
 */
export const getVersionService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new VersionConfig({ apiKey: idToken });
  const apiService = new VersionApi(config);
  return apiService;
};

/**
 * Get image service
 * @returns api service
 */
export const getImageService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new ImageConfig({ apiKey: idToken });
  const apiService = new ImageApi(config);
  return apiService;
};

/**
 * Get user override service
 * @returns api service
 */
export const getUserOverrideService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new UserOverrideConfig({ apiKey: idToken });
  const apiService = new UserOverrideApi(config);
  return apiService;
};

/**
 * Get issue snooze service
 * @returns api service
 */
export const getIssueSnoozeService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new IssueSnoozeConfig({ apiKey: idToken });
  const apiService = new IssueSnoozeApi(config);
  return apiService;
};

/**
 * Get review service
 * @returns api service
 */
export const getReviewService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new ReviewConfig({ apiKey: idToken });
  const apiService = new ReviewApi(config);
  return apiService;
};

/**
 * Get user admin service
 * @returns api service
 */
export const getUserAdminService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new UserAdminConfig({ apiKey: idToken });
  const apiService = new UserAdminApi(config);
  return apiService;
};

/**
 * Get warehouse status service
 * @returns api service
 */
export const getWarehouseStatusService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new WarehouseStatusConfig({ apiKey: idToken });
  const apiService = new WarehouseStatusApi(config);
  return apiService;
};

/**
 * Get client api service
 * @returns api service
 */
export const getClientAPIService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new ClientAPIConfig({ apiKey: idToken });
  const apiService = new ClientAPIApi(config);
  return apiService;
};

/**
 * Get custom issue logic service
 * @returns api service
 */
export const getCustomIssueLogicService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new CustomIssueLogicConfig({ apiKey: idToken });
  const apiService = new CustomIssueLogicApi(config);
  return apiService;
};

/**
 * Get custom issue logic service
 * @returns api service
 */
export const getOperationsSimulatorService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new NavSimulationConfig({ apiKey: idToken });
  const apiService = new OperationsSimulatorApi(config);
  return apiService;
};
