import { alpha } from '@mui/material';

export const mapStyle = {
  guide: {
    color: 'blue',
  },
  floor: {
    void: {
      backgroundColor: '#f2f2f2',
    },
    facility: {
      backgroundColor: '#fff',
    },
  },
  charger: {
    backgroundColor: '#000000',
    clickColor: '#87B622',
    hoverColor: '#6D8C2A',
    clickHoverColor: '#3A5107',
    fontColor: '#FFFFFF',
    area: {
      backgroundColor: '#645AD2',
    },
  },
  box: {
    textColor: 'white',
    color: 'rgb(128, 128, 128)',
    clickColor: 'hotpink',
    hoverColor: 'yellow',
    clickHoverColor: 'pink',
    fontColor: 'black',
  },
  noFlyZone: {
    creation: {
      backgroundColor: {
        primary: 'red',
      },
    },
    active: {
      backgroundColor: {
        primary: '#002C73',
      },
      textColor: {
        primary: '#fff',
      },
    },
    inactive: {
      backgroundColor: {
        primary: '#002C73',
      },
      textColor: {
        primary: '#002C73',
      },
    },
  },
  controlledZones: {
    creation: {
      backgroundColor: {
        primary: 'red',
      },
    },
    active: {
      backgroundColor: {
        primary: '#E3984C',
      },
      borderColor: {
        primary: '#AD5801',
        secondary: '#c4c4c4',
      },
      textColor: {
        primary: '#fff',
        secondary: '#fff',
      },
    },
    inactive: {
      backgroundColor: {
        primary: '#fff',
      },
      borderColor: {
        primary: '#AD5801',
        secondary: '#c4c4c4',
      },
      textColor: {
        primary: '#E3984C',
        secondary: '#E3984C',
      },
    },
  },
  openFlyZones: {
    active: {
      backgroundColor: {
        primary: '#F4D6B7',
      },
    },
  },
  flightAreas: {
    active: {
      backgroundColor: {
        primary: alpha('#E3984C', 0.8),
      },
      borderColor: {
        primary: alpha('#E3984C', 0.8),
      },
    },
    inactive: {
      backgroundColor: {
        primary: '#ffffff',
      },
      borderColor: {
        primary: '#c4c4c4',
      },
    },
  },
  countBadge: {
    active: {
      backgroundColor: {
        primary: '#BF6E1B',
        secondary: '#A95500',
      },
      textColor: {
        primary: '#ffffff',
      },
    },
  },
};
