import { useParams } from 'react-router-dom';
import { useRequestController } from '../../../../../../hooks';
import { ScheduleLockService } from '../../../../../../services/ScheduleLock';

const ERROR_MESSAGES = {
  403: 'You don`t have permission to perform this operation!',
  409: 'Schedule lock already exists!',
};

interface ISetScheduleLock {
  scheduleLock: { lock_at: string };
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
export const useSetScheduleLock = () => {
  const { requestController } = useRequestController('SetScheduleLock');
  const { systemId = '', flightDomainId = '' } = useParams();
  const { signal } = requestController.reserveSlotForRequest();
  return {
    setScheduleLock: ({ scheduleLock, setLoading, setError }: ISetScheduleLock) =>
      requestController.doRequest({
        request: ScheduleLockService.setScheduleLock,
        requestParams: [{ systemId, flightDomainId, scheduleLock, signal }],
        callbackBeforeSend: () => {
          setError('');
          setLoading(true);
        },
        callbackError: ({ response }: { response: { status: number } }) => {
          const errorMessage =
            ERROR_MESSAGES[response.status as keyof typeof ERROR_MESSAGES] ||
            'Something went wrong while performing this action!';
          setError(errorMessage);
        },
        callbackFinally: () => setLoading(false),
      }),
  };
};
