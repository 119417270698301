import { FormControl, Grid } from '@mui/material';
import { useState } from 'react';
import { locationStyles } from '../styles';
import { feedbackReasonsForUser, FeedbackReasonType } from './feedbackReason.model';
import { FeedbackReason } from './FeedbackReason';
import { OtherFeedbackReason } from './OtherFeedbackReason';
import { ShareFeedbackButton } from './ShareFeedbackButton';
import { buildCheckboxSelection } from './checkboxSelection.defaults';

export const FeedbackForm = ({
  sendLocationInfo,
}: {
  sendLocationInfo: (feedbackReasons: FeedbackReasonType[], message: string) => Promise<unknown>;
}) => {
  const { classes } = locationStyles();
  const [message, setMessage] = useState<string>('');
  const [checkboxSelection, setCheckboxSelection] = useState<Record<string, boolean>>(
    buildCheckboxSelection(),
  );

  const handleSubmit = async () => {
    const feedbackReasons: FeedbackReasonType[] = Object.entries(checkboxSelection)
      .filter(([key, isChecked]) => isChecked)
      .map(([key, isChecked]) => key as FeedbackReasonType);
    await sendLocationInfo(feedbackReasons, message ?? '');
  };

  const checkboxClick = (flag: FeedbackReasonType, isSelected: boolean) => {
    setCheckboxSelection((previousSelection) => ({ ...previousSelection, [flag]: isSelected }));
  };

  const isSubmitEnabled: boolean =
    (checkboxSelection.OTHER && message.length > 0) ||
    Object.entries(checkboxSelection)
      .filter(([key]) => key !== 'OTHER')
      .some(([_, isChecked]) => isChecked);

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Grid container>
        <Grid item xs={12} pb={3}>
          <i>
            Help us improve our insights. Please indicate the factor(s) that make the information
            provided inaccurate.
          </i>
        </Grid>

        {feedbackReasonsForUser().map(({ type, name }) => (
          <FeedbackReason
            key={type}
            type={type}
            name={name}
            isChecked={checkboxSelection[type]}
            onChange={(isChecked) => checkboxClick(type, isChecked)}
          />
        ))}
        <OtherFeedbackReason
          isChecked={checkboxSelection.OTHER}
          onCheckedChange={(isChecked) => checkboxClick('OTHER', isChecked)}
          message={message}
          onMessageChange={setMessage}
        />
        <ShareFeedbackButton isEnabled={isSubmitEnabled} onClick={handleSubmit} />
      </Grid>
    </FormControl>
  );
};
