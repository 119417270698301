import { USER_GROUPS } from 'common/userGroups';
import { ROLE, Role } from './role.model';

export const roleFromUserGroup = (userGroup: USER_GROUPS): Role => {
  switch (userGroup) {
    case USER_GROUPS.INVENTORY_MANAGER:
      return ROLE.INVENTORY_MANAGER;
    case USER_GROUPS.VERITY_USER:
      return ROLE.VERITY_USER;
    case USER_GROUPS.REVIEWER:
      return ROLE.REVIEWER;
    case USER_GROUPS.GROUND_CONTROL_OFFICER:
      return ROLE.GROUND_CONTROL_OFFICER;
    case USER_GROUPS.ANALYTICS:
      return ROLE.ANALYTICS;
    case USER_GROUPS.SAFETY_CONTROLS:
      return ROLE.SAFETY_CONTROLS;
    case USER_GROUPS.SUPER_ADMIN:
      return ROLE.SUPER_ADMIN;
    default:
      return ROLE.UNKNOWN;
  }
};

export const rolesFromUserGroups = (userGroups: USER_GROUPS[]): Role[] =>
  userGroups.map((userGroup: USER_GROUPS) => roleFromUserGroup(userGroup));
