import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { useParams } from 'react-router-dom';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import Footer from 'components/Footer/Footer';
import { FacilityMenu } from 'components/common/FacilityMenu/FacilityMenu';
import Spinner from 'components/common/Spinner';
import { useLoadFacilityLevelData } from '../../hooks';
import { useHideOnScroll } from '../../hooks/useHideOnScroll';
import { useFacilityModalsStore } from '../../store/Modals/facilityModals/facilityModalsStore';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

const FacilityPage = (props: { children: React.ReactNode }) => {
  const { stateGroundControl } = useGroundControlStore() as any;
  const { dispatchFacilityModals } = useFacilityModalsStore() as any;
  const { status } = stateGroundControl;

  const isSticky = useHideOnScroll({ threshold: 50 });

  const { systemId } = useParams();

  const logPrefix = getLogPrefixForType('ROUTE', `Facility Page: ${window.location.pathname}`);
  const { stateFacilityLevel } = useFacilityLevelStore();
  useLoadFacilityLevelData(logPrefix);

  useEffect(() => {
    dispatchFacilityModals({ type: FacilityModalsActionTypes.RESET });
  }, [dispatchFacilityModals]);

  /**
   * Only render the page when we have data about the facility available.
   */
  const shouldRenderPage = stateFacilityLevel.isFacilityDataInitiated && systemId;

  if (shouldRenderPage) {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <FacilityMenu systemId={systemId} stickyElement={isSticky || ''} status={status} />

          <Box component="main" sx={{ width: '100%', flex: '1 1 auto', minWidth: 0 }}>
            {props.children}
          </Box>
        </Box>
        <Footer />
      </>
    );
  }
  return <Spinner label="loading facility data ..." />;
};

export default React.memo(FacilityPage);
