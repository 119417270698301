import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MapViewOptions } from '../../DroneZonesPage.model';
import { useStyles } from './DroneZoneMapViewToggles.styles';

export const DroneZoneMapViewToggles = ({
  currentMapView,
  isDisabled,
  onChange,
}: {
  currentMapView: MapViewOptions;
  isDisabled: boolean;
  onChange: (newView: MapViewOptions) => void;
}) => {
  const { classes } = useStyles();

  const handleOnChange = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    newView: MapViewOptions,
  ) => {
    onChange(newView);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      disabled={isDisabled}
      value={currentMapView}
      onChange={handleOnChange}
    >
      <ToggleButton className={classes.toggleButton} value={MapViewOptions.PLANNING}>
        Planning
      </ToggleButton>
      <ToggleButton className={classes.toggleButton} value={MapViewOptions.FLIGHT_AREA}>
        Flight area
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
