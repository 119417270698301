// services & store
import { IUserPostRequestSTUserGroupsEnum, IUserResponseST } from 'codegen/user_admin';
import UserServices from '../services/UserServices';
import {
  IAddUserDataRequestParamST,
  IGetUserSystemIds,
  IUpdateUserRestRequestParamST,
  IUserST,
} from '../interfaces';

const getAllUsers = (systemId: string, signal: AbortSignal) =>
  UserServices.getFacilities(signal).then((response) => {
    const getUserFacilitiesNames = (user: IUserResponseST) => {
      const facilities = response.data;

      if (user.access_to_all_facilities) {
        return 'All Facilities';
      }

      const userFacilities = user.system_ids.map((system) => {
        if (facilities[system] === null) {
          return system;
        }

        return facilities[system]?.facility;
      });

      const sortedFacilities = userFacilities.filter(Boolean).sort();

      return sortedFacilities.join(', ');
    };

    return UserServices.getAllUsers(systemId, signal).then((res) => {
      const { users } = res.data;

      const mappedUsers = users.map((user) => {
        const userFacilities = getUserFacilitiesNames(user);

        return {
          ...user,
          id: user.username,
          system_ids: userFacilities,
          user_groups: user.user_groups?.join(', ') ?? [],
        };
      });

      return mappedUsers;
    });
  });

const getFacilities = (signal: AbortSignal) => UserServices.getFacilities(signal).then((r) => r);

// Get Facilities and user groups
const getFacilitiesAndGroups = (systemId: string, signal: AbortSignal) => {
  const facilitiesPromise = UserServices.getFacilities(signal).then((r) => {
    const facilities: { value: string; label: string }[] = [];
    for (const key in r.data) {
      // This is required by the lint rule https://eslint.org/docs/rules/guard-for-in
      if ({}.hasOwnProperty.call(r.data, key)) {
        const value: any = r.data[key];
        if (value === null) {
          facilities.push({ value: key, label: key });
        } else {
          facilities.push({ value: key, label: value.facility });
        }
      }
    }
    return facilities.filter((item) => item.label !== 'undefined');
  });

  const userGroupsPromise = UserServices.getUserGroups(systemId, signal).then(
    ({ data: { groups } }) =>
      groups.map((group: string) => ({
        value: group,
        label: group.replace(/([A-Z])/g, ' $1').trim(),
      })),
  );

  return Promise.all([facilitiesPromise, userGroupsPromise]).then((r) => ({
    facilities: r[0],
    groups: r[1],
  }));
};

// Get user facilities
const getUserFacilities = (signal: AbortSignal) =>
  UserServices.getUserFacilities(signal).then((r) => {
    const userFacilities: IGetUserSystemIds[] = Object.entries(r.data).map((userFacility) => {
      const [systemId, facilityDetails] = userFacility;
      const client = facilityDetails?.client;
      const facility = facilityDetails?.facility;

      return { systemId, client, facility };
    });

    return userFacilities;
  });

// Get user
const getUser = (systemId: string, email: string, signal: AbortSignal) =>
  UserServices.getUser(systemId, email, signal).then((r: any) => {
    const user: IUserST = { ...r.data.user, user_group: r.data.groups };
    return user;
  });

// Add new user
const addUser = (systemId: string, data: IAddUserDataRequestParamST, signal: AbortSignal) =>
  UserServices.addUser(systemId, data, signal).then((r) => r);

interface IUpdateUserDataParameter extends IUpdateUserRestRequestParamST {
  email: string;
  user_groups: IUserPostRequestSTUserGroupsEnum[];
}
// Update user
const updateUser = (systemId: string, data: IUpdateUserDataParameter, signal: AbortSignal) => {
  const { user_groups, email, ...rest } = data;

  const promise1 = UserServices.updateUserGroups(systemId, { email, user_groups }, signal);
  const promise2 = UserServices.updateUser(systemId, { email, rest }, signal);

  return Promise.all([promise1, promise2]);
};

// Delete user
const deleteUser = (systemId: string, data: string, signal: AbortSignal) =>
  UserServices.deleteUser(systemId, data, signal).then((r) => r);

const UserStore = {
  getAllUsers,
  getUser,
  addUser,
  deleteUser,
  updateUser,
  getFacilitiesAndGroups,
  getUserFacilities,
  getFacilities,
};

export default UserStore;
