import React from 'react';

import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useTabStyles = makeStyles()((theme: Theme) => ({
  tabs: {
    backgroundColor: theme.palette.shades.darkest,
  },
  tab: {
    color: theme.palette.shades.light,
    borderBottom: `5px solid ${theme.palette.shades.dark}`,
    minHeight: 65,
    flex: 1,
    whiteSpace: 'nowrap',
  },
  indicator: {
    backgroundColor: theme.palette.shades.white,
    height: '5px',
  },
  customTabsStyle: {
    '& .MuiTabScrollButton-root': {
      color: theme.palette.shades.light,
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      display: 'none',
    },
    '& .MuiTabs-scroller .Mui-selected': {
      color: '#eee',
    },
  },
}));

type EnhancedTableTabsProps = {
  tabs: {
    icon?: JSX.Element;
    label: any;
  }[];
  activeTab: number;
  handleTabChange: (...params: any) => void;
};

const EnhancedTableTabs = (props: EnhancedTableTabsProps) => {
  // props
  const { tabs, activeTab, handleTabChange } = props;

  // styles
  const { classes } = useTabStyles();

  return (
    <Paper square elevation={1}>
      <Tabs
        className={classes.tabs}
        value={activeTab}
        variant={tabs.length > 5 ? 'scrollable' : 'fullWidth'}
        onChange={handleTabChange}
        scrollButtons="auto"
        aria-label="tabs"
        classes={{
          indicator: classes.indicator,
          root: classes.customTabsStyle,
        }}
      >
        {tabs.map((tab) => (
          <Tab
            data-testid="c-table-tab"
            className={classes.tab}
            key={tab.label}
            icon={tab?.icon}
            label={tab.label}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default EnhancedTableTabs;
