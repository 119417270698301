import { Theme } from '@mui/material';

// mui core
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// mui icons
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// custom icons
import DroneIcon from 'components/common/FacilityMenu/Icons/DroneIcon';

// components
import TooltipedIcon from 'components/common/TooltipedIcon';
import DronOnChargerIcon from '../Icons/DroneOnChargerIcon';
import { TooltipTypes } from '../../../store/GroundControl/IGroundControlStore';

interface IFleetStatus {
  theme: Theme;
  flying: number;
  onCharger: number;
  landedInPlace: number;
  webSocketError: boolean;
  width: number;
  noData: boolean;
}

const FleetStatus = ({
  theme,
  flying,
  onCharger,
  landedInPlace,
  webSocketError,
  noData,
  width,
}: IFleetStatus) => (
  <Grid justifyContent="space-between" alignItems="center" container>
    <Grid display="flex" alignItems="center" item>
      <TooltipedIcon
        noMargin
        tooltip={TooltipTypes.FLYING}
        icon={<DroneIcon color={theme.palette.primary.light} />}
      />
      <Typography data-testid="c-n-drones-flying" pl={2} variant="body2" color="white">
        {noData || webSocketError ? '-' : flying}
      </Typography>
    </Grid>
    <Grid display="flex" alignItems="center" item>
      <TooltipedIcon
        noMargin
        tooltip={TooltipTypes.ON_CHARGERS}
        icon={<DronOnChargerIcon theme={theme} active={true} />}
      />
      <Typography data-testid="c-n-drones-on-charger" pl={2} variant="body2" color="white">
        {noData || webSocketError ? '-' : onCharger}
      </Typography>
    </Grid>
    <Grid display="flex" alignItems="center" item>
      <TooltipedIcon
        noMargin
        tooltip={TooltipTypes.ERRORS}
        icon={<WarningAmberIcon sx={{ color: '#fff' }} />}
      />
      <Typography data-testid="c-n-drones-displaced" pl={2} variant="body2" color="white">
        {noData || webSocketError ? '-' : landedInPlace}
      </Typography>
    </Grid>
  </Grid>
);

export default FleetStatus;
