import { Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { calendarTemplate, calendarTheme, createCalendars } from 'common/Calendar/CalendarSettings';
import { Calendar } from 'components/common/Calendar';
import { baseCardStyle } from 'components/BaseCard/styles';
import { LocalStore } from 'common/functions/storageFunctions';
import { OperatingHoursEventST } from '../API';
import { createOperatingHoursByWeekDayFromSchedule } from './Functions/CreateOperatingHoursByWeekDayFromSchedule';

const useStyles = makeStyles()(() => ({
  cardContent: {
    height: 600,
    '& .toastui-calendar-timegrid': {
      minHeight: 500,
    },
    '& .toastui-calendar-timegrid-now-indicator': {
      display: 'none',
    },
    '& .toastui-calendar-panel.toastui-calendar-time': {
      overflowY: 'inherit',
    },
    '& .toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time)': {
      overflowY: 'inherit',
    },
    '& .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time': {
      visibility: 'visible',
    },
    '& .toastui-calendar-day-name__date': {
      display: 'none',
    },
    '& .toastui-calendar-day-name__name': {
      fontSize: 14,
    },
  },
}));

interface IFlightHoursCalendarProps {
  schedule: OperatingHoursEventST[];
}
/**
 * Flight hours calendar
 * @param props IFlightHoursCalendarProps
 * @returns component
 */
export const FlightHoursCalendar = ({ schedule }: IFlightHoursCalendarProps) => {
  const { classes, cx } = useStyles();
  const { classes: cardStyles } = baseCardStyle();

  const theme = useTheme();

  const timezone = LocalStore.getTimezone();

  const events = createOperatingHoursByWeekDayFromSchedule(schedule).map((i, index) => ({
    id: index,
    calendarId: 'fhc',
    title: 'Drones flight hours',
    category: 'time',
    start: moment().tz(timezone).set({ day: i.weekDay, hour: i.fromHours, minutes: i.fromMinutes }),
    end: moment().tz(timezone).set({ day: i.weekDay, hour: i.toHours, minutes: i.toMinutes }),
  }));

  return (
    <Card elevation={3} className={cardStyles.card}>
      <CardHeader
        title="Drones flight hours calendar"
        subheader="Weekly schedule of drone flying hours"
        classes={{
          title: cardStyles.title,
          subheader: cardStyles.subheader,
        }}
      />
      <Divider />
      <CardContent className={cx(classes.cardContent, cardStyles.cardSection)}>
        <Calendar
          height="200px"
          view="week"
          week={{ taskView: false, eventView: ['time'], startDayOfWeek: 1 }}
          timezone={{ zones: [{ timezoneName: timezone }] }}
          calendars={createCalendars(theme)}
          events={events}
          template={calendarTemplate}
          theme={calendarTheme}
          usageStatistics={false}
          isReadOnly
        />
      </CardContent>
    </Card>
  );
};
