import { Button, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { LONG_DATETIME_FORMAT } from 'common/datetimeFormats';
import { baseCardStyle } from 'components/BaseCard/styles';
import { ActionsAlert } from 'components/common/ActionsAlert';
import { Box } from 'components/common/Box';

import { BackDropLoader } from './BackDropLoader';
import { useDeleteScheduleLock } from './Functions/DeleteScheduleLock';

interface IScheduleLockDeletionProps {
  scheduleLock: string;
}

/**
 * Schedule lock deletion
 * @param param0 IScheduleLockDeletionProps
 * @returns component
 */
export const ScheduleLockDeletion = ({ scheduleLock }: IScheduleLockDeletionProps) => {
  const { classes: cardStyles } = baseCardStyle();

  const { deleteScheduleLock } = useDeleteScheduleLock();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleDeleteScheduleLock = () =>
    deleteScheduleLock({
      setLoading: setIsLoading,
      setError,
    });

  return (
    <>
      <BackDropLoader isVisible={isLoading} />
      <CardContent
        className={cardStyles.cardContentSection}
        data-testid="c-schedule-lock-card-content"
      >
        <Grid
          container
          mt={1}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Grid item xs={12} display="flex" mx={2} flexDirection="column" justifyContent="center">
            <Box mb={2}>
              <ActionsAlert
                isActive={!!error}
                message={error}
                handleClose={() => setError('')}
                status="warning"
                handleClick={handleDeleteScheduleLock}
                handleClickLabel="Retry"
              />
            </Box>
            <TextField
              label="A lock is set at:"
              disabled
              value={moment(scheduleLock).format(LONG_DATETIME_FORMAT)}
            />
            <Typography mt={2} color="textSecondary">
              Drones will stop flying at the time of the scheduled lock.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box p={1} width="100%" display="flex" justifyContent="center" alignItems="center">
          <Button
            data-testid="c-add-schedule-lock-button"
            variant="outlined"
            color="error"
            size="medium"
            fullWidth
            onClick={handleDeleteScheduleLock}
          >
            REMOVE
          </Button>
        </Box>
      </CardActions>
    </>
  );
};
