import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeedbackForm } from './FeedbackForm';
import { ThanksAndSnooze } from './ThanksAndSnooze';
import { FeedbackReasonType } from './feedbackReason.model';
import { locationStyles } from '../styles';

export const ShareFeedback = ({
  isSnoozable,
  isSnoozed,
  snooze,
  sendLocationInfo,
}: {
  isSnoozable: boolean;
  isSnoozed: boolean;
  snooze: () => void;
  sendLocationInfo: (feedbackReasons: FeedbackReasonType[], message: string) => Promise<unknown>;
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const { classes } = locationStyles();
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);
  const propagateSendLocationInfo = (
    feedbackReasons: FeedbackReasonType[],
    message: string,
  ): Promise<unknown> => {
    setIsFeedbackSent(true);
    return sendLocationInfo(feedbackReasons, message);
  };

  return (
    <Accordion
      className="customAccordion"
      onChange={(event: React.SyntheticEvent, expanded: boolean) => {
        setIsAccordionOpen((isOpen: boolean) => !isOpen);
      }}
      expanded={isAccordionOpen}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography
          align="right"
          className={classes.accordionTitle}
          color="textSecondary"
          component="p"
        >
          Share feedback on location
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isFeedbackSent ? (
          <ThanksAndSnooze isSnoozed={isSnoozed} isSnoozable={isSnoozable} onSnooze={snooze} />
        ) : (
          <FeedbackForm sendLocationInfo={propagateSendLocationInfo} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
