import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import BaseCard, { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import BaseCardReportItem from 'components/BaseCard/BaseCardReportItem';
import { IProcessReportSummariesData } from 'udb/features/reports/reducer/report-store/processReportSummariesData.model';
import { ReportInCard } from 'udb/features/reports/reducer/report-store/reportInCard.model';
import { getFinishedReports } from './functions/getFinishedReports';
import { useRequestController } from '../../hooks';

interface ILatestReportsCardProps {
  activeTab: number;
}

const logPrefix = getLogPrefixForType('COMPONENT', 'LatestReportsCard');

export const LatestReportsCard = (props: ILatestReportsCardProps) => {
  const { activeTab } = props;
  const { systemId = '' } = useParams();

  const navigate = useNavigate();

  const { requestController } = useRequestController(logPrefix);
  const [isLoading, setIsLoading] = useState(false);
  const [reportsNoInfo, setReportsNoInfo] = useState({ numberOfReports: 0, lastUpdatedAt: '' });
  const [reports, setReports] = useState<ReportInCard[]>([]);

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      label: 'See all finished reports',
      clickHandler: () => {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`, {
          state: { activeTab },
        });
      },
    },
  ];

  const latestReportsData = {
    title: 'Latest reports',
    subtitle: reportsNoInfo.lastUpdatedAt ? `Last update: ${reportsNoInfo.lastUpdatedAt}` : '',
    counter: reportsNoInfo.numberOfReports.toString(),
    actionButtons,
    sections: reports,
  };

  const handleReports = (r: IProcessReportSummariesData) => {
    const { reportsInfo, finishedReports } = r;
    setReportsNoInfo(reportsInfo.finishedReports);
    setReports(finishedReports);
  };

  useEffect(
    () =>
      getFinishedReports({
        systemId,
        requestController,
        setIsLoading,
        onSuccess: handleReports,
      }),
    [requestController, systemId],
  );

  return (
    <BaseCard
      cardFor="latest reports"
      dataTestId="c-latest-reports-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      isLoading={isLoading}
      title={latestReportsData.title}
      subtitle={latestReportsData.subtitle}
      counter={latestReportsData.counter}
      actionButtons={latestReportsData.actionButtons}
      sx={{ order: 2 }}
    >
      {latestReportsData.sections &&
        latestReportsData.sections.map((section: ReportInCard) => (
          <BaseCardReportItem
            key={section.reportId}
            reportId={section.reportId}
            reportType={section.reportType}
            date={section.date}
            recurrence={section.recurrence}
            recurrenceDescription={section.recurrenceDescription}
            reportName={section.reportName}
            reportStatus={section.reportStatus}
            totalLocations={section.totalLocations}
            totalIssues={section.totalIssues?.toString()}
            showBottomDivider={true}
          />
        ))}
    </BaseCard>
  );
};
