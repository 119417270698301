import isNil from 'lodash/isNil';

// services and stores
import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';

// components
import UnlockModal from '../../pages/GroundControl/UnlockModal';
import LockModal from '../../pages/GroundControl/LockModal';
import EmergencyLandingModal from '../../pages/GroundControl/EmergencyLandingModal';

export const ModalsAndPopupsGroundControlLevel = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateGroundControl } = useGroundControlStore();
  const { stateGroundControlModals } = useGroundControlModalsStore();
  const { flightDomain, isExecutingEmergencyLanding } = stateGroundControl;

  return (
    <div>
      <EmergencyLandingModal
        opened={stateGroundControlModals.emergencyModalOpened}
        facilityName={stateFacilityLevel.facilityData.name}
        flightDomainName={flightDomain.flight_domain_name}
        flightDomainId={stateGroundControlModals.flightDomainId}
        isExecutingEmergencyLanding={isExecutingEmergencyLanding}
        nDronesFlying={flightDomain.fleet_status_summary?.num_drones_flying || 0}
        nDronesLandedInError={flightDomain.fleet_status_summary?.num_drones_landed_in_error || 0}
      />

      <UnlockModal
        opened={stateGroundControlModals.unlockModalOpened}
        facilityName={stateFacilityLevel.facilityData.name}
        flightDomainId={stateGroundControlModals.flightDomainId}
        flightDomainName={flightDomain.flight_domain_name}
      />

      <LockModal
        opened={stateGroundControlModals.lockModalOpened}
        facilityName={stateFacilityLevel.facilityData.name}
        flightDomainName={flightDomain.flight_domain_name}
        noData={isNil(flightDomain.flight_domain_status)}
        flightDomainId={stateGroundControlModals.flightDomainId}
        nDronesFlying={flightDomain.fleet_status_summary?.num_drones_flying || 0}
      />
    </div>
  );
};
