/* eslint-disable no-prototype-builtins */
import { ChipPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
  IIssue1STTypeEnum as IssueType,
  IIssue1STResolvedTypeEnum as IssueResolvedType,
} from 'codegen/report';

export type AllIssueTypes = IssueType | IssueResolvedType;

export const issueTypeEnumToStringMap: { [Key in AllIssueTypes]: string } = {
  [IssueType.Issue1WBcVEmpty]: 'Empty location',
  [IssueType.Issue2WBcNeVBc]: 'Barcode mismatch',
  [IssueType.Issue3WEmptyVBc]: 'Non-empty (barcode)',
  [IssueType.Issue4WEmptyVNotempty]: 'Non-empty (no barcode)',
  [IssueType.Issue5WBcDuplicate]: 'Duplicate barcodes',
  [IssueType.Inconclusive1WBcVNotempty]: 'No barcode',
  [IssueType.Inconclusive2WBcVNoresult]: 'No information',
  [IssueType.Inconclusive3WEmptyVNoresult]: 'No information',
  [IssueType.Inconclusive4WInvalid]: 'Invalid WMS data',
  [IssueType.Inconclusive5VInvalid]: 'Invalid Verity data',
  [IssueType.C0014DoubleDepth]: 'Double depth location',
  [IssueResolvedType.Correct1WEmptyVEmpty]: 'None',
  [IssueResolvedType.Correct2WBcEqVBc]: 'None',
  [IssueResolvedType.NoneVDataOutdated]: 'None',
  [IssueResolvedType.NoneSlotBlocked]: 'None',
  [IssueResolvedType.NoneWDataMissing]: 'None',
  [IssueResolvedType.NoneVDataMissing]: 'None',
  [IssueResolvedType.Null]: 'None',
} as const;

export type MuiColor = OverridableStringUnion<
  'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning',
  ChipPropsColorOverrides
>;

export const issueTypeEnumToColor: {
  [Key in IssueType | IssueResolvedType]: MuiColor;
} = {
  [IssueType.Issue1WBcVEmpty]: 'error',
  [IssueType.Issue2WBcNeVBc]: 'error',
  [IssueType.Issue3WEmptyVBc]: 'error',
  [IssueType.Issue4WEmptyVNotempty]: 'error',
  [IssueType.Issue5WBcDuplicate]: 'error',
  [IssueType.Inconclusive1WBcVNotempty]: 'warning',
  [IssueType.Inconclusive2WBcVNoresult]: 'warning',
  [IssueType.Inconclusive3WEmptyVNoresult]: 'warning',
  [IssueType.Inconclusive4WInvalid]: 'warning',
  [IssueType.Inconclusive5VInvalid]: 'warning',
  [IssueType.C0014DoubleDepth]: 'secondary',
  [IssueResolvedType.Correct1WEmptyVEmpty]: 'default',
  [IssueResolvedType.Correct2WBcEqVBc]: 'default',
  [IssueResolvedType.NoneVDataOutdated]: 'default',
  [IssueResolvedType.NoneSlotBlocked]: 'default',
  [IssueResolvedType.NoneWDataMissing]: 'default',
  [IssueResolvedType.NoneVDataMissing]: 'default',
  [IssueResolvedType.Null]: 'default',
};
