import findIndex from 'lodash/findIndex';

// variables, functions, configurations
import { TAB_NAMES } from '../settings';
import { ITableTabProp, TNumberOfItemsInTabs } from '../../interfaces';

const getTabsAccessibleToUser = (REPORTS_TABLE_TABS: ITableTabProp[]) =>
  REPORTS_TABLE_TABS?.filter((tab) => tab.show);

export interface IReportTabsParams {
  REPORTS_TABLE_TABS: ITableTabProp[];
  numberOfReportsFinished?: number;
  numberOfReportsOngoing?: number;
  numberOfReportsScheduled?: number;
  numberOfReportsToReview?: number;
  numberOfReportsArchived?: number;
}

export const reportsTabs = ({
  REPORTS_TABLE_TABS,
  numberOfReportsFinished,
  numberOfReportsOngoing,
  numberOfReportsScheduled,
  numberOfReportsToReview,
  numberOfReportsArchived,
}: IReportTabsParams) => {
  let tabs: any = [];

  const numberOfItemsInTabs = {
    FINISHED: numberOfReportsFinished,
    ONGOING: numberOfReportsOngoing,
    SCHEDULED: numberOfReportsScheduled,
    'TO REVIEW': numberOfReportsToReview,
    ARCHIVED: numberOfReportsArchived,
  };

  getTabsAccessibleToUser(REPORTS_TABLE_TABS) &&
    getTabsAccessibleToUser(REPORTS_TABLE_TABS)?.forEach((tab) => {
      // This prevents rendering of tabs that require special permissions
      // in case currently logged in user is a Client user

      tabs = [
        ...tabs,
        {
          icon: tab.icon,
          label: `${tab.label} (${numberOfItemsInTabs[tab.label as TNumberOfItemsInTabs]})`,
          show: tab.show,
          orderBy: tab.orderBy,
          order: tab.order,
        },
      ];
    });

  return tabs;
};

export const getReportsTabsMappings = (REPORTS_TABLE_TABS: any) => ({
  finishedTab: findIndex(
    getTabsAccessibleToUser(REPORTS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.REPORTS.FINISHED,
  ),
  ongoingTab: findIndex(
    getTabsAccessibleToUser(REPORTS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.REPORTS.ONGOING,
  ),
  scheduledTab: findIndex(
    getTabsAccessibleToUser(REPORTS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.REPORTS.SCHEDULED,
  ),
  toReviewTab: findIndex(
    getTabsAccessibleToUser(REPORTS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.REPORTS['TO REVIEW'],
  ),
  archivedTab: findIndex(
    getTabsAccessibleToUser(REPORTS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.REPORTS.ARCHIVED,
  ),
});
