import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { find, findIndex, includes, inRange, isArray, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';

import { singleRequestHandler } from 'common/requestHelpers';
import { getSanitizedInputValues } from 'common/functions/otherFunctions';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { DISPLAY_VERITY_STATES, VERITY_STATES, WMS_STATES } from 'common/slotStates';
import { ISSUE_BADGE_COLORS, ISSUE_BADGE_COLORS_KEYS } from 'common/colors';
import {
  DISPLAY_ISSUE_TYPES,
  DISPLAY_ISSUE_TYPES_KEYS,
  DISPLAY_ISSUE_TYPES_MESSAGE,
  ISSUE_STATES,
} from 'common/issueTypesAndStates';

import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import Spinner from 'components/common/Spinner';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import { ImageCarouselContainer } from 'components/common/ImageCarousel/ImageCarouselContainer';
import Badge from 'components/common/Badge';
import { Box } from 'components/common/Box';
import ReportStore from 'udb/features/reports/reducer/report-store/ReportStore';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useSettingsStore } from '../../../store/Settings/settingsStore';
import WarehouseStore from '../../../store/WarehouseStore';
import IssuesStore from '../../../store/IssuesStore';
import { useRequestController } from '../../../hooks';

import { locationStyles } from './styles';

import ModalBase from '../ModalBase';
import EnhancedTable from '../../EnhancedTable';
import { ShareFeedback } from './ShareFeedback/ShareFeedback';
import { FeedbackReasonType } from './ShareFeedback/feedbackReason.model';
import {
  IFacilityModalsState,
  ILocationData,
} from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { IIssueST, ISlotStatusST } from '../../../interfaces/slotsAndIssuesInterfaces';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import { LocationModalHelp } from './LocationModalHelp';

const OVERWRITE_MODES = {
  MANUAL: 'manually',
  USE_VERITY: 'original',
  USE_WMS: 'expected',
};

interface ILocationModalProps {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  data: ILocationData | any[]; // This needs to be fixed
  parentPage: IFacilityModalsState['parentPage'];
  reportId: string | null | undefined;
}

export const initialLocationData: ILocationData = {
  userOverride: '',
  verityState: '-',
  location: '',
  version: '-',
  verityValue: '',
  wmsState: '-',
  wmsValue: '',
  slotStatusVersion: '',
  isReview: false,
  userOverrideValue: '',
  length: 0,
  issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
};

interface IOverwritingItem {
  verity_correct: boolean | string;
  set_to_expected: boolean;
  barcodes: string[];
  state: string;
  comment: string;
}

const LocationModal = (props: ILocationModalProps) => {
  const { opened, closeModal, refreshDataOnClose, data, parentPage, reportId } = props;

  const { classes } = locationStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const { WHS_FULL_REPORT_COLUMNS, REPORT_FULL_REPORT_COLUMNS } = useSettingsStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilityModalsState } = useFacilityModalsStore();

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [carouselSpinner, setCarouselSpinner] = useState(false);
  const [locationsData] = useState(data);
  const [locationData, setLocationData] = useState<ILocationData>(initialLocationData);
  const [locationImageURLs, setLocationImageURLs] = useState([]);
  const [imageAvailabilityMessage, setImageAvailabilityMessage] = useState('');
  const [currentLocationData, setCurrentLocationData] = useState(
    isArray(data) ? find(data, (issue) => issue.selected) : data,
  );
  const [currentLocationIndex, setCurrentLocationIndex] = useState(
    isArray(data) ? findIndex(data, (issue) => issue.selected) : 0,
  );

  // state variables for overwrite form
  const [overwriteVerityCorrect, setOverwriteVerityCorrect] = useState('');
  const [overwriteMode, setOverwriteMode] = useState('');
  const [overwriteContentType, setOverwriteContentType] = useState('');
  const [overwriteBarcodes, setOverwriteBarcodes] = useState<string>('');
  const [overwriteComment, setOverwriteComment] = useState('');
  const [didUserOverwrite, setDidUserOverwrite] = useState(false);

  const { currentSystemId: systemId, facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const showAmendForm = facilitySettings.allow_user_amend || false;
  const showReportLocationForm =
    facilitySettings.support_email_recipients !== '' &&
    userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

  const { requestController } = useRequestController(`${parentPage} - LocationModal`);
  const { enqueueSnackbar } = useSnackbar();

  // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function
  // const { name: facilityName } = state.facilityData;

  const clearFormData = useCallback(() => {
    setOverwriteVerityCorrect('');
    setOverwriteMode('');
    setOverwriteContentType('');
    setOverwriteBarcodes('');
    setOverwriteComment('');
  }, [
    setOverwriteVerityCorrect,
    setOverwriteMode,
    setOverwriteContentType,
    setOverwriteBarcodes,
    setOverwriteComment,
  ]);

  const goToPreviousLocation = useCallback(
    (locationsData: ILocationData[], currentLocationIndex: number) => {
      // prevent navigation to previous location
      // if user is at first location
      if (currentLocationIndex !== 0) {
        clearFormData();
        setCurrentLocationData(locationsData[currentLocationIndex - 1]);
        setCurrentLocationIndex(currentLocationIndex - 1);

        searchParams.set('location', locationsData[currentLocationIndex - 1].location);
        setSearchParams(searchParams);
      }
    },
    [clearFormData, searchParams, setSearchParams],
  );

  const goToNextLocation = useCallback(
    (locationsData: ILocationData[], currentLocationIndex: number) => {
      if (currentLocationIndex !== locationsData.length - 1) {
        clearFormData();
        setCurrentLocationData(locationsData[currentLocationIndex + 1]);
        setCurrentLocationIndex(currentLocationIndex + 1);

        searchParams.set('location', locationsData[currentLocationIndex + 1].location);
        setSearchParams(searchParams);
      }
    },
    [clearFormData, searchParams, setSearchParams],
  );

  const navigateToLocation = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      const data = Array.isArray(locationsData) ? locationsData : [locationsData];
      if (event.key === 'ArrowLeft') {
        goToPreviousLocation(data, currentLocationIndex);
      } else if (event.key === 'ArrowRight') {
        goToNextLocation(data, currentLocationIndex);
      }
    },
    [currentLocationIndex, locationsData, goToNextLocation, goToPreviousLocation],
  );

  useEffect(() => {
    document.addEventListener('keydown', navigateToLocation);
    return () => {
      document.removeEventListener('keydown', navigateToLocation);
    };
  }, [navigateToLocation]);

  const overwriteVerityValue = useCallback(
    ({
      overwritingItem,
      location,
      version,
      callbackSuccess,
      callbackError,
    }: {
      overwritingItem: IOverwritingItem;
      location: string;
      version: number | '-';
      callbackSuccess: () => void;
      callbackError: (e: any) => void;
    }) => {
      requestController.doRequest({
        request: IssuesStore.userOverride,
        requestParams: [
          {
            systemId,
            data: overwritingItem,
            slot_label: location,
            version,
            isReview: false,
          },
        ],
        callbackSuccess: () => callbackSuccess(),
        callbackError: (e) => callbackError(e),
        messageSuccess: `Location ${location} was successfully modified`,
        messageErrorFallback: `Location ${location} could not be modified`,
      });
    },
    [requestController, systemId],
  );

  // update the modal's internal data structures
  // - the list of locations to navigate through
  // - the location currently being displayed
  const updateCurrentLocationAndLocationSet = useCallback(
    (loc: ISlotStatusST, issues: IIssueST[]) => {
      const rowDataAux = getRowForFullReportTable(loc.slot_label, loc, issues);
      const locData = rowDataAux.actions.data;

      setCurrentLocationData(locData);
    },
    [],
  );

  const refreshLocationData = useCallback(
    (locationLabel: string) => {
      clearFormData();

      switch (parentPage) {
        case 'WarehouseStatus':
          requestController.doRequest({
            request: WarehouseStore.getLocationsData,
            requestParams: [systemId, locationLabel, 1],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.locationsData[locationLabel],
                r.locationsData[locationLabel].issues,
              ),
            messageErrorFallback: 'Location Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        case 'Report':
          requestController.doRequest({
            request: ReportStore.getReportData,
            requestParams: [
              systemId,
              reportId,
              locationLabel,
              locationLabel,
              requestController.signal,
            ],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.reportData.locations_data[locationLabel],
                r.reportData.issues[locationLabel],
              ),
            messageErrorFallback: 'Report Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        default:
          break;
      }
    },
    [
      clearFormData,
      parentPage,
      reportId,
      requestController,
      systemId,
      updateCurrentLocationAndLocationSet,
    ],
  );

  const handleSubmit = useCallback(() => {
    setSpinnerLoading(true);

    const barcodes = getSanitizedInputValues(overwriteBarcodes);

    const overwritingItem: IOverwritingItem = {
      verity_correct: locationData.userOverride ? false : overwriteVerityCorrect,
      set_to_expected: overwriteMode === OVERWRITE_MODES.USE_WMS,
      barcodes: overwriteContentType === VERITY_STATES.BARCODE ? barcodes : [],
      state: overwriteContentType || locationData.verityState,
      comment: overwriteComment,
    };

    const locationLabel = locationData.location;
    const locationVersion = locationData.version;

    overwriteVerityValue({
      overwritingItem,
      location: locationLabel,
      version: locationVersion,
      callbackSuccess: () => {
        refreshLocationData(locationLabel);
        setDidUserOverwrite(true);
      },
      callbackError: () => {
        setSpinnerLoading(false);
      },
    });
  }, [
    locationData.location,
    locationData.userOverride,
    locationData.verityState,
    locationData.version,
    overwriteBarcodes,
    overwriteComment,
    overwriteContentType,
    overwriteMode,
    overwriteVerityCorrect,
    overwriteVerityValue,
    refreshLocationData,
  ]);

  const validateAndSubmitFormData = useCallback(() => {
    const {
      barcode_min_length: barcodeMinLength,
      barcode_max_length: barcodeMaxLength,
      barcode_invalid_lengths: barcodeInvalidLengths,
    } = stateFacilityLevel.facilitySettings;

    // Check if barcode(s) are of length specified in settings
    if (overwriteContentType === VERITY_STATES.BARCODE) {
      const barcodeValid = getSanitizedInputValues(overwriteBarcodes).reduce(
        (acc, barcode) =>
          // result accumulator (starts at true)
          acc &&
          // check if barcode has invalid length
          !includes(barcodeInvalidLengths, barcode.length) &&
          // check if barcode respects min and max lengths
          inRange(barcode.length, barcodeMinLength, barcodeMaxLength + 1),
        // accumulator's initial value
        true,
      );

      if (!barcodeValid) {
        const invalidLengthWarning = !isEmpty(barcodeInvalidLengths)
          ? `Barcodes of length ${barcodeInvalidLengths} are not allowed.`
          : '';
        let snackbarMessage = '';

        if (barcodeMinLength === barcodeMaxLength) {
          snackbarMessage = `The entered barcodes must be ${barcodeMinLength} digits long. ${invalidLengthWarning}`;
        } else {
          snackbarMessage = `The entered barcodes must be between ${barcodeMinLength} and ${barcodeMaxLength} digits long. ${invalidLengthWarning}`;
        }

        enqueueSnackbar(snackbarMessage, { variant: 'error', preventDuplicate: true });

        return;
      }
    }

    handleSubmit();
  }, [
    enqueueSnackbar,
    handleSubmit,
    overwriteBarcodes,
    overwriteContentType,
    stateFacilityLevel.facilitySettings,
  ]);

  // Change form content based on the overwrite mode selected by the user
  useEffect(() => {
    switch (overwriteMode) {
      case OVERWRITE_MODES.MANUAL:
        setOverwriteContentType('');
        setOverwriteBarcodes('');
        break;

      case OVERWRITE_MODES.USE_VERITY:
        setOverwriteContentType(locationData.verityState);
        setOverwriteBarcodes(
          locationData.verityState === VERITY_STATES.BARCODE ? locationData.verityValue : '',
        );
        break;

      case OVERWRITE_MODES.USE_WMS:
        setOverwriteContentType(locationData.wmsState || '');
        setOverwriteBarcodes(
          locationData.wmsState === WMS_STATES.BARCODE ? locationData.wmsValue : '',
        );
        break;
      default:
        setOverwriteContentType('');
        setOverwriteBarcodes('');
        break;
    }
  }, [overwriteMode, locationData]);

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const renderImageCarousel = (locationData: ILocationData, locationImageURLs: string[]) => {
    const { location, verityState, slotStatusVersion, isReview } = locationData;

    if (!isEmpty(locationImageURLs)) {
      return (
        <ImageCarouselContainer
          systemId={systemId!}
          location={location}
          isLoading={carouselSpinner}
          imageURLs={locationImageURLs}
          slotStatusVersion={slotStatusVersion}
          isReview={isReview}
          imageNavigationOnTop={true}
          keyboardNavigation="vertical"
        />
      );
    }
    const displayText =
      verityState === '-'
        ? 'Images are not available because Verity has no data for this location.'
        : imageAvailabilityMessage;

    return (
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {displayText}
        </Typography>
      </div>
    );
  };

  /**
   * rendering navigation buttons
   */
  const renderNavigationButtons = () => {
    const data = Array.isArray(locationsData) ? locationsData : [locationsData];
    return (
      <Box component="div" alignItems="flex-end" flexDirection="column" display="flex">
        <Typography color="textSecondary" variant="h6" className={classes.navigationButtons}>
          <IconButton
            size="medium"
            disabled={currentLocationIndex === 0}
            onClick={() => goToPreviousLocation(data, currentLocationIndex)}
            data-testid="previous-location-button"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Tooltip
            title={
              facilityModalsState.locationInfo
                ? `from ${facilityModalsState.locationInfo.tab} tab ${
                    facilityModalsState.locationInfo.searchTerm &&
                    `filtered by term "${facilityModalsState.locationInfo.searchTerm}"`
                  }`
                : ''
            }
          >
            <Typography data-testid="curr-loc">
              {`${currentLocationIndex + 1} of ${data.length}`}
            </Typography>
          </Tooltip>

          <IconButton
            size="medium"
            disabled={currentLocationIndex === data.length - 1}
            onClick={() => goToNextLocation(data, currentLocationIndex)}
            data-testid="next-location-button"
          >
            <NavigateNextIcon />
          </IconButton>
        </Typography>
      </Box>
    );
  };

  const getSlotImages = useCallback(() => {
    if (currentLocationData.slotStatusVersion !== '-') {
      singleRequestHandler({
        request: IssuesStore.getImages,
        requestParams: [
          systemId,
          currentLocationData.location,
          currentLocationData.slotStatusVersion,
          currentLocationData.isReview,
        ],
        dispatcher: enqueueSnackbar,
        callbackBeforeSend: () => setCarouselSpinner(true),
        callbackSuccess: (r: { data: { urls: any; num_requested: any } }) => {
          setLocationImageURLs(() => (r.data?.urls ? r.data?.urls : []));
          const numPendingImages = r.data?.num_requested ? r.data?.num_requested : 0;
          const numCurrentlyAvailableImages = r.data?.urls ? r.data?.urls.length : 0;

          if (numCurrentlyAvailableImages === 0 && numPendingImages === 0) {
            setImageAvailabilityMessage('There are no images available for this location.');
          } else if (numPendingImages > 0) {
            // We don't explicity tell the user how many pictures will be uploaded, because pending image requests may fail.
            // The client user does not need to know that some requests failed, as long as they don't all fail.
            // Exposing this number could result in a user expecting 3 pictures, and seeing the counter go down as their
            // requests fail, which would cause a lot of confusion.

            setImageAvailabilityMessage('Waiting for images to be uploaded.');
          } else {
            setImageAvailabilityMessage('');
          }

          // TODO::TR::2021-08-19:: Design a way to display the 3 counters to the verity users:
          // numFailedImages, numPendingImages, numCurrentlyAvailableImages
          // const numFailedImages = r.data?.num_failed ? r.data?.num_failed : 0;
        },
        callbackFinally: () => setCarouselSpinner(false),
      });
    } else {
      setLocationImageURLs(() => []);
    }
  }, [
    currentLocationData.location,
    currentLocationData.slotStatusVersion,
    currentLocationData.isReview,
    enqueueSnackbar,
    systemId,
  ]);

  const refreshData = () => {
    setDidUserOverwrite(true);
    refreshLocationData(locationData?.location);
  };

  const sendLocationInfo = (feedbackCategories: FeedbackReasonType[], message: string) => {
    const slotStatusVersion =
      currentLocationData.slotStatusVersion === '-' ? null : currentLocationData.slotStatusVersion;
    // Get the current URL and pass it as link to get back to this modal.
    // NOTE: as the location feedback can be done only form here, this is sufficient.
    const linkToTheCurrentLocation = window.location.href;

    return singleRequestHandler({
      request: WarehouseStore.sendLocationInfoEmail,
      requestParams: [
        systemId,
        currentLocationData.location,
        slotStatusVersion,
        currentLocationData?.wmsSlotStatusVersion || null,
        parentPage,
        reportId || null,
        feedbackCategories,
        message,
        linkToTheCurrentLocation,
      ],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinnerLoading(true),
      callbackFinally: () => setSpinnerLoading(false),
      messageSuccess: `Feedback on location ${currentLocationData.location} was submitted to Verity.`,
    });
  };

  // Question: why is this needed? locationData == currentLocationData?
  // update current location data
  useEffect(() => {
    // Get images for this location
    // Note: slotStatusVersion does not exist when there
    // is no verity_slot_status for a given location
    getSlotImages();

    // Update location data
    setLocationData(() => ({
      location: currentLocationData.location,
      issueState: currentLocationData.state,
      issueType: currentLocationData.issueType,

      wmsValue: currentLocationData.wmsValue,
      wmsState: currentLocationData.wmsState,
      wmsDate: currentLocationData.wmsDate,
      wmsArticleNo: currentLocationData.wmsArticleNo,
      wmsDescription: currentLocationData.wmsDescription,
      wmsQty: currentLocationData.wmsQty,
      wmsSlotStatusVersion: currentLocationData.wmsSlotStatusVersion,

      verityValue: currentLocationData.verityValue,
      verityState: currentLocationData.verityState,
      verityDate: currentLocationData.verityDate,
      verityAmended: currentLocationData.isReview ? currentLocationData.verityAmended : '',
      originalVerityState: currentLocationData.originalVerityState,
      originalVerityValue: currentLocationData.originalVerityValue,

      userOverride: currentLocationData.userOverride,
      userOverrideValue: currentLocationData.userOverrideValue,
      userOverrideUserName: currentLocationData.userOverrideUserName,
      showContentSetByUser: currentLocationData.userOverride,

      // Use version if only if it exists.
      // A version does not exist when there is no verity_slot_status for a given location
      version:
        currentLocationData.slotStatusVersion !== '-'
          ? currentLocationData.slotStatusVersion
          : null,

      length: currentLocationData.length,
      // Data to be displayed on the table
      rowData: currentLocationData.rowData,

      isReview: currentLocationData.isReview,
      isSnoozed: currentLocationData.isSnoozed,
      slotStatusVersion: currentLocationData.slotStatusVersion,
    }));
  }, [currentLocationData, getSlotImages]);

  useEffect(() => {
    if (
      stateFacilityLevel.inventory.imageAvailable?.data?.image_id &&
      currentLocationData.imageIds.includes(
        stateFacilityLevel.inventory.imageAvailable.data.image_id,
      )
    ) {
      getSlotImages();
    }
  }, [stateFacilityLevel.inventory.imageAvailable, currentLocationData.imageIds, getSlotImages]);

  const title = `${
    DISPLAY_ISSUE_TYPES[locationData.issueType] ? DISPLAY_ISSUE_TYPES[locationData.issueType] : ''
  } at ${locationData.location}`;

  const isSnoozable =
    (locationData.rowData?.actions?.actions as { label: string; disabled: boolean }[])?.find(
      (l) => l.label === 'Snooze',
    )?.disabled === false;

  const issueId = locationData.rowData?.issueId;

  const snooze = () =>
    IssuesStore.toggleSnoozeIssue(systemId!, issueId, ISSUE_ACTIONS.SNOOZE).then(() => {
      refreshLocationData(locationData?.location);
    });

  const isSnoozed = locationData.rowData?.state === ISSUE_STATES.SNOOZED;

  return (
    <ModalBase
      help={LocationModalHelp}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      disableBackdropClick={true}
      fullScreen
      title={
        <>
          <Box textAlign="left" p={3}>
            <Box display="flex" alignItems="center">
              <Badge
                bgcolor={
                  ISSUE_BADGE_COLORS[
                    (currentLocationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE'
                  ]?.background
                }
                color={
                  ISSUE_BADGE_COLORS[
                    (currentLocationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE'
                  ]?.text
                }
                bigBadge={false}
              >
                {(currentLocationData?.state && `${currentLocationData.state} ISSUE`) || 'NO ISSUE'}
              </Badge>
              <Typography
                className={classes.modalTitle}
                color="secondary"
                variant="h5"
                data-testid="title"
              >
                {title}
              </Typography>
              <IconButton
                aria-label="refresh"
                onClick={() => refreshLocationData(locationData?.location)}
                size="large"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <Box display="flex" className={classes.subtitleAndNavigation}>
              <Typography style={{ fontWeight: 400 }} color="textSecondary" variant="h6">
                {DISPLAY_ISSUE_TYPES_MESSAGE[locationData.issueType] || 'No issue at this location'}
              </Typography>
              {isArray(locationsData) && locationsData.length > 1 && renderNavigationButtons()}
            </Box>
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
            data-testid="c-close-modal-btn"
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </>
      }
      handleClose={handleCloseModal}
    >
      {spinnerLoading && <Spinner />}
      <EnhancedTable
        disablePagination={true}
        showHeader={false}
        headCells={
          parentPage === 'WarehouseStatus' ? WHS_FULL_REPORT_COLUMNS : REPORT_FULL_REPORT_COLUMNS
        }
        rows={locationData?.rowData ? [locationData.rowData] : []}
        isLoading={false}
        refreshData={{
          refreshData,
        }}
        showSearch={false}
        hideToolbar={true}
        enableHoverOnRows={false}
        allowSorting={false}
        currentActiveSpinner={{}}
        headerButton={undefined}
        tableFor=""
        tableSubtitle=""
        tableTitle=""
      />

      <Grid spacing={2} container>
        <Grid lg={4} md={5} sm={12} item>
          <Paper className={classes.paper} elevation={3}>
            {renderImageCarousel(locationData, locationImageURLs)}
          </Paper>
        </Grid>
        {
          // Show forms section
          (showAmendForm || showReportLocationForm) && (
            <Grid lg={8} md={7} sm={12} item>
              <Paper className={classes.paper} elevation={3}>
                {
                  // Locations with no verity state can not be overwritten (the BE does not allow it)
                  showAmendForm && locationData.verityState === '-' && (
                    <div className={classes.imageContainer}>
                      <Typography
                        className={classes.imageContainerText}
                        align="center"
                        color="textSecondary"
                        variant="h6"
                      >
                        Locations for which Verity has no data can not be edited.
                      </Typography>
                    </div>
                  )
                }
                {
                  // Locations with no verity state can be overwritten
                  showAmendForm && locationData.verityState !== '-' && (
                    <Accordion className="customAccordion">
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          align="right"
                          className={classes.accordionTitle}
                          color="textSecondary"
                          component="p"
                        >
                          Edit content found
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={classes.formWrapper}
                          style={{ maxWidth: '100%' }}
                        >
                          <div>
                            {
                              // Set overwrite content correct
                              !locationData.userOverride && (
                                <FormControl className={classes.formControl} variant="outlined">
                                  <CustomSelect
                                    id="SelectOverwriteVerityCorrect"
                                    name="SelectOverwriteVerityCorrect"
                                    testId="SelectOverwriteVerityCorrect"
                                    error={false}
                                    errorMessage=""
                                    defaultValue=""
                                    disabled={false}
                                    variant="outlined"
                                    label="Is the content found by Verity correct?"
                                    value={overwriteVerityCorrect}
                                    onChange={(e) => setOverwriteVerityCorrect(e.target.value)}
                                    valueOptions={[
                                      {
                                        value: false,
                                        label: 'No',
                                      },
                                      {
                                        value: true,
                                        label: 'Yes',
                                      },
                                    ]}
                                  />
                                </FormControl>
                              )
                            }

                            {
                              // Set overwrite mode
                              <FormControl className={classes.formControl} variant="outlined">
                                <CustomSelect
                                  id="SelectModifyValue"
                                  name="SelectModifyValue"
                                  testId="SelectModifyValue"
                                  error={false}
                                  errorMessage=""
                                  defaultValue=""
                                  variant="outlined"
                                  disabled={
                                    overwriteVerityCorrect === '' && !locationData.userOverride
                                  }
                                  label="How do you want to modify this value?"
                                  value={overwriteMode}
                                  onChange={(e) => setOverwriteMode(e.target.value)}
                                  valueOptions={[
                                    {
                                      // disable the selection of "expected content" only when:
                                      // - wms state is not invalid
                                      // - content found is ot the same as wmsValue
                                      //    - - wmsValue === verityValue, when userOverrideValue is not defined ("-")
                                      //    - - wmsValue === userOverrideValue, otherwise
                                      disabled:
                                        locationData.wmsState === WMS_STATES.INVALID ||
                                        (locationData.userOverrideValue === '' &&
                                          locationData.verityValue === locationData.wmsValue) ||
                                        (locationData.userOverrideValue !== '' &&
                                          locationData.userOverrideValue === locationData.wmsValue),
                                      value: OVERWRITE_MODES.USE_WMS,
                                      label: `Use expected content value: ${
                                        locationData.wmsValue === ''
                                          ? 'Empty Location'
                                          : locationData.wmsValue
                                      }`,
                                    },
                                    {
                                      // disable the selection of verity value only when:
                                      // - there is no user overwrite, or
                                      // - there is a user overwrite and it is the same as the original verity value
                                      disabled:
                                        locationData.userOverrideValue === '' ||
                                        (locationData.userOverrideValue !== '' &&
                                          locationData.userOverrideValue ===
                                            locationData.verityValue) ||
                                        locationData.verityValue === 'Unreachable Location',
                                      value: OVERWRITE_MODES.USE_VERITY,
                                      label: `Use original verity value: ${locationData.verityValue}`,
                                    },
                                    {
                                      value: OVERWRITE_MODES.MANUAL,
                                      label: 'Enter value manually',
                                    },
                                  ]}
                                />
                              </FormControl>
                            }

                            {
                              // Set overwrite content type
                              <FormControl className={classes.formControl} variant="outlined">
                                <CustomSelect
                                  id="SelectContentType"
                                  name="SelectContentType"
                                  testId="SelectContentType"
                                  error={false}
                                  errorMessage=""
                                  defaultValue=""
                                  variant="outlined"
                                  disabled={overwriteMode !== OVERWRITE_MODES.MANUAL}
                                  label={`Select the type for the content for ${locationData.location}`}
                                  value={overwriteContentType}
                                  valueOptions={[
                                    {
                                      value: VERITY_STATES.BARCODE,
                                      label: DISPLAY_VERITY_STATES.BARCODE,
                                    },
                                    {
                                      value: VERITY_STATES.EMPTY,
                                      label: DISPLAY_VERITY_STATES.EMPTY,
                                    },
                                    {
                                      value: VERITY_STATES.NOT_EMPTY,
                                      label: DISPLAY_VERITY_STATES.NOT_EMPTY,
                                    },
                                  ]}
                                  onChange={(e) => setOverwriteContentType(e.target.value)}
                                />
                              </FormControl>
                            }
                            {overwriteContentType === VERITY_STATES.BARCODE && (
                              <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                  disabled={overwriteMode !== 'manually'}
                                  label={`Enter barcodes for ${locationData.location}`}
                                  variant="outlined"
                                  value={overwriteBarcodes}
                                  onChange={(e) => setOverwriteBarcodes(e.target.value)}
                                />
                              </FormControl>
                            )}

                            <FormControl className={classes.formControl} variant="outlined">
                              <TextField
                                disabled={overwriteMode === ''}
                                label="Why are you amending this value?"
                                multiline
                                rows={1}
                                variant="outlined"
                                value={overwriteComment}
                                onChange={(e) => setOverwriteComment(e.target.value)}
                              />
                            </FormControl>

                            <Button
                              className={classes.actionButton}
                              onClick={() => validateAndSubmitFormData()}
                              disabled={
                                (overwriteVerityCorrect === '' && !locationData.userOverride) ||
                                overwriteMode === '' ||
                                overwriteContentType === '' ||
                                (overwriteContentType === VERITY_STATES.BARCODE &&
                                  overwriteBarcodes === '')
                              }
                              variant="contained"
                              color="primary"
                            >
                              Save changes for {locationData.location}
                            </Button>
                          </div>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  )
                }
                {showReportLocationForm && (
                  <ShareFeedback
                    key={locationData.location}
                    isSnoozed={isSnoozed}
                    isSnoozable={isSnoozable}
                    snooze={snooze}
                    sendLocationInfo={sendLocationInfo}
                  />
                )}
              </Paper>
            </Grid>
          )
        }
      </Grid>
    </ModalBase>
  );
};

export default LocationModal;
