import { useState, useRef, forwardRef, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { Theme } from '@mui/material/styles';
import UndoIcon from '@mui/icons-material/Undo';

import { singleRequestHandler } from 'common/requestHelpers';
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import { IInventoryRequestST } from 'codegen/inventory_request/api';
import {
  ScheduleForm,
  ScheduleFormRef,
} from 'udb/features/reports/features/report-scheduler/ScheduleForm';
import {
  getInitialScheduleFormState,
  scheduleFormReducer,
} from 'udb/features/reports/features/report-scheduler/reducers/ScheduleFormReducer';
import RequestStore from '../../store/RequestStore/RequestStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

import ModalBase from './ModalBase';
import { IAddUpdateRequestParams } from '../../interfaces/requestsInterfaces';

const useStyles = makeStyles()((theme: Theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    margin: '22px 0',
  },
  gridItem: {
    maxWidth: '100%',
    width: '100%',
  },
  cardItem: {
    transition: '.3s ease',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  expandContent: {
    width: '100%',
  },
  selectInputBox: {
    marginBottom: '22px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  modalContent: {
    width: '550px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

/**
 * Properties for the EditInventoryRequestModal component
 */
type EditInventoryRequestModalProps = {
  /**
   * The inventory request to edit
   */
  requestItem: IInventoryRequestST;
  /**
   * Is the modal open
   */
  opened: boolean;
  /**
   * Callback to close the modal
   */
  closeModal: () => void;
  /**
   * Callback to save the changes
   */
  onConfirmUpdate: () => void;
};

// lint rule is disabled because forwardRef() need ref prop
// eslint-disable-next-line no-unused-vars
const EditInventoryRequestModal = forwardRef((props: EditInventoryRequestModalProps, ref) => {
  // props
  const { requestItem, opened, closeModal, onConfirmUpdate } = props;

  const [formState, formDispatch] = useReducer(
    scheduleFormReducer,
    getInitialScheduleFormState(requestItem),
  );

  const formRef = useRef<ScheduleFormRef>(null);

  const { stateFacilityLevel } = useFacilityLevelStore();

  const timezone = stateFacilityLevel.facilityData?.timezone;

  const [spinner, setSpinner] = useState(0);

  // store the original setup of the inventory request
  const originalRequest = requestItem;
  const reportSpec = requestItem.report_spec;

  const systemId = useLocation().pathname.split('/')[1];

  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Submit the editing request
   */
  const submitRequest = (data: IAddUpdateRequestParams) => {
    singleRequestHandler({
      request: RequestStore.updateRequest,
      requestParams: [systemId, requestItem.request_id, data],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
      messageSuccess: 'Inventory request updated successfully.',
      callbackSuccess: () => onConfirmUpdate(),
      callbackFinally: () => setSpinner(0),
    });
  };

  return (
    <ModalBase
      testId="c-edit-inventory-request-modal"
      opened={opened}
      contentClass={classes.modalContent}
      maxWidth="md"
      // width={{ width: '500px' }}
      handleClose={closeModal}
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              Editing inventory request
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              {
                // FIX-ME::TR::2021-06-09 - Process all this data outside the render return()
                requestItem && requestItem.report_name
                  ? `"${requestItem.report_name}" by ${
                      requestItem.requesting_user_email ? requestItem.requesting_user_email : 'n.a.'
                    }`
                  : `Inventory request by ${
                      requestItem.requesting_user_email ? requestItem.requesting_user_email : 'n.a.'
                    }`
              }
            </Typography>
          </Box>
        </Box>
      }
      actionButtons={
        <>
          <IconButton
            data-testid="c-edit-request-undo"
            color="primary"
            onClick={() =>
              formDispatch({
                type: 'INITIALIZE_EXISTING_REQUEST',
                payload: { reportSpec, originalRequest },
              })
            }
            aria-label="Undo"
          >
            <UndoIcon />
          </IconButton>
          <Button
            data-testid="c-edit-request-confirm"
            onClick={() => formRef.current?.validateAndReschedule()}
            variant="contained"
            color="primary"
            fullWidth
          >
            Confirm changes
          </Button>
          <Button
            data-testid="c-edit-request-cancel"
            onClick={closeModal}
            variant="outlined"
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
        </>
      }
    >
      <ScheduleForm
        ref={formRef}
        systemId={systemId}
        reportSpec={reportSpec}
        timezone={timezone}
        originalRequest={originalRequest}
        onSubmit={submitRequest}
        state={formState}
        dispatch={formDispatch}
      />
      {Boolean(spinner) && <Spinner />}
    </ModalBase>
  );
});

export default EditInventoryRequestModal;
