import { ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum } from 'codegen/warehouse_status';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';

export type FeedbackReasonType = ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum;

export type FeedbackReason = {
  type: Exclude<FeedbackReasonType, 'OTHER'>;
  name: string;
};
export type FeedbackReasons = FeedbackReason[];

export const feedbackReasonsShortcutMap: Record<Exclude<FeedbackReasonType, 'OTHER'>, string> = {
  GLARE_OR_REFLECTION: '1',
  PICTURE_TOO_FAR: '2',
  BLURRY_PICTURE: '3',
  BARCODE_LABEL_ISSUE: '4',
  INCORRECT_BARCODE: '5',
  NO_BARCODE_BUT_BARCODE: '6',
  EMPTY_BUT_NON_EMPTY: '7',
  NON_EMPTY_BUT_EMPTY: '8',
  BARCODE_NOT_VISIBLE: '1',
  BARCODE_PARTIALLY_VISIBLE: '2',
  BARCODE_NOT_READABLE_BLUR: '3',
  BARCODE_NOT_READABLE_GLARE: '4',
  BARCODE_IGNORED_BUT_VISIBLE: '5',
  BARCODE_OBSTRUCTED: '6',
  SLOT_OUT_OF_BOUNDS: '7',
  EMPTY_SLOT_DETECTED: '8',
  BARCODE_IRRELEVANT: '9',
};
export const verityFeedbackReasons: FeedbackReasons = [
  {
    type: 'BARCODE_NOT_VISIBLE',
    name: 'Barcode ignored in diagnostic - not visible / cut by tile',
  },
  {
    type: 'BARCODE_PARTIALLY_VISIBLE',
    name: 'Barcode ignored in diagnostic - covered, damaged, not on pallet',
  },
  {
    type: 'BARCODE_NOT_READABLE_GLARE',
    name: 'Barcode ignored in diagnostic - glare',
  },
  {
    type: 'BARCODE_NOT_READABLE_BLUR',
    name: 'Relevant Barcode ignored in diagnostic view - blurry',
  },
  {
    type: 'BARCODE_IGNORED_BUT_VISIBLE',
    name: 'Barcode ignored in diagnostic - clearly visible, undamaged',
  },
  { type: 'BARCODE_OBSTRUCTED', name: 'Unexpected goods within slot boundaries' },
  { type: 'SLOT_OUT_OF_BOUNDS', name: 'Slot boundaries do not align with visible racking' },
  { type: 'EMPTY_SLOT_DETECTED', name: 'Empty Slot Detection - wrong occupancy' },
  { type: 'BARCODE_IRRELEVANT', name: 'Irrelevant Barcode not ignored in diagnostic view' },
];

export const clientFeedbackReasons: FeedbackReasons = [
  { type: 'GLARE_OR_REFLECTION', name: 'Glare or reflection issue on barcode' },
  { type: 'PICTURE_TOO_FAR', name: 'Picture too far left/right/up/down' },
  { type: 'BLURRY_PICTURE', name: 'Barcode is blurry in picture' },
  { type: 'BARCODE_LABEL_ISSUE', name: 'Barcode label is covered, damaged, wrapped, or similar' },
  { type: 'INCORRECT_BARCODE', name: 'Incorrect barcode detected' },
  { type: 'NO_BARCODE_BUT_BARCODE', name: 'Barcode visible but not detected' },
  { type: 'EMPTY_BUT_NON_EMPTY', name: 'Detected empty instead of non-empty' },
  { type: 'NON_EMPTY_BUT_EMPTY', name: 'Detected non-empty instead of empty' },
];

export const feedbackReasonsForUser = () =>
  userHasPermission(PERMISSION.ADVANCED_SHARE_FEEDBACK_ON_LOCATION)
    ? verityFeedbackReasons
    : clientFeedbackReasons;
