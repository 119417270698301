import { Chip, Typography } from '@mui/material';
import { Box } from 'components/common/Box';

export const LocationModalHelp = (
  <Box
    textAlign="left"
    paddingTop="1em"
    paddingRight="2em"
    paddingLeft="2em"
    paddingBottom="2em"
    width="28em"
  >
    <Typography variant="h5">Keyboard shortcuts</Typography>
    <Typography variant="h6" mt="1em">
      Navigation
    </Typography>
    <Typography mt="1em">
      <Chip label="&larr; (left arrow)" variant="outlined" style={{ marginRight: '1em' }} />
      Go to the previous Issue
    </Typography>
    <Typography mt="1em">
      <Chip label="&rarr; (right arrow)" variant="outlined" style={{ marginRight: '1em' }} />
      Go to the next Issue
    </Typography>
    <Typography mt="1em">
      <Chip label="ESC" variant="outlined" style={{ marginRight: '1em' }} />
      Close the dialog
    </Typography>
    <Typography variant="h6" mt="1.5em">
      Feedback
    </Typography>
    <Typography mt="1em">
      <Chip label="1-9 keys" variant="outlined" style={{ marginRight: '1em' }} />
      Toggle feedback categories
    </Typography>
    <Typography mt="1em">
      <Chip label="&#8626; (Enter)" variant="outlined" style={{ marginRight: '1em' }} />
      Submit feedback
    </Typography>
    <Typography variant="h6" mt="1.5em">
      Picture Carousel
    </Typography>
    <Typography mt="1em">
      <Chip label="&uarr; (up arrow)" variant="outlined" style={{ marginRight: '1em' }} />
      Show the previous Picture
    </Typography>
    <Typography mt="1em">
      <Chip label="&darr; (down arrow)" variant="outlined" style={{ marginRight: '1em' }} />
      Show the next Picture
    </Typography>
  </Box>
);
