import { ReactElement } from 'react';
import { IReportST } from 'codegen/report';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../../features/permissions/userHasPermission';
import { IRequestController } from '../../../hooks';
import { IReportRequestActionInternal } from '../../../interfaces';
import { getArchivedReports } from './utils/get-archived-reports/getArchivedReports';
import { getFinishedReports } from './utils/get-finished-reports/getFinishedReports';
import { getOngoingAndToReviewReports } from './utils/get-ongoing-reports/getOngoingAndToReviewReports';
import { getScheduledReports } from './utils/get-scheduled-reports/getScheduledReports';

/**
 * type for useLocation hook
 */
export type LocationProps = {
  state: {
    activeTab: string;
    loadSince: Dates;
  };
};

/**
 * type for dates range
 */
export type Dates = {
  from: Date;
  until: Date;
};

/**
 * type for report
 */
export type Report = {
  id: string;
  progress: number;
  status: string;
  allLocations: string;
  allowRedirection: boolean;
  completion: number;
  finishedAt: string;
  issues: number;
  locationsToScan: number;
  recurrence: string;
  reportName: string;
  reportType: string;
  requestParams: string;
  scannedLocations: number;
  scheduledBy: string;
  scheduledFor: string;
  startedAt: string;
  actions: IReportRequestActionInternal;
  priority: ReactElement;
  rawData: IReportST;
};

/**
 * type for reports
 */
export type Reports = {
  reportsOngoingAll: Report[];
  reportsToReviewAll: Report[];
  reportsScheduledAll: Report[];
  reportsArchivedAll: Report[];
  reportsFinishedAll: Report[];
};

/**
 * interface for getReportsAndInventory function
 */
interface ReportsAndInventory {
  dates: Dates;
  shouldLoad: boolean;
  setReportsFinished: (params: Report[]) => void;
  setReportsOngoing: (params: Report[]) => void;
  setReportsToReview: (params: Report[]) => void;
  setReportsScheduled: (params: Report[]) => void;
  setReportsArchived: (params: Report[]) => void;
  setSpinnerFinishedReports: (params: () => number) => void;
  requestController: IRequestController;
  systemId: string;
  setSpinnerOngoingAndToReviewReports: (params: () => number) => void;
  setSpinnerScheduledReports: (params: () => number) => void;
  setSpinnerArchivedReports: (params: () => number) => void;
  logPrefix: string;
}

/**
 * Get reports and inventory function
 * @param param0 ReportsAndInventory params
 */
export const getReportsAndInventory = async ({
  dates,
  shouldLoad,
  setReportsFinished,
  setReportsOngoing,
  setReportsToReview,
  setReportsScheduled,
  setReportsArchived,
  setSpinnerFinishedReports,
  requestController,
  systemId,
  setSpinnerOngoingAndToReviewReports,
  setSpinnerScheduledReports,
  setSpinnerArchivedReports,
  logPrefix,
}: ReportsAndInventory) => {
  if (userHasPermission(PERMISSION.VIEW_FINISHED_REPORTS)) {
    /**
     *  Get finished reports scheduled for a date in the specified date range
     * */
    await getFinishedReports({
      setData: setReportsFinished,
      dates,
      requestController,
      setSpinner: setSpinnerFinishedReports,
      systemId,
    });
  }

  if (shouldLoad) {
    const shouldLoadOngoingAndToReviewReports = userHasSomePermissions([
      PERMISSION.VIEW_ONGOING_REPORTS,
      PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    ]);
    if (shouldLoadOngoingAndToReviewReports) {
      /**
       * Get all ongoing and "to review" reports, irrespective of scheduled date
       */
      await getOngoingAndToReviewReports({
        setReportsOngoing,
        setReportsToReview,
        setSpinner: setSpinnerOngoingAndToReviewReports,
        requestController,
        systemId,
      });
    }

    if (userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT)) {
      /**
       * Get all scheduled reports, irrespective of scheduled dates
       */
      await getScheduledReports({
        requestController,
        setData: setReportsScheduled,
        setSpinner: setSpinnerScheduledReports,
        systemId,
      });
    }
  }

  if (userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT)) {
    /**
     * Get all archived reports, irrespective of scheduled dates
     */
    await getArchivedReports({
      dates,
      requestController,
      setData: setReportsArchived,
      setSpinner: setSpinnerArchivedReports,
      systemId,
    });
  }
};

/**
 * Format report progress
 * @param progress number
 * @returns formated number
 */
export const formatReportProgress = (progress: number): number =>
  parseFloat((progress * 100).toFixed(2));
