import { useCallback, useMemo } from 'react';
import { Vector3 } from 'three';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { Floor } from 'shared/map-container/features/mesh-floor/MeshFloor';
import { MapControls } from 'shared/map-container/features/map-controls/MapControls';
import { OrthoCamera } from 'shared/map-container/features/map-orthographic-camera/OrthoCamera';
import { initialCameraState, MutableState } from 'shared/map-container/reducer/MutableState';
import { Vec6 } from 'shared/map-container/MapContainer.model';

import { ORTHO_CAMERA_Z } from 'shared/map-container/defaults/orthographicCameraZ.default';
import { MapCanvas } from 'shared/map-container/features/map-canvas/MapCanvas';
import { Zoomer } from 'shared/map-container/utils/Zoomer';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';

import { FlightAreas } from '../model/flightAreas.model';
import { FlightAreaMesh } from './components/flight-area-mesh/FlightAreaMesh';

export const wrapperId = 'FlightAreaMapWrapper';

export const FlightAreasMap = ({ flightAreas }: { flightAreas: FlightAreas }) => {
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { options } = mapState;

  const worldBox: Vec6 = useMemo(
    () => (mapState?.map?.box as Vec6) || [0, 0, 0, 0, 0, 0],
    [mapState?.map?.box],
  );
  const { minX, minY, maxX, maxY } = mapFacilityVectors(worldBox);

  const mutableState = useMemo(() => {
    MutableState.destroyInstance();
    return MutableState.constructState(
      'FlightAreaMap',
      {
        ...initialCameraState,
        position: new Vector3((maxX + minX) / 2, (maxY + minY) / 2, ORTHO_CAMERA_Z),
        orthographicFOV: options.camera.orthographic.maxFOV,
        currentOrthographicFOV: options.camera.orthographic.maxFOV,
      },
      { worldBox, guidePosition: new Vector3(0, 0, 0), isFloorHeld: false },
      {},
    );
  }, [maxX, maxY, minX, minY, options.camera.orthographic.maxFOV, worldBox]);

  const zoomer = useMemo(
    () => new Zoomer(options.camera, mapState.canvasSize),
    [options.camera, mapState.canvasSize],
  );

  const camera = useMemo(
    () => (
      <OrthoCamera
        worldBounds={[minX, maxX, minY, maxY]}
        options={options.camera}
        fovAxisRatio={mapState.canvasProportion}
        zoomer={zoomer}
      />
    ),
    [minX, maxX, minY, maxY, options.camera, mapState.canvasProportion, zoomer],
  );

  const flightAreaMeshes = useMemo(
    () =>
      Object.values(flightAreas).map((flightArea) => (
        <FlightAreaMesh key={flightArea.id} flightArea={flightArea} />
      )),
    [flightAreas],
  );

  const handleFloorDown = useCallback(() => {
    const mutState = MutableState.getState();

    mutState.interaction.isFloorHeld = true;
  }, []);

  const handleFloorUp = useCallback(() => {
    const mutState = MutableState.getState();

    mutState.interaction.isFloorHeld = false;
  }, []);

  return (
    <MapCanvas
      mapState={mapState}
      mapDispatch={mapDispatch}
      mutableState={mutableState}
      canvasSize={{ width: 0, height: 630 }}
      floor={
        <Floor
          mapState={mapState}
          zoomer={zoomer}
          onFloorDown={handleFloorDown}
          onFloorUp={handleFloorUp}
        />
      }
      zoomControls={<MapControls zoomer={zoomer} zoomSteps={options.camera.numberOfZoomSteps} />}
      mapElements={flightAreaMeshes}
      camera={camera}
      wrapperId={wrapperId}
    />
  );
};
