import { alpha, Backdrop, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Spinner, { ISpinnerProps } from 'components/common/Spinner';

const useStyles = makeStyles()((theme: Theme) => ({
  backDrop: {
    backgroundColor: alpha(theme.palette.primary.light, 0.7),
    position: 'absolute',
    zIndex: 6,
  },
}));

interface IBackDropLoaderProps {
  className?: string;
  isVisible: boolean;
  spinnerProps?: ISpinnerProps;
  showLoader?: boolean;
}

/**
 * Backdrop loader.
 * NOTE: in order to be displayed inside component parent should have position relative
 * @param param0 IBackDropLoaderProps params
 * @returns component
 */
export const BackDropLoader = ({
  isVisible,
  className,
  showLoader = true,
  spinnerProps = {},
}: IBackDropLoaderProps) => {
  const { classes, cx } = useStyles();
  return (
    <Backdrop className={cx(classes.backDrop, className)} aria-busy={isVisible} open={isVisible}>
      {showLoader && <Spinner {...spinnerProps} />}
    </Backdrop>
  );
};
