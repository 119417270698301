import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FeedbackReasonType, feedbackReasonsShortcutMap } from './feedbackReason.model';
import { useHandleShortcut } from './useHandleShortcut';
import { locationStyles } from '../styles';

export const FeedbackReason = ({
  type,
  name,
  isChecked,
  onChange,
}: {
  type: Exclude<FeedbackReasonType, 'OTHER'>;
  name: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}) => {
  const { classes } = locationStyles();
  const { isShortcutPressed } = useHandleShortcut([
    {
      key: feedbackReasonsShortcutMap[type],
      onShortcutPress: () => onChange(!isChecked),
    },
  ]);

  return (
    <Grid item md={12} lg={6} xl={6} key={type}>
      <FormControlLabel
        control={
          <>
            {isShortcutPressed('Alt') ? (
              <div className={classes.shortcutHint}>{feedbackReasonsShortcutMap[type]}</div>
            ) : null}
            <Checkbox
              checked={isChecked}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                onChange((event.target as HTMLInputElement).checked)
              }
            />
          </>
        }
        label={name}
        data-testid={`c-issue-feedback-checkbox-${type}`}
      />
    </Grid>
  );
};
