import Card from '@mui/material/Card';
import { CardContent, Typography } from '@mui/material';
import { Box } from '../../../../../components/common/Box';

type InfoCardProps = {
  title: string;
  content: string | JSX.Element;
  direction?: 'row' | 'column';
};

export const InfoCard = ({ title, content, direction }: InfoCardProps) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box textAlign="left" p={0.5} mb={1}>
        <Typography
          data-testid="c-click-one-of-the-item-title"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          variant="subtitle1"
        >
          {title}
        </Typography>
      </Box>
      <Box
        textAlign="left"
        p={0.5}
        mb={1}
        display="flex"
        sx={{
          cursor: 'pointer',
        }}
        flexDirection={direction || 'row'}
        gap={2}
        component="div"
      >
        {content}
      </Box>
    </CardContent>
  </Card>
);
