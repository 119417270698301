import { Button, Typography } from '@mui/material';
import { noFlyZoneListStyle } from './noFlyZoneListStyle';

export const NoFlyZoneCreator = ({ onCancelCreateNFZ }: { onCancelCreateNFZ: () => void }) => {
  const { classes } = noFlyZoneListStyle();

  return (
    <div className={classes.creationPlaceholderWrapper}>
      <div className={classes.creationPlaceholderBody}>
        <Typography mb={7}>Draw a new no-fly zone on the map.</Typography>

        <Button
          variant="outlined"
          onClick={onCancelCreateNFZ}
          color="primary"
          fullWidth
          size="medium"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
