import moment from 'moment';
import { PICKER_DATETIME_FORMAT } from '../datetimeFormats';
import { LocalStore } from './storageFunctions';

export const formatDateInTimezone = (
  date: string,
  format: string = PICKER_DATETIME_FORMAT,
): string => {
  const timezone = LocalStore.getTimezone();

  return moment(date).tz(timezone).format(format);
};
