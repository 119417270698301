import { TimeRange } from './TimeRange';

/**
 * The calculation includes start hour, start minutes, end hour, end minutes
 * @param hour number
 * @param minutes number
 * @param timeRanges array of TimeRange
 * @returns boolean
 */
export const isHourAndMinutesWithinTimeRanges = (
  hour: number,
  minutes: number,
  timeRanges: TimeRange[],
): boolean =>
  timeRanges.some((timeRange: TimeRange) => {
    const startTime = 60 * timeRange.fromHours + timeRange.fromMinutes;
    const endTime = 60 * timeRange.toHours + timeRange.toMinutes;
    const currentTime = 60 * hour + minutes;
    return currentTime >= startTime && currentTime <= endTime;
  });
