import { IFleetVersionResponseST } from 'codegen/flight_domain';
import {
  FleetStatusUpdateWS,
  FleetOverviewST,
} from '../../pages/GroundControl/pages/FleetOverview/API';
import { FlightDomainScheduleST } from '../../pages/GroundControl/pages/Schedule/API';
import { IFlightDomainData, IAlertInfoData } from './IGroundControlStore';

/*
 * Ground control store action types
 */
export enum GroundControlActionNames {
  SET_FLIGHT_DOMAIN,
  SET_CURRENT_FLIGHT_DOMAIN_PERMISSIONS,
  SET_FLEET_OVERVIEW,
  SET_FLEET_VERSION,
  UPDATE_FLEET_OVERVIEW,
  SET_NO_FLY_ZONES,
  TOGGLE_STATUS_BAR,
  SET_FLIGHT_DOMAIN_SETTINGS,
  SET_WEBSOCKET_ERROR,
  SET_ALERT_INFO,
  SET_IS_LOADING_FLEET_DATA,
  CLEAR_GROUND_CONTROL_STORE,
}

export type GroundControlLevelAction =
  | { type: GroundControlActionNames.SET_FLIGHT_DOMAIN; payload: IFlightDomainData }
  | { type: GroundControlActionNames.SET_FLEET_OVERVIEW; payload: FleetOverviewST }
  | { type: GroundControlActionNames.SET_FLEET_VERSION; payload: IFleetVersionResponseST }
  | { type: GroundControlActionNames.UPDATE_FLEET_OVERVIEW; payload: FleetStatusUpdateWS }
  | {
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN_SETTINGS;
      payload: FlightDomainScheduleST;
    }
  | {
      type: GroundControlActionNames.SET_ALERT_INFO;
      payload: {
        key: 'fleetCard' | 'statusCard' | 'main' | 'flightHoursCard';
        variant?: IAlertInfoData['variant'];
        message?: IAlertInfoData['message'];
      };
    }
  | {
      type: GroundControlActionNames.SET_WEBSOCKET_ERROR;
      payload: boolean;
    }
  | {
      type: GroundControlActionNames.SET_IS_LOADING_FLEET_DATA;
      payload: boolean;
    }
  | {
      type: GroundControlActionNames.CLEAR_GROUND_CONTROL_STORE;
    };
