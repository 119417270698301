import moment from 'moment';
// mui
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
// functions
import { weekDayIndexTo3Letter } from 'common/weekDays';
import { HOURS_AND_MINUTES_FORMAT } from 'common/datetimeFormats';
// components
import { Box } from 'components/common/Box';
import { OperatingHoursEvent } from './FlightHoursCard';
import { useStyles } from './FlightHoursStyles';

export const FlightHoursItem = (props: {
  hours: OperatingHoursEvent;
  remove: () => void;
  edit: () => void;
  /**
   * If set to true the component is read only
   */
  isReadOnlyMode?: boolean;
}) => {
  const {
    hours: { start, end },
    remove,
    edit,
    isReadOnlyMode,
  } = props;
  const { classes } = useStyles();

  const startDay = weekDayIndexTo3Letter(start.weekDay);
  const startTime = moment(start.timePoint).format(HOURS_AND_MINUTES_FORMAT);
  const endDay = weekDayIndexTo3Letter(end.weekDay);
  const endTime = moment(end.timePoint).format(HOURS_AND_MINUTES_FORMAT);

  return (
    <Box className={classes.flightHoursItem}>
      <Grid container spacing={2}>
        <Grid item xs={8} lg={10}>
          <Typography className={classes.itemText}>
            {`${startDay} ${startTime}`}
            <span className={classes.wideDash}> - </span>
            {`${endDay} ${endTime}`}
          </Typography>
        </Grid>
        {!isReadOnlyMode && (
          <>
            <Grid item xs={2} lg={1}>
              <IconButton
                onClick={() => edit()}
                aria-label="flight-hours-edit-item"
                data-testid="c-flight-hours-edit-item"
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2} lg={1}>
              <IconButton
                onClick={() => remove()}
                aria-label="flight-hours-remove-item"
                data-testid="c-flight-hours-remove-item"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
