import { useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { MapContainer } from 'shared/map-container/MapContainer';
import {
  IGetEstimatedObstaclesResponseST,
  IGetSpaceReservationsResponseST,
} from 'codegen/nav_simulation';
import { makeBatteryDetailedStateString } from 'pages/GroundControl/pages/FleetOverview/FleetOverviewFunctions';
import { dateFormatFleet } from '../../utils/dateFormatFleet';

import { LiveMap, wrapperId } from '../../../../../pages/GroundControl/pages/LiveMap/LiveMap';
import ModalBase from '../../../../../components/ModalsAndPopups/ModalBase';

import { IFleetTableRows } from '../../model/IFleetTableRows';
import { InfoCard } from './InfoCard';
import { useStyles } from './styles/droneErrorModal.styles';
import { BatteryMetrics } from './BatteryMetrics';
import { ChargingStats } from './ChargingStats';
import { ChargingStrategy } from './ChargingStrategy';
import { BatteryCycles } from './BatteryCycles';
import { Metric } from './Metric';
import { MarkdownMetric } from './MarkdownMetric';
import { BatteryStateMetric } from './BatteryState';

export const DroneErrorModal = ({
  closeModal,
  droneErrorPayload,
  estimatedObstacles,
  spaceReservations,
}: {
  closeModal: () => void;
  droneErrorPayload: IFleetTableRows;
  estimatedObstacles: IGetEstimatedObstaclesResponseST['estimated_obstacles'];
  spaceReservations: IGetSpaceReservationsResponseST['space_reservations'];
}) => {
  const { classes } = useStyles();

  const { stateGroundControl } = useGroundControlStore();

  const { id: droneId, drone_name: droneName } = droneErrorPayload;

  const droneErrorData = stateGroundControl.fleetOverview.fleet_status[droneId] || {};
  const droneVersionData =
    stateGroundControl.fleetVersions.drone_versions[droneErrorData.drone_name] || {};

  const { pathname } = useLocation();

  const systemId = pathname.split('/')[1];
  const flightDomainId = pathname.split('/')[3];

  return (
    <ModalBase
      opened={true}
      testId="c-drone-error-modal"
      contentClass={classes.root}
      maxWidth="lg"
      title={
        <Box p={2}>
          <Typography
            data-testid="c-choose-the-facility-title"
            style={{ fontWeight: 'bold', textAlign: 'left' }}
            color="secondary"
            variant="h5"
          >
            {droneName}
          </Typography>
        </Box>
      }
      handleClose={closeModal}
    >
      <Grid alignItems="stretch" spacing={2} p={2} container className="c-page-content">
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Drone Serial Number"
            content={<Metric value={droneVersionData.drone_serial || 'Undefined'} />}
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Software version"
            content={<Metric value={droneVersionData.version || 'Undefined'} />}
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Battery Serial Number"
            content={<Metric value={droneErrorPayload.battery_serial_number || 'Undefined'} />}
            direction="column"
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Battery Management Strategy"
            content={<ChargingStrategy strategy={droneErrorPayload.charging_strategy} />}
          />
        </Grid>

        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Battery State"
            content={
              <BatteryStateMetric
                state={droneErrorPayload.battery_state}
                detailedState={makeBatteryDetailedStateString(
                  droneErrorPayload.detailed_battery_state,
                )}
              />
            }
            direction="column"
          />
        </Grid>

        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Battery Metrics"
            content={
              <BatteryMetrics
                voltage={droneErrorPayload.battery_voltage}
                current={droneErrorPayload.battery_current}
                tempCelsius={droneErrorPayload.battery_temperature}
              />
            }
            direction="column"
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Charging Metrics"
            content={
              <ChargingStats
                currentLimit={droneErrorPayload.charging_current_limit}
                timeToChargeInSeconds={droneErrorPayload.time_to_optimal_charge}
              />
            }
            direction="column"
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Battery Cycles"
            content={
              <BatteryCycles
                cycles={droneErrorPayload.num_charging_cycles}
                maxRecommendedCycles={droneErrorPayload.max_recommended_num_charging_cycles}
              />
            }
            direction="column"
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Operational feedback"
            content={
              <Metric
                value={
                  droneErrorData.last_operation_feedback
                    ? droneErrorData.last_operation_feedback
                    : 'None'
                }
              />
            }
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Position State"
            content={<Metric value={droneErrorData.pose_state || 'Undefined'} />}
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Last Reliable Position Time"
            content={<Metric value={dateFormatFleet(droneErrorData.last_reliable_pose_time)} />}
          />
        </Grid>
        <Grid md={3} sm={6} xs={12} item>
          <InfoCard
            title="Not ready to fly Reason"
            content={<Metric value={droneErrorData.not_ready_reason || 'None'} />}
          />
        </Grid>

        <Grid md={12} sm={12} xs={12} item>
          <InfoCard
            title="Additional diagnostics"
            content={
              <MarkdownMetric
                message={
                  droneErrorData.additional_diagnostics || 'No additional diagnostics available'
                }
              />
            }
          />
        </Grid>
        <Grid xl={12} xs={12} item id={wrapperId}>
          <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
            <LiveMap
              namesOfDronesToShow={[droneErrorPayload.drone_name?.toString() || 'Undefined']}
              estimatedObstacles={estimatedObstacles}
              spaceReservations={spaceReservations}
            />
          </MapContainer>
        </Grid>
      </Grid>
    </ModalBase>
  );
};
