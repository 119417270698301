import moment from 'moment';
import { geometryToBottomLeftDimensions } from 'shared/map-container/utils/creation.util';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { IControlledZoneStatus1ST, IIntervalST } from 'codegen/controlled_zone';
import { DroneZoneTypes } from './droneZones.model';
import { allNumbersToFixed2 } from '../../../../../shared/numberFormatting';

export const transformIntoControlledZone = (
  zone: IControlledZoneStatus1ST & { id: string },
): ControlledZone => {
  const now = moment.utc(new Date());
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(zone.geometry);

  const isActive = !!zone?.enabled_interval_list?.some((interval: IIntervalST) => {
    const { start_time, end_time } = interval;
    if (!start_time && !end_time) return false;

    if (start_time && !end_time) return moment.utc(start_time).isBefore(now);

    return now.isBetween(moment.utc(start_time), moment.utc(end_time));
  });

  return {
    id: zone.id,
    type: DroneZoneTypes.controlledZone,
    description: zone.description,
    waitingForDronesToComeHome: !!zone?.waiting_for_drones_to_come_home,
    lastModifiedBy: zone.last_zone_permission_change_by,
    name: zone.name,
    isActive,
    sizeAndPosition: allNumbersToFixed2({ w, l, h, minX, minY, minZ, maxZ }),
    isSelected: false,
  };
};
