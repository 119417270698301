/* tslint:disable */
/* eslint-disable */
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IAssociateSoftwareTokenPostRequestST
 */
export interface IAssociateSoftwareTokenPostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IAssociateSoftwareTokenPostRequestST
   */
  access_token: string;
}
/**
 *
 * @export
 * @interface IAssociateSoftwareTokenPostResponseST
 */
export interface IAssociateSoftwareTokenPostResponseST {
  /**
   * A unique generated shared secret code that is used in the TOTP algorithm to generate a one time code
   * @type {string}
   * @memberof IAssociateSoftwareTokenPostResponseST
   */
  secret_code: string;
}
/**
 *
 * @export
 * @interface IAuthChallengeMFAAndSMSST
 */
export interface IAuthChallengeMFAAndSMSST {
  /**
   * The challenge name
   * @type {string}
   * @memberof IAuthChallengeMFAAndSMSST
   */
  challenge_name: IAuthChallengeMFAAndSMSSTChallengeNameEnum;
  /**
   * The code sent to user via email or SMS. Required for both `SMS_MFA` and `SOFTWARE_TOKEN_MFA` challenges
   * @type {string}
   * @memberof IAuthChallengeMFAAndSMSST
   */
  code: string;
  /**
   * The email of the user
   * @type {string}
   * @memberof IAuthChallengeMFAAndSMSST
   */
  username: string;
  /**
   * The session which should be passed
   * @type {string}
   * @memberof IAuthChallengeMFAAndSMSST
   */
  session: string;
}

export const IAuthChallengeMFAAndSMSSTChallengeNameEnum = {
  SmsMfa: 'SMS_MFA',
  SoftwareTokenMfa: 'SOFTWARE_TOKEN_MFA',
} as const;

export type IAuthChallengeMFAAndSMSSTChallengeNameEnum =
  typeof IAuthChallengeMFAAndSMSSTChallengeNameEnum[keyof typeof IAuthChallengeMFAAndSMSSTChallengeNameEnum];

/**
 *
 * @export
 * @interface IAuthChallengeNewPasswordST
 */
export interface IAuthChallengeNewPasswordST {
  /**
   * The challenge name
   * @type {string}
   * @memberof IAuthChallengeNewPasswordST
   */
  challenge_name: IAuthChallengeNewPasswordSTChallengeNameEnum;
  /**
   * New password for user. Required for `NEW_PASSWORD_REQUIRED` challenge
   * @type {string}
   * @memberof IAuthChallengeNewPasswordST
   */
  password: string;
  /**
   * The email of the user
   * @type {string}
   * @memberof IAuthChallengeNewPasswordST
   */
  username: string;
  /**
   * The session which should be passed
   * @type {string}
   * @memberof IAuthChallengeNewPasswordST
   */
  session: string;
}

export const IAuthChallengeNewPasswordSTChallengeNameEnum = {
  NewPasswordRequired: 'NEW_PASSWORD_REQUIRED',
} as const;

export type IAuthChallengeNewPasswordSTChallengeNameEnum =
  typeof IAuthChallengeNewPasswordSTChallengeNameEnum[keyof typeof IAuthChallengeNewPasswordSTChallengeNameEnum];

/**
 *
 * @export
 * @interface IAuthChallengeParametersST
 */
export interface IAuthChallengeParametersST {
  /**
   * The email of the user
   * @type {string}
   * @memberof IAuthChallengeParametersST
   */
  username: string;
  /**
   * The session which should be passed
   * @type {string}
   * @memberof IAuthChallengeParametersST
   */
  session: string;
}
/**
 * @type IAuthChallengePostRequestST
 * @export
 */
export type IAuthChallengePostRequestST = IAuthChallengeMFAAndSMSST | IAuthChallengeNewPasswordST;

/**
 *
 * @export
 * @interface IAuthenticationResponseST
 */
export interface IAuthenticationResponseST {
  /**
   * Contains claims about the identity of the authenticated user
   * @type {string}
   * @memberof IAuthenticationResponseST
   */
  id_token: string;
  /**
   * Contains scopes and groups and is used to grant access to authorized resources
   * @type {string}
   * @memberof IAuthenticationResponseST
   */
  access_token: string;
  /**
   * The refresh token
   * @type {string}
   * @memberof IAuthenticationResponseST
   */
  refresh_token: string;
  /**
   * The expiration period of the authentication result in seconds
   * @type {number}
   * @memberof IAuthenticationResponseST
   */
  expires_in: number;
  /**
   * The token type
   * @type {string}
   * @memberof IAuthenticationResponseST
   */
  token_type: IAuthenticationResponseSTTokenTypeEnum;
  /**
   *
   * @type {IUserPoolDescriptionST}
   * @memberof IAuthenticationResponseST
   */
  user_pool_description: IUserPoolDescriptionST;
}

export const IAuthenticationResponseSTTokenTypeEnum = {
  Bearer: 'Bearer',
} as const;

export type IAuthenticationResponseSTTokenTypeEnum =
  typeof IAuthenticationResponseSTTokenTypeEnum[keyof typeof IAuthenticationResponseSTTokenTypeEnum];

/**
 *
 * @export
 * @interface IChallengeResponseST
 */
export interface IChallengeResponseST {
  /**
   * The name of the challenge which you are responding to with this call
   * @type {string}
   * @memberof IChallengeResponseST
   */
  challenge_name: string;
  /**
   * The responses in this parameter should be used to compute inputs to the next call
   * @type {object}
   * @memberof IChallengeResponseST
   */
  challenge_parameters: object;
  /**
   * This session should be passed as it is to the next call
   * @type {string}
   * @memberof IChallengeResponseST
   */
  session: string;
}
/**
 *
 * @export
 * @interface IChangeAttributePostRequestST
 */
export interface IChangeAttributePostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IChangeAttributePostRequestST
   */
  access_token: string;
  /**
   * The name of the attribute
   * @type {string}
   * @memberof IChangeAttributePostRequestST
   */
  attribute_name: string;
  /**
   * The value of the attribute
   * @type {string}
   * @memberof IChangeAttributePostRequestST
   */
  attribute_value: string;
}
/**
 *
 * @export
 * @interface IChangeAttributePostResponseST
 */
export interface IChangeAttributePostResponseST {
  /**
   * The code delivery details returned by the server in response to the request to reset a password
   * @type {object}
   * @memberof IChangeAttributePostResponseST
   */
  code_delivery_details: object;
}
/**
 *
 * @export
 * @interface IChangePasswordPostRequestST
 */
export interface IChangePasswordPostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IChangePasswordPostRequestST
   */
  access_token: string;
  /**
   * The old password
   * @type {string}
   * @memberof IChangePasswordPostRequestST
   */
  previous_password: string;
  /**
   * The new password
   * @type {string}
   * @memberof IChangePasswordPostRequestST
   */
  proposed_password: string;
}
/**
 *
 * @export
 * @interface IConfirmForgotPasswordPostRequestST
 */
export interface IConfirmForgotPasswordPostRequestST {
  /**
   * The email of the user
   * @type {string}
   * @memberof IConfirmForgotPasswordPostRequestST
   */
  username: string;
  /**
   * User password
   * @type {string}
   * @memberof IConfirmForgotPasswordPostRequestST
   */
  password: string;
  /**
   * The confirmation code sent by a user\'s request to retrieve a forgotten password
   * @type {string}
   * @memberof IConfirmForgotPasswordPostRequestST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IForgotPasswordPostRequestST
 */
export interface IForgotPasswordPostRequestST {
  /**
   * The email of the user
   * @type {string}
   * @memberof IForgotPasswordPostRequestST
   */
  username: string;
}
/**
 *
 * @export
 * @interface IForgotPasswordPostResponseST
 */
export interface IForgotPasswordPostResponseST {
  /**
   * The code delivery details returned by the server in response to the request to reset a password
   * @type {object}
   * @memberof IForgotPasswordPostResponseST
   */
  code_delivery_details: object;
}
/**
 *
 * @export
 * @interface IGetUserPostRequestST
 */
export interface IGetUserPostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IGetUserPostRequestST
   */
  access_token: string;
}
/**
 *
 * @export
 * @interface IGetUserPostResponseST
 */
export interface IGetUserPostResponseST {
  /**
   * The user name of the user
   * @type {string}
   * @memberof IGetUserPostResponseST
   */
  username: string;
  /**
   *
   * @type {IUserAttributesST}
   * @memberof IGetUserPostResponseST
   */
  user_attributes: IUserAttributesST;
  /**
   * The MFA options that are enabled for the user
   * @type {Array<string>}
   * @memberof IGetUserPostResponseST
   */
  user_mfa_setting_list: Array<string>;
}
/**
 *
 * @export
 * @interface IRefreshTokenPostRequestST
 */
export interface IRefreshTokenPostRequestST {
  /**
   * The refresh token
   * @type {string}
   * @memberof IRefreshTokenPostRequestST
   */
  refresh_token: string;
  /**
   * The user username
   * @type {string}
   * @memberof IRefreshTokenPostRequestST
   */
  username: string;
}
/**
 *
 * @export
 * @interface IRefreshTokenPostResponseST
 */
export interface IRefreshTokenPostResponseST {
  /**
   * Contains claims about the identity of the authenticated user
   * @type {string}
   * @memberof IRefreshTokenPostResponseST
   */
  id_token: string;
  /**
   * Contains scopes and groups and is used to grant access to authorized resources
   * @type {string}
   * @memberof IRefreshTokenPostResponseST
   */
  access_token: string;
  /**
   * The refresh token
   * @type {string}
   * @memberof IRefreshTokenPostResponseST
   */
  refresh_token?: string;
  /**
   * The expiration period of the authentication result in seconds
   * @type {number}
   * @memberof IRefreshTokenPostResponseST
   */
  expires_in: number;
  /**
   * The token type
   * @type {string}
   * @memberof IRefreshTokenPostResponseST
   */
  token_type: IRefreshTokenPostResponseSTTokenTypeEnum;
  /**
   *
   * @type {IUserPoolDescriptionST}
   * @memberof IRefreshTokenPostResponseST
   */
  user_pool_description: IUserPoolDescriptionST;
}

export const IRefreshTokenPostResponseSTTokenTypeEnum = {
  Bearer: 'Bearer',
} as const;

export type IRefreshTokenPostResponseSTTokenTypeEnum =
  typeof IRefreshTokenPostResponseSTTokenTypeEnum[keyof typeof IRefreshTokenPostResponseSTTokenTypeEnum];

/**
 *
 * @export
 * @interface IResendConfirmationCodePostRequestST
 */
export interface IResendConfirmationCodePostRequestST {
  /**
   * User email
   * @type {string}
   * @memberof IResendConfirmationCodePostRequestST
   */
  username: string;
}
/**
 *
 * @export
 * @interface IResendConfirmationCodePostResponseST
 */
export interface IResendConfirmationCodePostResponseST {
  /**
   * The code delivery details returned by the server in response to the request to reset a password
   * @type {object}
   * @memberof IResendConfirmationCodePostResponseST
   */
  code_delivery_details: object;
}
/**
 *
 * @export
 * @interface IRevokeRefreshTokenPostRequestST
 */
export interface IRevokeRefreshTokenPostRequestST {
  /**
   * The refresh token
   * @type {string}
   * @memberof IRevokeRefreshTokenPostRequestST
   */
  refresh_token: string;
}
/**
 *
 * @export
 * @interface ISetUserMFAPreferencePostRequestST
 */
export interface ISetUserMFAPreferencePostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof ISetUserMFAPreferencePostRequestST
   */
  access_token: string;
  /**
   * Specifies whether SMS text message MFA is enabled
   * @type {boolean}
   * @memberof ISetUserMFAPreferencePostRequestST
   */
  sms_enabled: boolean;
  /**
   * Specifies whether SMS is the preferred MFA method
   * @type {boolean}
   * @memberof ISetUserMFAPreferencePostRequestST
   */
  sms_preferred: boolean;
  /**
   * Specifies whether software token MFA is enabled
   * @type {boolean}
   * @memberof ISetUserMFAPreferencePostRequestST
   */
  software_token_enabled?: boolean;
  /**
   * Specifies whether software token MFA is the preferred MFA method
   * @type {boolean}
   * @memberof ISetUserMFAPreferencePostRequestST
   */
  software_token_preferred?: boolean;
}
/**
 *
 * @export
 * @interface ISignInPostRequestST
 */
export interface ISignInPostRequestST {
  /**
   * The email of the user
   * @type {string}
   * @memberof ISignInPostRequestST
   */
  username: string;
  /**
   * User password
   * @type {string}
   * @memberof ISignInPostRequestST
   */
  password: string;
  /**
   * The confirmation code sent by a user\'s request to retrieve a forgotten password
   * @type {string}
   * @memberof ISignInPostRequestST
   */
  code?: string;
}
/**
 *
 * @export
 * @interface ITokenValidityUnitsST
 */
export interface ITokenValidityUnitsST {
  /**
   *
   * @type {string}
   * @memberof ITokenValidityUnitsST
   */
  AccessToken: ITokenValidityUnitsSTAccessTokenEnum;
  /**
   *
   * @type {string}
   * @memberof ITokenValidityUnitsST
   */
  IdToken: ITokenValidityUnitsSTIdTokenEnum;
  /**
   *
   * @type {string}
   * @memberof ITokenValidityUnitsST
   */
  RefreshToken: ITokenValidityUnitsSTRefreshTokenEnum;
}

export const ITokenValidityUnitsSTAccessTokenEnum = {
  Seconds: 'seconds',
  Minutes: 'minutes',
  Hours: 'hours',
  Days: 'days',
} as const;

export type ITokenValidityUnitsSTAccessTokenEnum =
  typeof ITokenValidityUnitsSTAccessTokenEnum[keyof typeof ITokenValidityUnitsSTAccessTokenEnum];
export const ITokenValidityUnitsSTIdTokenEnum = {
  Seconds: 'seconds',
  Minutes: 'minutes',
  Hours: 'hours',
  Days: 'days',
} as const;

export type ITokenValidityUnitsSTIdTokenEnum =
  typeof ITokenValidityUnitsSTIdTokenEnum[keyof typeof ITokenValidityUnitsSTIdTokenEnum];
export const ITokenValidityUnitsSTRefreshTokenEnum = {
  Seconds: 'seconds',
  Minutes: 'minutes',
  Hours: 'hours',
  Days: 'days',
} as const;

export type ITokenValidityUnitsSTRefreshTokenEnum =
  typeof ITokenValidityUnitsSTRefreshTokenEnum[keyof typeof ITokenValidityUnitsSTRefreshTokenEnum];

/**
 *
 * @export
 * @interface IUserAttributesST
 */
export interface IUserAttributesST {
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  sub: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  email_verified: IUserAttributesSTEmailVerifiedEnum;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  phone_number_verified: IUserAttributesSTPhoneNumberVerifiedEnum;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  given_name: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  family_name: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  user_system_ids: string;
  /**
   *
   * @type {Array<number>}
   * @memberof IUserAttributesST
   */
  system_ids: Array<number>;
  /**
   * Allow access to all client\'s facilities
   * @type {boolean}
   * @memberof IUserAttributesST
   */
  access_to_all_facilities: boolean;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  create_date: string | null;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  last_modified_date: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IUserAttributesST
   */
  enabled: boolean | null;
  /**
   *
   * @type {string}
   * @memberof IUserAttributesST
   */
  status: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof IUserAttributesST
   */
  user_groups: Array<string>;
}

export const IUserAttributesSTEmailVerifiedEnum = {
  True: 'true',
  False: 'false',
} as const;

export type IUserAttributesSTEmailVerifiedEnum =
  typeof IUserAttributesSTEmailVerifiedEnum[keyof typeof IUserAttributesSTEmailVerifiedEnum];
export const IUserAttributesSTPhoneNumberVerifiedEnum = {
  True: 'true',
  False: 'false',
} as const;

export type IUserAttributesSTPhoneNumberVerifiedEnum =
  typeof IUserAttributesSTPhoneNumberVerifiedEnum[keyof typeof IUserAttributesSTPhoneNumberVerifiedEnum];

/**
 *
 * @export
 * @interface IUserPoolDescriptionST
 */
export interface IUserPoolDescriptionST {
  /**
   *
   * @type {number}
   * @memberof IUserPoolDescriptionST
   */
  access_token_validity: number;
  /**
   *
   * @type {number}
   * @memberof IUserPoolDescriptionST
   */
  id_token_validity: number;
  /**
   *
   * @type {number}
   * @memberof IUserPoolDescriptionST
   */
  refresh_token_validity: number;
  /**
   *
   * @type {ITokenValidityUnitsST}
   * @memberof IUserPoolDescriptionST
   */
  token_validity_units: ITokenValidityUnitsST;
}
/**
 *
 * @export
 * @interface IVerifySoftwareTokenPostRequestST
 */
export interface IVerifySoftwareTokenPostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IVerifySoftwareTokenPostRequestST
   */
  access_token: string;
  /**
   * The one time password computed using the secret code
   * @type {string}
   * @memberof IVerifySoftwareTokenPostRequestST
   */
  user_code: string;
}
/**
 *
 * @export
 * @interface IVerifySoftwareTokenPostResponseST
 */
export interface IVerifySoftwareTokenPostResponseST {
  /**
   * The status of the verify software token
   * @type {string}
   * @memberof IVerifySoftwareTokenPostResponseST
   */
  status: string;
}
/**
 *
 * @export
 * @interface IVerifyUserAttributePostRequestST
 */
export interface IVerifyUserAttributePostRequestST {
  /**
   * The access token
   * @type {string}
   * @memberof IVerifyUserAttributePostRequestST
   */
  access_token: string;
  /**
   * The name of the attribute
   * @type {string}
   * @memberof IVerifyUserAttributePostRequestST
   */
  attribute_name: string;
  /**
   * The verification code in the request to verify user attributes
   * @type {string}
   * @memberof IVerifyUserAttributePostRequestST
   */
  code: string;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Responds to the authentication challenge requested from AWS.
     * @summary Responds to the authentication challenge.
     * @param {IAuthChallengePostRequestST} iAuthChallengePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authChallenge: async (
      iAuthChallengePostRequestST: IAuthChallengePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iAuthChallengePostRequestST' is not null or undefined
      assertParamExists(
        'authChallenge',
        'iAuthChallengePostRequestST',
        iAuthChallengePostRequestST,
      );
      const localVarPath = `/auth-challenge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iAuthChallengePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Allows a user to update a specific attribute (one at a time).
     * @summary Update user attribute.
     * @param {IChangeAttributePostRequestST} iChangeAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAttribute: async (
      iChangeAttributePostRequestST: IChangeAttributePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iChangeAttributePostRequestST' is not null or undefined
      assertParamExists(
        'changeAttribute',
        'iChangeAttributePostRequestST',
        iChangeAttributePostRequestST,
      );
      const localVarPath = `/change-attribute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iChangeAttributePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Changes the password for a user.
     * @summary Changes the password for a user.
     * @param {IChangePasswordPostRequestST} iChangePasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (
      iChangePasswordPostRequestST: IChangePasswordPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iChangePasswordPostRequestST' is not null or undefined
      assertParamExists(
        'changePassword',
        'iChangePasswordPostRequestST',
        iChangePasswordPostRequestST,
      );
      const localVarPath = `/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iChangePasswordPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Allows a user to enter a confirmation code to reset a forgotten password.
     * @summary Reset a forgotten password.
     * @param {IConfirmForgotPasswordPostRequestST} iConfirmForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmForgotPassword: async (
      iConfirmForgotPasswordPostRequestST: IConfirmForgotPasswordPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iConfirmForgotPasswordPostRequestST' is not null or undefined
      assertParamExists(
        'confirmForgotPassword',
        'iConfirmForgotPasswordPostRequestST',
        iConfirmForgotPasswordPostRequestST,
      );
      const localVarPath = `/confirm-forgot-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iConfirmForgotPasswordPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calling this endpoint causes a message to be sent to the end user with a confirmation code that is required to change the user\'s password.
     * @summary Resets the user\'s password.
     * @param {IForgotPasswordPostRequestST} iForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword: async (
      iForgotPasswordPostRequestST: IForgotPasswordPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iForgotPasswordPostRequestST' is not null or undefined
      assertParamExists(
        'forgotPassword',
        'iForgotPasswordPostRequestST',
        iForgotPasswordPostRequestST,
      );
      const localVarPath = `/forgot-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iForgotPasswordPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a unique generated shared secret key code for the user account.
     * @summary Associate software token to the user account.
     * @param {IAssociateSoftwareTokenPostRequestST} iAssociateSoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretCode: async (
      iAssociateSoftwareTokenPostRequestST: IAssociateSoftwareTokenPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iAssociateSoftwareTokenPostRequestST' is not null or undefined
      assertParamExists(
        'getSecretCode',
        'iAssociateSoftwareTokenPostRequestST',
        iAssociateSoftwareTokenPostRequestST,
      );
      const localVarPath = `/associate-software-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iAssociateSoftwareTokenPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return attributes for a user.
     * @summary Return attributes for a user.
     * @param {IGetUserPostRequestST} iGetUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      iGetUserPostRequestST: IGetUserPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iGetUserPostRequestST' is not null or undefined
      assertParamExists('getUser', 'iGetUserPostRequestST', iGetUserPostRequestST);
      const localVarPath = `/get-user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iGetUserPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Will take in a valid refresh token and return new tokens.
     * @summary Refresh user tokens.
     * @param {IRefreshTokenPostRequestST} iRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      iRefreshTokenPostRequestST: IRefreshTokenPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iRefreshTokenPostRequestST' is not null or undefined
      assertParamExists('refreshToken', 'iRefreshTokenPostRequestST', iRefreshTokenPostRequestST);
      const localVarPath = `/refresh-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRefreshTokenPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Resends the confirmation (for confirmation of registration) to a specific user.
     * @summary Resends the confirmation code.
     * @param {IResendConfirmationCodePostRequestST} iResendConfirmationCodePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendCode: async (
      iResendConfirmationCodePostRequestST: IResendConfirmationCodePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iResendConfirmationCodePostRequestST' is not null or undefined
      assertParamExists(
        'resendCode',
        'iResendConfirmationCodePostRequestST',
        iResendConfirmationCodePostRequestST,
      );
      const localVarPath = `/resend-confirmation-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iResendConfirmationCodePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Will take in a valid refresh token and revoke it.
     * @summary Revoke the refresh tokens.
     * @param {IRevokeRefreshTokenPostRequestST} iRevokeRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeRefreshToken: async (
      iRevokeRefreshTokenPostRequestST: IRevokeRefreshTokenPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iRevokeRefreshTokenPostRequestST' is not null or undefined
      assertParamExists(
        'revokeRefreshToken',
        'iRevokeRefreshTokenPostRequestST',
        iRevokeRefreshTokenPostRequestST,
      );
      const localVarPath = `/revoke-refresh-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRevokeRefreshTokenPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Set the user\'s multi-factor authentication (MFA) method preference.
     * @summary Set the user\'s MFA.
     * @param {ISetUserMFAPreferencePostRequestST} iSetUserMFAPreferencePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUserMfaPreference: async (
      iSetUserMFAPreferencePostRequestST: ISetUserMFAPreferencePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iSetUserMFAPreferencePostRequestST' is not null or undefined
      assertParamExists(
        'setUserMfaPreference',
        'iSetUserMFAPreferencePostRequestST',
        iSetUserMFAPreferencePostRequestST,
      );
      const localVarPath = `/set-user-mfa-preference`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iSetUserMFAPreferencePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Authenticate user using username and password.
     * @summary Authenticate user.
     * @param {ISignInPostRequestST} iSignInPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signIn: async (
      iSignInPostRequestST: ISignInPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iSignInPostRequestST' is not null or undefined
      assertParamExists('signIn', 'iSignInPostRequestST', iSignInPostRequestST);
      const localVarPath = `/sign-in`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iSignInPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to register a user\'s entered TOTP code and mark the user\'s software token MFA status as \"verified\".
     * @summary Mark MFA status as \"verified\".
     * @param {IVerifySoftwareTokenPostRequestST} iVerifySoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySoftwareToken: async (
      iVerifySoftwareTokenPostRequestST: IVerifySoftwareTokenPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iVerifySoftwareTokenPostRequestST' is not null or undefined
      assertParamExists(
        'verifySoftwareToken',
        'iVerifySoftwareTokenPostRequestST',
        iVerifySoftwareTokenPostRequestST,
      );
      const localVarPath = `/verify-software-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iVerifySoftwareTokenPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Verifies the specified user attribute.
     * @summary Verifies the specified user attribute.
     * @param {IVerifyUserAttributePostRequestST} iVerifyUserAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUserAttribute: async (
      iVerifyUserAttributePostRequestST: IVerifyUserAttributePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iVerifyUserAttributePostRequestST' is not null or undefined
      assertParamExists(
        'verifyUserAttribute',
        'iVerifyUserAttributePostRequestST',
        iVerifyUserAttributePostRequestST,
      );
      const localVarPath = `/verify-user-attribute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iVerifyUserAttributePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     * Responds to the authentication challenge requested from AWS.
     * @summary Responds to the authentication challenge.
     * @param {IAuthChallengePostRequestST} iAuthChallengePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authChallenge(
      iAuthChallengePostRequestST: IAuthChallengePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAuthenticationResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authChallenge(
        iAuthChallengePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Allows a user to update a specific attribute (one at a time).
     * @summary Update user attribute.
     * @param {IChangeAttributePostRequestST} iChangeAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeAttribute(
      iChangeAttributePostRequestST: IChangeAttributePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IChangeAttributePostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeAttribute(
        iChangeAttributePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changes the password for a user.
     * @summary Changes the password for a user.
     * @param {IChangePasswordPostRequestST} iChangePasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(
      iChangePasswordPostRequestST: IChangePasswordPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(
        iChangePasswordPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Allows a user to enter a confirmation code to reset a forgotten password.
     * @summary Reset a forgotten password.
     * @param {IConfirmForgotPasswordPostRequestST} iConfirmForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmForgotPassword(
      iConfirmForgotPasswordPostRequestST: IConfirmForgotPasswordPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmForgotPassword(
        iConfirmForgotPasswordPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Calling this endpoint causes a message to be sent to the end user with a confirmation code that is required to change the user\'s password.
     * @summary Resets the user\'s password.
     * @param {IForgotPasswordPostRequestST} iForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPassword(
      iForgotPasswordPostRequestST: IForgotPasswordPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IForgotPasswordPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(
        iForgotPasswordPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns a unique generated shared secret key code for the user account.
     * @summary Associate software token to the user account.
     * @param {IAssociateSoftwareTokenPostRequestST} iAssociateSoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecretCode(
      iAssociateSoftwareTokenPostRequestST: IAssociateSoftwareTokenPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IAssociateSoftwareTokenPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretCode(
        iAssociateSoftwareTokenPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return attributes for a user.
     * @summary Return attributes for a user.
     * @param {IGetUserPostRequestST} iGetUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      iGetUserPostRequestST: IGetUserPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetUserPostResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        iGetUserPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Will take in a valid refresh token and return new tokens.
     * @summary Refresh user tokens.
     * @param {IRefreshTokenPostRequestST} iRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      iRefreshTokenPostRequestST: IRefreshTokenPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRefreshTokenPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        iRefreshTokenPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Resends the confirmation (for confirmation of registration) to a specific user.
     * @summary Resends the confirmation code.
     * @param {IResendConfirmationCodePostRequestST} iResendConfirmationCodePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resendCode(
      iResendConfirmationCodePostRequestST: IResendConfirmationCodePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IResendConfirmationCodePostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resendCode(
        iResendConfirmationCodePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Will take in a valid refresh token and revoke it.
     * @summary Revoke the refresh tokens.
     * @param {IRevokeRefreshTokenPostRequestST} iRevokeRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revokeRefreshToken(
      iRevokeRefreshTokenPostRequestST: IRevokeRefreshTokenPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.revokeRefreshToken(
        iRevokeRefreshTokenPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Set the user\'s multi-factor authentication (MFA) method preference.
     * @summary Set the user\'s MFA.
     * @param {ISetUserMFAPreferencePostRequestST} iSetUserMFAPreferencePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setUserMfaPreference(
      iSetUserMFAPreferencePostRequestST: ISetUserMFAPreferencePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setUserMfaPreference(
        iSetUserMFAPreferencePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Authenticate user using username and password.
     * @summary Authenticate user.
     * @param {ISignInPostRequestST} iSignInPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signIn(
      iSignInPostRequestST: ISignInPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAuthenticationResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(
        iSignInPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Use this endpoint to register a user\'s entered TOTP code and mark the user\'s software token MFA status as \"verified\".
     * @summary Mark MFA status as \"verified\".
     * @param {IVerifySoftwareTokenPostRequestST} iVerifySoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifySoftwareToken(
      iVerifySoftwareTokenPostRequestST: IVerifySoftwareTokenPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVerifySoftwareTokenPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifySoftwareToken(
        iVerifySoftwareTokenPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Verifies the specified user attribute.
     * @summary Verifies the specified user attribute.
     * @param {IVerifyUserAttributePostRequestST} iVerifyUserAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyUserAttribute(
      iVerifyUserAttributePostRequestST: IVerifyUserAttributePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyUserAttribute(
        iVerifyUserAttributePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthenticationApiFp(configuration);
  return {
    /**
     * Responds to the authentication challenge requested from AWS.
     * @summary Responds to the authentication challenge.
     * @param {IAuthChallengePostRequestST} iAuthChallengePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authChallenge(
      iAuthChallengePostRequestST: IAuthChallengePostRequestST,
      options?: any,
    ): AxiosPromise<IAuthenticationResponseST> {
      return localVarFp
        .authChallenge(iAuthChallengePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Allows a user to update a specific attribute (one at a time).
     * @summary Update user attribute.
     * @param {IChangeAttributePostRequestST} iChangeAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAttribute(
      iChangeAttributePostRequestST: IChangeAttributePostRequestST,
      options?: any,
    ): AxiosPromise<IChangeAttributePostResponseST> {
      return localVarFp
        .changeAttribute(iChangeAttributePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Changes the password for a user.
     * @summary Changes the password for a user.
     * @param {IChangePasswordPostRequestST} iChangePasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(
      iChangePasswordPostRequestST: IChangePasswordPostRequestST,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .changePassword(iChangePasswordPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Allows a user to enter a confirmation code to reset a forgotten password.
     * @summary Reset a forgotten password.
     * @param {IConfirmForgotPasswordPostRequestST} iConfirmForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmForgotPassword(
      iConfirmForgotPasswordPostRequestST: IConfirmForgotPasswordPostRequestST,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .confirmForgotPassword(iConfirmForgotPasswordPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calling this endpoint causes a message to be sent to the end user with a confirmation code that is required to change the user\'s password.
     * @summary Resets the user\'s password.
     * @param {IForgotPasswordPostRequestST} iForgotPasswordPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(
      iForgotPasswordPostRequestST: IForgotPasswordPostRequestST,
      options?: any,
    ): AxiosPromise<IForgotPasswordPostResponseST> {
      return localVarFp
        .forgotPassword(iForgotPasswordPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a unique generated shared secret key code for the user account.
     * @summary Associate software token to the user account.
     * @param {IAssociateSoftwareTokenPostRequestST} iAssociateSoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretCode(
      iAssociateSoftwareTokenPostRequestST: IAssociateSoftwareTokenPostRequestST,
      options?: any,
    ): AxiosPromise<IAssociateSoftwareTokenPostResponseST> {
      return localVarFp
        .getSecretCode(iAssociateSoftwareTokenPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return attributes for a user.
     * @summary Return attributes for a user.
     * @param {IGetUserPostRequestST} iGetUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(
      iGetUserPostRequestST: IGetUserPostRequestST,
      options?: any,
    ): AxiosPromise<IGetUserPostResponseST> {
      return localVarFp
        .getUser(iGetUserPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Will take in a valid refresh token and return new tokens.
     * @summary Refresh user tokens.
     * @param {IRefreshTokenPostRequestST} iRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      iRefreshTokenPostRequestST: IRefreshTokenPostRequestST,
      options?: any,
    ): AxiosPromise<IRefreshTokenPostResponseST> {
      return localVarFp
        .refreshToken(iRefreshTokenPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Resends the confirmation (for confirmation of registration) to a specific user.
     * @summary Resends the confirmation code.
     * @param {IResendConfirmationCodePostRequestST} iResendConfirmationCodePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendCode(
      iResendConfirmationCodePostRequestST: IResendConfirmationCodePostRequestST,
      options?: any,
    ): AxiosPromise<IResendConfirmationCodePostResponseST> {
      return localVarFp
        .resendCode(iResendConfirmationCodePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Will take in a valid refresh token and revoke it.
     * @summary Revoke the refresh tokens.
     * @param {IRevokeRefreshTokenPostRequestST} iRevokeRefreshTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeRefreshToken(
      iRevokeRefreshTokenPostRequestST: IRevokeRefreshTokenPostRequestST,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .revokeRefreshToken(iRevokeRefreshTokenPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Set the user\'s multi-factor authentication (MFA) method preference.
     * @summary Set the user\'s MFA.
     * @param {ISetUserMFAPreferencePostRequestST} iSetUserMFAPreferencePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUserMfaPreference(
      iSetUserMFAPreferencePostRequestST: ISetUserMFAPreferencePostRequestST,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .setUserMfaPreference(iSetUserMFAPreferencePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Authenticate user using username and password.
     * @summary Authenticate user.
     * @param {ISignInPostRequestST} iSignInPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signIn(
      iSignInPostRequestST: ISignInPostRequestST,
      options?: any,
    ): AxiosPromise<IAuthenticationResponseST> {
      return localVarFp
        .signIn(iSignInPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to register a user\'s entered TOTP code and mark the user\'s software token MFA status as \"verified\".
     * @summary Mark MFA status as \"verified\".
     * @param {IVerifySoftwareTokenPostRequestST} iVerifySoftwareTokenPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySoftwareToken(
      iVerifySoftwareTokenPostRequestST: IVerifySoftwareTokenPostRequestST,
      options?: any,
    ): AxiosPromise<IVerifySoftwareTokenPostResponseST> {
      return localVarFp
        .verifySoftwareToken(iVerifySoftwareTokenPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Verifies the specified user attribute.
     * @summary Verifies the specified user attribute.
     * @param {IVerifyUserAttributePostRequestST} iVerifyUserAttributePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUserAttribute(
      iVerifyUserAttributePostRequestST: IVerifyUserAttributePostRequestST,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .verifyUserAttribute(iVerifyUserAttributePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
  /**
   * Responds to the authentication challenge requested from AWS.
   * @summary Responds to the authentication challenge.
   * @param {IAuthChallengePostRequestST} iAuthChallengePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authChallenge(
    iAuthChallengePostRequestST: IAuthChallengePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .authChallenge(iAuthChallengePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Allows a user to update a specific attribute (one at a time).
   * @summary Update user attribute.
   * @param {IChangeAttributePostRequestST} iChangeAttributePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public changeAttribute(
    iChangeAttributePostRequestST: IChangeAttributePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .changeAttribute(iChangeAttributePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changes the password for a user.
   * @summary Changes the password for a user.
   * @param {IChangePasswordPostRequestST} iChangePasswordPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public changePassword(
    iChangePasswordPostRequestST: IChangePasswordPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .changePassword(iChangePasswordPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Allows a user to enter a confirmation code to reset a forgotten password.
   * @summary Reset a forgotten password.
   * @param {IConfirmForgotPasswordPostRequestST} iConfirmForgotPasswordPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public confirmForgotPassword(
    iConfirmForgotPasswordPostRequestST: IConfirmForgotPasswordPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .confirmForgotPassword(iConfirmForgotPasswordPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calling this endpoint causes a message to be sent to the end user with a confirmation code that is required to change the user\'s password.
   * @summary Resets the user\'s password.
   * @param {IForgotPasswordPostRequestST} iForgotPasswordPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public forgotPassword(
    iForgotPasswordPostRequestST: IForgotPasswordPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .forgotPassword(iForgotPasswordPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a unique generated shared secret key code for the user account.
   * @summary Associate software token to the user account.
   * @param {IAssociateSoftwareTokenPostRequestST} iAssociateSoftwareTokenPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getSecretCode(
    iAssociateSoftwareTokenPostRequestST: IAssociateSoftwareTokenPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .getSecretCode(iAssociateSoftwareTokenPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return attributes for a user.
   * @summary Return attributes for a user.
   * @param {IGetUserPostRequestST} iGetUserPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getUser(iGetUserPostRequestST: IGetUserPostRequestST, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .getUser(iGetUserPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Will take in a valid refresh token and return new tokens.
   * @summary Refresh user tokens.
   * @param {IRefreshTokenPostRequestST} iRefreshTokenPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public refreshToken(
    iRefreshTokenPostRequestST: IRefreshTokenPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .refreshToken(iRefreshTokenPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Resends the confirmation (for confirmation of registration) to a specific user.
   * @summary Resends the confirmation code.
   * @param {IResendConfirmationCodePostRequestST} iResendConfirmationCodePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public resendCode(
    iResendConfirmationCodePostRequestST: IResendConfirmationCodePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .resendCode(iResendConfirmationCodePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Will take in a valid refresh token and revoke it.
   * @summary Revoke the refresh tokens.
   * @param {IRevokeRefreshTokenPostRequestST} iRevokeRefreshTokenPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public revokeRefreshToken(
    iRevokeRefreshTokenPostRequestST: IRevokeRefreshTokenPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .revokeRefreshToken(iRevokeRefreshTokenPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Set the user\'s multi-factor authentication (MFA) method preference.
   * @summary Set the user\'s MFA.
   * @param {ISetUserMFAPreferencePostRequestST} iSetUserMFAPreferencePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public setUserMfaPreference(
    iSetUserMFAPreferencePostRequestST: ISetUserMFAPreferencePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .setUserMfaPreference(iSetUserMFAPreferencePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Authenticate user using username and password.
   * @summary Authenticate user.
   * @param {ISignInPostRequestST} iSignInPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public signIn(iSignInPostRequestST: ISignInPostRequestST, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .signIn(iSignInPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to register a user\'s entered TOTP code and mark the user\'s software token MFA status as \"verified\".
   * @summary Mark MFA status as \"verified\".
   * @param {IVerifySoftwareTokenPostRequestST} iVerifySoftwareTokenPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public verifySoftwareToken(
    iVerifySoftwareTokenPostRequestST: IVerifySoftwareTokenPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .verifySoftwareToken(iVerifySoftwareTokenPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Verifies the specified user attribute.
   * @summary Verifies the specified user attribute.
   * @param {IVerifyUserAttributePostRequestST} iVerifyUserAttributePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public verifyUserAttribute(
    iVerifyUserAttributePostRequestST: IVerifyUserAttributePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationApiFp(this.configuration)
      .verifyUserAttribute(iVerifyUserAttributePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
