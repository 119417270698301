// libraries
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// store
import { AUTH_PAGES_URLS } from 'common/pages.tsx';
import { LocalStore } from 'common/functions/storageFunctions';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useClientModalsStore, useFacilityModalsStore } from '../../store/Modals';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';

// variables, functions
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { ClientModalsActionTypes, FacilityModalsActionTypes } from '../../store/Modals/types';

const SignOut = ({ onSignout }) => {
  // navigate
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { dispatchClientLevel } = useClientLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { dispatchClientModals } = useClientModalsStore();

  // snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  // Display logout message
  useEffect(() => {
    enqueueSnackbar(location.state?.logoutMessage || 'You are logged out!', {
      variant: location.state?.variant || 'success',
      preventDuplicate: true,
    });
  });

  // Logout
  useEffect(() => {
    LocalStore.cleanStateAndLocalStorage({
      dispatchFacilityLevel,
      dispatchClientLevel,
      dispatchUserLevelStore,
    });
    dispatchFacilityModals({ type: FacilityModalsActionTypes.RESET });
    dispatchClientModals({ type: ClientModalsActionTypes.RESET });
    dispatchUserLevelStore({ type: UserLevelActionNames.SIGNOUT });
    if (onSignout) onSignout();
    navigate(AUTH_PAGES_URLS.SIGNIN, { state: {} });
  });

  return null;
};

SignOut.propTypes = { onSignout: PropTypes.func };
export default SignOut;
