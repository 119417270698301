import React, { ChangeEvent, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';

import { SelectChangeEvent, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';

import {
  calculateElapsedTimeMoment,
  getValidIntervalOptions,
  IntervalOption,
  notEver,
} from 'common/functions/otherFunctions';
import {
  validateOccurrenceInterval,
  getRecurrenceData,
  generateRruleFromUIComponents,
} from 'common/functionsReportSpec';
import {
  IntervalLabel,
  pluralizeInterval,
  RELATIVE_DATETIME_FORMAT,
  WeekDayShort,
} from 'common/datetimeFormats';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import Spinner from 'components/common/Spinner';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import { Box } from 'components/common/Box';
import WeekdaysForm from './WeekdaysForm';
import { ScheduleFormAction, ScheduleFormState } from '../reducers/ScheduleFormReducer';

const useStyles = makeStyles()((theme: Theme) => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  formInput: {
    width: '100%',
    margin: '12px 0',
    '&:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  recurrenceMessage: {
    marginTop: theme.spacing(1),
    '& p': {
      margin: theme.spacing(0),
    },
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

/**
 * Props for the Recurrence component
 */
interface IRecurrenceProps {
  /**
   * System ID
   */
  systemId: string;
  /**
   * ScheduleForm state
   */
  scheduleFormState: ScheduleFormState;
  /**
   * Action dispatcher
   */
  dispatch: React.Dispatch<RecurrenceAction | ScheduleFormAction>;
  /**
   * Timezone for report the recurrence is part of
   */
  timezone: string;
  /**
   * Set days function
   */
  setDays: (days: WeekDayShort[]) => void;
  /**
   * Source of trigger for report recurrence
   */
  reportSpecTriggering: string;
}

/**
 * Recurrence state type
 */
export type RecurrenceState = {
  intervalOptions: IntervalOption[];
  recurrenceMessage: JSX.Element | null;
  isLoading: boolean;
  rText: string;
  nextOccurrence: moment.Moment | null;
  occurrencesCount: number;
  countCapped: boolean;
};

/**
 *
 * @returns Recurrence initial state
 */
export const getInitialRecurrenceState = (): RecurrenceState => ({
  intervalOptions: [],
  recurrenceMessage: null,
  isLoading: false,
  rText: '',
  nextOccurrence: null,
  occurrencesCount: 0,
  countCapped: false,
});

/**
 * Recurrence actions
 */
export type RecurrenceAction =
  | { type: 'SET_IS_LOADING'; isLoading: boolean }
  | {
      type: 'SET_RECURRENCE_DATA';
      payload: {
        occurrencesCount: number;
        countCapped: boolean;
        rText: string;
        nextOccurrence: moment.Moment;
      };
    }
  | { type: 'SET_INTERVAL_OPTIONS'; intervalOptions: IntervalOption[] };

/**
 *
 * @param state RecurrenceState params
 * @param action RecurrenceAction params
 * @returns RecurrenceState stateisValidOccurrencesValue
 */
export const recurrenceReducer = (
  state: RecurrenceState,
  action: RecurrenceAction,
): RecurrenceState => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'SET_RECURRENCE_DATA':
      return { ...state, ...action.payload };
    case 'SET_INTERVAL_OPTIONS':
      return { ...state, intervalOptions: action.intervalOptions };
    default:
      notEver(action);
      return state;
  }
};

const logPrefix = getLogPrefixForType('COMPONENT', 'Recurrence');

export const Recurrence = ({
  systemId,
  scheduleFormState,
  dispatch,
  timezone,
  reportSpecTriggering,
  setDays,
}: IRecurrenceProps) => {
  const { classes } = useStyles();

  const state = scheduleFormState.recurrenceState;
  const { days, isRecurring, dateFrom, dateUntil, occurrenceInterval, interval } =
    scheduleFormState;

  moment.tz.setDefault(timezone);

  const { enqueueSnackbar } = useSnackbar();

  const isValidOccurrencesValues =
    scheduleFormState.occurrenceInterval > 0 &&
    moment(scheduleFormState.dateFrom).isValid() &&
    moment(scheduleFormState.dateUntil).isValid() &&
    moment(scheduleFormState.dateFrom).isBefore(scheduleFormState.dateUntil) &&
    scheduleFormState.interval;

  const { rString } = generateRruleFromUIComponents({
    days,
    setDays,
    isRecurring,
    dateFrom,
    dateUntil,
    occurrenceInterval,
    timezone,
    reportSpecTriggering,
    interval,
  });

  /**
   * Handle first incoming occurrence message
   */
  const nextReportMessage = () => {
    let message = moment(state.nextOccurrence).calendar(null, RELATIVE_DATETIME_FORMAT);
    if (!message.includes('today') && !message.includes('tomorrow')) {
      message = `on ${message}`;
    }
    return message;
  };

  const { hours, minutes } = calculateElapsedTimeMoment(
    scheduleFormState.dateFrom,
    scheduleFormState.dateUntil,
  );

  const intervalOptions = getValidIntervalOptions(Number(hours * 60) + Number(minutes), true);

  const selectedIntervalIndex = scheduleFormState.interval
    ? intervalOptions.findIndex((option) => option.label === scheduleFormState.interval)
    : intervalOptions.findIndex((option) => option.enabled);

  const selectedInterval: IntervalLabel = intervalOptions[selectedIntervalIndex]?.enabled
    ? intervalOptions[selectedIntervalIndex].label
    : ('' as IntervalLabel);

  const intervalSelectOptions = intervalOptions.map((inter) => ({
    key: inter.label,
    disabled: !inter.enabled,
    value: inter.label,
    label: inter.enabled
      ? capitalize(inter.label)
      : `${capitalize(inter.label)} - the dates selected do not allow this recurrence pattern`,
  }));

  const recurrenceMessage = isValidOccurrencesValues ? (
    <Box width="100%" display="flex" flexDirection="column">
      <Alert sx={{ mb: 1 }} icon={false} severity="info">
        <Typography>Occurs {state.rText}</Typography>
      </Alert>
      <Typography sx={{ pl: 2, mb: 2 }}>Next report will start {nextReportMessage()}</Typography>
      <Typography sx={{ pl: 2 }} data-testid="c-recurrence-count">
        {`Number of reports to be generated: ${state.occurrencesCount}${
          state.countCapped ? '+' : ''
        }`}
      </Typography>
    </Box>
  ) : (
    <Alert icon={false} severity="warning" sx={{ height: '100%' }}>
      <Box height="100%">
        <Typography>No report will be generated within the defined interval</Typography>
      </Box>
    </Alert>
  );

  const { valid: isValidInterval, errorMsg: intervalErrorMessage } = validateOccurrenceInterval(
    scheduleFormState.isRecurring,
    scheduleFormState.occurrenceInterval,
    scheduleFormState.allowedOccurrenceIntervals,
  );

  const handleSelectedIntervalChange = (e: SelectChangeEvent<string>) => {
    console.debug(logPrefix, 'handleSelectedIntervalChange', 'SET_INTERVAL', e.target.value);
    dispatch({ type: 'SET_INTERVAL', payload: e.target.value as IntervalLabel });
  };

  const handleDaysChange = (newDays: WeekDayShort[]) => {
    console.debug(logPrefix, 'handleDaysChange', 'SET_DAYS', newDays);
    dispatch({ type: 'SET_DAYS', payload: newDays });
  };

  const handleOccurrenceIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.debug(logPrefix, 'handleOccurrenceIntervalChange', 'SET_INTERVAL', e.target.value);
    const newOccurrenceInterval = Number(e.target.value);
    dispatch({ type: 'SET_OCCURRENCE_INTERVAL', payload: newOccurrenceInterval });
  };

  useEffect(() => {
    if (isValidOccurrencesValues) {
      getRecurrenceData(systemId!, rString, dispatch, enqueueSnackbar);
    }
  }, [systemId, rString, dispatch, isValidOccurrencesValues, enqueueSnackbar]);

  return (
    <>
      <Grid className={classes.gridItem} xs={12} md={12} item>
        <Box className={classes.formControl} width="100%" px={0}>
          <FormControl className={classes.formInput} variant="outlined">
            <CustomSelect
              testId="c-recurrence-intervals"
              id="demo-simple-select-outlined"
              label="Interval"
              value={selectedInterval}
              variant="outlined"
              onChange={handleSelectedIntervalChange}
              valueOptions={intervalSelectOptions}
              name=""
              error={false}
              errorMessage=""
              defaultValue=""
              disabled={false}
            />
          </FormControl>
          {includes(['weekly', 'monthly', 'yearly'], scheduleFormState.interval) && (
            // When the 'type' prop of TextField input is set to 'number' and it has an initial value,
            // once the entered number is deleted, the displayed value becomes 0.
            // If you then enter another number, the zero will stay unless the page is refreshed.
            // Which gives us slightly bad UI. That's why we convert value to string.
            // This is a general bug in react.
            // source: https://github.com/mui-org/material-ui/issues/8380

            <FormControl className={classes.formInput} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Every</InputLabel>
              <OutlinedInput
                data-testid="c-n-recurrence-field"
                fullWidth
                label="Every"
                value={scheduleFormState.occurrenceInterval.toString()}
                endAdornment={
                  <InputAdornment position="end">
                    {pluralizeInterval(
                      scheduleFormState.occurrenceInterval,
                      scheduleFormState.interval,
                    )}
                  </InputAdornment>
                }
                inputProps={{ min: 1, max: scheduleFormState.allowedOccurrenceIntervals }}
                onChange={handleOccurrenceIntervalChange}
                type="number"
                error={!isValidInterval}
              />{' '}
              <FormHelperText error={!isValidInterval} id="filled-weight-helper-text">
                {intervalErrorMessage}
              </FormHelperText>
            </FormControl>
          )}
        </Box>

        {scheduleFormState.interval === 'weekly' && (
          <FormControl className={classes.formInput} variant="outlined">
            <WeekdaysForm days={scheduleFormState.days} setDays={handleDaysChange} />
          </FormControl>
        )}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        height={isValidOccurrencesValues ? 150 : 50}
        sx={{ ml: 2 }}
      >
        {state.isLoading ? <Spinner /> : recurrenceMessage}
      </Box>
    </>
  );
};
