import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(0.5),
    background: 'white',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    boxShadow: '0 2px 8px -2px rgba(0,0,0,0.1), 0 2px 12px -4px rgba(0,0,0,0.2)',
  },
  button: {
    '> svg': {
      fontSize: 'inherit',
    },
  },
  buttonHidden: {
    display: 'none',
  },
}));
