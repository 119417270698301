import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';
import { IFacilityModalsState } from './IFacilityModalsStore';

/**
 * Initial state of the facility modal store
 */
export const initialFacilityModalsState: IFacilityModalsState = {
  locationInfo: {},
  // requests and issues
  editRequestModalOpened: false,
  deleteRequestModalOpened: false,
  toggleRequestStateModalOpened: false,
  toggleSnoozeStateModalOpened: false,
  amendModalOpened: false,
  overwriteData: [
    {
      userOverride: {},
      verityState: '-',
      location: '',
      version: '-',
      verityValue: [],
      wmsState: '-',
      wmsValue: '',
      slotStatusVersion: '',
      isReview: false,
      userOverrideValue: '',
      verityAmended: '',
      originalVerityState: '-',
      originalVerityValue: '',
      showContentSetByUser: false,
      imageIds: [],
      reportId: '',
      verityDate: '',
      length: 0,
      selected: false,
      userOverrideUserName: '',
    },
  ],
  locationModalOpened: false,
  requestToEdit: {},

  // reports
  abortReportModalOpened: false,
  abortReportModalData: {},
  archiveReportModalOpened: false,
  archiveReportModalData: {},
  restoreReportModalOpened: false,
  restoreReportModalData: {},
  updateReportModalOpened: false,
  updateReportModalData: {},

  // user managment
  addEditUserModalOpened: false,
  deleteUserModalOpened: false,
  deleteUserData: {},
  allUserEmails: [],

  // facility
  resetSystemModalOpened: false,
  resetSystemData: {},

  // delivery fleet
  droneErrorModalOpened: false,
  droneErrorData: {
    id: -1,
    drone_name: '-1',
    serial: '0000000',
    chargerId: '',
    chargerPosition: '',
    battery_level: '',
    battery_state: '',
    wifiStatus: '',
    drone_state: '',
    drone_online: 'OFFLINE',
    last_operation_feedback: '',
    not_ready_reason: '',
    additional_diagnostics: '',
    updatedAt: '',
  },

  // common
  deleteReportSpecModalOpened: false,
  deleteReportSpecData: {},
  locationData: {
    userOverride: '',
    verityState: '-',
    location: '',
    version: '-',
    verityValue: '',
    wmsState: '-',
    wmsValue: '',
    slotStatusVersion: '',
    isReview: false,
    userOverrideValue: '',
    length: 0,
    issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
  },
};
