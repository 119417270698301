import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';
import { COLOR_PALETTE } from 'common/colors';

export const useStyles = makeStyles()((theme: Theme) => ({
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 56,
    background: 'white',
    textAlign: 'start',
    listStyle: 'none',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    paddingInline: theme.spacing(1),

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },

    '&:focus-within': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  itemWrapperActive: {
    backgroundColor: `${alpha(COLOR_PALETTE.BLUE, 0.2)} !important`,
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    minWidth: 0,
    border: 0,
    background: 'none',
    justifyContent: 'space-between',
    textAlign: 'inherit',
    cursor: 'pointer',
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
    alignSelf: 'stretch',
    order: 1,

    ':focus': {
      outline: 0,
    },
  },
  detailsName: {
    flex: '1 1 auto',
    minWidth: 0,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
  },
  detailsWms: {
    color: alpha('#000000', 0.4),
  },
  actionArea: {
    flex: '0 0 auto',
    paddingBlock: theme.spacing(1),
    order: 0,
  },
}));
