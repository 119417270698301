import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const schedulerStyles = makeStyles()((theme: Theme) => ({
  mainCard: {
    margin: theme.spacing(2, 0),
  },
  mainGrid: {
    padding: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  templateIcon: {
    paddingRight: theme.spacing(2),
  },
  cardHeader: {
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: '600',
    color: theme.palette.secondary.main,
  },
  fullWidth: {
    maxWidth: '100%',
    width: '100%',
  },
  noReportSpecsMessageHolder: {
    margin: theme.spacing(20, 0),
    textAlign: 'center' as 'center',
  },
  reportSpecInfoCard: {
    margin: theme.spacing(0, 2),
  },
  reportSpecInfoCardParagraph: {
    padding: theme.spacing(2, 0),
  },
  spaciousDivider: {
    margin: theme.spacing(4, 0),
  },
  '&.MuiFormHelperText-filled': {
    textColor: theme.palette.error.main,
  },
}));

export const schedulerFormStyles = makeStyles()((theme: Theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  formControl: {
    width: '100%',
    margin: '12px 0 12px 0',
  },
  formFirstControl: {
    width: '100%',
    margin: '12px 0 0 0',
  },
  formFlexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {},
    '& > div:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  gridItem: {
    maxWidth: '100%',
    width: '100%',
  },
  cardItem: {
    transition: '.3s ease',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  expandContent: {
    width: '100%',
  },
  selectInputBox: {
    marginBottom: '22px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectOkWrapper: {
    height: 40,
    position: 'sticky' as 'sticky',
    zIndex: 1000,
    bottom: 0,
    background: '#FFF',
  },
  selectOkButton: {
    background: theme.palette.primary.main,
    color: '#FFF',
    marginRight: theme.spacing(1),
  },
  formHelper: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(-2.8),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  divider: {
    marginTop: '10px',
    marginBottom: '30px',
  },
}));

export const inputSelectListStyle = {
  ITEM_HEIGHT: 58,
  ITEM_PADDING_TOP: 8,
};
