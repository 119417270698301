import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Checkbox, Typography, useTheme } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import Spinner from 'components/common/Spinner';

import GroundControlServices from '../../services/GroundControlServices';

import { GroundControlModalsActionNames } from '../../store/Modals/groundControlModals/groundControlModalsActions';

import { useRequestController } from '../../hooks';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { GroundControlActionNames } from '../../store/GroundControl/groundControlLevelActions';
import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

/**
 * Interface for Unlock modal props
 */
interface IUnlockModalProps {
  opened: boolean;
  facilityName: string;
  flightDomainId: string;
  flightDomainName: string;
}

const UnlockModal = (props: IUnlockModalProps) => {
  const { opened, flightDomainId, flightDomainName, facilityName } = props;

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();
  const [loading, setLoading] = useState(false);
  const [dualChecked, setDualChecked] = useState(false);

  const theme = useTheme() as any;

  const systemId = useLocation().pathname.split('/')[1];

  const { requestController } = useRequestController('Unlock System Modal');

  /**
   * Handle flight domain unlock
   */
  const handleSystemUnlock = () => {
    const lp = getLogPrefixForType('FUNCTION', 'handleSystemUnlock');
    const reservation = requestController.reserveSlotForRequest();

    return requestController.doRequest({
      request: GroundControlServices.unlockFlightDomain,
      requestParams: [systemId, flightDomainId, reservation.signal],
      callbackBeforeSend: () => {
        console.debug(lp, 'Unlocking the flight domain');
        setLoading(true);
      },
      messageErrorFallback: 'Error occured while unlocking the flight domain.',
      messageSuccess: 'Flight domain unlocked',
      callbackSuccess: async () => {
        console.debug(lp, 'Flight domain unlocked');
        dispatchGroundControlModals({
          type: GroundControlModalsActionNames.UNLOCK_MODAL,
        });
        dispatchGroundControlLevel({
          type: GroundControlActionNames.SET_ALERT_INFO,
          payload: {
            key: 'statusCard',
            variant: stateGroundControl.activeAlertVariant,
            message: 'System has been unlocked.',
          },
        });
      },
      callbackFinally: () => {
        setDualChecked(false);
        setLoading(false);
      },
    });
  };

  const closeModal = () => {
    // NOTE: closing the modal doesn't actually un-mount the component but simply hides it
    // so, if we want the checkbox to be not checked next time the dialog is open
    // we need to reset here the "dualChecked" flag
    setDualChecked(false);
    dispatchGroundControlModals({
      type: GroundControlModalsActionNames.UNLOCK_MODAL,
    });
  };

  const isUnlockButtonDisabled = useMemo(() => {
    console.debug(
      'isUnlockButtonDisabled',
      'stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock',
      stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock,
    );
    console.debug('isUnlockButtonDisabled', 'dualChecked', dualChecked);
    return !!stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock && !dualChecked;
  }, [dualChecked, stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock]);

  return (
    <ModalBase
      testId="c-unlock-modal"
      closeButton={true}
      disableBackdropClick={true}
      opened={opened}
      handleClose={closeModal}
      title={
        <Box display="flex" p={2} alignItems="flex-start">
          <Box mt={0.6} mr={1}>
            <LockOpenIcon
              sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.grey[700] }}
            />
          </Box>
          <Box data-testid="c-unlock-modal-title" textAlign="left" mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="textPrimary.main" variant="h5">
              Unlock System
            </Typography>
            <Typography
              textTransform="uppercase"
              data-testid="c-unlock-modal-subtitle"
              color="secondary"
              variant="subtitle1"
            >
              {`${facilityName} - ${flightDomainName}`}
            </Typography>
          </Box>
        </Box>
      }
      actionButtons={
        <>
          <Button
            data-testid="c-unlock-modal-btn-cancel"
            onClick={closeModal}
            variant="outlined"
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
          <Button
            data-testid="c-unlock-modal-btn-confirm"
            onClick={handleSystemUnlock}
            variant="contained"
            fullWidth
            disabled={isUnlockButtonDisabled}
            color="primary"
            startIcon={<LockOpenIcon />}
          >
            Unlock system
          </Button>
        </>
      }
    >
      <Box data-testid="c-lock-modal-content">
        {`
        Unlocking will allow drones to take off and perform their tasks. Please ensure that you have
        followed your internal safety protocols before you enable flying in ${flightDomainName}.`}
      </Box>

      {loading && <Spinner />}
      {stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock && (
        <Box
          data-testid="c-dual-check-on-unlock-disclaimer"
          sx={{
            mt: 4,
            backgroundColor: 'rgb(229, 246, 253)',
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '1.5em',
            paddingRight: '1em',
            lineHeight: '2.5em',
          }}
        >
          <Checkbox
            checked={dualChecked}
            onChange={() => setDualChecked(!dualChecked)}
            sx={{ marginLeft: '-0.7em', marginTop: '-3px' }}
          />
          {stateFacilityLevel.facilitySettings?.dual_check_on_unlock_disclaimer}
        </Box>
      )}
    </ModalBase>
  );
};

export default UnlockModal;
