import moment from 'moment-timezone';
import isNil from 'lodash/isNil';
import inRange from 'lodash/inRange';

// variables
import { IWMSFilesST } from 'codegen/wms_data';
import { GROUND_CONTROL_STATUS_COLORS } from '../colors';
import { DATETIME_FORMAT, DATETIME_FORMAT_WITH_WEEKDAY, IntervalLabel } from '../datetimeFormats';

// services & store
import UploadStore from '../../store/UploadStore';

// functions
import { isVerityUser } from './userFunctions';
import { IFileInfoST, UPLOADED_FILES_TYPES } from '../../interfaces';
import { IRequestController } from '../../hooks';
import { LOCK_REASON } from '../groundControlHelpers';
import {
  FleetStatusSummaryST,
  FlightDomainStatusST,
} from '../../store/GroundControl/IGroundControlStore';
import { getLogPrefixForType } from './logFunctions';
import { UploadedFilesRequestParams } from './uploadedFilesRequestParams.model';

/**
 * Get the installed Front End version
 * @returns FE Version
 */
export const getVersionIfAvailable = () => ({
  version: import.meta.env.VITE_APP_VERSION ? import.meta.env.VITE_APP_VERSION : 'not available',
  commitId: import.meta.env.VITE_APP_COMMIT_ID
    ? import.meta.env.VITE_APP_COMMIT_ID
    : 'not available',
  commitMessage: import.meta.env.VITE_APP_COMMIT_MESSAGE
    ? import.meta.env.VITE_APP_COMMIT_MESSAGE
    : 'not available',
  feLastUpdated: import.meta.env.VITE_APP_LAST_UPDATED_AT
    ? import.meta.env.VITE_APP_LAST_UPDATED_AT
    : 'not available',
});

interface ISort {
  array: any[];
  sortingOrder: 'asc' | 'desc';
  sortBy: string;
}
export const sort = ({ array, sortingOrder, sortBy }: ISort) => {
  if (sortingOrder === 'asc') {
    return array.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      if (b[sortBy] > a[sortBy]) {
        return -1;
      }
      return 0;
    });
  }
  if (sortingOrder === 'desc') {
    return array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return 1;
      }
      if (b[sortBy] < a[sortBy]) {
        return -1;
      }
      return 0;
    });
  }
  return array;
};

/**
 * Sorts the array while preserving type of the array
 * @param arr array to sort
 * @param order 'asc' or 'desc' order to sort by
 * @param sortByPick function that picks the property to sort by
 * @returns sorted array of same type as arr
 * @example pickSort(arr, 'asc', (o) => o.name.toLowerCase())
 */
export const pickSort = <T>(
  arr: T[],
  order: 'asc' | 'desc',
  sortByPick: (o: T) => T[keyof T],
): T[] => {
  if (order === 'asc') {
    return arr.sort((x, y) => (sortByPick(x) > sortByPick(y) ? 1 : -1));
  }
  return arr.sort((x, y) => (sortByPick(x) < sortByPick(y) ? 1 : -1));
};

/**
 * Sorts the array while perserving type of the array
 * @param arr array to sort
 * @param order 'asc' or 'desc' order to sort by
 * @param sortBy property to sort by
 * @returns sorted array of same type as arr
 */
export const tSort = <T>(arr: T[], order: 'asc' | 'desc', sortBy: keyof T): T[] => {
  if (order === 'asc') {
    return arr.sort((x, y) => (x[sortBy] > y[sortBy] ? 1 : -1));
  }
  return arr.sort((x, y) => (x[sortBy] < y[sortBy] ? 1 : -1));
};

interface ICalculateElapsedTime {
  from: string;
  until: string;
}

export const calculateElapsedTime = ({ from, until }: ICalculateElapsedTime) => {
  const hours: number = Math.floor(moment(until).diff(moment(from), 'minutes') / 60);
  // hours = hours.toString();
  let minutes: number | string = moment(until).diff(moment(from), 'minutes') % 60;
  minutes = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

  return {
    label: `${hours}h ${minutes}m`,
    hours,
    minutes,
  };
};

/**
 * Calculate elapsed time moment
 * @param from date from
 * @param until date until
 * @returns object with label, houes and minutes
 */
export const calculateElapsedTimeMoment = (from: moment.MomentInput, until: moment.MomentInput) => {
  const hours: number = Math.floor(moment(until).diff(moment(from), 'minutes') / 60);
  // hours = hours.toString();
  let minutes: number | string = moment(until).diff(moment(from), 'minutes') % 60;
  minutes = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

  return {
    label: `${hours}h ${minutes}m`,
    hours,
    minutes,
  };
};

/**
 * Function returns  array of valid interval options based on minutes between dateFrom and dateUntil
 * @param min number of minutes
 * @param canScheduleHourly whether the user can schedule hourly reports
 * @returns array of valid interval options
 */
export const getValidIntervalOptions = (min: number, canScheduleHourly: boolean = true) => {
  // if the user can schedule hourly reports, include the hourly option in the list
  let options = canScheduleHourly ? [{ label: 'hourly' as IntervalLabel, enabled: min >= 60 }] : [];

  options = options.concat([
    { label: 'daily' as IntervalLabel, enabled: min >= 1440 },
    { label: 'weekly' as IntervalLabel, enabled: min >= 10080 },
    { label: 'monthly' as IntervalLabel, enabled: min >= 44640 },
    { label: 'yearly' as IntervalLabel, enabled: min >= 525600 },
  ]);

  return options;
};

/**
 * Calculates valid interval options for given start and end time
 * @param from start time of the interval
 * @param until end time of the interval
 * @returns interval options possible in given time-frame
 */
export const calculateValidIntervalOptions = (
  from: moment.MomentInput,
  until: moment.MomentInput,
) => {
  const { hours, minutes } = calculateElapsedTimeMoment(from, until);
  const passedMinutes = Number(hours * 60) + Number(minutes);
  return getValidIntervalOptions(passedMinutes);
};

// Facility Settings validations
// #############################
export const validRangeGtZero = (
  min: number | undefined | null,
  max: number | undefined | null,
) => {
  if (!min || !max) {
    return {
      min: 'You must enter both a minimum and maximum value',
      max: 'You must enter both a minimum and maximum value',
    };
  }

  let textMin = '';
  let textMax = '';

  if (min > max) {
    textMin = 'Min must not be higher than max';
    textMax = 'Max must not be lower than min';
  } else if (min === 0) {
    textMin = 'Values must be higher than 0';
  } else if (max === 0) {
    textMax = 'Values must be higher than 0';
  }
  return {
    min: textMin,
    max: textMax,
  };
};

export const validBarcodeInvalidLength = (value: number, min: number, max: number) => {
  let text = '';
  if (!inRange(value, min, max)) {
    text = `Barcode invalid lengths must be in range ${min}-${max}`;
  }
  return text;
};

export const validIfInRangeInclusive = (
  value: number | undefined | null,
  min: number,
  max: number,
) => {
  let text = '';
  if (typeof value !== 'number' || value < min || value > max) {
    text = `Value must be in range ${min}-${max} (inclusive)`;
  }
  return text;
};

export const validIfGtZero = (value?: number | null | undefined, mustBeDefined?: boolean) => {
  let text = '';
  if (!value && mustBeDefined) {
    text = 'Value must be a number';
  } else if (!isNil(value) && value <= 0) {
    text = 'Value must be higher than 0';
  }
  return text;
};

export interface IProcessedFile {
  fileURL: string;
  clientFileName: string;
  totalLocations: number;
  updatedLocations: any;
  uploadedAt: string;
  lastEntryUpdate: string;
}

const processLatestFile = (fileInfo: IFileInfoST, timezone: string): IProcessedFile => ({
  fileURL: isVerityUser() ? fileInfo.wms_parser_output_url : fileInfo.client_file_url,
  clientFileName: fileInfo.client_file_name,
  totalLocations: fileInfo.total_locations,
  updatedLocations: fileInfo.updated_locations,
  uploadedAt: fileInfo.last_modified
    ? moment(fileInfo.last_modified).tz(timezone).format(DATETIME_FORMAT_WITH_WEEKDAY)
    : '-',
  lastEntryUpdate: fileInfo.most_recent_entry_updated_at
    ? moment(fileInfo.most_recent_entry_updated_at)
        .tz(timezone)
        .format(DATETIME_FORMAT_WITH_WEEKDAY)
    : '-',
});

/**
 * Parameters for the asyncGetUploadedFiles
 */
interface IGetUploadedFiles {
  /**
   * System ID
   */
  systemId: string;
  /**
   * File type
   */
  fileType: UPLOADED_FILES_TYPES;
  /**
   * Request parameters
   */
  params: UploadedFilesRequestParams;
  /**
   * Set spinner function
   */
  setSpinner?: (params: (repliesPending: number) => number) => void;
  /**
   * Time Zone
   */
  timezone: string;
  /**
   * Request Controller
   */
  requestController: IRequestController;
  /**
   * Request ID for the request inside the passed request controller
   */
  requestId: string;
  /**
   * Axios abort signal
   */
  signal: AbortSignal;
  /**
   * Call-back invoked after each page is fetched. Used e.g. to partially populate reports list
   * as the list is loaded.
   */
  callBackPerPage?: (files: IWMSFilesST[]) => void;
}

export const asyncGetUploadedFiles = async ({
  systemId,
  fileType,
  params,
  setSpinner,
  timezone,
  requestController,
  requestId,
  signal,
  callBackPerPage,
}: IGetUploadedFiles) => {
  let allUploadedFilesFetched = false;
  let files: IWMSFilesST[] = [];
  let lastFileInfo = {} as IFileInfoST;
  let reqParams = { ...params };

  const lp = getLogPrefixForType('FUNCTION', 'asyncGetUploadedFiles');

  // Reset the spinner
  // NOTE: we need this because in the case requests gets cancelled their corresponding
  // finally callback might not be executed
  if (setSpinner) {
    setSpinner((repliesPending: number) => 0);
  }

  console.debug(
    lp,
    'get uploaded files in a paginated fashion, loop while pageToken is in response',
  );
  let currentPageLoading = 0;
  while (!allUploadedFilesFetched && !requestController.isRequestCancelled(requestId)) {
    console.debug(lp, `requesting page ${currentPageLoading} with params ${reqParams}`);
    const resp = await requestController.doRequest({
      request: UploadStore.getUploadedFiles,
      requestParams: [systemId, fileType, reqParams, signal],
      messageErrorFallback: 'Something went wrong while getting the list of uploaded files',
      callbackBeforeSend: () => {
        setSpinner && setSpinner((repliesPending: number) => repliesPending + 1);
      },
      callbackFinally: () => {
        setSpinner && setSpinner((repliesPending: number) => repliesPending - 1);
      },
    });

    if (requestController.isRequestCancelled(requestId)) {
      console.debug(lp, 'the request has been cancelled, interrupt the loop');
      break;
    }

    if (resp) {
      lastFileInfo = resp.fullResponse as IFileInfoST;
      const { uploadedFiles, pageToken } = resp;

      files = [...files, ...uploadedFiles];
      if (callBackPerPage) {
        callBackPerPage(files);
      }
      if (pageToken) {
        reqParams = { ...reqParams, pageToken };
        // Continue takes you back to the beginning of the while loop
        console.debug(lp, 'there is a continuation token, continue');
        currentPageLoading += 1;
        continue;
      }
    }
    console.debug(lp, 'all files have been fetchted');
    allUploadedFilesFetched = true;
  }

  const lastProcessedFile = processLatestFile(lastFileInfo, timezone);
  console.debug(lp, 'the last processed file is: ', lastProcessedFile);

  return { files, lastProcessedFile };
};

// Get dates object, based on loadSince string
// Returns:
//    An object with from and until properties,
//    which are dates that define time interval.
//    This object is passed to the routes (reports, file uploads)
//    to get data in time interval specified in this object
export const getTimeIntervalDates = (
  loadSince: 'last week' | 'last two weeks' | 'last month' | 'all',
) => {
  let dates = {};

  switch (loadSince) {
    case 'last week':
      dates = { from: moment.utc().subtract(7, 'd').format(DATETIME_FORMAT) };
      break;
    case 'last two weeks':
      dates = { from: moment.utc().subtract(14, 'd').format(DATETIME_FORMAT) };
      break;
    case 'last month':
      dates = { from: moment.utc().subtract(30, 'd').format(DATETIME_FORMAT) };
      break;
    case 'all':
    default:
      break;
  }

  return dates;
};

// TODO:: Remove this function and refactor code to always use lodash isFunction
// Checks if a variable is a function
export const isFunction = (functionToCheck: any) =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

// Sanitize given string of values
// (separated by '\n', ',', ';' or ' ')
//  and return an array of those values
export const getSanitizedInputValues = (valuesString: string) =>
  valuesString
    .replaceAll('\n', ' ')
    .replaceAll(',', ' ')
    .replaceAll(';', ' ')
    .split(' ')
    .filter((value) => value);

export const isExecutingEmergencyLanding = (
  flightDomainStatus: FlightDomainStatusST,
  fleetStatusSummary: FleetStatusSummaryST,
) =>
  flightDomainStatus.lock_reason === LOCK_REASON.EMERGENCY_LAND &&
  flightDomainStatus.locked &&
  fleetStatusSummary.num_drones_flying > 0;

export const isExecutingReturnHome = (
  flightDomainStatus: FlightDomainStatusST,
  fleetStatusSummary: FleetStatusSummaryST,
) =>
  flightDomainStatus.lock_reason !== LOCK_REASON.EMERGENCY_LAND &&
  flightDomainStatus.locked &&
  fleetStatusSummary.num_drones_flying > 0;

export const getFlightDomainStatusColor = ({
  locked,
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
}: {
  locked: boolean;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
}) => {
  if (isExecutingEmergencyLanding) {
    return GROUND_CONTROL_STATUS_COLORS.EMERGENCY_COLOR;
  }
  if (isExecutingReturnHome || !locked) {
    return GROUND_CONTROL_STATUS_COLORS.UNSAFE_COLOR;
  }
  return GROUND_CONTROL_STATUS_COLORS.SAFE_COLOR;
};

/**
 * When this function is called in default case of a switch statement,
 * it will show an error if the switch statement is not exhaustive.
 * @param a action to be checked for exhaustive switch statement
 */
export const notEver = (a: never) => {
  /* do nothing */
};

/**
 * To be used at the top of reducer functions whose state contains sub-states that have their own reducers.
 * @param state state given to current reducer
 * @param action action given to current reducer
 * @param subStateName prop name of sub-state in current state
 * @param subReducer reducer for sub-state
 * @returns current state with reduced sub-state and action that excludes sub action type
 */
export const callSubReducer = <S, A, SSN extends keyof S, SA extends A>(
  state: S,
  action: A,
  subStateName: SSN,
  subReducer: (subState: S[SSN], act: SA) => S[SSN],
): [S, Exclude<A, SA>] => [
  { ...state, [subStateName]: subReducer(state[subStateName], action as SA) },
  action as Exclude<A, SA>,
];

/**
 * Type-checked function for mapping object entries.
 * @param kv Object with entries to be mapped.
 * @param f Mapping function.
 * @returns Array of f return type.
 */
export const mapEntries = <KeyT, ValT, ResT>(
  kv: { [key in KeyT as string]: ValT },
  f: (k: KeyT, v: ValT) => ResT,
): ResT[] => Object.entries(kv).map((v) => f(v[0] as KeyT, v[1] as ValT));

/**
 * Type-checked function for running forEach on object entries.
 * @param kv Object with entries to be mapped.
 * @param f forEach callback.
 */
export const forEachEntry = <ObjT extends {}>(
  obj: ObjT,
  f: (k: keyof ObjT, v: ObjT[keyof ObjT]) => void,
): void => Object.entries(obj).forEach((v) => f(v[0] as keyof ObjT, v[1] as ObjT[keyof ObjT]));

type EntriesReducer<ObjT extends {}, ResT> = (a: ResT, k: keyof ObjT, v: ObjT[keyof ObjT]) => ResT;
type ReduceObjectEntries = <ObjT extends {}, ResT>(
  o: ObjT,
  f: EntriesReducer<ObjT, ResT>,
  r: ResT,
) => ResT;

/**
 * Type-checked function for reducing object entries.
 * @param kv Object with entries to be reduced.
 * @param f Reducing function.
 * @param acc Reducing accumulator.
 * @returns Accumulator type.
 */
export const reduceObjectEntries: ReduceObjectEntries = (obj, f, acc) =>
  Object.entries(obj).reduce(
    (a, kv) => f(a, kv[0] as keyof typeof obj, kv[1] as typeof obj[keyof typeof obj]),
    acc,
  );

/**
 * Recurrence options for scheduling reports
 */
export type IntervalOption = {
  /**
   * Label for the option
   */
  label: IntervalLabel;
  /**
   * Is option enabled given selected time interval
   */
  enabled: boolean;
};
