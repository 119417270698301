// libraries
import React from 'react';
import { createRoot } from 'react-dom/client';

import Providers from './Providers';
import App from './App';

import * as serviceWorker from './serviceWorker';

import './index.css';
import { initSentry } from './common/sentry';

const container = document.getElementById('root');
const root = createRoot(container!);

initSentry();

root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
